import { Translations } from "@aidkitorg/i18n/lib";
import { useToast } from "@aidkitorg/component-library";
import { apiPath, get_deployment } from "./API";
import { SupportedLanguage } from "./Context";
import { useLocalizedStringsNoContext } from "./Localization";
import { offlineLogger } from "./utils";

const isLocalhost = process.env.NODE_ENV === 'development' && get_deployment() === 'postgres';


// gets all the things started for offline
async function warmup(options: { L: Translations, handleToast?: (params: Parameters<ReturnType<typeof useToast>['toast']>[0]) => void }) {
  const result = await fetch(new URL(
    '/offline/warm?' + new URLSearchParams({
      program: get_deployment(),
      apiPath: apiPath(),
      deviceName: localStorage.getItem('device_name')!,
    }),
    window.location.href
  ).toString(), {
    method: 'POST'
  });

  // this indicates warmup was likely 
  // attempted in offline mode,
  // so there's no point in warning
  // about an outdated worker.
  if(result.status === 204) {
    return;
  }
  
  const actualVersion = await result.text();
  if(actualVersion !== __SW_VERSION__ && options.handleToast) {
    console.log("outdated", actualVersion, __SW_VERSION__)
    options.handleToast({
      id: 'worker_outdated',
      duration: 3000,
      description: options.L.offline.worker_outdated
    })
  }
}

export async function register(options?: {lang?: SupportedLanguage, handleToast?: (params: Parameters<ReturnType<typeof useToast>['toast']>[0]) => void}) {
  const lang = options?.lang ?? 'en';
  const L = useLocalizedStringsNoContext(lang);
  
  // only runs in supported browsers
  if ("serviceWorker" in navigator) {
    if (navigator.storage) {
      const persisted = await navigator.storage.persist();
      offlineLogger.info("persistent storage enabled?", persisted);
    }

    const estimate = await navigator.storage.estimate();
    const storageLeft = (estimate.quota ?? 0) - (estimate.usage ?? 0);
    if (storageLeft <= 1_000_000 /* 1MB */) {
      if (options?.handleToast) {
        options.handleToast({
          description: L.offline.insufficient_storage,
          autoClose: false,
          variant: 'error',
        });
      }
      await unregister();
      return;
    } else if (storageLeft < 10_000_000 /* 10MB */) {
      if (options?.handleToast) {
        options.handleToast({
          description: L.offline.very_low_storage,
          autoClose: false,
        });
      }
    }
    else if (storageLeft < 100_000_000 /* 100MB */) {
      if (options?.handleToast) {
        options.handleToast({
          description: L.offline.low_storage,
          autoClose: false,
        });
      }
      
    }

    try {
      offlineLogger.warn('getting service worker ready');
      const registrations = await navigator.serviceWorker.getRegistrations();

      if (registrations.length > 0 && registrations.some(r => r.active?.state === 'activated')) {
        offlineLogger.dev.info("service worker already activated");
        await warmup({L: L, handleToast: options?.handleToast});
        return;
      }
    } catch (e) {
      offlineLogger.error("error getting service worker", e);
    }

    offlineLogger.debug("registering service worker");

    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      offlineLogger.info("public url not the same as the host");
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;

    if (isLocalhost) {
      offlineLogger.info("detected local dev mode");
      offlineLogger.debug("checking to ensure service worker file isn't corrupted");
      await checkValidServiceWorker(L, swUrl);
    } else {
      offlineLogger.debug(get_deployment());
      offlineLogger.debug(process.env.NODE_ENV);
      // Is not localhost. Just register service worker
      await registerValidSW(L, swUrl);
    }
  } else {
    await unregister();
  }
}

async function registerValidSW(L: Translations, swUrl: string) {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);
    if (registration.active?.state === 'activated') {
      await warmup({L: L});
    }
    if (registration.waiting) {
      offlineLogger.info("service worker waiting");
      registration.waiting.postMessage({ data: { action: 'skipWaiting' } });
      return;
    }
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker === null) {
        return;
      }
      offlineLogger.info("service worker state change", installingWorker.state);
      installingWorker.onstatechange = async () => {
        offlineLogger.info("service worker state change", installingWorker.state);
        if (installingWorker.state === 'activated') {
          await warmup({L: L});
        }
      };
    };
  } catch (error: any) {
    offlineLogger.error("Error during service worker registration:", error);
  }
}

async function checkValidServiceWorker(L: Translations, swUrl: string) {
  try {
    // Check if the service worker can be found. If it can't reload the page.
    const response = await fetch(swUrl, {
      headers: { "Service-Worker": "script" },
    });
    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get("content-type");
    if (
      response.status === 404 ||
      (contentType !== null && contentType.indexOf("javascript") === -1)
    ) {
      // No service worker found. Probably a different app. Reload the page.
      await navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    } else {
      // Service worker found. Proceed as normal.
      await registerValidSW(L, swUrl);
    }
  } catch (e) {
    offlineLogger.info(
      "No internet connection found. App is running in offline mode.",
      e
    );
  }
}

export async function unregister() {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready

      await registration.unregister();
      // wipe out caches to ensure proper unregistration.
      const keys = await caches.keys();
      offlineLogger.info("removing caches", keys);
      await Promise.allSettled(keys.map(k => caches.delete(k)));
    } catch (error: any) {
      offlineLogger.error(error.message);
    }
  }
}

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceSetup = void 0;
var neverthrow_1 = require("neverthrow");
var json_1 = require("../../util/json");
var request_1 = require("../../util/request");
var s3_1 = require("../../util/s3");
exports.DeviceSetup = {
    getSlugs: function (params) {
        var dependencies = [];
        var metadata = (0, json_1.safeParse)(params.infoDefs[params.targetField]['metadata'] || '{}');
        var fixedPhoneKey = metadata['fixed_phone'];
        if (fixedPhoneKey) {
            dependencies.push(fixedPhoneKey);
        }
        var slugs = [];
        slugs.push.apply(slugs, __spreadArray([], __read(['_url', '_error']), false));
        slugs.push.apply(slugs, __spreadArray([], __read(['imei', 'phone_number']), false));
        return (0, neverthrow_1.ok)({ slugs: slugs, dependencies: dependencies });
    },
    compute: function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var key, phone_number, imei, error, previousDecoded, decoded, emptyResults, results, value, urls, _a, _b, _c, _i, originalPath, v, uri, response, e_1, keys, phone, fixed_phone_key, isPhoneFixed;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        // Don't compute for imei or phone_number derived types.
                        if (['imei', 'phone_number'].indexOf(params.targetField) !== -1)
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        if (params.targetField.endsWith('_error'))
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        key = params.targetField.replace(/\_url$/, '');
                        phone_number = "phone_number";
                        imei = "imei";
                        error = key + "_error";
                        previousDecoded = params.previousInfo[key + "_url"];
                        decoded = false;
                        emptyResults = (_d = {},
                            _d[key + "_url"] = "",
                            _d[imei] = "",
                            _d[phone_number] = "",
                            _d[error] = "",
                            _d);
                        results = __assign({}, emptyResults);
                        value = params.newInfoKeys[key];
                        // If we dont have a value, set all our derived results to empty strings
                        if (!value)
                            return [2 /*return*/, (0, neverthrow_1.ok)(emptyResults)];
                        return [4 /*yield*/, (0, s3_1.getDocumentURLs)(value.split(',').filter(function (v) { return !!v.trim(); }))];
                    case 1:
                        urls = _e.sent();
                        console.log("URLs:", urls);
                        _a = urls;
                        _b = [];
                        for (_c in _a)
                            _b.push(_c);
                        _i = 0;
                        _e.label = 2;
                    case 2:
                        if (!(_i < _b.length)) return [3 /*break*/, 8];
                        _c = _b[_i];
                        if (!(_c in _a)) return [3 /*break*/, 7];
                        originalPath = _c;
                        v = urls[originalPath];
                        if (!v) {
                            results[error] = "Could not get document URL";
                            return [3 /*break*/, 7];
                        }
                        ;
                        uri = '';
                        _e.label = 3;
                    case 3:
                        _e.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, (0, request_1.request)("GET", "https://g7xwsyz2m1.execute-api.us-east-2.amazonaws.com/default/qrdecode?url=" + v, {
                                headers: {
                                    "content-type": "application/json"
                                }
                            }, {})];
                    case 4:
                        response = _e.sent();
                        console.log(response);
                        uri = response.message;
                        return [3 /*break*/, 6];
                    case 5:
                        e_1 = _e.sent();
                        console.log("Error decoding QR code", e_1);
                        results[error] = e_1 instanceof Error ? e_1.message : e_1.toString();
                        return [3 /*break*/, 7];
                    case 6:
                        if (!uri || uri === 'Internal Server Error') {
                            results[error] = "Error processing QR code";
                            return [3 /*break*/, 7];
                        }
                        results[key + "_url"] = uri;
                        decoded = true;
                        keys = new URLSearchParams(uri.split('?').pop());
                        if (!keys.get('phone') || !keys.get('imei')) {
                            // invalid qr code
                            results[key + "_error"] = "Code returned no phone or IMEI";
                            return [3 /*break*/, 8];
                        }
                        results[imei] = keys.get('imei').replace(/[^0-9]/g, '');
                        if (keys.get('phone') !== '[None]') {
                            phone = keys.get('phone').replace(/[^0-9]/g, '');
                            fixed_phone_key = JSON.parse(params.infoDefs[key].metadata || '{}').get('fixed_phone');
                            if (fixed_phone_key) {
                                isPhoneFixed = params.previousInfo[fixed_phone_key];
                                if (!isPhoneFixed || isPhoneFixed !== 'yes') {
                                    results[phone_number] = phone;
                                }
                            }
                            else {
                                results[phone_number] = phone;
                            }
                        }
                        if (results[phone_number] && results[imei]) {
                            results[error] = "";
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        _i++;
                        return [3 /*break*/, 2];
                    case 8:
                        if (!decoded) {
                            results[key + "_url"] = "";
                        }
                        return [2 /*return*/, (0, neverthrow_1.ok)(results)];
                }
            });
        });
    }
};

import { useContext, useEffect, useState } from "react";
import { usePost } from "./API";
import { snakeToEnglish } from "./Util";
import { useLocalizedStrings } from "./Localization";
import InterfaceContext, { PublicConfigurationContext } from "./Context";
import { Text, interfaceNumber } from "@aidkitorg/types/lib/survey";

interface Application {
  uid: string,
  [key: string]: string | string[];
}

export function ApplicantIdentities(props: {
  applicant: string,
  path: string,
}) {
  const [currentApplication, setCurrentApplication] = useState<Application>({} as Application);
  const [otherApplications, setOtherApplications] = useState([] as Application[]);
  const [title, setTitle] = useState<Text>({} as Text);
  const getSharedKeyApplicants = usePost("/applicant/get_shared_key_apps");
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
    
  const publicConfig = useContext(PublicConfigurationContext);
  const aOrApplicant = interfaceNumber(publicConfig.interface?.version) > 0 ? 'a' : 'applicant';

  function formatValue(val: string | string[]) {
    if (!val || Array.isArray(val)) {
      return val;
    }
    const dateFormatRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    if (dateFormatRegex.test(val)) {
      return val;
    }
    const date = new Date(val);
    if (!isNaN(date.getTime())) {
      return date.toLocaleString();
    }

    return val;
  }

  useEffect(() => {
    (async () => {
      const res = await getSharedKeyApplicants({
        applicant: props.applicant,
        path: props.path
      });

      if (!res.apps) {
        setCurrentApplication({} as Application);
        setOtherApplications([]);
        setTitle({} as Text);
        return;
      }
      const currApp = res.apps.find(a => a.uid === props.applicant);
      setCurrentApplication(currApp);

      // figure out which values are shared between other applicants and the current applicant.
      let otherApps = res.apps.filter(a => a.uid !== props.applicant);
      const withSharedVals = otherApps.map(a => {
        const sharedVals : string[] = (Object.keys(a).map(k => currApp[k] === a[k] ? k : '')).filter(f => !!f);

        return {
          ...a,
          shared_vals: sharedVals
        }
      });
      setOtherApplications(withSharedVals);
      setTitle(res.title as Text);
    })()
  }, [props.applicant, props.path]);

  return (
    <div className="bg-gray-100 h-full px-8 mb-3">
      <header className="mb-8">
        <div className="max-w-7xl mx-auto py-2 px-2 sm:px-6 lg:px-8 border-t-0 border-l-0 border-r-0 border-b-3 border-solid border-gray-300 gap-2 sm:gap-4">
          <div className="text-4xl mt-2 ml-4 text-black font-medium">
            {title[context.lang as keyof typeof title]}
          </div>
        </div>
      </header>

      <h4 className="m-4">{L.app_identities.current_application}</h4>
      {currentApplication && 
        <div className="flex items-center justify-between m-4 rounded-lg shadow bg-white ">
          <div className="w-full ">
            <div className="flex items-start gap-x-3 bg-gray-50 p-3 rounded-lg">
              <span className="font-semibold" >{L.app_identities.application_uid}: <a href={`/${aOrApplicant}/${currentApplication.uid}`}>{currentApplication.uid}</a></span>
            </div>
            <div className="mt-3">
              {Object.keys(currentApplication).map(key => {
                if (key !== 'uid') {
                  return (
                    <div className="mt-1 leading-5 text-gray-700 px-3">
                      <p className="whitespace-nowrap">
                        <span className="font-semibold">{snakeToEnglish(key)}:</span> {formatValue(currentApplication[key])}
                      </p>
                    </div>)
                }
              })}
            </div>
          </div>
        </div>}
      <h4 className="mt-8 ml-4">{L.app_identities.matched_applications}</h4>
      <ul role="list">
        {otherApplications?.map((app) => (
          <li key={app.uid}>
            <div className="flex items-center justify-between m-4 rounded-lg shadow bg-white ">
              <div className="w-full ">
                <div className="flex items-start gap-x-3 bg-gray-50 p-3 rounded-lg">
                  <span className="font-semibold" >{L.app_identities.application_uid}: <a href={`/${aOrApplicant}/${app.uid}`}>{app.uid}</a></span>
                </div>
                <div className="flex justify-between">
                  <div className="mt-3">
                    {Object.keys(app).map(key => {
                      if (key !== 'uid' && key !== 'shared_vals') {
                        return (
                          <div className="mt-1 leading-5 text-gray-700 px-3">
                            <p className="whitespace-nowrap">
                              <span className="font-semibold">{snakeToEnglish(key)}:</span> {formatValue(app[key])}
                            </p>
                          </div>)
                      }
                    })}
                  </div>
                  {(Array.isArray(app['shared_vals']) && app['shared_vals'].length > 0) && 
                                <div className="border-t-0 border-r-0 border-b-0 border-l-1 border-solid border-gray-100 rounded my-2">
                                  <div className="px-4 font-semibold" >{L.app_identities.overlap_with_current_app}:</div>
                                  {(app['shared_vals']).map(key => {
                                    return (
                                      <div className="mt-1 leading-5 text-gray-700 px-4">
                                        <span className="">{snakeToEnglish(key)}</span>
                                      </div>)
                                  })}
                                </div>}
                </div>

              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDocumentURLs = exports.getBankRoutingNumberMap = exports.getS3 = void 0;
var client_s3_1 = require("@aws-sdk/client-s3");
var s3_request_presigner_1 = require("@aws-sdk/s3-request-presigner");
var getS3 = function () { return new client_s3_1.S3(__assign({}, (process.env.NODE_ENV === 'development' ? {
    endpoint: 'http://127.0.0.1:4323',
    sslEnabled: false,
    credentials: {
        accessKeyId: 'test',
        secretAccessKey: 'testtesttest'
    }
} : {}))); };
exports.getS3 = getS3;
/* Used to read S3 objects as strings */
var streamToString = function (stream) {
    return new Promise(function (resolve, reject) {
        var chunks = [];
        stream.on("data", function (chunk) { return chunks.push(chunk); });
        stream.on("error", reject);
        stream.on("end", function () { return resolve(Buffer.concat(chunks).toString("utf8")); });
    });
};
function getBankRoutingNumberMap() {
    return __awaiter(this, void 0, void 0, function () {
        var s3, options, command, file, _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    s3 = (0, exports.getS3)();
                    options = { Bucket: 'roboscreener-resources', Key: 'routing-to-bank-name.json' };
                    command = new client_s3_1.GetObjectCommand(options);
                    return [4 /*yield*/, s3.send(command)];
                case 1:
                    file = _c.sent();
                    _b = (_a = JSON).parse;
                    return [4 /*yield*/, streamToString(file.Body)];
                case 2: return [2 /*return*/, _b.apply(_a, [(_c.sent()) || '{}'])];
            }
        });
    });
}
exports.getBankRoutingNumberMap = getBankRoutingNumberMap;
function getDocumentURLs(paths) {
    return __awaiter(this, void 0, void 0, function () {
        var results, s3, promises, _loop_1, paths_1, paths_1_1, path_1;
        var e_1, _a;
        var _this = this;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    results = {};
                    s3 = (0, exports.getS3)();
                    promises = [];
                    _loop_1 = function (path_1) {
                        if (path_1.indexOf('https://api.twilio') !== -1) {
                            results[path_1] = path_1;
                        }
                        if (!path_1 || path_1.indexOf('https') === -1)
                            return "continue";
                        var _c = __read(path_1.match(/https:\/\/([^\.]+).s3.(us-east-2.)?amazonaws.com\/(.*)/)), match = _c[0], bucket = _c[1], region = _c[2], key = _c[3], rest = _c.slice(4);
                        if (['aidkit-documents', 'workersfund-documents'].indexOf(bucket) === -1) {
                            return "continue";
                        }
                        var command = new client_s3_1.GetObjectCommand({
                            Bucket: bucket,
                            Key: decodeURI(key)
                        });
                        promises.push((function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = results;
                                        _b = path_1;
                                        return [4 /*yield*/, (0, s3_request_presigner_1.getSignedUrl)(s3, command, { expiresIn: 300 })];
                                    case 1:
                                        _a[_b] = _c.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })());
                    };
                    try {
                        for (paths_1 = __values(paths), paths_1_1 = paths_1.next(); !paths_1_1.done; paths_1_1 = paths_1.next()) {
                            path_1 = paths_1_1.value;
                            _loop_1(path_1);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (paths_1_1 && !paths_1_1.done && (_a = paths_1.return)) _a.call(paths_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    return [4 /*yield*/, Promise.all(promises)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, results];
            }
        });
    });
}
exports.getDocumentURLs = getDocumentURLs;
if (process.env.method === '__main__') {
    // call S3 to retrieve upload file to specified bucket
    var uploadParams = { Bucket: 'roboscreener-resources', Key: '', Body: '' };
    var file = './lib/resources/routing-to-bank-name.json';
    // import * as bankMap from '../resources/routing-to-bank-name.json';
    // uploadParams.Body = JSON.stringify(bankMap);
    var path = require('path');
    uploadParams.Key = path.basename(file);
    // call S3 to retrieve upload file to specified bucket
    var s3_1 = (0, exports.getS3)();
    var command_1 = new client_s3_1.PutObjectCommand(uploadParams);
    (function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, s3_1.send(command_1)];
                case 1:
                    response = _a.sent();
                    console.log(response.ETag, response);
                    return [2 /*return*/];
            }
        });
    }); })();
}

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useAPI, useAPIPost } from "../API";
import { useLocalizedStrings } from "../Localization";
import { Link } from "react-router-dom";

export type ICampaign = {
  id: any,
  name: string,
  query: string,
  metadata: string,
  active: boolean
};

type ApplicantWithPhone = {
  uid: string,
  legal_name: string,
  phone_number: string,
  language: string
};

function CampaignRow(c: ICampaign) {
  const L = useLocalizedStrings();

  const exec = useAPIPost("/campaign/" + c.id + "/pull");
  const embark = useAPIPost("/campaign/" + c.id + "/embark");

  const metadata = JSON.parse(c.metadata || '{}');

  const [applicants, setApplicants] = useState<any[]>([]);
  const [sent, setSent] = useState<string>("");

  async function pullApplicants() {
    const result = await exec({});

    if (result && result.data) {
      setApplicants(result.data);
    }
  };

  async function sendCampaign() {
    const result = await embark({applicants});

    if (result && result.status) {
      setSent(result.status);
    }
  }

  return (
    <tr key={`campaign-${c.id}`}>
      <td>{c.name}</td>
      <td>{c.metadata}</td>
      <td>{c.query}</td>
      <td>
        {applicants.length === 0 && (
          <Button key={`pull-${c.id}`} variant="link" onClick={() => pullApplicants()}>
            {L.campaigns.pull_applicants}
          </Button>
        )}
        {applicants.length > 0 && applicants.length < 20 && (
          <>
            <ul>
              {applicants.map((a: ApplicantWithPhone) => {
                return (
                  <li>
                    <Link key={`${c.id}-applicant-${a.uid}`} title={a.phone_number + ', ' + a.language} to={'/applicant/' + a.uid}>{a.legal_name}</Link>
                  </li>
                );
              })}
            </ul>
          </>
        )}
        {applicants.length >= 20 && (
          <>
            {L.campaigns.number_of_applicants}: {applicants.length}
          </>
        )}
      </td>
      <td>
        {Object.keys(metadata).map((k: any) => {
          if (k.indexOf("Content") >= 0) {
            return (
              <>
                <strong>{k}</strong>:
                <p>{metadata[k]}</p>
              </>
            );
          }
          return <></>;
        })}
      </td>
      <td>
        {applicants.length === 0 && (
          <></>
        )}
        {applicants.length > 0 && sent.length === 0 && (
          <Button variant="link" onClick={() => sendCampaign()}>
            {L.campaigns.queue_messaging}
          </Button>
        )}
        {sent}
      </td>
    </tr>
  );
}

function CampaignsPage() {
  const L = useLocalizedStrings();

  const campaigns = useAPI("/campaigns/get_campaign_list");

  return (
    <>
      <div className="container my-4 p-4">
        <div className="row">
          <div className="col" key="campaign-column">
            <table className="table table-responsive" key="table">
              <thead key="thead">
                <tr key="table-header">
                  <th>{L.campaigns.campaign_name}</th>
                  <th>{L.campaigns.metadata}</th>
                  <th>{L.campaigns.query}</th>
                  <th>{L.campaigns.applicants}</th>
                  <th>{L.campaigns.content}</th>
                  <th>{L.campaigns.queue}</th>
                </tr>
              </thead>
              <tbody>
                {campaigns.data && campaigns.data.map((c: ICampaign) => {
                  if (!c.active) return (<></>);
                  return React.createElement(CampaignRow, {
                    ...c
                  });
                })}
                {campaigns.data?.length === 0 && (
                  <tr key="no-campaigns">
                    <td colSpan={5}>
                      {L.campaigns.no_campaigns_found}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );

}

export default CampaignsPage;
import { useContext } from "react";
import { UserInfoContext } from "../Context";

/**
 * Assuming we have a user info context, returns if the user is allowed by any or all of the perms specified
 */
export const useUserAllowed = (perms: string[], kind?: "any" | "all") => {
  const user = useContext(UserInfoContext);

  const allowed = !perms || ((!kind || kind === 'any') 
    ? (user?.tags ?? []).some((r) => perms.includes(r)) 
    : (user?.tags ?? []).every(r => perms.includes(r)));

  return allowed;
}
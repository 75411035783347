"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.typeValueBlocked = exports.evalDistroConditional = exports.evalDistroExpression = exports.evalConditional = exports.createClockProxy = exports.getDerivedTypesFromArray = exports.getUpdatedKeys = exports.combineChangesWithPreviousInfo = void 0;
var blocklist_1 = require("../resources/blocklist");
var derived_1 = require("./derived");
var wrapper_1 = require("./wrapper");
var quickJSModule_1 = require("../compute/quickJSModule");
var expr_to_js_1 = require("@aidkitorg/types/lib/translation/expr_to_js");
/** Method to combine previousInfo with changedKeys */
function combineChangesWithPreviousInfo(infoDefs, previousInfo, changedKeys) {
    var out = __assign({}, previousInfo);
    var hasUpdates = false;
    for (var k in changedKeys) {
        var doUpdate = false;
        if (previousInfo[k] !== undefined) {
            if (previousInfo[k] !== changedKeys[k]) {
                doUpdate = true;
            }
        }
        else {
            doUpdate = true;
        }
        if (doUpdate) {
            out[k] = changedKeys[k];
            hasUpdates = true;
        }
    }
    return out;
}
exports.combineChangesWithPreviousInfo = combineChangesWithPreviousInfo;
function getUpdatedKeys(infoDefs, previousInfo, allKeysAfterUpdate) {
    var out = {};
    var previousInfoKeys = Object.keys(previousInfo);
    for (var k in allKeysAfterUpdate) {
        // Don't update if the key is 'uid'
        if (['uid', 'screener_org', 'screener_uid', 'org_uid'].indexOf(k) !== -1)
            continue;
        if (previousInfoKeys.indexOf(k) < 0) {
            // Add the key even if it's an empty string. (unless it's an _error, don't polute)
            if (k.endsWith('_error') && allKeysAfterUpdate[k] === '' && !infoDefs[k]) {
                continue;
            }
            out[k] = allKeysAfterUpdate[k] || '';
        }
        else {
            if (previousInfo[k] !== allKeysAfterUpdate[k]) {
                // If the value changed, set it.
                out[k] = allKeysAfterUpdate[k] || '';
            }
        }
    }
    ;
    return out;
}
exports.getUpdatedKeys = getUpdatedKeys;
// 
function getDerivedTypesFromArray(arr) {
    return arr.filter(function (val) {
        if (derived_1.DERIVED_TYPES.indexOf(val) !== -1)
            return true;
        return false;
    });
}
exports.getDerivedTypesFromArray = getDerivedTypesFromArray;
function createClockProxy(clock) {
    var proxy = new Proxy(Date, {
        construct: function (target, argArray) {
            if (argArray.length === 0) {
                return clock;
            }
            else {
                return target;
            }
        }
    });
    return proxy;
}
exports.createClockProxy = createClockProxy;
function evalConditional(conditional, info, org, screener, options) {
    return __awaiter(this, void 0, void 0, function () {
        var wrappedResult;
        return __generator(this, function (_a) {
            wrappedResult = (0, wrapper_1.wrappedFn)("return (function(info, org, screener, view_info) { const out = " + conditional + "; return out });", null, {
                args: [info, org, screener, (options === null || options === void 0 ? void 0 : options.viewInfo) || {}],
                mockDate: options === null || options === void 0 ? void 0 : options.customClockTimestamp
            });
            if (wrappedResult === null || wrappedResult.error) {
                console.error("Error processing conditional:", conditional, "returning true: ", wrappedResult);
                return [2 /*return*/, true];
            }
            return [2 /*return*/, !!wrappedResult.result];
        });
    });
}
exports.evalConditional = evalConditional;
function evalDistroExpression(expression, info, useQuickJS) {
    return __awaiter(this, void 0, void 0, function () {
        var expr, wrappedResult, _a, deps, res, err;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    expr = (0, expr_to_js_1.CompileExpressionToJS)(expression);
                    if (!useQuickJS) return [3 /*break*/, 2];
                    return [4 /*yield*/, quickJSModule_1.QuickJSModule.computeWithDeps(expr, {
                            info: info
                        })];
                case 1:
                    _a = __read.apply(void 0, [_b.sent(), 3]), deps = _a[0], res = _a[1], err = _a[2];
                    wrappedResult = { result: res, error: err };
                    return [3 /*break*/, 3];
                case 2:
                    wrappedResult = (0, wrapper_1.wrappedFn)("return (function(info, org, screener, view_info) { const out = " + expr + "; return out });", null, {
                        args: [info, {}, {}, {}]
                    });
                    _b.label = 3;
                case 3:
                    if (wrappedResult === null || wrappedResult.error) {
                        console.error("Error processing distro expression:", expr, "returning true: ", wrappedResult);
                        return [2 /*return*/, true];
                    }
                    return [2 /*return*/, wrappedResult.result];
            }
        });
    });
}
exports.evalDistroExpression = evalDistroExpression;
function evalDistroConditional(conditional, info, useQuickJS) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, evalDistroExpression(conditional, info, useQuickJS)];
                case 1: return [2 /*return*/, !!(_a.sent())];
            }
        });
    });
}
exports.evalDistroConditional = evalDistroConditional;
/** This function may also notify someone that an abuse key is trying to be used */
function typeValueBlocked(type, value) {
    if (['Phone Number', 'Address'].indexOf(type) < 0)
        return false;
    if (type === 'Phone Number' && blocklist_1.DONOTSERVE_PHONES.indexOf(value) !== -1) {
        console.warn("Trying to use value in donotserve_phnoes: " + value);
        return true;
    }
    if (type === 'Address' && blocklist_1.DONOTSERVE_ADDRESSES.indexOf(value) !== -1) {
        console.warn("Trying to use value in donotserve_addresses: " + value);
        return true;
    }
    return false;
}
exports.typeValueBlocked = typeValueBlocked;

import { useEffect, useState } from 'react';
import { useAPIPost, get_rs_host } from '../API';

export function useSurveyTopology(props: any) {

  const [dmap, setDmap] = useState({} as Record<string, string[]>);
  const [pdmap, setPdmap] = useState({} as Record<string, string[]>);

  const [depsError, setDepsError] = useState('');

  const [loadingDeps, setLoadingDeps] = useState(true); // We will load immediately.

  const [queue, setQueue] = useState({} as Record<string, number>);

  const getDeps = useAPIPost(get_rs_host() + "/get_deps", {
    includeDeploymentKeyInData: true,
    includeTokenInData: true
  });

  useEffect(() => {
    setLoadingDeps(true);
    (async () => {

      const response: { 
        value?: { 
          queue: number[], 
          vertices: string[], 
          pdmap: Record<string, string[]>, 
          dmap: Record<string, string[]> 
        }
        error?: string
      } = await getDeps({} as Record<string,string>);

      console.log("Got response: ", response);
      if (response.value) {
        console.log("Setting dmap to: ", response.value.dmap);
        setDmap(response.value.dmap);
        setPdmap(response.value.pdmap);
        const qu = {} as Record<string, number>;
        response.value.queue.forEach((x: number, index: number) => {
          qu[response.value!.vertices[x]] = index;
        })
        setQueue(qu);
      }
      if (response.error) {
        setDepsError(response.error);
      }
    })();

    setLoadingDeps(false);
  }, [])

  return {
    dmap,
    pdmap,
    depsError,
    loadingDeps,
    queue
  }
}
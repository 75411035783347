import React, { useState, useEffect } from "react";
import QRCode from 'qrcode.react';
import { useLocalizedStrings } from "./Localization";

function DeviceCodePage() {
  const L = useLocalizedStrings();

  const [imei, setImei] = useState('')
  const [sim, setSim] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    setImei('' + Math.round(10000000*Math.random()));
    setSim('' + Math.round(10000000*Math.random()));
  }, []);

  return (
    <div>
      <br />
      <br />
      <div>
        <span>{L.device.phone_number}</span>&nbsp;
        <input value={phone} onChange={(e) => setPhone(e.target.value.replace(/\D/g,'').substring(0, 10))} />
      </div>
      <br />
      <QRCode value={window.location.protocol + "//" + window.location.host + "/device_setup?imei=[" + imei +"]&sim=[" + sim + "]&phone=[" + phone + "]"} />
    </div>
  );
}

export default DeviceCodePage;
import { useToast } from "@aidkitorg/component-library";
import { handleDataError } from "../../API";

export function handleErrors(data: any, handleToast: (payload: Parameters<ReturnType<typeof useToast>['toast']>[0]) => void): void {
  if (data && data.error) {
    switch (data.error) {
      case 'Not authorized':
      case "ld2_session_empty_please_start_over":
      case "ld2_link_expired":
      case "ld2_session_expired":
      case "ld2_not_authorized":
        // let these errors pass through and be handled by LivenessDetectionSessionPage
        // this prevents unnecessary toast errors
        return;
      default:
        // other errors should get handled normally
        return handleDataError(data, handleToast);
    }
  }
}
import { useContext } from 'react';
import InterfaceContext, { PublicConfigurationContext } from '../Context';
import { useLocalizedStrings } from '../Localization';

const primarySubdomain = window.location.hostname.split('.')[0];

export function SupportButton(props:{ Metadata:string }) {
  const L = useLocalizedStrings();
  const { lang } = useContext(InterfaceContext);
  const { Metadata='{}' } = props;
  const { messageTextOverride, buttonTextOverride, supportEmailOverride } = JSON.parse(Metadata) || {};
  const needAssistanceText = messageTextOverride?.[lang] || L.support.need_assistance;
  const contactSupportText = buttonTextOverride?.[lang] || L.support.contact_support;    
  const supportEmailAddress = supportEmailOverride || `support@${primarySubdomain}.aidkit.org`;

  const supportEmailURL = new URL(`mailto:${supportEmailAddress}`);
  supportEmailURL.searchParams.set('subject', L.support.email_subject);
  supportEmailURL.searchParams.set('body', `${L.support.email_body}`);

  return <div className="flex flex-wrap gap-4">
    <h4>{needAssistanceText}</h4>
    <a 
      className="inline-flex items-center -mt-2 px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 hover:no-underline focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" 
      href={supportEmailURL.href}>
      {contactSupportText}
    </a>
  </div>;
} 
// This is used to consistently, randomly order the choices to select questions
export class LinearCongruentialGenerator {
  private seed: number;

  constructor(seed: number) {
    this.seed = seed;
  }

  // Generate a pseudo-random number between 0 and 1
  nextFloat(): number {
    // Parameters for the generator (Java's java.util.Random)
    const a = 1664525;
    const c = 1013904223;
    const m = 4294967296; // 2^32

    this.seed = (a * this.seed + c) % m;
    return this.seed / m;
  }
}

export function hash(base: number, extra: string): number {
  let hashValue = base;
  for (let i = 0; i < extra.length; i++) {
    const char = extra.charCodeAt(i);
    hashValue = ((hashValue << 5) - hashValue) + char;
    hashValue = hashValue >>> 0; // Convert to a 32-bit integer
  }
  return hashValue;
}

// Pseudo-randomly reorder an array based on a provided seed and target field
// This is used to randomize the order of choices in a Select question
export function fisherYatesShuffle<T>(array: T[], seed: number, targetField: string): T[] {
  const questionSpecificSeed = hash(seed, targetField);
  const rng = new LinearCongruentialGenerator(questionSpecificSeed);
  const result = [...array];

  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(rng.nextFloat() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }

  return result;
}
import React, { useCallback, useContext, useEffect, useState } from "react";
import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { languageContent } from "../Util";
import { QuestionProps } from "./Props";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

export function DateQuestion(props: QuestionProps) {
  const prev = (props.info[props["Target Field"]!] || '').match(/^(01|02|03|04|05|06|07|08|09|10|11|12)\/([0-3][0-9])\/((19|20)[0-9][0-9])$/);
  const context = useContext(InterfaceContext);

  const L = useLocalizedStrings();
  const [month, setMonth] = React.useState(prev ? prev[1] : '--');
  const [day, setDay] = React.useState(prev ? prev[2] : '');
  const [year, setYear] = React.useState(prev ? prev[3] : '');
  const targetField = props['Target Field']!;

  const checkValid = (month: string, day: string, year: string) => {
    const dayValid = ['01','03','05','07','08','10','12'].includes(month) ? 
      !!day.match(/^(0[1-9]|[12]\d|3[01])$/) :  // Days 01-31 for months with 31 days
      month === '02' ? 
        parseInt(year || '0') % 4 === 0 ? 
          !!day.match(/^(0[1-9]|1\d|2[0-9])$/) :  // Days 01-29 for February in a leap year
          !!day.match(/^(0[1-9]|1\d|2[0-8])$/) :  // Days 01-28 for February in a non-leap year
        !!day.match(/^(0[1-9]|[12]\d|30)$/);  // Days 01-30 for months with 30 days
  
    const yearValid = !!year.match(/^(19|20|99)[0-9][0-9]$/);
  
    const allValid = dayValid && yearValid && month !== '--';

    return allValid;
  }
  const content = useModularMarkdown({
    content: props[languageContent(context.lang)] || '',
    info: props.info,
    replacer: (str) => str.replace(' **\n', '**\n').replace('^** ', '**')
  });
  
  const [allValid, setAllValid] = useState(false);
  
  const checkAndUpdate = useCallback((day: string, month: string, year: string, saveInfo: boolean) => {
    setDay(day);
    setMonth(month);
    setYear(year);

    const allValid = checkValid(month, day, year);
    setAllValid(allValid);

    if (saveInfo) {
      if (allValid) {
        props.setInfoKey(targetField, `${month}/${day}/${year}`, true, false);
      } else {
        props.setInfoKey(targetField, '', false, false);
      }
    }
  }, [targetField, props.setInfoKey]);

  let formatted = false;
  if ((props["Additional Options"] || []).indexOf("Formatted") !== -1) {
    formatted = true;
  }

  useEffect(() => {
    if (prev) {
      checkAndUpdate(prev[2], prev[1], prev[3], false);
    }
  }, [prev, checkAndUpdate])

  return <fieldset>
    <legend>
      {formatted ? content : 
        <b>{props[languageContent(context.lang)]}<br /></b>}
    </legend>
    <div className="flex flex-col sm:flex-row">
      <div>
        <label htmlFor={"month_" + targetField}>{L.months.month}:&nbsp;</label>
        <select
          id={"month_" + targetField}
          name={"date_" + targetField}
          className={"appearance-none mt-1 mr-2 shadow-sm inline-block w-42 sm:w-36 pl-3 bg-white pr-10 py-2 text-black text-base border-2 border-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" +
              (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
                " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}
          value={month}
          onChange={(e) => {
            const m = e.target.value;
            checkAndUpdate(day, m, year, true);
          }}>
          <option value="--" disabled hidden>{L.months.month}</option>
          <option value="01">{L.months.jan}</option>
          <option value="02">{L.months.feb}</option>
          <option value="03">{L.months.mar}</option>
          <option value="04">{L.months.apr}</option>
          <option value="05">{L.months.may}</option>
          <option value="06">{L.months.jun}</option>
          <option value="07">{L.months.jul}</option>
          <option value="08">{L.months.aug}</option>
          <option value="09">{L.months.sep}</option>
          <option value="10">{L.months.oct}</option>
          <option value="11">{L.months.nov}</option>
          <option value="12">{L.months.dec}</option>
        </select>
      </div>
      <div>
        <label htmlFor={"day_" + targetField}>{L.admin.invoices.day}:&nbsp;</label>
        <input
          id={"day_" + targetField}
          className={"max-w-lg mr-2 inline-block ml-2 w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
                (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
                  " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}
          placeholder="DD"
          pattern="([0-2]\d|3[0-1])"
          value={day}
          onChange={(e) => {
            const d = e.target.value.slice(0,2);
            checkAndUpdate(d, month, year, true);
          }}
          onBlur={(e) => {
            if (e.target.value.length === 1) {
              checkAndUpdate('0' + e.target.value, month, year, true);
            }
          }}
        />
      </div>
      <span className="whitespace-nowrap">
        <label htmlFor={"year_" + targetField}>{L.admin.invoices.year}:&nbsp;</label>&nbsp;<input
          id={"year_" + targetField}
          pattern="[1-2][0-9]{3}"
          className={"max-w-lg inline-block w-16 shadow-sm border-solid p-2 ml-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
                (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
                  " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}
          placeholder="YYYY"
          value={year}
          onChange={(e) => {
            const y = e.target.value.slice(0,4);
            checkAndUpdate(day, month, y, true);
          }}
        />
      </span>
    </div>

  </fieldset>
}
import { SupportedColor } from "./Color";

export function Badge(props: {
  children: React.ReactNode,
  color: SupportedColor,
  extraClasses?: string,
  border?: SupportedColor
}) {

  return <span className={`${props.extraClasses ? 
    props.extraClasses : 
    'bg-gray-100 text-gray-800 dark:bg-gray-200 text-gray-800'} 
        ${BadgeColors(props.color)} text-xs font-semibold px-2.5 py-0.5 rounded`}>
    {props.children}
  </span>
}

function BadgeColors(color: SupportedColor) {
  return {
    red: 'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800',
    blue: 'bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800',
    green: 'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-800',
    indigo: 'bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-800',
    gray: 'bg-gray-100 text-gray-800 dark:bg-gray-200 dark:text-gray-800',
    yellow: 'bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-800',
    white: 'bg-white text-gray-800 dark:bg-gray-200 dark:text-gray-800'
  }[color];
}

export function AdminOnlyBadge(props: any) {
  return <Badge color="gray">Admin Only</Badge>
}
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";
import { useLocalizedStrings } from "../Localization";

export function HeroNumber(props: {
  value: string,
  suffix?: string,
  prefix?: string,
  valueToCompare?: string,
  useCommas?: boolean,
  decimalPlaces?: number,
}) {
  const L = useLocalizedStrings();
  const {
    value,
    suffix = '',
    prefix = '',
    valueToCompare,
    useCommas = false,
    decimalPlaces = 2,
  } = props;
  const potentiallyStale = !!valueToCompare && valueToCompare !== value;

  const getFormattedValue = (value: string): string => {
    const formattedValue = useCommas
      ? new Intl.NumberFormat('en-US', {
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces,
      }).format(parseFloat(value))
      : parseFloat(value).toFixed(decimalPlaces);
    
    return `${prefix}${formattedValue}${suffix}`;
  };

  return <div>
    <dd className="mt-1 text-3xl font-semibold text-gray-900">
      {getFormattedValue(value)}{
        potentiallyStale && <sup><ExclamationCircleIcon className="h-6 w-6 text-yellow-400" /></sup>
      }
    </dd>
    {potentiallyStale &&
            <dd className="-mt-3 text-gray-300">
              ({L.online}:&nbsp;{getFormattedValue(valueToCompare)})
            </dd>
    }
  </div>
}

/*
 * Inline SVGs
 */

function CardFront(props: any) {
  return <svg width="820" height="560" {...props} viewBox="0 0 820 560" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M864 -174H-44V734H864V-174ZM85 52C62.9086 52 45 69.9086 45 92V468C45 490.091 62.9086 508 85 508H735C757.091 508 775 490.091 775 468V92C775 69.9086 757.091 52 735 52H85Z" fill="white"/>
    <rect x="47" y="54" width="726" height="452" rx="38" stroke="black" strokeWidth="4"/>
    <rect x="342" y="179" width="195" height="19" fill="#D9D9D9"/>
    <rect x="342" y="253" width="179" height="15" fill="#D9D9D9"/>
    <rect x="342" y="285" width="179" height="15" fill="#D9D9D9"/>
    <rect x="342" y="410" width="179" height="15" fill="#D9D9D9"/>
    <rect x="342" y="444" width="99" height="15" fill="#D9D9D9"/>
    <rect x="546" y="444" width="99" height="15" fill="#D9D9D9"/>
    <rect x="342" y="218" width="160" height="15" fill="#D9D9D9"/>
    <rect x="82" y="98" width="646" height="42" fill="#D9D9D9"/>
    <circle cx="197.5" cy="280.5" r="61.5" fill="#D9D9D9"/>
    <mask id="mask0_0_1" style={{ 'maskType': 'alpha' }} maskUnits="userSpaceOnUse" x="82" y="179" width="230" height="280">
      <rect x="82" y="179" width="230" height="280" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_0_1)">
      <circle cx="199" cy="502" r="117" fill="#D9D9D9"/>
    </g>
    <rect x="84" y="181" width="226" height="276" stroke="#D9D9D9" strokeWidth="4"/>
  </svg>;
}

function DebitCardFront(props: any) {
  return <svg xmlns="http://www.w3.org/2000/svg" {...props} width="820" height="560" fill="none" viewBox="0 0 820 560" >
    <path fillRule="evenodd" clipRule="evenodd" d="M864 -174H-44V734H864V-174ZM85 52C62.9086 52 45 69.9086 45 92V468C45 490.091 62.9086 508 85 508H735C757.091 508 775 490.091 775 468V92C775 69.9086 757.091 52 735 52H85Z" fill="white"/>
    <rect x="47" y="54" width="726" height="452" rx="38" stroke="black" strokeWidth="4" />
    <g fill="#C0C0C0" fill-opacity="0.5">
      <rect x="100" y="153" height="99.679" width="110.32" rx="20.323" fill-opacity="0.5" />
      <rect x="120" y="153" width="2" height="100" fill="#fff" />
      <rect x="140" y="153" width="2" height="100" fill="#fff" />
      <rect x="100" y="180" width="111" height="2" fill="#fff" />
      <rect x="100" y="200" width="111" height="2" fill="#fff" />
      <text y="350" font-size="56" style={{ fontFamily: 'Courier-New', display: 'block' }}>
        <tspan x="150">1234 1234 1234 1234</tspan>
      </text>
      <text y="420" font-size="24" style={{ fontFamily: 'Courier-New', display: 'block' }}>
        <tspan x="550">MM/YY</tspan>
      </text>
    </g></svg>
}

function CardBack(props: any) {
  return <svg width="820" height="560" {...props} viewBox="0 0 820 560" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M864 -174H-44V734H864V-174ZM85 52C62.9086 52 45 69.9086 45 92V468C45 490.091 62.9086 508 85 508H735C757.091 508 775 490.091 775 468V92C775 69.9086 757.091 52 735 52H85Z" fill="white"/>
    <rect x="47" y="54" width="726" height="452" rx="38" stroke="black" strokeWidth="4"/>
    <rect x="50" y="107" width="721" height="91" fill="#D9D9D9"/>
    <rect x="233" y="359" width="17" height="94" fill="#D9D9D9"/>
    <rect x="254" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="262" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="270" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="282" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="290" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="298" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="302" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="310" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="318" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="282" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="290" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="298" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="322" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="330" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="338" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="342" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="350" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="358" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="322" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="330" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="338" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="362" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="370" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="378" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="382" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="390" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="398" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="362" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="370" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="378" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="402" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="410" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="418" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="422" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="430" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="438" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="402" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="410" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="418" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="442" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="450" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="458" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="462" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="470" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="478" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="442" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="450" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="458" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="482" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="490" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="498" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="502" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="510" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="518" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="482" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="490" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="498" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="522" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="530" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="538" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="542" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="550" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="558" y="390" width="4" height="32" fill="#D9D9D9"/>
    <rect x="522" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="530" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="538" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="562" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="570" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="578" y="359" width="4" height="31" fill="#D9D9D9"/>
    <rect x="562" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="570" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="578" y="422" width="4" height="31" fill="#D9D9D9"/>
    <rect x="590" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="598" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="606" y="359" width="4" height="94" fill="#D9D9D9"/>
    <rect x="614" y="359" width="17" height="94" fill="#D9D9D9"/>
  </svg>;
}

function Selfie(props: any) {
  return <svg width="690" height="870" {...props} viewBox="0 0 690 870" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M799 -19H-109V889H799V-19ZM344.5 810C510.462 810 645 642.107 645 435C645 227.893 510.462 60 344.5 60C178.538 60 44 227.893 44 435C44 642.107 178.538 810 344.5 810Z" fill="white"/>
    <path d="M644.95 434.5C644.95 643.384 509.675 810.55 345.2 810.55C180.725 810.55 45.45 643.384 45.45 434.5C45.45 225.616 180.725 58.45 345.2 58.45C509.675 58.45 644.95 225.616 644.95 434.5Z" stroke="black" strokeWidth="10.9"/>
    <circle cx="241.65" cy="380" r="29.975" fill="#D9D9D9"/>
    <path d="M293.425 380C293.425 361.94 270.855 347.3 243.012 347.3C215.17 347.3 192.6 361.94 192.6 380" stroke="#D9D9D9" strokeWidth="10.9"/>
    <path d="M503.25 380C503.25 361.94 480.68 347.3 452.838 347.3C424.995 347.3 402.425 361.94 402.425 380" stroke="#D9D9D9" strokeWidth="10.9"/>
    <circle cx="454.2" cy="382.725" r="29.975" fill="#D9D9D9"/>
    <path d="M296.15 543.5C296.15 558.55 318.72 570.75 346.562 570.75C374.405 570.75 396.975 558.55 396.975 543.5" stroke="#D9D9D9" strokeWidth="10.9"/>
    <path d="M464.197 641.6C463.02 645.155 460.665 648.784 456.978 652.425C451.363 657.968 442.959 663.212 432.135 667.731C410.511 676.761 380.26 682.475 346.563 682.475C312.865 682.475 282.614 676.761 260.99 667.731C250.167 663.212 241.763 657.968 236.148 652.425C232.46 648.784 230.105 645.155 228.928 641.6L464.197 641.6Z" stroke="#D9D9D9" strokeWidth="10.9"/>
    <line x1="176.25" y1="311.875" x2="296.15" y2="311.875" stroke="#D9D9D9" strokeWidth="10.9"/>
    <line x1="396.975" y1="311.875" x2="516.875" y2="311.875" stroke="#D9D9D9" strokeWidth="10.9"/>
  </svg>;
}

export { CardFront, DebitCardFront, CardBack, Selfie };
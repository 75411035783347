import React from "react";
import { Button } from "react-bootstrap";
import { useAPI, useAPIPost } from "./API";
import { useLocalizedStrings } from "./Localization";

function AdminPage() {
  const L = useLocalizedStrings();
  const jobs = useAPI("/scheduled_jobs");
  const runJob = useAPIPost("/scheduled_job/{job}")

  return (
    <>
      <div className="p-5">
        <h2>{L.admin.scheduled_jobs}</h2>
        <ul>
          {jobs.jobs && jobs.jobs.map((job: any) => {
            async function run() {
              alert(JSON.stringify(await runJob({}, '/scheduled_job/' + job)));
            }
            return (
              <li key={job}>{job} <Button onClick={run}>{L.admin.run_job}</Button><br /><br /></li>
            );
          })}
        </ul>
        <hr/>
      </div>
    </>
  );
}

export default AdminPage; 
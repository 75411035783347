import { SurveyFieldDefinitions, SurveyItemDefinition } from "@aidkitorg/roboscreener/lib/model/core.model";
import { useContext, useEffect, useState } from "react";
import { doRawPost } from "../API";
import { ConfigurationContext, SupportedLanguage, SurveyContext } from "../Context";
import { InfoDict } from "../Questions/Props";
import { safeParse, safeParseValidatedFormula } from "../Util";
import { RichText } from "@aidkitorg/types/lib/survey";
import { LANG_DICTS } from "../Localization";

export class RoboScreener {
  deployment: string;
  applicant?: string;

  // Proto-RS mechanics 
  collectedDependencies: boolean = false;
  allDeps: Record<string, Record<string, true>> = {};
  formulas: Record<string, string>;

  // All current info
  info: InfoDict = {};
  // Things to save that have changed
  changed: Set<string>;
  // Timer to debounce updates
  pendingUpdate?: number;

  save: (info: InfoDict) => Promise<void>;

  constructor(deployment: string, formulas: Record<string, string>, save: (info: InfoDict) => Promise<void>, applicant?: string) {
    this.deployment = deployment;
    this.formulas = formulas;
    this.changed = new Set();
    this.applicant = applicant;
    this.save = save;
  }

  setKey(key: string, value: string) {
    this.info = {...this.info, [key]: value};
    (this.computeUpdates(key).forEach as ((cb: ((value: string) => void)) => void))(this.changed.add.bind(this.changed));
    if (this.pendingUpdate) clearTimeout(this.pendingUpdate);
    this.pendingUpdate = setTimeout(this.persistUpdates.bind(this), 1000) as unknown as number;
  }

  async persistUpdates() {
    console.log(this.changed, this.info)
    if (this.applicant) {
      try {
        await doRawPost('/applicants/batch_update', {
          applicant: this.applicant,
          updates: Array.from(this.changed.values()).reduce((dict: InfoDict, key: string) => {
            if (!dict) dict = {};
            dict[key] = this.info[key];
            return dict;
          }, {} as Record<string, string>),
        });
        this.changed.clear();
      } catch (e) {
        console.error(e);
      }
    } else {
      this.save(this.info);
      this.changed.clear();
    }
  }
  
  computeWithDeps(info: Record<string, any>, formula: string): [Set<string>, string, string] {
    const deps = new Set<string>();
    const proxy = new Proxy(info, {
      get: (target, key: string) => {
        deps.add(key);
        return info[key]
      }
    })
    try {
      const func = Function(
        "return (function(info, org, screener) { const out = " + formula + "; return out });"
      )();
      let result = func(proxy);
      if (result !== undefined && result !== null) {
        result = result.toString();
      } else {
        result = '';
      }
      return [deps, result, ''];
    } catch (e) {
      return [deps, '', e instanceof Error ? e.message : (e as string)];
    }
  }

  computeUpdates(dirtyKey?: string) {
    let dirty: string[] = dirtyKey ? [dirtyKey] : [];
    let updated = new Set();
    updated.add(dirtyKey);
      
    const compute = (key: string) => {
      const [deps, result, error] = this.computeWithDeps(this.info, this.formulas[key]);
      Array.from(deps).map((dep) => {
        if (dep === key) return;
        if (!this.allDeps[dep]) {
          this.allDeps[dep] = {}
        }
        this.allDeps[dep][key] = true;
      })
      if (result !== this.info[key]) {
        this.info[key] = result;
        dirty.push(key);
      }
      // Add the _error if it's different and one of them exists
      if ((error || this.info[key + "_error"]) && error !== this.info[key + "_error"]) {
        this.info[key + "_error"] = error;
      }
    }

    // If we haven't yet computed dependencies, run all formulas with the proxy to compute deps
    if (!this.collectedDependencies) {
      Object.keys(this.formulas).map(compute);
      this.collectedDependencies = true;
    }

    // Recursively compute all dirty keys
    while (dirty.length > 0) {
      const next = dirty.pop();
      const toRecompute = this.allDeps[next!] || [];
      Object.keys(toRecompute).forEach(compute);
    }

    return updated;
  }
}

/** Always use RS */
export function useRoboScreener(particularMethod?: string) {
  return true;
}

/** This hook converts the python survey API response to the RS infoDefs style for use in Computed and Validated */
type SurveyItemDefinitionWithContent = SurveyItemDefinition & { index: number, content: RichText };
type SurveyFieldsWithContent = Record<string, SurveyItemDefinitionWithContent>;

type LegacyFieldDef = SurveyItemDefinition & { index: number };
type LegacyFieldDefs = Record<string, LegacyFieldDef>;

export function useSurveyDescription<GetContent extends boolean = false>(props?: { getContent?: GetContent }) {
  const survey = useContext(SurveyContext);

  type Description = GetContent extends true ? SurveyFieldsWithContent : LegacyFieldDefs;

  const [infoDefs, setInfoDefs] = useState({} as Description);

  useEffect(() => {
    if ((survey.sections || []).length === 0) return;
    
    const defs = {} as Description;
    let index = 0;
    for (const s of survey.sections) {
      for (const q of s.Questions) {
        const key = q["Target Field"];
        if (!key) continue; // Don't need these

        const type = q['Field Type'];
        const NOT_NEEDED = ['Flag Review', 'Show Field', 'Show Date'];
        if (NOT_NEEDED.indexOf(type) !== -1) continue;
        
        const def = {
          index,
          key,
          type,
          ...(props?.getContent ? { content: Object.keys(q).reduce((content: RichText, k: string) => {
            let supportedKey = k;
            if (k === 'English Content') supportedKey = "en";
            if (k === "Spanish Content") supportedKey = "es";
            if (LANG_DICTS[supportedKey as SupportedLanguage]) {
              content[supportedKey as SupportedLanguage] = q[k];
            }
            return content;
          }, {} as RichText) } : {}),
          editableBy: q['Who Can Edit'],
          name: q['Question'],
          metadata: q['Metadata'],
          options: q['Additional Options'],
          choices: q['Options (if relevant)']
        } as GetContent extends true ? SurveyItemDefinitionWithContent : LegacyFieldDef;
        index++;

        // Add formula
        if (type === 'Computed') {
          def['formula'] = safeParse(q['Metadata'] || '{}')['formula'];
        } else if (type === 'Validated') {
          def['formula'] = safeParseValidatedFormula(q['Metadata'] || '');
        }

        // Add conditional (only if computed or validated)
        if (['Computed','Validated'].indexOf(q['type']) !== -1) {
          if (q['Conditional On']) {
            const cKey = q['Conditional On'];
            if (q['Conditional On Value']) {
              def['conditional'] = q['Conditional On Value'].map((cv: string) => {
                return `info.${cKey} === '${cv}'`;
              }).join(' || ');
            } else {
              def['conditional'] = `info.${cKey} !== '' && info.${cKey} !== undefined`;
            }
          } else if (q['Metadata'] && type !== 'Validated') {
            const metadata = safeParse(q['Metadata']);
            if (metadata?.conditional) {
              def['conditional'] = metadata.conditional;
            }
          }
        }
        

        // Now check if this infoDef exists, exactly like the one we have
        if (!infoDefs[key]) {
          defs[key] = def;
          continue;
        }

        /** 
         * 
         for (const k in def) {
           const prop = k as keyof SurveyItemDefinition;
           if (infoDefs[key][prop] !== def[prop]) {
             defs[key] = def;
             break;
           }
         }
         */
      }
    }

    if (Object.keys(defs).length !== 0) {
      setInfoDefs((prevState: Description) => {
        return Object.assign({}, {...prevState, ...defs})
      });
    }

  }, [survey.sections]);

  return infoDefs;
}
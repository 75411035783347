import React, { useContext, useState } from 'react';
import { Alert, Button, Image, ListGroup } from 'react-bootstrap';
import InterfaceContext from '../Context';
import { useLocalizedStrings } from '../Localization';
import { languageContent, safeParse, getBadgeVariant, SpacedSpinner } from '../Util';
import { useAPIPost } from '../API';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from './Props';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { createImageWindow } from './Attachment';

declare global {
  interface Window { persona: any; personaOptions: any; }
}

type VerificationCheck = {
  name: string,
  status: string,
  reasons: string[],
  metadata: any
};

type IdentityMetadata = {
  status?: string,
  document?: {
    type: string // Passport, ID, etc.
    first_name: string,
    last_name: string,
    address?: {
      state: string
    }
    status: string,
    [attribute: string]: string | undefined | {
      [secondary_attribute: string]: string | undefined
    } | VerificationCheck[],
  },
  selfie?: {
    status?: string,
    [attribute: string]: string | undefined | {
      [secondary_attribute: string]: string | undefined 
    } | VerificationCheck[]
  }
}

export function IdentityDocument(props: QuestionProps){
    
  const L = useLocalizedStrings();
  const metadata = safeParse(props.info[props['Target Field']!] || "{}") as IdentityMetadata;
    
  const getPhotos = useAPIPost("/applicant/" + props.uid + "/download_persona_images");
  const [images, setImages] = useState([] as any);

  // We need this so it doesn't query infinite times.
  const [fetching, setFetching] = useState(false);

  const imageKeys = [
    {key: 'front', label: L.questions.identity.document},
    {key: 'back', label: L.questions.identity.document},
    {key: 'selfie', label: L.questions.identity.selfie}
  ];

  async function fetchDocuments() {
    if (!metadata['document']){
      return;
    }

    const image_map = {
      "front": metadata['document']['front-photo-url'],
      'back': metadata['document']['back-photo-url'],
    } as Record<string, string>;

    if (metadata['selfie']){
      image_map["selfie"] = metadata['selfie']['center-photo-url'] as string;
    }

    setFetching(true);
    try {
      const response = await getPhotos({ image_map });
      const responseImages = imageKeys.map((obj: any) => response[obj['key']]);
      setImages(responseImages);
    }finally {
      setFetching(false);
    }
  }

  if (!metadata['document']){
    return <></>;
  }

  return (
    <>
      {images.length === 0 && (
        <Button variant="primary"
          onClick={() => fetchDocuments()}>{fetching && <SpacedSpinner />}<span>{L.questions.identity.show_identity_documents}</span></Button>
      )}
      <ul>
        {images && images.map((imageResponse: any, index: number) => {
          if (!imageResponse) return <></>;
          const url = 'data:' + imageResponse['content-type'] + ';base64,' + imageResponse['content'];

          return (
            <li className="mb-2">
              <h5>{imageKeys[index]['label']}:</h5>
              <Image src={url} alt="document" onClick={(e) => {}}
                style={{ maxWidth: "200px", cursor: "pointer" }} />
            </li>
          );
  
        })}
      </ul>
    </>
  );

}

function IdentityFlagReview(props: QuestionProps){
  const context = useContext(InterfaceContext);
  
  const L = useLocalizedStrings();
  
  const targetField = props['Target Field']!;
  
  const checks =
        (safeParse(props.info[targetField] || "{}") as any)[
          "checks"
        ] || [];
  
  const comment = props.info[targetField + "_comment"] || "";
  
  if (checks.length) {
    return (
      <Alert variant="danger">
        {props[languageContent(context.lang)]}
        <br />
        <br />
        <ol>
          {checks &&
                checks.map((check: VerificationCheck, i: number) => {
                  if (check.status === 'not_applicable') return <></>;
                  return (
                    <li>
                      {check.name}&nbsp;{getBadgeVariant(check.status, L.questions.identity.passed, L.questions.identity.failed)}
                      <ListGroup>
                        {check.reasons && check.reasons.map((reason: string) => {
                          return (
                            <ListGroup.Item variant="danger">
                              {reason}
                            </ListGroup.Item>
                          )
                        })}
                      </ListGroup>
                    </li>
                  );
                })}
        </ol>
        <br />
        {comment && <>{L.questions.flags.the_screener_said} {comment}</>}
      </Alert>
    );
  }
  return <></>;
}


export { IdentityFlagReview }
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VALIDATED = exports.COMPUTED = exports.COMPUTED_TYPES = exports.CONDITIONAL_ON_VALUE = exports.CONDITIONAL_ON = exports.SUBQUESTIONS = exports.OPTIONS_IF_RELEVANT = exports.TARGET_FIELD = exports.FIELD_TYPE = exports.QUESTION = exports.SURVEY_TABLE = void 0;
exports.SURVEY_TABLE = 'Rental Survey';
exports.QUESTION = 'Question';
exports.FIELD_TYPE = 'Field Type';
exports.TARGET_FIELD = 'Target Field';
exports.OPTIONS_IF_RELEVANT = 'Options (if relevant)';
exports.SUBQUESTIONS = 'Subquestions';
exports.CONDITIONAL_ON = 'Conditional On';
exports.CONDITIONAL_ON_VALUE = 'Conditional On Value';
exports.COMPUTED_TYPES = ['Computed', 'Validated'];
exports.COMPUTED = 'Computed';
exports.VALIDATED = 'Validated';

import { useState, useEffect, Fragment } from 'react';
import { timezones } from '@aidkitorg/types/lib/timezones';
import { classNames } from '../Util';
import { Listbox, Transition } from '@headlessui/react';
import { useLocalizedStrings } from '../Localization';
import { ClockIcon } from '@heroicons/react/24/outline';

const truncateToMinutes = (m?: Date | number): string => {
  if (!m) { return '' }
  if (typeof m === 'number') {
    m = new Date(m);
  }
  const padWithZero = (num: number) => num.toString().padStart(2, '0');
  return `${m.getUTCFullYear()}-${padWithZero(m.getUTCMonth() + 1)}-${padWithZero(m.getUTCDate())}T${padWithZero(m.getUTCHours())}:${padWithZero(m.getUTCMinutes())}`;
}

const defaultDateFormat = new Intl.DateTimeFormat(undefined, {
  timeStyle: 'short',
  dateStyle: 'medium',
  timeZone: 'UTC'
});

export function adjustIntoTimezone(targetOffset: number, dt: Date | number) {
  const adjustment = targetOffset * (3_600_000 /* 1 hour in millis */);
  const base = (typeof dt === 'number' ? dt : dt?.getTime());
  return base ? base + adjustment : base;
}

export default function PresetDateTimePicker(props: {
  disabled?: boolean,
  extraClasses?: string,
  showSelected?: boolean,
  onChanged?: (date: number | undefined) => void,
  onSelected?: (date?: number) => void,
  dateFormat?: { format(date?: Date | number): string },
  options: { altTextForMenu?: string | JSX.Element, alias?: string | JSX.Element, date?: number }[],
  timezones?: { local: (typeof timezones)[number], reference?: (typeof timezones)[number] }
}) {

  const [opt, setOpt] = useState<number>(0);
  const [date, setDate] = useState<number | undefined>();
  let dateFormat = props.dateFormat ?? defaultDateFormat;
  const localTZ = props.timezones?.local ?? { offset: 0, abbr: 'UTC' };
  const targetTZ = props.timezones?.reference ?? localTZ;

  const opts = props.options.map(({ altTextForMenu, alias, date }, i) => {
    if (!date) {
      const title = altTextForMenu ?? alias;
      return <Listbox.Option
        onClick={() => props.onSelected?.(date)}
        value={i}
        key={i}
        className={({ active }) => classNames(
          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
          'cursor-default select-none p-2')}>
        {() => <>
          <div>
            {typeof title === 'string' ?
              <span className="text-lg font-semibold">{title}</span>
              : title}
          </div>
        </>
        }
      </Listbox.Option>
    }
    const localDate = adjustIntoTimezone(localTZ.offset, date);
    const targetDate = adjustIntoTimezone(targetTZ.offset, date);

    return (
      <Listbox.Option
        value={i}
        key={i}
        onClick={() => props.onSelected?.(date)}
        className={({ active }) => classNames(
          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
          'cursor-default select-none p-2 text-sm')}>
        {() => <>
          <div>
            <p className="text-lg font-semibold">{alias ?? dateFormat.format(targetDate)}</p>
            <p className="text-sm">{dateFormat.format(localDate)}&nbsp;({localTZ.abbr})</p>
            <p className="text-sm">{dateFormat.format(targetDate)}&nbsp;({targetTZ.abbr})</p>
          </div>
        </>
        }
      </Listbox.Option>
    )
  });

  function updateButtonText(opt: number) {
    if (props.onChanged) {
      setDate(props.options?.[opt]?.date);
      setOpt(opt);
    }
  }

  return (
    <Listbox value={opt} onChange={updateButtonText} disabled={props.disabled}>
      {({ open }) => <div className='h-full'>
        <Listbox.Button className={classNames(
          props.disabled ? 'bg-gray-300' : 'bg-indigo-600',
          props.extraClasses,
          "border-gray-200 border w-auto text-white p-2 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 focus:ring-offset-gray-50 h-full"
        )}>
          <div><ClockIcon className="h-5 w-5" /></div>
          <div hidden={!props.showSelected}><span>{props.options?.[opt as number]?.alias ?? dateFormat.format(date)}</span></div>
        </Listbox.Button>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute right-2 z-10 mt-2 w-auto origin-top-left divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {opts}
          </Listbox.Options>
        </Transition>
      </div>}
    </Listbox>
  );
}

export function CustomDateTimePicker(props: {
  id?: string,
  name?: string,
  before?: number,
  after?: number,
  initialValue?: number,
  dateFormat?: { format(date?: Date | number): string },
  onChange?: (date: number) => void,
  timezones?: { local: (typeof timezones)[number], reference?: (typeof timezones)[number] }
}) {

  const localTZ = props.timezones?.local ?? { offset: 0, abbr: 'UTC' };
  const targetTZ = props.timezones?.reference ?? localTZ;
  let dateFormat = props.dateFormat ?? defaultDateFormat;

  const L = useLocalizedStrings();
  const [after, setAfter] = useState<number>();
  const [afterDisplay, setAfterDisplay] = useState<string>();
  const [before, setBefore] = useState<number>();
  const [beforeDisplay, setBeforeDisplay] = useState<string>();
  const [reference, setReference] = useState<number>();
  const [defaultValue, setDefaultValue] = useState<string>();
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (props.after) {
      const value = props.after;
      setAfter(value);
      setAfterDisplay(truncateToMinutes(adjustIntoTimezone(targetTZ.offset, value)));
    }
    if (props.before) {
      const value = props.before;
      setBefore(value);
      setBeforeDisplay(truncateToMinutes(adjustIntoTimezone(targetTZ.offset, value)));
    }
  }, [props.after, props.before]);

  useEffect(() => {
    let value = props.initialValue ?? after;
    if(!value) {
      return;
    }
    setReference(adjustIntoTimezone(localTZ.offset, value));
    setDefaultValue(truncateToMinutes(adjustIntoTimezone(targetTZ.offset, value)));
  }, [props.initialValue, after]);

  let outOfBoundsMsg = L.support.date_must_be_between;
  if (props.before) {
    outOfBoundsMsg = outOfBoundsMsg.replace('$before', dateFormat.format(adjustIntoTimezone(targetTZ.offset, props.before)));
  }
  if (props.after) {
    outOfBoundsMsg = outOfBoundsMsg.replace('$after', dateFormat.format(adjustIntoTimezone(targetTZ.offset, props.after)));
  }

  return (<ul
    title={valid ? '' : outOfBoundsMsg}
    className={classNames("rounded-l-md m-0 divide-y divide-gray-300", !valid ? 'border-red-200 border-2' : '')}>
    <li className="flex justify-end m-0">
      <input id={props.id} 
        name={props.name}
        type="datetime-local"
        className="bg-gray-50"
        defaultValue={defaultValue}
        onChange={(e) => {
          const newValue = adjustIntoTimezone(-targetTZ.offset, e.target.valueAsNumber);

          const isValid = (!after || newValue >= after) && (!before || newValue <= before);
          setValid(isValid);

          if (!isNaN(newValue) && isValid) {
            setReference(adjustIntoTimezone(localTZ.offset, newValue));
            props.onChange?.(newValue);
          }
        }}
        min={afterDisplay}
        max={beforeDisplay}
      />
      <span hidden={!props.timezones} className="self-center">({targetTZ.abbr})</span>
    </li>
    <li hidden={!reference} className="flex justify-between m-0">
      <time>{dateFormat.format(reference)}</time>
      <span className="ml-[18px]">({localTZ.abbr})</span>
    </li>
  </ul>);
}

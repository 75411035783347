import { useRef, useEffect } from 'react';

export function useDebounce(fn: (...args: any[]) => any, delay: number) {
  const timeoutRef = useRef<any>(null);

  const cancel = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    return cancel;
  }, []);

  const debouncedFn = (...args: any) => {
    cancel();
    timeoutRef.current = setTimeout(() => {
      fn(...args);
    }, delay);
  };

  return [debouncedFn, cancel];
}

// FNV-1a hash
export function hash(str?: string) {
  /*jshint bitwise:false */
  var i, l, hval = 0x811c9dc5;
  for (i = 0, l = (str || '').length; i < l; i++) {
    hval ^= (str || '').charCodeAt(i);
    hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  return (hval >>> 0).toString(16).substring(-8);
}

export function toName(name: string) {
  return 'data-' + (name.toLowerCase().replace(/ /g, '-'));
}

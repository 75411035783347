export const sanitizeDangerousText = (str: string = ""): string => {
  return str.replace(/[&<>"']/g, function (match) {
    const escape: Record<string, string> = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;'
    };
    return escape[match];
  });
};
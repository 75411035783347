import { Collection, PaymentModule } from "../../survey";

export const generateAchPaymentsCollection = (config: PaymentModule): Collection => ({
  "kind": "Collection",
  "name": {
    "en": "ACH"
  },
  "options": {},
  "components": [
    {
      "kind": "Section",
      "title": {
        "en": "ACH Payments"
      },
      "components": [
        {
          "kind": "Explanation",
          "content": {
            "en": "# Payment Information and Preferences \n\n**There are four ways you can receive funds.** \n\n**1. Have funds sent to your bank account.**\n\n**2. Have funds sent to a pre-paid debit card that you pick up from $program_name.** \n\n**3. Have funds sent to a virtual pre-paid debit card that will be sent to you by email and text.** \n\n**4. Have funds sent to to a pre-paid debt card that will be mailed to your home address.**\n\n*Please carefully read the information about each payment method before making your decision.*\n",
          }
        },
        {
          "kind": "Explanation",
          "content": {
            "en": "## Bank Account Information\n\nYou can request funds be sent to a bank account you already have or a new bank account. \n\nDon’t have a bank account? It is not too late! \n\nHaving funds sent to a bank account has several benefits depending on the account you choose:\n\n- The ability to withdraw cash at an ATM or in-person with a teller \n- The ability to write checks or request cashier’s checks \n- Protections if there is an issue with your account \n\nWe know it can sometimes be hard to open a bank account or that people may choose to manage their money in other ways.\n\nIf you are interested in opening a bank account now may be a good time!\n\nBank On is made up of local organizations and financial institutions (banks and credit unions) who partner to connect people to safe, affordable bank accounts. Bank On certified accounts meet national standards for being easier to access and more affordable. You can [visit Bank On](https://joinbankon.org/accounts/) to find accounts.\n\nWhen making any financial decision, it is important to be an informed consumer and pick a product or service that will best meet your needs. You can access these resources from the Consumer Financial Protection Bureau to learn more!\n- [Opening a checking or savings account](https://files.consumerfinance.gov/f/documents/cfpb_your-money-your-goals_open-checking-savings_tool_2018-11.pdf)\n- [Comparing financial service providers](https://files.consumerfinance.gov/f/documents/cfpb_your-money-your-goals_compare-fin-serv-prov_tool_2018-11.pdf)\n\nDon’t want to apply for an account in-person? You can consider these 31 virtual options for [Bank On certified accounts.](https://bankononline.org/?gclid=CjwKCAjwjMiiBhA4EiwAZe6jQ5_85TBqW21FVdckChi21_lkvszhYdcR5kvK955H1D31A7vqR3P60RoCb9kQAvD_BwE#faq-section)",
          }
        },
        {
          "kind": "Select",
          "targetField": "applicant_payment_type",
          "content": {
            "en": "**Which payment method are you choosing?**\n\n*If you have a bank account or are able to sign up for one, direct deposit is the most secure and convenient option.*",
          },
          "choices": [
            {
              "value": "ach",
              "label": {
                "en": "Direct Deposit: Direct transfer to your bank account (Note: the bank account must be in your name)",
              }
            }
          ]
        },
        {
          "kind": "Explanation",
          "content": {
            "en": "**Collecting Bank Info:**\n\nPlease enter your bank account information below. We take your privacy very seriously. We will only use this information to send you payments on behalf of the this program.\n\n![Bank Info Update](https://raw.githubusercontent.com/aidkitorg/aidkitorg.github.io/main/bank_info_reference.png)\n",
          }
        },
        {
          "kind": "BankRoutingNumber",
          "content": {
            "en": "**Please enter your Bank Account Routing Number** (Enter 121000358 for Testing)\n\n*The routing number is a 9-digit identification number used to identify your bank.*",
          },
          "targetField": "bank_routing_number"
        },
        {
          "kind": "Number",
          "content": {
            "en": "**Please enter your Bank Account Number** \n\n*Note: We encrypt your bank account number as soon as you hit save.*\n\n*Your account number is the unique number assigned to your specific bank account. This is not the number that is on your debit card, and is best located on a check or by accessing a bank statement, your online bank account, or your bank directly..*",
          },
          "targetField": "bank_account_number",
          "min": 0,
          "encrypted": true
        },
        {
          "kind": "TextEntry",
          "content": {
            "en": "** Personal name on the Bank Account (i.e. the name of person associated with the bank account, not the name of your bank).**\n\n\n**Note the bank account must be under your name.**\n**Do not put the brand name of your bank.**",
          },
          "targetField": "bank_account_name",
          "format": "legalName"
        },
        {
          "kind": "Select",
          "content": {
            "en": "** Is this a checking or savings account?**",
          },
          "targetField": "bank_account_type",
          "choices": [
            {
              "value": "checking",
              "label": {
                "en": "Checking",
              }
            },
            {
              "value": "savings",
              "label": {
                "en": "Savings",
              }
            }
          ]
        }
      ]
    },
    {
      "kind": "Section",
      "title": {
        "en": "Ancillary Items"
      },
      "components": [
        {
          "kind": "Explanation",
          "content": {
            "en": "## The following items accompany an ACH build \n\n- Legal Name and Bank Account Name Check + Reviewer Override Option\n- Payment Method Update Subsurvey\n\nThe correction could be made by the applicant in the app or a screener by signing in.\n"
          }
        },
        {
          "kind": "Show Field",
          "targetField": "legal_name",
          "headerText": {
            "en": "Legal Name",
          }
        },
        {
          "kind": "Show Field",
          "targetField": "bank_account_name",
          "headerText": {
            "en": "Name on Bank Account",
          }
        },
        {
          "kind": "Computed",
          "targetField": "bank_account_name_score",
          "content": {
            "en": "Bank Account Name Score (If under 0.4, this applicant will require a review and name update or manual override)",
          },
          "formula": "(function sim(a, b) { let trgm = function (x) { let p = '  ' + (x || '').trim().toLowerCase().normalize('NFD').replace(/[\\\\u0300-\\\\u036f]/g, '').split(' ').join('   ') + ' '; return p.split('').map(function (_, i) { return p.slice(i, i + 3); }) .filter(function (t) { return t.length == 3 && t.trim().length !== 0 && t.slice(1) != '  '; }) .reduce(function (d, t) { return (Object.assign(Object.assign({}, d), (function (t) { let a = {}; a[t] = true; return a })(t))); }, {}); }; let at = trgm(a); let bt = trgm(b); return Object.keys(at).filter(function (k) { return bt[k] !== undefined; }).length / Object.keys(Object.assign({}, at, bt)).length; })(info.bank_account_name || '', info.legal_name || '')"
        },
        {
          "kind": "Conditional Block",
          "components": [
            {
              "kind": "Explanation",
              "content": {
                "en": "Manual Override is required on scores below 0.4. Please override if you believe the names to match. If not, please reach out to the applicant to confirm.",
              }
            },
            {
              "kind": "Confirmation",
              "targetField": "bank_account_name_override",
              "content": {
                "en": "The name on the bank account doesn't seem to match the applicant's name and *it will halt the payment*. \n\nThe name on the account must be the applicant's name, **NOT the name of the bank**.\n\n**You could override by checking the box below when it is one of the following cases. Otherwise, please check the name again.**\n- The name is a nickname such as Nate vs. Nathaniel\n- The family name / surname is your maiden name\n- The middle name is omitted\n- The suffix such as Jr., Sr., I, II, III, and IV is omitted",
              }
            }
          ],
          "conditionalOn": {
            "kind": "Value Less Than",
            "value": {
              "kind": "Field",
              "field": "bank_account_name_score"
            },
            "lessThan": {
              "kind": "StringValue",
              "value": "0.4"
            }
          }
        },
        {
          "kind": "Computed",
          "targetField": "payment_ok",
          "content": {
            "en": "For ACH, Legal Name and Bank Account Name should match, so this field must be 'Yes' to attempt payment.\n\nReplace true with the conditions that must be true for an applicant to be considered eligible for payment.",
          },
          "formula": "(true) ? info.applicant_payment_type === 'ach' ? info.legal_name && info.bank_account_name ? (info.bank_account_name_score >= 0.4 || info.bank_account_name_override === 'yes') ? 'yes' : '' : '' : 'yes' : ''"
        }
      ]
    }
  ]
})
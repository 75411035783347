import { useState } from "react";
import { usePost } from "./API";
import { BUTTON_CLASS, RigidSpinner } from "./Util";

export default function EncryptedReport() {
  const getReport = usePost('/admin/encrypted_report');
  const [working, setWorking] = useState(false);
  const [uids, setUIDs] = useState('');

  async function handleEncryptedReportDownload() {
    if (working) {
      return;
    }

    setWorking(true);

    const signedUrl = await getReport({
      excludeUids: (uids && uids.length > 0)
        ? uids.replace(/\n/g, " ").replace(/,/g, " ").split(" ").filter((l: string) => l.length > 0).map(t => t.trim())
        : []
    });
    if (typeof signedUrl === 'string') {
      const link = document.createElement('a');
      link.href = signedUrl;
      link.setAttribute('download', 'aidkit_encrypted_ffy_data_' + new Date().toISOString().slice(0, 10)); // YYYY-MM-DD
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Error generating report: ', signedUrl.error);
    }
    setWorking(false);

  };

  return <>
    <h1>Download Encrypted Report</h1>
    <div>
      <label htmlFor="uids" className="my-2">Enter UIDs to exclude from report - separated by commas, spaces, or lines</label>
      <textarea id="uids" className="my-1 w-full" value={uids}
        onChange={(e) => setUIDs(e.target.value)} />
      <button 
        className={BUTTON_CLASS}
        disabled={working}
        onClick={handleEncryptedReportDownload}>
        {working
          ? <RigidSpinner /> : <span>Download Report</span>
        }
      </button>
    </div>
  </>;
}
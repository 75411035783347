/**
 * Validates that a name contains two or more words and each word begins with a letter.
 * Each word can include combinations of letter characters, hyphens, and apostrophes
 * but no two spaces/hyphens/apostrophes in a row.
 * 
 * Allows a wide range of Latin letter characters, including:
 * - Basic Latin letters: a-z, A-Z
 * - Latin-1 Supplement letters: À-Ö, Ø-ö, ø-ÿ
 * - Latin Extended-A letters: Ā-ſ
*/
export function legalNameValidator(name: string, min_length?: number, max_length?: number): boolean {
  return name.length > 0
      && (!max_length || name.length <= max_length)
      && (!min_length || name.length >= min_length)
      && /^[a-zA-ZÀ-ÖØ-öø-ÿĀ-ſ]+([-'\s][a-zA-ZÀ-ÖØ-öø-ÿĀ-ſ]+)* [a-zA-ZÀ-ÖØ-öø-ÿĀ-ſ]+([-'\s][a-zA-ZÀ-ÖØ-öø-ÿĀ-ſ]+)*$/g.test(name.trim());
};

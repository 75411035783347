import React, { useEffect, useState } from 'react';
import { useGet, usePost } from '../API';
import { Dropdown } from '../Components/Dropdown';

export function HashedUploadSearchPage(props: any) {

  const getUploadPaths = usePost('/program/admin/get_hashed_lookup_paths');
    
  const [uploadPaths, setUploadPaths] = useState(null as null | string[]);
  const [path, setPath] = useState("");
    
  const searchUploadPath = usePost('/program/admin/search_hashed_lookup_file');
  const [searchTerm, setSearchTerm] = useState("");

  const [results, setResults] = useState(null as null | { [key: string]: string | number }[] );

  useEffect(() => {
    (async () => {
      const paths = await getUploadPaths({});
      setUploadPaths(paths);
      console.log("paths:", paths);
    })();
  }, []);

  const doSearch = async () => {
    const results = await searchUploadPath({
      file: path,
      searchKey: 'Name',
      searchTerm
    });

    setResults(results);
  }

  return (
    <div className="flex-col h-full justify-start p-2">
      {/** component to specify which data file to search */}
            
      <label htmlFor="name" className="block text-xs font-medium text-gray-900">
        Upload to Search
      </label>
      <Dropdown label={"Path" + (path ? ": " + path : "")}
        options={(uploadPaths || []).map(p => {
          return { label: p, callback: () => {
            setPath(p);
          }}
        })} />
            
      {/** component to do the search */}
      {path && <div className="w-fit mt-2">
        <label htmlFor="search" className="block text-sm font-medium text-gray-700">
          Quick search
        </label>
        <div className="relative mt-1 flex items-center" onKeyUp={(e) => {
          if (e.key === "Enter") {
            doSearch();
          }
        }}>
          <input
            type="text"
            name="search"
            id="search"
            onChange={(e) => setSearchTerm(e?.target?.value || '')}
            className="block w-full h-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <kbd className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
              Enter
            </kbd>
          </div>
        </div>
      </div>}
      {/** component to display the results */}
      <hr/>
      <h3 className="text-lg font-medium leading-6 aidkit-blue">{results ? `Results (${results.length})` : 'Use the search above to get started'}</h3>
      <div className="mt-10">

        {(results || []).map((r, i) => {
          return <div key={i} className="mt-2">
            <div className="bg-white shadow sm:rounded-lg py-3">
              <div className="px-4 py-1 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900 sticky top-0 z-10">Name: {r['Name']}</h3>
              </div>
              <div className={`overflow-y-scroll max-h-80 border border-top-2 border-left-0 border-right-0 border-bottom-0 
                            border-gray-200 py-3 sm:p-0`}>
                <dl>
                  {Object.keys(r).map((k, i2) => {
                    return <div key={k + "-" + i2} className={`py-2 border border-top-0 border-left-0 
                                        border-right-0 border-bottom-2 border-gray-100 sm:grid sm:grid-cols-3`}>
                      <dt className="h-8 text-sm font-medium text-gray-500 px-2">{k}</dt>
                      <dd className="h-8 mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 px-2">{r[k]}</dd>
                    </div>
                  })}
                </dl>
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
  )
}
import { PermaCache } from '../';
import { copy, getLang, getToken, offlineLogger, success } from '../../utils';
import { limitConcurrentRequests } from '@aidkitorg/types/lib/util';
import slugid from 'slugid';
import { v5 } from 'uuid';
import { ok } from 'neverthrow';
import { Api, publishSyncState, SyncState, SyncStatus } from '.';
import { Directory } from 'aidkit/lib/directory';
import { Directory as RoboApi } from '@aidkitorg/roboscreener/lib/api/directory';
import { DirTree, getOrCreateHttpCache, Readable, RequestEntry, Writable } from '../';
import { getURLsFromCSV } from '@aidkitorg/roboscreener/lib/util/urls_from_csv';
import { Deletable, Property } from '../store.types';
import { asJSON, asStr } from '../store.offline';
import { getOrCreateKVPCache } from '../store.kvp';
import { captureException } from '@sentry/browser';

/**
 * this represents an applicant within a logical tree structure.
 * the main things are:
 *      @param baseline the original applicant info, stored on the server OR a new applicant's blank info
 *      @param changes a list of changes to the baseline, stored locally 
 *      @param valid whether the baseline is valid (i.e. if it exists locally)
 *      @param exists whether the baseline exists on the server
 */
export type ApplicantTree = DirTree<{
  uid: string,
  // these are persisted, and can be read/written
  baseline: Readable<{ file: File, meta?: File }> & Writable<RequestEntry> & Deletable<void>,
  changes: Readable<(sorted?: boolean) => Readable<{ file: File, meta?: File }[]>> & Writable<RequestEntry> & Deletable<string>,
  // fields that are computed at runtime, and not persisted
  valid: boolean,
  exists: boolean,
  state: Property<SyncState>,
  latest: () => Record<string, any>,
  changeCount(approx?: boolean): number,
  move: (uid: string) => ApplicantTree,
  erase: () => void
}>;

/**
 * this implmements the Applicant Tree specification
 */
export async function toApplicant(dir: PermaCache<string, RequestEntry>): Promise<ApplicantTree> {

  const exists = () => dir.get({ req: new Request('/applicant/get_everything', { method: 'POST' }) });
  const create = () => dir.get({ req: new Request('/applicant/create', { method: 'POST' }) });

  const valid = async () => create().catch(() => exists());

  const changeDir = () => dir.down('changes');
  const archiveDir = () => dir.down('archive');
  const baseline = () => valid().then(({ handle, meta }) => ({ file: handle, meta }));
  const changes = async (sorted?: boolean) => {
    try {
      const files = await (await changeDir()).values(true);
      if (sorted === undefined || sorted) {
        files.sort((a, b) => a.file.lastModified - b.file.lastModified);
      }
      return files;
    } catch (e) {
      offlineLogger.dev.warn('could not get change files for reasons', e);
      return [];
    }
  }

  const status: ApplicantTree['state'] = async () => {
    const existing = await success(exists());
    const brandNew = await success(create());
    const changeCount = await success(changeDir()) ? (await changes()).length : 0;
    const id = dir.name;
    if (brandNew) {
      return { id, status: SyncStatus.BrandNew };
    } else if (changeCount > 0) {
      return {
        id,
        status: SyncStatus.Ahead,
        changes: changeCount
      };
    } else if (existing) {
      return { id, status: SyncStatus.InSync };
    }

    return { id, status: SyncStatus.Unavailable };
  };

  return {
    uid: async () => dir.name,
    state: status,
    baseline: {
      get: baseline,
      set: async (entry) => { await dir.put(entry); },
      delete: () => create().then(async c => {
        await dir.move(await archiveDir(), c.handle.name);
      })

    },
    changes: {
      get: changes,
      async set(entry) {
        await (await changeDir()).put(entry);
      },
      async delete(key) {
        await (await changeDir()).move(await archiveDir(), key);
      }
    },
    exists: () => success(exists()),
    valid: () => success(valid()),
    latest: async () => {
      try {
        const b = await baseline();
        const data = JSON.parse(await b.file.text());
        const { info, ...rest } = data;
        data.info = { ...(info ?? rest) };
        for (const c of await changes()) {
          const change = JSON.parse(await c.file.text());
          Object.entries<any>(change.changedKeys)
            .forEach(([key, value]) => { data.info[key] = value });
        }
        return data;
      } catch (e) {
        offlineLogger.error(e);
        throw e;
      }
    },

    async changeCount(approx: boolean = true): Promise<number> {
      if (approx) {
        return (await changes(false)).length;
      } else {
        const changesToCount = await changes(false);
        let changeSet = new Set<string>();
        for (const c of changesToCount) {
          const change = JSON.parse(await c.file.text());
          Object.entries<any>(change.changedKeys)
            .forEach(([value]) => { changeSet.add(value) });
        }
        return changeSet.size;
      }
    },

    async move(uid: string) {
      const newDir = await dir.parentDir!.down(uid);
      return await toApplicant(await dir.move(newDir));
    },

    async erase() {
      await dir.erase();
    }
  };
}


/**
 * this is the routing logic for an applicant's data.
 * @param applicants returns a persistent location on disk of the applicant,
 * which can be used to read/write data
 */
export class Applicant {
  constructor(
    private readonly applicants: (uid: string) => Promise<ApplicantTree>
  ) { }



  get routes(): Api<Partial<Directory & RoboApi>> {
    const applicants = this.applicants;
    return {
      /** 
             * handles creating a baseline of an applicant,
             * as well as using that baseline + changes (from compute_and_save)
             * to reconstitute the applicant during offline mode
             */
      "/applicant/get_everything": {
        async whenOffline({ uid }) {
          const reader = await applicants(uid!);
          offlineLogger.dev.debug("using offline copy for", uid);
          return await reader.latest() as any;
        },
        async whenOnline({ uid }, req, resp) {
          offlineLogger.dev.info("creating baseline for", uid);
          const applicant = await applicants(uid!);
          await publishSyncState({ id: uid, status: SyncStatus.Processing });
          await applicant.baseline.set({ req, resp });
          return new Response(JSON.stringify(
            await applicant.latest()
          ));
        }
      },
      /** 
             * enables creation of new applicants during offline mode 
             */
      "/applicant/create": {
        async whenOffline(params, { request }) {
          offlineLogger.dev.info("creating applicant offline");

          const uid = 'draft-' + (slugid.encode(v5(Date.now().toString(), v5.URL)));
          const tracker = await applicants(uid);
          await tracker.baseline.set({ req: request });
          await publishSyncState(await tracker.state());

          return {
            // this is for the new behavior
            success: true,
            newId: uid,
            // this is to accomodate legacy behavior
            // TODO: delete when legacy is gone
            data: { uid },
            info: params,
            ...params
          };
        }
      },
      /**
             * persists changes made in the Applicant page 
            */
      "/compute_and_save": {
        async whenOffline(params, { request }) {
          await publishSyncState({ id: params.uid, status: SyncStatus.Processing });
          delete (params as any).token;
          const tracker = await applicants(params.uid);
          const req = await copy(request.clone(), {
            modifyUrl(url) {
              url = new URL(url);
              url.searchParams.set('change', slugid.encode(v5(JSON.stringify(params), v5.URL)));
              return url;
            },
            body: JSON.stringify(params)
          });

          await tracker.changes.set({ req });
          await publishSyncState({
            id: params.uid,
            status: SyncStatus.Ahead,
            changes: await tracker.changeCount(false)
          });

          return ok(params.changedKeys);
        },
        async whenOnline({ uid }) {
          const tracker = await applicants(uid);
          await publishSyncState(await tracker.state());
        }
      },
      "/encrypt": {
        async whenOffline({ text }) {
          try {
            const staticDir = await getOrCreateHttpCache('static');
            const { content } = await staticDir.get({ req: new Request('/offline/encrypt/public-key', { method: 'POST' }) }, asJSON);
            const publicKey = importPublicKey(content.publicKey);
            const payload = await encryptWithPublicKey(await publicKey, text);
            const value = Buffer.from(JSON.stringify({
              value: payload,
              keyId: content.keyId
            }));
            return { value: value.toString('base64') };
          } catch (e) {
            offlineLogger.error('error while encrypting', e);
            captureException(e);
            return { value: text };
          }
        }
      },
      "/user/info": {
        async whenOnline(_, req, resp) {
          const staticDir = await getOrCreateHttpCache('static');
          await staticDir.put({ req, resp });
        },
        async whenOffline(_, { request }) {
          const staticDir = await getOrCreateHttpCache('static');
          const { content } = await staticDir.get({ req: request }, asJSON);
          return content;
        }
      },
      "/applicant/find_related": {
        async whenOffline({ uid, keys }) {
          const target = await (await applicants(uid)).latest();

          const appDir = await getOrCreateHttpCache('applicants');
          const apps = await appDir.dirs();

          const related = await Promise.allSettled(apps.map(async (app) => {
            const appData = await (await app.as(toApplicant)).latest();
            if (app.name !== uid && keys.every(key => appData.info[key] === target.info[key])) {
              return { uid: app.name, name: appData.info?.legal_name };
            }
          }));

          return related
            .filter(r => r.status === 'fulfilled' && r.value)
            .map(r => (r as any).value);
        }
      }
    };
  }
}

async function importPublicKey(key: string) {
  let publicKey = await crypto.subtle.importKey(
    "spki",
    Buffer.from(key, 'base64'),
    {
      name: "RSA-OAEP",
      hash: "SHA-256"
    },
    true,
    ["encrypt"]
  );

  return publicKey;
}

async function encryptWithPublicKey(publicKey: CryptoKey, plaintext: string) {
  let encoder = new TextEncoder();
  let data = encoder.encode(plaintext);

  let encryptedData = await crypto.subtle.encrypt(
    {
      name: "RSA-OAEP"
    },
    publicKey,
    data
  );
  return Buffer.from(encryptedData).toString('base64');
}

export async function fetchFn(uids?: string[]) {
  const metaDir = await getOrCreateKVPCache('meta');
  const { content: program } = await metaDir.get({ key: 'program' }, asStr);
  await new ApplicantSync(async (req) => {
    const token = await getToken();
    if (!token) return new Response('no token found', { status: 401 });
    req = await copy(req, {
      modifyUrl(url) {
        url = new URL(url);
        url.searchParams.set('token', token);
        url.searchParams.set('auth', token);
        url.searchParams.set('program', program as string);
        return url;
      }
    });
    return fetch(req);
  }).synchronize(uids)
}

/**
 *
 */
export class ApplicantSync {

  constructor(
    private readonly executeRequest: (req: Request) => Promise<Response>
  ) { }

  async sync(tree: ApplicantTree): Promise<void> {
    offlineLogger.debug('syncing', await tree.uid());
    const { meta, file } = await tree.baseline.get();
    offlineLogger.debug('got baseline', meta, file);
    if (!(await tree.exists())) {
      offlineLogger.debug('creating new applicant');
      // create applicant
      const { url, ...rest } = JSON.parse(await meta!.text());
      const req = new Request(
        url,
        {
          ...rest,
          body: await file.arrayBuffer()
        }
      );
      const resp = await this.throwIfError(this.executeRequest(req));

      const respBody = await resp.json();
      await tree.baseline.delete();
      // this migrates changes to the created UID location in storage,
      // which allows a more seamless experience to the user while 
      // synchronization completes.
      tree = await tree.move(respBody.uid ?? respBody.newId);
    }
    const token = await getToken();
    const uid = await tree.uid();
    // publish applicant survey changes
    const changeFiles = await tree.changes.get(true);
    offlineLogger.debug('got changes', changeFiles);
    const fileDir = await getOrCreateHttpCache('files');
    offlineLogger.debug('file dir', fileDir);
    for (const { file, meta } of changeFiles) {
      const body = JSON.parse(await file.text());
      offlineLogger.debug('change body', body);
      for (const [key, value] of Object.entries<string>(body.changedKeys)) {
        try {
          offlineLogger.debug('change key', key, value);
          if (typeof value === 'object' || typeof value === 'function') {
            captureException(new Error(`value for key ${key} is not a string: ${value}`));
          }
          //TODO: simplify file claiming process
          if (typeof value === 'string' && value.includes('http://127.0.0.1')) {
            const newValue: string[] = [];
            const files = getURLsFromCSV(value);
            offlineLogger.debug('claiming files', value);
            for (const f of files) {
              // upload files and update key values with new URLs
              if (f.startsWith('http://127.0.0.1')) {
                offlineLogger.debug('claiming file', f);
                newValue.push(await this.processUploads(fileDir, f));
              } else {
                newValue.push(f);
              }
            }
            body.changedKeys[key] = newValue.join(',');
          }
          offlineLogger.debug('processed change key', key);
        } catch (err) {
          captureException(err);
          offlineLogger.error('error while processing change key', key, err);
        }
      }
      // TODO: remove need for token to be 
      // in the body of the request
      body.token = token;
      body.uid = uid;
      const { url, ...rest } = JSON.parse(await meta!.text());

      await this.throwIfError(this.executeRequest(new Request(
        url,
        {
          ...rest,
          body: JSON.stringify(body)
        }
      )));

      await tree.changes.delete(file.name);
      await publishSyncState({
        status: SyncStatus.InSync,
        id: uid,
      });

    }
  }

  async synchronize(only?: string[]) {
    const start = Date.now();
    await publishSyncState({
      status: SyncStatus.Processing
    });
    const failures: { uid: string, error: any }[] = [];
    const applicantDir = await getOrCreateHttpCache(['applicants']);
    for (const dir of await applicantDir.dirs()) {
      if (!only || only.includes(dir.name)) {
        const applicant = await toApplicant(dir);
        const state = await applicant.state();
        await publishSyncState(state);
        if (state.status !== SyncStatus.Unavailable) {
          try {
            await this.sync(applicant);
          } catch (error) {
            failures.push({
              uid: await applicant.uid(),
              error
            });
          }
        }
      }
    };

    const refreshes = (await applicantDir.dirs()).map(dir => async () => {
      const applicant = await toApplicant(dir);
      const lang = await getLang();
      // refresh baseline after sync is complete
      const metaDir = await getOrCreateKVPCache('meta');
      const { content: apiPath } = await metaDir.get({ key: 'apiPath' }, asStr);
      const getEverythingUrl = new URL('/applicant/get_everything', apiPath as string);
      const getEverythingReq = new Request(getEverythingUrl.toString(), {
        method: 'POST',
        body: JSON.stringify({ uid: dir.name, lang }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const refreshedBaseline = await this.throwIfError(this.executeRequest(getEverythingReq));
      await applicant.baseline.set({ req: getEverythingReq, resp: refreshedBaseline });
    });

    const refreshResults = await limitConcurrentRequests(refreshes, 5);

    offlineLogger.debug('results from applicant refresh', refreshResults);

    await publishSyncState({
      status: SyncStatus.InSync,
      failures,
      timeTaken: Date.now() - start
    });
  }

  private async throwIfError(response: Promise<Response>): Promise<Response> {
    const resp = await response;
    // TODO: remove JSON parsing once APIs all return non-200s for failures
    const body = await resp.clone().json().catch((error) => ({ error }));
    // HTTP 204 is No Content, so we can expect a JSON parsing error as its a null body.
    // This is mitigated by checking for the status first.
    if (!resp.ok || (resp.status !== 204 && body.error)) {
      throw new Error(`Retry Failed (HTTP ${resp.status}): ${body.error ?? resp.statusText}`);
    }
    return resp;
  }

  private async processUploads(fileDir: PermaCache<string, RequestEntry>, path: string) {
    // the first part of the path is always 'files'
    const { content } = await fileDir.get({ req: new Request(path, { method: 'PUT' }) });
    const { content: metaContent, meta } = await fileDir.get({ req: new Request(path, { method: 'OPTION' }) });
    const metadata = JSON.parse(await meta.text());

    const uploadUrl = new URL(metadata.headers['x-aidkit-upload-url']);
    const uploadUrlReq = new Request(uploadUrl.toString(), {
      method: 'POST',
      body: metaContent,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const uploadUrlResp = await this.throwIfError(this.executeRequest(uploadUrlReq));
    const { uploadURL, savedPath } = await uploadUrlResp.json();
    offlineLogger.info('upload and saved paths for new file', uploadURL, savedPath);

    const resp = await fetch(new Request(uploadURL, { method: 'PUT', body: content }));

    if (!resp.ok) {
      const error = await resp.clone().text();
      offlineLogger.error('upload failed', resp, error);
      captureException(new Error(`Upload Failed (HTTP ${resp.status}): ${error ?? resp.statusText}`));
    } else {
      offlineLogger.info('upload result', resp);
    }

    return savedPath;
  }
}

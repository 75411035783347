import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ClickableButton } from './Components/Button';
import { SurveyFieldsList } from './Components/SurveyFieldsList';
import { useSurveyTopology } from './Hooks/SurveyTopology';
import { useLocalizedStrings } from './Localization';
import { SpacedSpinner } from './Util';

export default function SurveyDesignPage(props: any) {

  const L = useLocalizedStrings();
  const [field, setField] = useState('');

  const surveyTopology = useSurveyTopology({});

  return <div className="flex flex-row">
    <SurveyFieldsList dmap={surveyTopology.dmap} onFieldClick={(key) => setField(key)} />
    <div className="ml-20 mb-40" style={{ maxWidth: "800px", padding: "10px" }}>
      <div className="p-3">
        <h1>{L.admin.survey_design.field_hierarchy}</h1>
        <Form>
          <Form.Group controlId="formForNumberEncryption">
            <Form.Label>{L.admin.survey_design.enter_a_target_field}</Form.Label>
            <Form.Control type="text" placeholder=""
              value={field}
              onChange={(e) => setField(e.target.value)} />
          </Form.Group>
        </Form>
        {surveyTopology.queue && surveyTopology.queue[field] ? <>
          Queue spot: {surveyTopology.queue[field]}
        </> : <></>}


        {surveyTopology.loadingDeps && <span>
          <SpacedSpinner /> {L.admin.survey_design.loading_dependency_graph}
        </span>}
        {!surveyTopology.loadingDeps && surveyTopology.depsError && (
          <span>{L.admin.survey_design.error_loading_deps} {surveyTopology.depsError}</span>
        )}
        {!surveyTopology.loadingDeps && !surveyTopology.depsError && field ? 
          <>
            <h3>
              {L.admin.survey_design.depends_on}
            </h3>
            {(surveyTopology.dmap[field] && surveyTopology.dmap[field].length > 0) && 
                        <div className="flex flex-wrap">{(surveyTopology.dmap[field] || []).map((key: string) => {
                          return <div className="m-1" key={`dmap-key-${key}`}>
                            <ClickableButton color="indigo" onClick={() => setField(key)}>
                              {key}
                            </ClickableButton>
                          </div>
                        })}</div>
            }
            {(!surveyTopology.dmap[field] || surveyTopology.dmap[field].length === 0) && <span>
              <em>{L.admin.survey_design.does_not_depend}</em>
            </span>}
            <hr/>
            <h3>
              {L.admin.survey_design.influences}
            </h3>
            {(surveyTopology.pdmap[field] && surveyTopology.pdmap[field].length > 0) && 
                        <div className="flex flex-wrap">
                          {(surveyTopology.pdmap[field] || []).map((key: string) => {
                            return <div className="m-1" key={`pdmap-key-${key}`}>
                              <ClickableButton color="indigo" onClick={() => setField(key)}>{key}</ClickableButton>
                                    &nbsp;
                            </div>
                          })}
                        </div>
            }
            {(!surveyTopology.pdmap[field] || surveyTopology.pdmap[field].length === 0) && <span>
              <em>{L.admin.survey_design.does_not_influence}</em>
            </span>}
          </> : <></>
        }
      </div>
    </div>
  </div>
}
import { enumerate, createLogger, DefaultColors, DefaultStyles, Logger, success } from "../utils";
import slugid from 'slugid';
import { v5 } from "uuid";
import { DirTree } from "./";


export type PermaOpts<E> = {
  keyPartsFn?(entry: E): string[],
  contentFn?(entry: E): Promise<ArrayBuffer>,
  metaFn?(entry: E): Promise<ArrayBuffer>,
  parent?: FileSystemDirectoryHandle,
  watcher?: CacheWatcher<E>,
};

export interface CacheWatcher<E> {
  onPut(cache: PermaCache<string, E>, key: string, entry: E, value: ArrayBuffer): Promise<void>;
  willPut?(cache: PermaCache<string, E>, key: string, entry: E, value: ArrayBuffer): Promise<void>;
  willDelete?(cache: PermaCache<string, E>): Promise<void>;
  onDelete?(cache: PermaCache<string, E>, key: string): Promise<void>;
  onErase?(cache: PermaCache<string, E>): Promise<void>;
  willErase?(cache: PermaCache<string, E>): Promise<void>;
};

export async function asStr(buf: ArrayBuffer): Promise<string> {
  return new TextDecoder().decode(buf);
}

export async function asJSON(buf: ArrayBuffer): Promise<any> {
  return JSON.parse(await asStr(buf));
}

/**
 * a low-level recursive API for storing and retrieving objects 
 * from persistent storage (specifically OPFS)
 *
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/File_System_API/Origin_private_file_system}
 *
 * Primarily, this is used to implement various {@link DirTree} types.
 */
export class PermaCache<T extends string, E> {

  private readonly logger: Logger;

  constructor(
    private readonly root: FileSystemDirectoryHandle,
    private readonly keyPartsFn: (entry: E) => string[],
    private readonly contentFn: (entry: E) => Promise<ArrayBuffer>,
    private readonly metaFn: (entry: E) => Promise<ArrayBuffer>,
    private readonly parent?: PermaCache<any, E>,
    private readonly watcher?: CacheWatcher<E>
  ) {
    this.logger = console
  }

  private async write(key: string, value: string | ArrayBuffer | ReadableStream) {
    const file = await this.root.getFileHandle(key, { create: true });
    const writable = await file.createWritable();
    await writable.write(value);
    await writable.close();
  }

  get name(): string {
    return this.root.name;
  }

  async put(entry: E): Promise<string> {
    try {
      const key = await this.computeKey(entry);
      const content = await this.contentFn(entry);

      if (this.watcher?.willPut) {
        this.watcher.willPut(this, key, entry, content);
      }

      await this.write(key, content);
      await this.write(key + '.meta', await this.metaFn(entry));

      if (this.watcher?.onPut) {
        this.logger.debug('triggering watcher', key, entry);
        await this.watcher.onPut(this, key, entry, content);
      }

      return key;
    } catch (e) {
      this.logger.error('unable to put entry', entry);
      throw e;
    }
  }

  async head<TObj = ArrayBuffer>(
    transform?: (buf: ArrayBuffer) => Promise<TObj>
  ): Promise<{ content: ArrayBuffer | TObj, handle: File, meta: File } | undefined> {
    const key = (await this.keys())[0];
    if (key) {
      return this.getByKey(key, transform);
    }
  }

  async get<TObj = ArrayBuffer>(
    entry: E,
    transform?: (buf: ArrayBuffer) => Promise<TObj>
  ): Promise<{ content: ArrayBuffer | TObj, handle: File, meta: File }> {
    try {
      const key = await this.computeKey(entry);
      return this.getByKey(key, transform);
    } catch (e) {
      this.logger.debug('unable to find file using entry', entry);
      throw e;
    }
  }

  async has(entry: E): Promise<boolean> {
    try {
      const key = await this.computeKey(entry);
      return (await this.keys()).includes(key);
    } catch {
      return false;
    }
  }

  async meta(entry: E): Promise<File> {
    const key = await this.computeKey(entry);
    const fh = await this.root.getFileHandle(key + '.meta');
    return await fh.getFile();
  }

  async getByKey<TObj = ArrayBuffer>(
    key: string,
    transform?: (buf: ArrayBuffer) => Promise<TObj>
  ): Promise<{ content: ArrayBuffer | TObj, handle: File, meta: File }> {
    const fileHandle = await this.root.getFileHandle(key);
    let meta = await this.root.getFileHandle(key + '.meta');
    const file = await fileHandle.getFile();
    let resp: ArrayBuffer | TObj = await file.arrayBuffer();
    return {
      content: transform ? await transform(resp) : resp,
      handle: file,
      meta: await meta.getFile()
    };
  }

  async delete(...entries: E[]) {
    const keys = await Promise.all(entries.map(entry => this.computeKey(entry)));
    await this.deleteByKey(...keys);
  }

  async deleteByKey(...entries: string[]) {
    // its a no-op if root doesn't exist
    if (this.parent && !(await success(this.parent?.root.getDirectoryHandle(this.root.name)))) {
      return;
    }
    for (const key of entries) {
      if (this.watcher?.willDelete) {
        await this.watcher.willDelete(this);
      }
      await this.root.removeEntry(key);
      await this.root.removeEntry(key + '.meta');
      if (this.watcher?.onDelete) {
        await this.watcher.onDelete(this, key);
      }
    }
  }

  async erase(): Promise<PermaCache<T, E> | undefined> {
    if (this.watcher?.willErase) {
      await this.watcher.willErase(this);
    }
    await this.root.remove({ recursive: true });
    if (this.watcher?.onErase) {
      await this.watcher.onErase(this);
    }
    return this.parent;
  }

  async keys(): Promise<string[]> {
    return (await this.values(false)).map(({ file: { name } }) => name);
  }

  async values(meta: boolean = false): Promise<{ file: File, meta?: File }[]> {
    return await Promise.all((await enumerate(this.root.values()))
      .filter(({ name }) => !name.endsWith('.meta'))
      .filter(f => f.kind === 'file')
      .map(async k => ({
        file: await (k as FileSystemFileHandle).getFile(),
        meta: !meta ? undefined : await this.root.getFileHandle(k.name + '.meta').then(f => f.getFile())
      })));
  }

  async down<U extends string>(path: T): Promise<PermaCache<U, E>> {
    return new PermaCache<U, E>(
      await this.root.getDirectoryHandle(path, { create: true }),
      this.keyPartsFn,
      this.contentFn,
      this.metaFn,
      this,
      this.watcher
    );
  }

  async into<U extends string, D extends DirTree<any>>(path: T, as: (dir: PermaCache<U, E>) => Promise<D>): Promise<D> {
    const dir = new PermaCache<U, E>(
      await this.root.getDirectoryHandle(path, { create: true }),
      this.keyPartsFn,
      this.contentFn,
      this.metaFn,
      this,
      this.watcher
    );

    return as(dir);
  }

  as<R extends DirTree<any>>(readerFn: (p: PermaCache<T, E>) => Promise<R>): Promise<R> {
    return readerFn(this);
  }

  async move(to: PermaCache<T, E>, ...keys: string[]) {
    const keysHere = await this.keys();
    const dirsHere = (await this.dirs()).map(d => d.name);
    // if no keys are provided, move everything
    if (!keys || keys.length === 0) {
      // ensures that we preserve chronology
      const filesHere = await this.values();
      filesHere.sort((a, b) => a.file.lastModified - b.file.lastModified);
      keys = filesHere.map(f => f.file.name).concat(dirsHere);
    }
    for (const key of keys) {
      if (keysHere.includes(key)) {
        const { handle, meta } = await this.getByKey(key);
        await to.write(handle.name, await handle.arrayBuffer());
        await to.write(meta.name, await meta.arrayBuffer());
        await this.deleteByKey(key);
      } else if (dirsHere.includes(key)) {
        const dir = await this.down(key as T);
        const newDir = await to.down(key as T);
        await dir.move(newDir);
        await dir.erase();
      }
    }
    return to;
  }

  /**
     * returns the storage size of this current directory
    */
  get storageSize(): Promise<number> {
    return (async () => {
      const files = await this.values(true);
      const size = files
        .map(({ file, meta }) => file.size + (meta?.size ?? 0))
        .reduce((a, b) => a + b, 0);

      const subDirs = await this.dirs();
      const subDirSizes = await Promise.allSettled(subDirs.map(d => d.storageSize));

      return size + subDirSizes.reduce((a, b) => a + (b.status === 'fulfilled' ? b.value ?? 0 : 0), 0);
    })();
  }

  /**
     * @example
     * ```typescript
     * const applicants = getOrCreateHttpCache('applicants');
     * // user is prompted to grant permissions to view and save files
     * const newParent = window.showDirectoryPicker();
     * const backup = getOrCreateHttpCache('applicants_backup', { parent: newParent });
     * await applicants.copy(backup);
     * // done
     * ```
    */
  async copy(to: PermaCache<T, E>, ...keys: string[]) {
    const keysHere = await this.keys();
    const dirsHere = (await this.dirs()).map(d => d.name);
    // if no keys are provided, move everything
    if (!keys || keys.length === 0) {
      // ensures that we preserve chronology
      const filesHere = await this.values();
      filesHere.sort((a, b) => a.file.lastModified - b.file.lastModified);
      keys = filesHere.map(f => f.file.name).concat(dirsHere);
    }
    for (const key of keys) {
      if (keysHere.includes(key)) {
        const { handle, meta } = await this.getByKey(key);
        await to.write(handle.name, await handle.arrayBuffer());
        await to.write(meta.name, await meta.arrayBuffer());
      } else if (dirsHere.includes(key)) {
        const dir = await this.down(key as T);
        const newDir = await to.down(key as T);
        await dir.copy(newDir);
      }
    }
    return to;
  }

  async import(from: FileSystemDirectoryHandle, 
    filter: (entry: FileSystemHandle) => boolean = () => true,
    renamer: (name: string, kind: 'file' | 'directory') => string = n => n) {
    for(const entry of (await enumerate(from.values())).filter(filter).sort((a, b) => a.name.localeCompare(b.name))) {
      switch(entry.kind) {
        case 'file': {
          const file = await (entry as FileSystemFileHandle).getFile();
          await this.write(
            renamer(file.name, entry.kind), 
            await file.arrayBuffer()
          );
        }
          break;
        case 'directory': {
          const dir = await this.down(renamer(entry.name, entry.kind) as T);
          await dir.import(entry as FileSystemDirectoryHandle, filter, renamer);
        }
          break;
        default:
          console.log('huh');
          break;
      }
    }
  }


  get parentDir(): PermaCache<T, E> | undefined {
    return this.parent;
  }

  get path(): string[] {
    return this.parent ? [...this.parent.path, this.name] : !!this.name ? [this.name] : [];
  }

  async dirs(): Promise<PermaCache<string, E>[]> {
    return Promise.all((await enumerate(this.root.values()))
      .filter(f => f.kind === 'directory')
      .map(d => this.down(d.name as T)));
  }

  private async computeKey(entry: E): Promise<string> {
    const keyBits = this.keyPartsFn(entry).join('__')
    return slugid.encode(v5(keyBits, v5.URL))
  }
}



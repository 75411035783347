import { Collection, Root, Subsurvey } from "../survey";

export function jsonReplace(obj: any, substitutions: {key: string, value: string}[]): any {
  if (!(String.prototype as any).replaceAll) {
    (String.prototype as any).replaceAll = function(str: string, newStr: string){
	
      // If a regex pattern
      if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
        return this.replace(str, newStr);
      }
	
      // If a string
      return this.replace(new RegExp(str, 'g'), newStr);
    };
  }

  // Sort substitutions to make sure we replace the longest strings first
  const sortedSubstitutions = substitutions.sort((a, b) => b.key.length - a.key.length);

  if (Array.isArray(obj)) {
    return obj.map((o: any) => jsonReplace(o, sortedSubstitutions));
  } else if (typeof obj === 'object') {
    return Object.keys(obj).reduce((o: any, k: string) => {
      o[k] = jsonReplace(obj[k], sortedSubstitutions);
      return o;
    }, {});
  } else if (typeof obj === 'string'){
    if (sortedSubstitutions.length) {
      let toReturn = obj as any;
      for (const {key, value} of sortedSubstitutions) {
        toReturn = toReturn.replaceAll(key, value);
      }
      return toReturn;
    }
  }
  return obj;
}

export function expandGenericTemplates(obj: any): any {
  if (Array.isArray(obj)) {
    const toReturn = obj.map((o: any) => expandGenericTemplates(o));
    return toReturn.reduce((acc, o) => {
      if(!o) {
        return acc;
      }
      else if (o.kind === 'Templated Block') {
        for (const iter of o.iterations) {
          const toInsert = jsonReplace(o.components, iter.substitutions);
          acc.push(...toInsert);
        }
      } else {
        acc.push(o);
      }
      return acc;
    }, []);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((o: any, k: string) => {
      o[k] = expandGenericTemplates(obj[k]);
      return o;
    }, {});
  }
  return obj;
}

export function addGeneratedSubsurveys(obj: Root): Root {
  function processCollection(collection: Collection): Collection {
    // Permissions are based on Collections, so we need to append subsurveys to their nearest parent Collection
    const localSubsurveysToAppend: Subsurvey[] = [];
    let newComponents = collection.components.flatMap((component: any) => {
      return processObjectOrArray(component, localSubsurveysToAppend);
    });

    // Filter subsurveys that don't already exist
    const subsurveysToAdd = localSubsurveysToAppend.filter(subsurvey =>
      !newComponents.some((component: any) => component.kind === 'Subsurvey' && component.path === subsurvey.path)
    );

    return { ...collection, components: [...newComponents, ...subsurveysToAdd] };
  }
  function processObjectOrArray(item: any, subsurveysToAppend: Subsurvey[]): any {
    // Create a subsurvey for each attachment that has allowSendingLinkToUpload set to true
    // these can be shared with applicants via linked subsurveys.
    if (item.kind === 'Attachment' && item.allowSendingLinkToUpload === true) {
      const subsurvey: Subsurvey = {
        kind: 'Subsurvey',
        path: item.targetField + '_automated_collection_form',
        defaultExpiration: {days: 1, weeks: 0, months: 0},
        hideFromNavigation: true,
        sections: [{
          kind: 'Section',
          // TODO: use localization once it is moved to it's own package so we don't have to maintain these translations here.
          title: {
            'en': 'Upload Document',
            'es': 'Subir documento',
            'zh_CN': '上传文档',
            'ar': 'تحميل الوثيقة',
            'fr': 'Télécharger le document',
          },
          components: [{
            ...item, // duplicate the attachment component
            allowSendingLinkToUpload: false,
          }]
        }]
      };
      subsurveysToAppend.push(subsurvey);
      return { ...item };
    } else if (item.kind === 'Collection') {
      return processCollection(item);
    } else if (Array.isArray(item)) {
      return item.map(i => processObjectOrArray(i, subsurveysToAppend));
    } else if (item.survey) {
      item.survey = processObjectOrArray(item.survey, subsurveysToAppend);
    } else if (['Section', 'Conditional Block'].includes(item.kind)) {
      item.components = processObjectOrArray(item.components, subsurveysToAppend);
    } else if (['Subsurvey'].includes(item.kind)) {
      item.sections = processObjectOrArray(item.sections, subsurveysToAppend);
    }
    return item;
  }

  const subsurveysToAppend: Subsurvey[] = [];
  const expanded = processObjectOrArray(obj, subsurveysToAppend);

  if (subsurveysToAppend.length) {
    // append the subsurveys to the root of the expanded survey if they aren't in a Collection
    if (Array.isArray(expanded)) {
      return [
        ...expanded,
        ...subsurveysToAppend.filter(subsurvey => !expanded.some((component: any) => component.kind === 'Subsurvey' && component.path === subsurvey.path))
      ];
    } else {
      expanded.survey = [
        ...expanded.survey,
        ...subsurveysToAppend.filter(subsurvey => !expanded.survey.some((component: any) => component.kind === 'Subsurvey' && component.path === subsurvey.path))];
      return expanded;
    }
  }

  return expanded;
}
import { EllipsisHorizontalCircleIcon, ArrowDownTrayIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useRef, useState } from "react";
import { useToast } from "@aidkitorg/component-library";
import { usePost } from "../API";
import { OnlineAwarenessContext, PublicConfigurationContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import { getOrCreateHttpCache } from "../offline";
import { ApplicantTree, toApplicant } from '../offline/routes';
import { bottleneck } from "../utils";

export default function DLOfflineButton(props: { applicants: Record<string, any>[], size?: number }) {
  const publicConfig = useContext(PublicConfigurationContext);

  if (!publicConfig.experimental?.enableOfflineMode || !navigator.serviceWorker) {
    return <></>;
  }

  return <DLOfflineButtonInner {...props} />
}

function DLOfflineButtonInner(props: { applicants: Record<string, any>[], size?: number }) {
  const { online } = useContext(OnlineAwarenessContext);
  const getEverything = usePost('/applicant/get_everything');
  const L = useLocalizedStrings();
  const [undownloaded, setUndownloaded] = useState<Record<string, any>[]>([]);
  const [downloading, setDownloading] = useState(false);
  const [tooltip, setTooltip] = useState<string>('');
  const offlineFileLastModified = useRef<number | null>(null);
  const [trackers, setTrackers] = useState<{ tracker: ApplicantTree, applicant: Record<string, any> }[]>([]);
  const { toast } = useToast();
  
  useEffect(() => {
    (async () => {
      const dir = await getOrCreateHttpCache('applicants');
      setTrackers(
        await Promise.all(
          props.applicants.map(async applicant =>
            ({ applicant, tracker: await dir.into(applicant.uid, toApplicant) })
          )
        )
      );
    })();
  }, [props.applicants]);

  useEffect(() => {
    (async () => {
      if (!props.applicants) {
        setTooltip(L.offline.download_applicants);
        return;
      }

      const notFetched: Record<string, any>[] = [];
      const fetched: Record<string, any>[] = [];
      for (const { tracker, applicant } of trackers) {
        if (await tracker.valid()) {
          fetched.push(applicant);
        } else {
          notFetched.push(applicant);
        }
      }
      setUndownloaded(notFetched);

      // this is a very expensive I/O operation, and can kill the framerate unless its done once.
      if (!offlineFileLastModified.current) {
        offlineFileLastModified.current = (await Promise.allSettled(
          trackers.map(t => t.tracker)
            .filter(async t => await t.valid())
            .map(async i => (await i.baseline.get()).file.lastModified)
        ))
          .flat()
          .map(r => r.status === 'rejected' ? Number.MAX_VALUE : r.value)
          .reduce((a, b) => a !== null ? Math.min(a, b) : b, null as null | number);
      }

      const name = (arr: Record<string, any>[]) => {
        return arr.length !== 1 ?
          `${arr.length} ${L.offline.offline_applicants}` :
          arr[0].legal_name || arr[0].first_name || '';
      };
      if (offlineFileLastModified.current !== null) {
        setTooltip(`${name(fetched)} ${L.offline.downloaded_on} ${new Date(offlineFileLastModified.current!)}`)
      } else {
        setTooltip(L.offline.download_n_applicants.replace("$n", name(notFetched)));
      };
    })()
  }, [props.applicants, trackers, downloading, offlineFileLastModified]);

  let download: () => Promise<void> = async () => {
    if (!online || downloading || props.applicants.length === 0) {
      return;
    }

    setDownloading(true);
    let toFetch = undownloaded;
    let refresh = false;
    if (undownloaded.length === 0) {
      if (confirm(L.offline.offline_refresh)) {
        refresh = true;
        toFetch = props.applicants;
      } else {
        setDownloading(false);
        return;
      }
    }
    if (toFetch.length === 1) {
      const { uid, first_name, legal_name } = toFetch[0];
      try {
        await getEverything({ uid });
        toast({
          description: `${refresh ? L.offline.refresh_success : L.offline.download_success} ${legal_name || first_name || ''}`,
          variant: 'success'
        });
      } catch {
        toast({
          description: `Unable to download ${legal_name || first_name || ''}`,
          variant: 'error'
        });
      }
    } else if (refresh || confirm(L.offline.download_confirmation.replace("$n", toFetch.length.toString()))) {
      const dl = await bottleneck(
        toFetch.flatMap(a => () => getEverything({ uid: a.uid })),
        100
      );
      toast({
        description: (refresh ? L.offline.download_n_applicants : L.offline.download_n_success).replace("$n", (dl.length).toString()),
        variant: 'success'
      });
    }
    offlineFileLastModified.current = null;
    setDownloading(false);
  };

  if (!props.applicants) {
    return (<></>);
  }

  const color = Date.now() - (offlineFileLastModified.current || Date.now()) < (24 * 60 * 60 * 1000) ?
    'green' :
    'yellow';

  const downloaded = undownloaded.length === 0;
  const size = props.size ?? 30;

  return (<span
    title={tooltip}
    className="absolute group">
    <div className={`${downloaded && !downloading ? 'visible' : 'invisible'}`}>
      <CheckCircleIcon width={size} height={size} className={`absolute text-${color}-400 group-hover:hidden`} />
      <ArrowPathIcon width={size} height={size} className={`absolute hidden group-hover:inline-block text-blue-500 cursor-pointer`} onClick={download} />
    </div>
    <EllipsisHorizontalCircleIcon width={size} height={size} className={`absolute ${!downloading ? "invisible" : ""} text-gray-500 animate-pulse`} />
    <ArrowDownTrayIcon width={size} height={size} className={`absolute ${(downloaded || downloading || !online) ? "invisible" : ""} hover:text-green-500 cursor-pointer`} onClick={download} />
  </span>
  );
}

import { useContext } from "react";
import { OnlineAwarenessContext } from "./Context";
import { useLocalizedStrings } from "./Localization";

export default function OfflineBanner() {

  const { online } = useContext(OnlineAwarenessContext);
  const L = useLocalizedStrings();

  if (online) {
    return <></>;
  }

  return (
    <div className={`h-7 bg-sky-500 text-center fixed top-auto bottom-0 w-full mt-2`}>
      <strong>-- {L.ahh.currently_offline} --</strong>
    </div>
  );
}

import { useToast } from "@aidkitorg/component-library";
import { OAuthResponse, OAuthState } from "@aidkitorg/types/lib/auth/sso";
import * as Sentry from "@sentry/react";
import { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { usePost } from "./API";
import { UserInfoContext } from "./Context";
import { SpacedSpinner } from "./Util";
import { useLocalizedStrings } from "./Localization";

// This is used to handle the callback from Google during an OAuth flow
// Google will send a user back to tenant.aidkit.org/auth/google/callback 
// and we'll render this component to finish the last step in OAuth.
//
// Note that since wildcard redirect URIs are not allowed, we may receive
// an OAuth callback for a different program. This is expected and we'll 
// determine which tenant the user should be redirected to through the clientDomain
// supplied by OAuth state and redirect them there.
const GoogleCallback = () => {
  const L = useLocalizedStrings();
  const history = useHistory();
  const [, setCookie,] = useCookies(["auth_token"]);
  const userContext = useContext(UserInfoContext);
  const verifyOAuthCode = usePost("/authenticate/google/verify");
  const { toast } = useToast();

  const oAuthPayload = new URLSearchParams(window.location.search)
  const oAuthCode = oAuthPayload.get('code');
  const oAuthState = oAuthPayload.get('state');

  useEffect(() => {
    const oAuthParsedState: OAuthState = oAuthState ? JSON.parse(Buffer.from(oAuthState, 'base64').toString('binary')) : {};

    // Early return if there is no OAuth code or state 
    // OR if we weren't provided a clientDomain which we need to 
    // redirect the user to the right tenant.
    if (!oAuthCode || !oAuthState || !oAuthParsedState.clientDomain) {
      history.push("/login");
      toast({
        title: "SSO Error",
        description: "Error authenticating",
        variant: "error"
      })
      return;
    }

    // Redirect to the correct tenant based on the state payload
    if (window.location.origin !== oAuthParsedState?.clientDomain) {
      window.location.href = `${oAuthParsedState.clientDomain}/auth/google/callback?code=${oAuthCode}&state=${oAuthState}`;
      return;
    }

    // Validate the oauth token from Google and authenticate
    const validateToken = async () => {
      const result: OAuthResponse = await verifyOAuthCode({
        code: oAuthCode,
        state: oAuthState || ''
      });

      if (!result || 'error' in result || !result.authToken || result.oAuthStateToken !== sessionStorage.getItem('oauth_state_token')) {
        sessionStorage.removeItem('oauth_state_token');
        history.push("/login");
        toast({
          title: "SSO Error",
          description: "Authentication error",
          variant: "error"
        })
        return;
      }

      // Create auth token that self-expires after 86400 seconds (~1 rotation of the earth -- 24 hours)
      setCookie("auth_token", result.authToken, {
        maxAge: 86400, path: "/"
      });

      // Causes the UserInfoWrapper which provides UserInfoContext to call /user/info again now that we have a verified token
      if (userContext?.refreshContext) {
        userContext.refreshContext();
      } else {
        Sentry.captureMessage('Could not refresh UserInfoContext, callback missing', 'warning');
      }

      // Remove temp oauth state token after successful auth
      sessionStorage.removeItem('oauth_state_token');

      // Find the place they came in on.
      history.push(result.next || '/');
    }
    validateToken();
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="sr-only">{L.applicant.loading}</div>
      <SpacedSpinner />
    </div>
  );
}

export default GoogleCallback;

import { useState } from "react";
import { usePost } from "../API";
import { BUTTON_CLASS } from "../Util";

export function DeleteApplicants(props: any) { 
  const [uids, setUids] = useState('');

  const deleteApplicants = usePost('/admin/delete_applicants');

  const submit = async () => {
    alert(JSON.stringify(await deleteApplicants({  
      userIds: uids.replace(/\n/g, " ").replace(/,/g, " ").split(" ").filter((l: string) => l.length > 0).map(t => t.trim()) 
    })));
  }

  return (
    <div>
      <fieldset>
        <legend>UIDs (comma, line, or space separated)</legend>
        <textarea value={uids} onChange={(e) => setUids(e.target.value)} 
          className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
      </fieldset>
      <button className={BUTTON_CLASS} onClick={submit}>Delete Applicants</button>
      <div className='my-4'>Note: this function can only be run by Super Admins</div>
    </div>
  )
}
import colors from 'tailwindcss/colors';
import React from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}  

export function ClickableButton(props: {
  color: "blue" | "gray" | "indigo" | "red" | "green",
  colorIntensity?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900,
  onClick: (e: any) => void,
  disabled?: boolean,
  children?: React.ReactNode,
  extraClasses?: string,
}) {
  const intensity = props.colorIntensity || 100;
  const hoverIntensity = intensity + 100;

  const borderColor = "border-" + props.color + "-" + intensity;
  const bgColor = "bg-" + props.color + "-" + intensity;

  const hoverBgColor = "hover:bg-" + props.color + "-" + hoverIntensity;
  const extraClasses = props.extraClasses || '';

  return <button disabled={props.disabled} onClick={props.onClick} type="button"
    className={classNames('inline-flex items-center px-4 py-2 border', 
      borderColor, extraClasses, props.disabled ? 'bg-gray-300 hover:bg-gray-300' : bgColor + ' ' + hoverBgColor,
      'text-sm font-medium rounded-md text-gray-700',
      "focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500")}>
    {props.children}
  </button>
}

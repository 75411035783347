import { BucketDate, DashboardDescription, NumberFields, ValueExpr } from "@aidkitorg/types/lib/survey";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { Bar, BarChart as RechartsBarChart, Tooltip, CartesianGrid, XAxis, YAxis, Cell, LabelList, TooltipProps, Legend } from "recharts"
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { snakeToEnglish, sortRecordsInPlace } from "../Util";
import { stringToColor, getFormattedNumber } from './ChartTools';
import { FullScreenModal } from "./FullScreenModal";

const label = (data: any, key: string, isModal: boolean) => function(params: {
  x?: any,
  y?: any,
  value?: any,
  width?: any,
  height?: any,
}) {
  let { x, y, width, height, value } = params;
  const radius = 10;
  width ||= 0;
  height ||= 0;
  value ||= '';

  return (
    <g>
      <text
        fontSize={isModal ? 16 : 12}
        x={x + width / 2 - 10} y={y + height} transform={`rotate(90, ${x + width / 2}, ${y + height})`} fill="#6B7280" textAnchor="end" dominantBaseline="central">
        {value}
      </text>
    </g>
  );
};

const CustomTooltip = (label2: string, bucket: BucketDate["bucket"], numberFormat?: NumberFields) => ({ active, payload, label, }: TooltipProps<ValueType, NameType>) => {
  if (active && payload?.[0]?.payload) {
    return (
      <div className="bg-white shadow-xl rounded-lg p-2">
        <b>{bucket ? formatTime(payload?.[0].payload[label2], bucket).join('') : `${snakeToEnglish(payload?.[0].payload[label2] || '' as string)}`}</b><br />
        {payload.map((p) => (
          <>
            {`${snakeToEnglish(p.dataKey as string)}: ${getFormattedNumber(Array.isArray(p.value) ? p.value[0] : p.value, numberFormat)}`}
            <br />
          </>
        ))}
      </div>
    );
  }

  return null;
};

const TooltipWithUnits = (numberFormat?: NumberFields) => ({ active, payload, label, }: TooltipProps<ValueType, NameType>) => {
  if (active && payload) {
    return (
      <div className="bg-white shadow-xl rounded-lg p-2">
        {payload.map(p => {
          return (
            <span style={{ color: stringToColor(p.dataKey as string) }}>
              {`${p.dataKey}: ${getFormattedNumber(Array.isArray(p.value) ? p.value[0] : p.value, numberFormat)}`}<br />
              <br />
            </span>
          );
        })}
			
      </div>
    );
  }

  return null;
};

function formatTime(time: string, bucket: BucketDate["bucket"]): string[] {
  const date = new Date(time);
  if (isNaN(date.getTime())) throw new Error('Invalid format');

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hour = date.getUTCHours().toString().padStart(2, '0');
  const minute = date.getUTCMinutes().toString().padStart(2, '0');

  switch (bucket) {
    case 'minute':
      return [`${month}-${day} ${hour}:${minute}`];
    case 'hour':
      return [`${month}-${day} ${hour}:00`];
    case 'day':
      return [`${year}-${month}-${day}`];
    case 'week':
      const weekDate = new Date(date);
      weekDate.setUTCDate(date.getUTCDate() - date.getUTCDay());

      const startDate = `${weekDate.getUTCFullYear()}-${(weekDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${weekDate.getUTCDate().toString().padStart(2, '0')}`;

      weekDate.setUTCDate(weekDate.getUTCDate() + 6);

      const endDate = `${weekDate.getUTCFullYear()}-${(weekDate.getUTCMonth() + 1).toString().padStart(2, '0')}-${weekDate.getUTCDate().toString().padStart(2, '0')}`;

      return [`${startDate} - `, `${endDate}`];
    case 'month':
      return [`${year}-${month}`];
    case 'year':
      return [`${year}`];
    default:
      throw new Error('Invalid bucket');
  }
}

export function BarChart(props: {
  data: Record<string, any>[],
  groupKeys: string[],
  summaryKey: string,
  timeSeriesGroupIndex?: number,
  timeSeriesBucket?: BucketDate["bucket"],
  order?: 'ascending' | 'descending',
  isCustom?: boolean,
  hideXAxisLabels?: boolean,
  height?: number,
  width?: number,
  stack?: boolean,
  stackKeys?: string[],
  totalKey?: string,
  hideTotal?: boolean,
  units?: string,
  description?: string,
  descriptionPlacement?: DashboardDescription['placement'],
  isExpanded: boolean,
  numberFormat?: NumberFields,
  showBarLabels?: boolean,
}) {  
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const descPlacement = props.descriptionPlacement ?? 'bottom';

  const numberFormat: NumberFields = {
    ...props.numberFormat || {},
    // TODO: migrate type 'units' to 'numberFormat.suffix' for consistent use of NumberFields
    suffix: props.units || props.numberFormat?.suffix, // merge units and suffix, which specify the same format
  };

  useEffect(() => {
    const updateDimensions = () => {
      setChartWidth(window.innerWidth * 0.9);
      setChartHeight(window.innerHeight * 0.8);
    };
    window.addEventListener("resize", updateDimensions);
    updateDimensions();
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  let totalCount: number;
  let data = props.data;
  const stacks = new Set<string>();

  if (props.isCustom) {
    // Sum all counts over all groupKeys (columns)
    totalCount = data.reduce(
      (acc, v) => acc += (
        props.totalKey
          ? Number(v[props.totalKey])
          : props.groupKeys.reduce(                
            (acc, currentKey) => acc += Number((v[currentKey] ?? 0)), 
            0
          )
      ),
      0
    );
  } else {
    data = data.filter(d => !!d[props.groupKeys[0]]);

    data.forEach(d => {
      d[props.summaryKey] = Number(parseFloat(d[props.summaryKey]).toFixed(2));
      if (props.timeSeriesGroupIndex !== -1) {
        const asDate = new Date(d[props.groupKeys[0]]);
        d['time'] = asDate.getTime();
      }
      d[props.groupKeys[0]] = snakeToEnglish(d[props.groupKeys[0]]);

      // If we have stack keys, get the stacks ready to graph.
      if (props.stackKeys) {
        props.stackKeys.forEach(s => {
          let stackValue = d[s];
          if (stackValue) {
            d[stackValue] = d[props.summaryKey];
            stacks.add(stackValue);
          }
        })
      }
    });

    totalCount = data.reduce((prev, cur) => prev + cur.count_applicants, 0);

    if (props.timeSeriesGroupIndex !== -1) {
      data.sort((a, b) => a.time - b.time);
    } else if (props.order && data[0]) {
      // [0] is the count, so we use [1] to sort
      const key = Object.keys(data[0])[1];
      sortRecordsInPlace(data, key, props.order)
    }

  }

  const labels = label(data, props.groupKeys[0], props.isExpanded);

  const CustomTickLabel = (props: any) => {
    const { x, y, payload, bucket } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize={ props.isExpanded ? 14 : 12 }>
          { bucket ? formatTime(payload.value, bucket).map((t: string, index: number) => (
            <tspan key={index} x={0} dy={index === 0 ? 0 : 16}>{t}</tspan>
          )) : payload.value }
        </text>
      </g>
    );
  };

  const CustomBarLabel = (props: any) => {
    const { x, y, width, value, numberFormat } = props;
    const numberValue = Number(value);
    // Skip rendering if value is zero, null, undefined, or not a number
    if (!numberValue || isNaN(numberValue)) return null;

    return (
      <text
        x={x + width / 2} // Position the label in the center of the bar
        y={y} // Position the label above the bar
        dy={-6} // Adjust vertical position slightly above the bar
        fill="#666"
        textAnchor="middle" // Center align the text horizontally
      >
        {getFormattedNumber(numberValue, numberFormat)}
      </text>
    );
  };

  // for stacks / groupings to work, each stack value needs to be in the same data point per x value.
  if (stacks.size) {
    let dataPerXValue: Record<string, Record<string, any>> = {};
    data.forEach(d => {
      const xValue = d[props.groupKeys[0]];
      if (!dataPerXValue[xValue]) {
        dataPerXValue[xValue] = {};
      }

      dataPerXValue[xValue] = {
        ...dataPerXValue[xValue], 
        ...d
      }
    });

    data = Object.values(dataPerXValue);
  }

  const yAxisDomain = numberFormat.suffix === '%' ? [0, 100] : undefined;
  const tooltipContent = props.isCustom || !props.timeSeriesBucket
    ? TooltipWithUnits(numberFormat)
    : CustomTooltip(props.groupKeys[0], props.timeSeriesBucket, numberFormat);

  const renderChart = (width: number, height: number) => {
    if (props.isCustom) {
      return <RechartsBarChart width={width} height={height} data={data} className='m-auto'>
        <YAxis domain={yAxisDomain} />
        {props.groupKeys.map((key, i) => (
          <Bar
            key={key}
            dataKey={key}
            stackId={props.stack ? 'same' : i}
            fill={stringToColor(key)}
            label={props.showBarLabels && <CustomBarLabel numberFormat={numberFormat} />}
          />
        ))}
        <XAxis
          dataKey={props.summaryKey}
          angle={-35} // This is needed here even though we rotate later for the inner layout engine to work properly
          height={70}
          hide={props.hideXAxisLabels}
          tick={<CustomTickLabel />}
        />
        <Legend />
        <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0.2)' }} content={React.createElement(tooltipContent)} />
      </RechartsBarChart>
    }

    if (stacks.size) {
      return <RechartsBarChart width={width} height={height} data={data} className='m-auto'>
        <YAxis domain={yAxisDomain}/>
        <XAxis
          dataKey={props.groupKeys[0]}
          angle={-35}
          height={70}
          hide={props.hideXAxisLabels}
          tick={<CustomTickLabel bucket={props.timeSeriesBucket} />}
        />
        {Array.from(stacks).map((key, i) =>
          <Bar
            dataKey={key}
            stackId={props.stack ? 'same' : i}
            fill={stringToColor(key)}
            label={props.showBarLabels && <CustomBarLabel numberFormat={numberFormat} />}
          />
        )}
        <Legend />
        <Tooltip
          cursor={{ fill: 'rgba(0, 0, 0, 0.2)' }}
          content={React.createElement(tooltipContent)}
        />
      </RechartsBarChart>
    }
    return (
      <RechartsBarChart width={width} height={height} data={data} className='m-auto'>
        <YAxis domain={yAxisDomain} />
        <Bar
          dataKey={props.summaryKey}
          fill="#000"
          barSize={data.length > 1 ? undefined : 100}
          label={props.showBarLabels && <CustomBarLabel numberFormat={numberFormat} />}
        >
          {data?.map((datum, i) => <Cell key={i}
            fill={stringToColor(props.timeSeriesGroupIndex !== -1 ? props.groupKeys[0] : datum[props.groupKeys[0]]) }
          />)}
          {props.timeSeriesGroupIndex !== -1 ? <></> : <LabelList content={labels} dataKey={props.groupKeys[0]} textBreakAll={false} position="inside" angle={-90} fill="#fff" />}
        </Bar>
        {props.timeSeriesGroupIndex !== -1 ? <XAxis
          dataKey='time'
          domain={['auto', 'auto']}
          name='Time'
          tickFormatter={time => ''}
          tickLine={false}
          type='number'
          angle={-90}
          padding={'no-gap'}
        /> : <XAxis
          dataKey={'empty'}
          angle={-45}
          padding={'no-gap'}
        />}
        <Tooltip
          cursor={{ fill: 'rgba(0, 0, 0, 0.2)' }}
          content={React.createElement(tooltipContent)}/>
      </RechartsBarChart>
    );
  };

  if (props.isExpanded) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <dl className="w-full h-full">
          <dt hidden={!props.description || descPlacement !== 'top'} className="text-gray-400 text-center">
            {props.description}
          </dt>
          <dd className="mt-1 font-medium">
            {renderChart(chartWidth, chartHeight)}
          </dd>
        </dl>
        {(!props.hideTotal && !!totalCount) && <div>{'Total: ' + getFormattedNumber(totalCount, numberFormat)}</div>}
        <div hidden={!props.description || descPlacement !== 'bottom'} className="text-gray-400 text-center">
          {props.description}
        </div>
      </div>
    );
  }

  return (
    <div>
      <dl>
        <dt hidden={!props.description || descPlacement !== 'top'} title={props.description} className="text-gray-400 max-w-xs truncate text-center">
          {props.description}
        </dt>
        <dd className="mt-1 font-medium">
          {renderChart(props.width ?? 350, props.height ?? 300)}
        </dd>
      </dl>
      {(!props.hideTotal && !!totalCount) && <div>{'Total: ' + getFormattedNumber(totalCount, numberFormat)}</div>}
      <div hidden={!props.description || descPlacement !== 'bottom'} title={props.description} className="text-gray-400 max-w-xs truncate text-center">
        {props.description}
      </div>
    </div>
  );
}

import { useLocalizedStrings } from "../Localization";
import * as survey from "@aidkitorg/types/lib/survey";
import { ReactNode, useContext } from "react";
import { encodeQueueForExplore } from "../Explore";
import InterfaceContext, { PublicConfigurationContext } from "../Context";
import { usePost } from "../API";
import { openWindowWithLoadingContent } from "../LoadingTabUtil";
import { useToast } from "@aidkitorg/component-library";
import { useHistory } from "react-router-dom";

type QueueProps = {
  component: survey.Queue,
  sqlButton: ReactNode,
  refreshButton: ReactNode,
  numberInQueue: number,
  activeFilter?: survey.BooleanExpr,
  queryExecutionTimeMs?: ReactNode,
  publicAccessKey?: string,
  localizedTitle?: string,
  lastUpdatedAt?: number,
}

export default function Queue(props: QueueProps) {
  const L = useLocalizedStrings();
  const publicContext = useContext(PublicConfigurationContext);
  const context = useContext(InterfaceContext);
  const takeApplicant = usePost('/queues/take_applicant');
  const history = useHistory();
  const { toast } = useToast();

  async function confirmAndClaimApplicant(queue: survey.Queue): Promise<void> {
    if (window.confirm(L.dashboard.claim_applicant_confirmation)) {
      // This is required for safari to not block the popup in an async handler
      const win = openWindowWithLoadingContent();

      let resp: Awaited<ReturnType<typeof takeApplicant>>;
      try {
        resp = await takeApplicant({ queue: queue });
      } catch (e) {
        console.warn("Error: ", e)
        toast({
          description: L.dashboard.claim_applicant_error,
          variant: 'error'
        });
        win?.close();
        return;
      }

      if ('applicant' in resp) {
        const appPath = survey.interfaceNumber(publicContext?.interface?.version) >= 1 ? '/a/' : '/applicant/';
        const loc = appPath + resp.applicant + (queue.startSection
          ? (typeof queue.startSection === 'string' ? '#' + encodeURIComponent(queue.startSection) : '/' + queue.startSection.path)
          : '');
        if (win) {
          win.location = loc;
          win.focus();
        } else {
          history.push(loc)
        }
        toast({
          description: L.dashboard.applicant_claimed,
          variant: 'success'
        });
      } else {
        toast({
          description: L.dashboard.claim_applicant_error + ' ' + resp.error,
          variant: 'error'
        });
        win?.close();
      }
    }
  }

  if (props.publicAccessKey) {
    return null;
  }

  return (
    <div className="mt-4 clear-both border-gray-300 border-solid border-l-4 border-r-0 border-y-0 px-4">
      <h2>{`${L.dashboard.queue}: ${props.localizedTitle}`}</h2>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-4 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {props.sqlButton}{props.refreshButton}{L.dashboard.number_of_applicants_in_queue}
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {L.dashboard.allowed_users}
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {L.dashboard.relevant_survey_section}
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {L.dashboard.description}
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {(publicContext.experimental?.showLastUpdatedOnDashboards && props?.lastUpdatedAt) && (
                      <span className="text-gray-400 text-xs">
                        {L.dashboard.updated} {new Date(props.lastUpdatedAt).toLocaleTimeString([], { timeZoneName: 'short' })}
                      </span>
                    )}
                  </th>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {props.queryExecutionTimeMs}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <tr>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{props.numberInQueue}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{props.component.allowedUserTags.join(', ')}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{typeof props.component.startSection === 'string' ? props.component.startSection : props.component.startSection.path}</td>
                  <td className="whiatespace-nowrap px-3 py-4 text-sm text-gray-500">{props.component.description ? props.component.description[context.lang] : ''}</td>
                  {!props.component.hideExploreButton &&
                    <td key='view' className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <a href={'/explore?run=yes#' + encodeQueueForExplore(props.component, props.activeFilter)} target='_blank' className="text-indigo-600 hover:text-indigo-900">
                        {L.dashboard.explore_queue}
                      </a>
                    </td>
                  }
                  <td key='take' className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <button
                      className={`${props.numberInQueue > 0 ? "cursor-pointer text-gray-500 hover:text-blue-500 bg-slate-50 hover:bg-blue-200 border-solid border-2 border-blue-200 hover:border-blue-400" : "border-none"} ml-2 rounded-xl p-2`}
                      disabled={props.numberInQueue === 0}
                      onClick={async () => confirmAndClaimApplicant(props.component as survey.Queue)}>
                      {L.applicant.claim_applicant}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
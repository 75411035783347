import { User, UserGroup, Users } from "@aidkitorg/types/lib/survey";
import { parseTableToUsers } from "@aidkitorg/types/lib/translation/excelToUsers";
import React, { Suspense, useEffect, useRef } from "react";
import { useState } from "react";
import { get_deployment, get_host, useAPIPost, usePost } from "../API";

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

export function UserImportPage () {
  const [toImport, setToImport] = useState('');
  const [parsed, setParsed] = useState<User[]>([]);
  const [orgLookup, setOrgLookup] = useState<Record<string, string>>({});
  let distroRef = useRef<React.ComponentRef<typeof Distro>>(null);
  const updateUsers = usePost('/survey/update_users');

  const program = get_deployment();
  const listLocations = { 
    'sifh': 'https://aidkit-public.s3.us-east-2.amazonaws.com/sifh/sifh_cbo-list.json',
    'postgres': `${get_host().split(':8001')[0]}:4324/aidkit-public/postgres/postgres_cbo-list.json`,
    'sifh2': 'https://aidkit-public.s3.us-east-2.amazonaws.com/sifh2/sifh2_cbo-list.json'
  } as Record<string, string | undefined>;

  useEffect(() => {
    (async () => {
      if (!listLocations[program]) return;

            type Resp = {
              id: string,
              text: string
            }[]

            const orgList = listLocations[program]!;
            const list = await (await fetch(orgList)).json() as Resp
            let orgLookup = {} as any;
            for (const org of list) {
              orgLookup[org.text] = org.id
            }
            setOrgLookup(orgLookup)
            if (process.env.NODE_ENV === 'development') console.log("Org lookup:", orgLookup)
    })()
  }, []);

  useEffect(() => {
    try {
      const users = parseTableToUsers(toImport, orgLookup);
      console.log(users)
      distroRef.current?.initialize(users)
    } catch {

    }
  }, [toImport]);

  async function commit() {
    if (!confirm('Are you sure you want to update all users? Any users that you have not pasted will be disabled (and no longer be able to log in)')) return;

    alert(JSON.stringify(await updateUsers({
      content: toImport
    })));
  }

  return <div className="bg-gray-100 h-full p-4">
    <h2 className="">IEL Spreadsheet Content</h2>
    
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <div className="relative">
          <div className="overflow-hidden bg-white rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Paste the full user spreadsheet here (select all, copy and paste here)
            </label>
            <textarea
              value={toImport} 
              onChange={(e) => setToImport(e.target.value)}
              rows={3}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Paste the full user spreadsheet here (select all, copy and paste here)"
              defaultValue={''}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
            </div>
            <div className="flex-shrink-0">
              <button
                onClick={commit}
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Publish 
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <h2 className="mt-4">Parsed Preview</h2>
    <div className="bg-white">
      <Suspense>
        <Distro value={parsed} ref={distroRef} types='src/survey.ts' name='Users'/>
      </Suspense>
    </div>
  </div>;
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DERIVED_TYPES = exports.DERIVED_MODELS = void 0;
console.time("Loading Derived");
var TemplateDummy_1 = require("./TemplateDummy");
var Address_1 = require("./Address");
var BankRoutingNumber_1 = require("./BankRoutingNumber");
var BenefitsCalculator_1 = require("./BenefitsCalculator");
var Flag_1 = require("./Flag");
var Contract_1 = require("./Contract");
var Encrypted_1 = require("./Encrypted");
var CardManagement_1 = require("./CardManagement");
var DeviceSetup_1 = require("./DeviceSetup");
var DetectFaces_1 = require("./DetectFaces");
var Other_1 = require("./Other");
var Lookup_1 = require("./Lookup");
var HouseholdCalculation_1 = require("./HouseholdCalculation");
var IncomeCalculation_1 = require("./IncomeCalculation");
var ExtractText_1 = require("./ExtractText");
var Payment_1 = require("./Payment");
var ContactConfirmation_1 = require("./ContactConfirmation");
var PlaidBankAccount_1 = require("./PlaidBankAccount");
var ApplicantCreator_1 = require("./ApplicantCreator");
var ScanBarcode_1 = require("./ScanBarcode");
var LiveAttachment_1 = require("./LiveAttachment");
/** Derived Models:
 * Each key must be the field type or Additional Option present which designates it as having derived fields.
 * Each value should point to a const of type DerivedField (see types.ts)
 * */
exports.DERIVED_MODELS = {
    Address: Address_1.Address,
    ApplicantCreator: ApplicantCreator_1.ApplicantCreator,
    'Bank Routing Number': BankRoutingNumber_1.BankRoutingNumber,
    'Benefits Calculator': BenefitsCalculator_1.BenefitsCalculator,
    'Card Management': CardManagement_1.CardManagement,
    'Contact Confirmation': ContactConfirmation_1.ContactConfirmation,
    Contract: Contract_1.Contract,
    'Detect Faces': DetectFaces_1.DetectFaces,
    'Device Setup': DeviceSetup_1.DeviceSetup,
    Encrypted: Encrypted_1.Encrypted,
    'Extract Text': ExtractText_1.ExtractText,
    Flag: Flag_1.Flag,
    'Household Calculation': HouseholdCalculation_1.HouseholdCalculation,
    'Income Calculation': IncomeCalculation_1.IncomeCalculation,
    'Live Attachment': LiveAttachment_1.LiveAttachment,
    Lookup: Lookup_1.Lookup,
    Payment: Payment_1.Payment,
    'Plaid Bank Account': PlaidBankAccount_1.PlaidBankAccount,
    'Scan Barcode': ScanBarcode_1.ScanBarcode,
    'Template Dummy': TemplateDummy_1.TemplateDummy,
    Other: Other_1.Other
};
/** Extracted from the defined DERIVED_MODELS above */
exports.DERIVED_TYPES = Object.keys(exports.DERIVED_MODELS);
console.timeEnd("Loading Derived");

import { useEffect, useState } from "react";
import { usePost } from "./API";

export default function CronPage() {

  const getJobs = usePost('/dev/cron/enumerate');
  const triggerJob = usePost('/admin/cron/schedule');
  const [jobs, setJobs] = useState<string[]>([]);
    

  useEffect(() => {
    getJobs({}).then((resp) => {
      setJobs(resp.jobs);
    })
  }, [])

  return <>
    <h1>Cron Jobs</h1>
    <div>
      {jobs.map((job) => <div key={job}>{job} 
                &nbsp;<button onClick={async () => alert(JSON.stringify(await triggerJob({ name: job })))}>run</button>
      </div> )}
    </div>
  </>;
}

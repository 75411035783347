function hexToRgb(hex) {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}
function rgbToHex(r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}
function luminance(r, g, b) {
    const a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}
function contrast(rgb1, rgb2) {
    const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05;
    const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05;
    return lum1 > lum2 ? lum1 / lum2 : lum2 / lum1;
}
function hexToHsl(hex) {
    const [r, g, b] = hexToRgb(hex).map((v) => v / 255);
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s = 0, l = (max + min) / 2;
    if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }
    return [h * 360, s * 100, l * 100];
}
function hslToHex(h, s, l) {
    s /= 100;
    l /= 100;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs((h / 60) % 2 - 1));
    const m = l - c / 2;
    let r = 0, g = 0, b = 0;
    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    }
    else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    }
    else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    }
    else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    }
    else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    }
    else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);
    return rgbToHex(r, g, b);
}
export function getAccessibleTextColor(hex) {
    const rgbBackground = hexToRgb(hex);
    const luminanceBackground = luminance(rgbBackground[0], rgbBackground[1], rgbBackground[2]);
    if (luminanceBackground > 0.95) {
        return '#000000';
    }
    else if (luminanceBackground < 0.05) {
        return '#FFFFFF';
    }
    const [h, s] = hexToHsl(hex);
    let textColor;
    let contrastRatio;
    for (let l = 0; l <= 100; l++) {
        textColor = hslToHex(h, s, l);
        contrastRatio = contrast(rgbBackground, hexToRgb(textColor));
        if (contrastRatio >= 7) {
            return textColor;
        }
    }
    return luminanceBackground > 0.179 ? '#000000' : '#FFFFFF';
}
export function getAccessibleHoverColor(hex) {
    const rgb = hexToRgb(hex);
    const lum = luminance(rgb[0], rgb[1], rgb[2]);
    const [h, s, l] = hexToHsl(hex);
    if (lum <= 0.05) {
        const brightenedLightness = Math.min(l + 25, 100);
        return hslToHex(h, s, brightenedLightness);
    }
    const newLightness = lum > 0.5 ? Math.max(l - 10, 0) : Math.min(l + 10, 100);
    return hslToHex(h, s, newLightness);
}

import { And, Block, Collection, CollectionComponent, FieldEquals, GenericTemplatedBlock, Notification, Or, Payment, PaymentModule, TemplatedBlock, TopLevelTemplatedBlock } from "../../survey";

export const generatePaymentsAndNotificationsCollection = (config: PaymentModule): Collection => ({
  "kind": "Collection",
  "name": {
    "en": "Sample Payment and Notification Setups"
  },
  "options": {},
  "components": [
    ...[generatePrePaymentEnrollmentNotifs(config)],
    ...[generateIncentivePayment(config)],
    ...(config.frequency.kind === 'One Time Payment') ? [generateOneTimePaymentCollection(config)] : [],
    ...(config.frequency.kind === 'Recurring Payment') ? [generateRecurringPaymentCollection(config)] : [],
  ]
});

const generatePrePaymentEnrollmentNotifs = (config: PaymentModule): Collection => ({
  "kind": "Collection",
  "name": {
    "en": "Pre-Payment Enrollment Completed Notifs"
  },
  "options": {},
  "components": [
    {
      "kind": "Notification",
      "version": 1,
      "name": "Enrollment Complete",
      "targetPrefix": "enrollment_complete",
      "enableKey": "",
      "initial_notification": {
        "enabled_when": {
          "kind": "Click",
          "expr": {
            "kind": "Never"
          }
        },
        "email_subject": {
          "en": "Enrollment Complete"
        },
        "message": [
          ...(config.ach.enabled) ? [{
            "components": [
              {
                "en": "Congratulations $actual_name! You have been selected to receive $$grant_amount from $program_name. Your first payment will be sent on $first_payment_date. Please allow 3-5 business days for your bank to deposit the funds in your account. \n\nYou can access FAQs about payments here: $payment_faqs"
              }
            ],
            "conditional": {
              "field": "applicant_payment_type",
              "kind": "Equals",
              "value": "ach"
            } as FieldEquals
          }] : [],
          ...(config.debit.kind === 'GiveCard' || config.debit.kind === 'Usio' && config.debit.pickup.enabled) ? [{
            "components": [
              {
                "en": "Congratulations $actual_name! You have been selected to receive $$grant_amount from $program_name.\n\nYou selected to receive your payment via debit card pick up. Please call or text us at $support_phone to schedule a time to pick up your card. Your first payment will be sent $first_payment_date if you pick up your card before hand or within 24 hrs of picking up your card if after that date.\n\nYou can access FAQs about payments here: $payment_faqs "
              }
            ],
            "conditional": {
              "kind": "Or",
              "clauses": [
                {
                  "field": "applicant_payment_type",
                  "kind": "Equals",
                  "value": "usio_pickup"
                },
                {
                  "field": "applicant_payment_type",
                  "kind": "Equals",
                  "value": "givecard_load"
                }
              ]
            } as Or
          }] : [],
          ...(config.debit.kind === 'Usio' && config.debit.virtual.enabled) ? [{
            "components": [
              {
                "en": "Congratulations $actual_name! You have been selected to receive $$grant_amount from $program_name. \n\nOn $first_payment_date, you will receive a link to activate your virtual debit card. After completing the activation, the funds will be loaded to your card within 24 hours.\n\nYou can access FAQs about payments here: $payment_faqs "
              }
            ],
            "conditional": {
              "field": "applicant_payment_type",
              "kind": "Equals",
              "value": "usio_virtual"
            } as FieldEquals
          }] : [],
          ...(config.debit.kind === 'Usio' && config.debit.mail.enabled) ? [{
            "components": [
              {
                "en": "Congratulations $actual_name! You have been selected to receive $$grant_amount from $program_name. \n\nOn $first_payment_date, your debit card will be mailed to the address provided in the application. It may take up to 4 weeks for the card to arrive. After completing the activation, the funds will be loaded to your card within 24 hours.\n\nYou can access FAQs about payments here: $payment_faqs "
              }
            ],
            "conditional": {
              "field": "applicant_payment_type",
              "kind": "Equals",
              "value": "usio_mail"
            } as FieldEquals
          }] : [],
          ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
            "components": [
              {
                "en": "Congratulations $actual_name! You have been selected to receive $$grant_amount from $program_name. \n\nOn $first_payment_date, your debit card will be mailed to the address provided in the application. It may take up to 7 days for the card to arrive. After completing the activation, the funds will be loaded to your card within 24 hours.\n\nYou can access FAQs about payments here: $payment_faqs "
              }
            ],
            "conditional": {
              "field": "applicant_payment_type",
              "kind": "Equals",
              "value": "givecard_mail"
            } as FieldEquals
          }] : [],
        ]
      },
      "recipient": "Applicant",
      "contactMethod": "all"
    }
  ]
});

const generateIncentivePayment = (config: PaymentModule): Collection => ({
  "kind": "Collection",
  "name": {
    "en": "Incentive Payment"
  },
  "options": {},
  "components": [
    {
      "name": "Incentive Payment",
      "targetField": "incentive_payment",
      "amount": "incentive_amount",
      "enableKey": "",
      "type": "applicant_payment_type",
      "kind": "Payment",
      "condition": {
        "kind": "Click",
        "expr": {
          "kind": "Never"
        }
      }
    },
    {
      "kind": "Notification",
      "version": 1,
      "name": "Incentive Payment Notification",
      "targetPrefix": "incentive_payment",
      "enableKey": "",
      "initial_notification": {
        "email_subject": {
          "en": "Incentive Payment Issued"
        },
        "enabled_when": {
          "kind": "Click",
          "expr": {
            "kind": "Never"
          }
        },
        "message": [
          ...(config.ach.enabled) ? [{
            "components": [
              {
                "en": "Good news! You're getting $$incentive_amount from $program_name. It will be in your bank account in 2-3 business days. If there's any issue with your payment, contact us at $support_email or $support_phone."
              }
            ],
            "conditional": {
              "field": "applicant_payment_type",
              "kind": "Equals",
              "value": "ach"
            } as FieldEquals
          }] : [],
          ...(config.debit.kind === 'GiveCard' || config.debit.kind === 'Usio' && config.debit.pickup.enabled) ? [{
            "components": [
              {
                "en": "Good news! You're getting $$incentive_amount through $program_name. You'll get it on your reloadable debit card. If there's any issue with your payment, contact us at $support_email or $support_phone."
              }
            ],
            "conditional": {
              "kind": "Or",
              "clauses": [
                {
                  "field": "applicant_payment_type",
                  "kind": "Equals",
                  "value": "usio_pickup"
                },
                {
                  "field": "applicant_payment_type",
                  "kind": "Equals",
                  "value": "usio_load"
                },
                {
                  "field": "applicant_payment_type",
                  "kind": "Equals",
                  "value": "givecard_load"
                }
              ]
            } as Or
          }] : [],
          ...(config.debit.kind === 'Usio' && config.debit.virtual.enabled) ? [{
            "components": [
              {
                "en": "Your incentive payment of $$incentive_amount has been sent to your new virtual debit card.\n\nIf you have previously activated your virtual card, the funds will load there.\n\nPlease visit the following link to activate your new virtual debit card: $card_id_setup_link\n\nThere are three steps to take to get your money:\nClick this link $card_id_setup_link, enter your information, and select a pin. \nIn the portal click on “View your virtual card number.”\n\nTake those details and click “Activate” in the bottom right corner. \n\nYou can access FAQs about payments here: $link\n\nIf you have any questions or concerns, please contact us at $support_email or $support_phone."
              }
            ],
            "conditional": {
              "field": "applicant_payment_type",
              "kind": "Equals",
              "value": "usio_virtual"
            } as FieldEquals
          }] : [],
          ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
            "components": [
              {
                "en": "Hello from $program_name! In order to receive your incentive payment, you will need to associate your debit card. It will take less than a minute, make sure you have your card in front of you. Use this link when you are ready: $givecard"
              }
            ],
            "conditional": {
              "field": "applicant_payment_type",
              "kind": "Equals",
              "value": "givecard_mail"
            } as FieldEquals
          }] : [],
        ],
        "subsurveys": [
          {
            "name": "payment-faqs",
            "variable": "link"
          },
          ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
            "name": "gc-mail",
            "variable": "givecard"
          }] : [],
        ]
      },
      "recipient": "Applicant",
      "contactMethod": "all",
      "testUIDs": [],
      "scheduleSettings": {
        "timezone": "America/Chicago",
        "timeWindow": {
          "startTime": {
            "hour": 9,
            "am_pm": "AM"
          },
          "endTime": {
            "hour": 6,
            "am_pm": "PM"
          }
        }
      }
    }
  ]
});

const generateOneTimePaymentCollection = (config: PaymentModule): Collection => ({
  "kind": "Collection",
  "name": {
    "en": "One Time Payment"
  },
  "options": {},
  "components": (config.cohorts ? withCohorts(config, generateOneTimePayment) : generateOneTimePayment(config))
});

const generateRecurringPaymentCollection = (config: PaymentModule): Collection => ({
  "kind": "Collection",
  "name": {
    "en": "Recurring Payment"
  },
  "options": {},
  "components": (config.cohorts ? withCohorts(config, generateRecurringPayment) : generateRecurringPayment(config))
});

const generateOneTimePayment = (config: PaymentModule): CollectionComponent[] => ([
  {
    "name": "Grant Payment",
    "targetField": "grant_payment",
    "amount": "grant_amount",
    "enableKey": "",
    "type": "applicant_payment_type",
    "kind": "Payment",
    "condition": {
      "kind": "Click",
      "expr": {
        "kind": "And",
        "clauses": [
          {
            "field": "approved_payment",
            "kind": "Equals",
            "value": "yes"
          },
          {
            "kind": "Or",
            "clauses": [
              ...(config.ach.enabled) ? [{
                "kind": "And",
                "clauses": [
                  {
                    "field": "applicant_payment_type",
                    "kind": "Equals",
                    "value": "ach"
                  },
                  {
                    "field": "payment_ok",
                    "kind": "Equals",
                    "value": "yes"
                  }
                ]
              } as And] : [],
              ...(config.debit.kind === 'Usio' && config.debit.pickup.enabled) ? [{
                "kind": "And",
                "clauses": [
                  {
                    "field": "applicant_payment_type",
                    "kind": "Equals",
                    "value": "usio_pickup"
                  },
                  {
                    "field": "dc_confirm_pickup",
                    "kind": "Equals",
                    "value": "yes"
                  },
                  {
                    "field": "card_id",
                    "kind": "Exists"
                  }
                ]
              } as And] : [],
              ...(config.debit.kind === "Usio" && config.debit.virtual.enabled) ? [{
                "field": "applicant_payment_type",
                "kind": "Equals",
                "value": "usio_virtual"
              } as FieldEquals] : [],
              ...(config.debit.kind === "Usio" && config.debit.mail.enabled) ? [{
                "field": "applicant_payment_type",
                "kind": "Equals",
                "value": "usio_mail"
              } as FieldEquals] : [],
              ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
                "field": "applicant_payment_type",
                "kind": "Equals",
                "value": "givecard_mail"
              } as FieldEquals] : [],
            ]
          }
        ]
      }
    },
    ...(config.debit.kind === 'Usio' && config.debit.mail.autoAssociate && { "autoAssociate": true }),
  },
  {
    "kind": "Notification",
    "version": 1,
    "name": "Payment Notification",
    "targetPrefix": "payment",
    "enableKey": "",
    "initial_notification": {
      "email_subject": {
        "en": "Payment Issued"
      },
      "enabled_when": {
        "kind": "Click",
        "expr": {
          "kind": "Never"
        }
      },
      "message": [
        ...(config.ach.enabled) ? [{
          "components": [
            {
              "en": "Good news! You're getting $$grant_amount from $program_name. It will be in your bank account in 2-3 business days. If there's any issue with your payment, contact us at $support_email or $support_phone."
            }
          ],
          "conditional": {
            "field": "applicant_payment_type",
            "kind": "Equals",
            "value": "ach"
          } as FieldEquals
        }] : [],
        ...(config.debit.kind === 'Usio' || config.debit.kind == 'GiveCard' && config.debit.pickup.enabled) ? [{
          "components": [
            {
              "en": "Good news! You're getting $$grant_amount through $program_name. You'll get it on your reloadable debit card. If there's any issue with your payment, contact us at $support_email or $support_phone."
            }
          ],
          "conditional": {
            "kind": "Or",
            "clauses": [
              {
                "field": "applicant_payment_type",
                "kind": "Equals",
                "value": "usio_pickup"
              },
              {
                "field": "applicant_payment_type",
                "kind": "Equals",
                "value": "usio_load"
              },
              {
                "field": "applicant_payment_type",
                "kind": "Equals",
                "value": "givecard_load"
              }
            ]
          } as Or
        }] : [],
        ...(config.debit.kind === "Usio" && config.debit.virtual) ? [{
          "components": [
            {
              "en": "Your payment of $$grant_amount has been sent to your new virtual debit card.\n\nIf you have previously activated your virtual card, the funds will load there.\n\nPlease visit the following link to activate your new virtual debit card: $card_id_setup_link\n\nThere are three steps to take to get your money:\nClick this link $card_id_setup_link, enter your information, and select a pin. \nIn the portal click on “View your virtual card number.”\n\nTake those details and click “Activate” in the bottom right corner. \n\nYou can access FAQs about payments here: $link\n\nIf you have any questions or concerns, please contact us at $support_email or $support_phone."
            }
          ],
          "conditional": {
            "field": "applicant_payment_type",
            "kind": "Equals",
            "value": "usio_virtual"
          } as FieldEquals
        }] : [],
        ...(config.debit.kind === "GiveCard" && config.debit.mail.enabled) ? [{
          "components": [
            {
              "en": "Hello from $program_name! In order to receive your first payment, you will need to associate your debit card. It will take less than a minute, make sure you have your card in front of you. Use this link when you are ready: $givecard"
            }
          ],
          "conditional": {
            "field": "applicant_payment_type",
            "kind": "Equals",
            "value": "givecard_mail"
          } as FieldEquals
        }] : []
      ],
      "subsurveys": [
        {
          "name": "payment-faqs",
          "variable": "link"
        },
        ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
          "name": "gc-mail",
          "variable": "givecard"
        }] : []
      ]
    },
    "recipient": "Applicant",
    "contactMethod": "all",
    "testUIDs": [],
    "scheduleSettings": {
      "timezone": "America/Chicago",
      "timeWindow": {
        "startTime": {
          "hour": 9,
          "am_pm": "AM"
        },
        "endTime": {
          "hour": 6,
          "am_pm": "PM"
        }
      }
    }
  }
]
);


const generateRecurringPayment = (config: PaymentModule): CollectionComponent[] => {
  if (config.frequency.kind === 'Recurring Payment') {
    return [{
      "kind": "Templated Block",
      "iterations": [
        ...Array.from({ length: config.frequency.numberOfPayments }, (_, index) => (
          {
            "substitutions": [
              {
                "key": "ITER",
                "value": (index + 1).toString()
              },
              {
                "key": "PAYMENT_ENABLE_KEY",
                "value": ""
              },
              {
                "key": "NOTIFICATION_ENABLE_KEY",
                "value": ""
              }
            ]
          }
        ))
      ],
      "components": [
        {
          "name": (config.cohorts && config.cohorts > 0) ? "Cohort COHORT Payment ITER" : "Payment ITER",
          "targetField": "payment_ITER",
          "amount": "",
          "enableKey": "PAYMENT_ENABLE_KEY",
          "condition": {
            "kind": "Click",
            "expr": {
              "kind": "Never"
            }
          },
          "type": "applicant_payment_type",
          ...(config.debit.kind === 'Usio' && config.debit.mail.autoAssociate && { "autoAssociate": true }),
        },
        {
          "kind": "Notification",
          "version": 1,
          "name": (config.cohorts && config.cohorts > 0) ? "Cohort COHORT Payment ITER" : "Payment ITER",
          "targetPrefix": "payment_ITER",
          "enableKey": "NOTIFICATION_ENABLE_KEY",
          "initial_notification": {
            "enabled_when": {
              "kind": "Click",
              "expr": {
                "kind": "Never"
              }
            },
            "email_subject": {
              "en": "Payment ITER Issued!"
            },
            "message": [
              {
                "components": [
                  {
                    "en": "Payment ITER: Good news! You're getting $$grant_amount from $program_name. It will be in your bank account in 2-3 business days. If there's any issue with your payment, contact us at $support_email or $support_phone."
                  }
                ],
                "conditional": {
                  "field": "applicant_payment_type",
                  "kind": "Equals",
                  "value": "ach"
                }
              },
              {
                "components": [
                  {
                    "en": "Payment ITER: Good news! You're getting $$grant_amount from $program_name. It will be in your bank account in 2-3 business days. If there's any issue with your payment, contact us at $support_email or $support_phone."
                  }
                ],
                "conditional": {
                  "kind": "Or",
                  "clauses": [
                    ...(config.debit.kind === 'Usio' && config.debit.pickup.enabled) ? [{
                      "field": "applicant_payment_type",
                      "kind": "Equals",
                      "value": "usio_pickup"
                    } as FieldEquals] : [],
                    ...(config.debit.kind === 'Usio' && config.debit.mail.enabled) ? [{
                      "field": "applicant_payment_type",
                      "kind": "Equals",
                      "value": "usio_mail"
                    } as FieldEquals] : [],
                    ...(config.debit.kind === 'Usio' && config.debit.virtual.enabled) ? [{
                      "field": "applicant_payment_type",
                      "kind": "Equals",
                      "value": "usio_virtual"
                    } as FieldEquals] : [],
                    ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
                      "field": "applicant_payment_type",
                      "kind": "Equals",
                      "value": "givecard_mail"
                    } as FieldEquals] : [],
                    ...(config.debit.kind === 'GiveCard' && config.debit.pickup.enabled) ? [{
                      "field": "applicant_payment_type",
                      "kind": "Equals",
                      "value": "givecard_load"
                    } as FieldEquals] : [],
                  ]
                }
              }
            ]
          },
          "recipient": "Applicant",
          "contactMethod": "all"
        }
      ]
    }]
  }

  return [];
}

const withCohorts = <T extends (config: PaymentModule) => CollectionComponent[]>(config: PaymentModule, fn: T): CollectionComponent[] => {
  if (!config.cohorts || config.cohorts === 0) return [];

  return [{
    "kind": "Templated Block",
    "iterations": [
      ...Array.from({ length: config.cohorts as number }, (_, index) => (
        {
          "substitutions": [
            {
              "key": "COHORT",
              "value": (index + 1).toString()
            }
          ]
        }
      ))
    ],
    "components": fn(config)
  }];
}
import { headersToObject } from '../utils';
import { PermaCache, PermaOpts } from './';

export type RequestEntry = { req: Request, resp?: Response };

/**
 * this is the default implementation of a PermaCache, used for storing HTTP related data.
 */
export async function getOrCreateHttpCache(name?: string | string[], opts?: PermaOpts<RequestEntry>) {
  let root = new PermaCache<string, RequestEntry>(
    opts?.parent ?? await navigator.storage.getDirectory(),
    opts?.keyPartsFn ?? function({ req }) {
      return [
        req?.method ?? 'GET',
        new URL(req.url, 'http://127.0.0.1').pathname,
        Array.from(new URL(req.url).searchParams.entries())
          .filter(kv => !['token', 'auth', 'program', 'mode', 'lang'].includes(kv[0]))
          .map(kv => kv.join('='))
          .join('&')
      ];
    },
    opts?.contentFn ?? (async ({ req, resp }) => await (resp ?? req).clone().arrayBuffer()),
    opts?.metaFn ?? (async ({ req }) => {
      const url = new URL(req.url);
      // get rid of secrets prior to writing to disk.
      // its not useful to keep em, since we will 
      // use a fresh token when syncing
      url.searchParams.delete('token');
      url.searchParams.delete('auth');
      const meta = JSON.stringify({
        url,
        headers: headersToObject(req.headers),
        method: req.method,
        integrity: req.integrity,
      });
      return new TextEncoder().encode(meta);
    }),
    undefined,
    opts?.watcher
  );
  if(!name) return root;
  let path: string[] = typeof name === 'string' ? [name] : name;
  for (let p of path) {
    root = await root.down(p);
  }
  return root;
}


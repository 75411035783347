import React, { useEffect, useState, useContext } from "react";
import { Link } from 'react-router-dom';
import { usePost, get_deployment } from "./API";
import { ConfigurationContext, PublicConfigurationContext } from "./Context";
import { AidKitLogo } from "./Util";
import { Button, useToast } from '@aidkitorg/component-library'
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { useDownloader } from "./API";
import { useLocalizedStrings } from "./Localization";
import Papa from 'papaparse';

import type { ReconciliationOverview } from 'aidkit/lib/program/admin/accounting';

type ReconciliationReportType = 'allFunds' | 'fundsIn' | 'fundsOut';

type ReconciliationReport = {
  type: ReconciliationReportType;
  snakeCasedType: string,
  label: string;
  count: number;
};

function convertIsoMonthToDescription(isoMonth: string) {
  // Create a date object by appending '-01' to the ISO month string
  const date = new Date(`${isoMonth}-01`);
  // Format the date as "Month Year" for display
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', timeZone: 'UTC' });
}

export default function AccountingPage() {
  const fetchMonthlyReconciliationOverview = usePost("/accounting/monthly_reconciliation_overview");
  const [reportsOverview, setReportsOverview] = useState<ReconciliationOverview[] | undefined>();
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [reportsError, setReportsError] = useState<string | null>(null);
  const selectedReport: ReconciliationOverview | undefined = reportsOverview?.find((report) => report.month === selectedMonth);
  const [monthData, setMonthData] = useState<{ allFunds: any[], fundsIn: any[], fundsOut: any[] } | void>()

  const fetchReconcililationDataByMonth = usePost('/accounting/monthly_reconciliation_data');
  const [downloadedReports, setDownloadedReports] = useState<ReconciliationReportType[]>([]);
  const downloadFile = useDownloader();

  const configContext = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configContext.applicant_facing_logo;
  const applicantFacingLogoWidth = publicConfig.interface?.applicantFacingLogo?.width || configContext.applicant_facing_logo_width || '50';
  const program = get_deployment();
  const programDisplayName = publicConfig.name || configContext.program_name;
  const L = useLocalizedStrings();

  const { toast } = useToast();

  // Fetch overview of downloadable reports
  useEffect(() => {
    setOverviewLoading(true);
    const fetchData = async () => {
      try {
        const data = await fetchMonthlyReconciliationOverview({ program });
        if (!data || 'error' in data || data instanceof Error) {
          throw new Error('Failed to fetch monthly reconciliation overview');
        } else {
          setReportsOverview(data);
          setSelectedMonth(data[0]?.month || ''); // latest available month is the default
          setReportsError(null);
        }
      } catch (e) {
        setReportsOverview(undefined);
        setReportsError("Hmmm, something's gone wrong. Please try again later.");
      } finally {
        setOverviewLoading(false);
      }
    };
    fetchData();
  }, []);

  const reportsInfo: ReconciliationReport[] = [
    { type: 'allFunds', snakeCasedType: 'all_funds', label: 'All Funds', count: selectedReport?.allFundsCount || 0 },
    { type: 'fundsIn', snakeCasedType: 'funds_in', label: 'Funds In', count: selectedReport?.fundsInCount || 0 },
    { type: 'fundsOut', snakeCasedType: 'funds_out', label: 'Funds Out', count: selectedReport?.fundsOutCount || 0 },
  ];

  const getFilename = (reportType: ReconciliationReportType) => {
    return `${selectedMonth}_${program || 'aidkit'}_reconciliation_${reportsInfo.find(report => report.type === reportType)?.snakeCasedType || 'report'}`;
  }

  const generateAndDownloadCSV = (data: any[], filename: string) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const csvDownloadUrl = window.URL.createObjectURL(blob);
    downloadFile(csvDownloadUrl, filename);
    window.URL.revokeObjectURL(csvDownloadUrl);
  };

  const handleMonthChange = (month: string) => {
    setSelectedMonth(month);
    setMonthData(undefined);
    setDownloadedReports([]);
  };

  const handleReportDownload = async (reportType: ReconciliationReportType) => {
    try {
      // Fetch data for the selected month or use cached data if available
      const dataForSelectedMonth = monthData || await fetchReconcililationDataByMonth({ month: selectedMonth });
      // Cache the fetched data
      setMonthData(dataForSelectedMonth);
      // Get the data for the selected report type
      const dataForReportDownload = dataForSelectedMonth?.[reportType] || [];

      // Do the download
      generateAndDownloadCSV(dataForReportDownload, getFilename(reportType));
      // Mark the report as downloaded
      setDownloadedReports([...downloadedReports, reportType]);
    } catch (error) {
      toast({
        description: "Failed to download CSV",
        variant: 'error'
      })
    }
  };

  const ProgramBanner = () => (
    <div className="program-banner -m-6 mb-4 px-4 py-2 bg-gray-100 flex gap-4 items-center border-gray-300 border-solid border-b border-l-0 border-t-0 border-r-0">
      <Link to={"/"}>
        {applicantFacingLogo ? (
          <img
            src={applicantFacingLogo}
            width={applicantFacingLogoWidth}
            style={{ maxHeight: 30 }}
            alt={programDisplayName}
          />
        ) : (
          <AidKitLogo width={60} height={30} />
        )}
      </Link>
      <h2 className="text-sm m-0">{programDisplayName}</h2>
    </div>
  );

  const ReportsDropdown = () => (
    reportsOverview?.length ? (
      <>
        <label htmlFor="report-month-year" className="block mt-3 text-lg text-gray-900">
          Reports available:
        </label>
        <select
          id="report-month-year"
          name="report-month-year"
          value={selectedMonth}
          onChange={(e) => handleMonthChange(e.target.value)}
          className="mt-2.5 mb-4 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 text-lg ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 cursor-pointer"
        >
          {reportsOverview.map(report => (
            <option key={report.month} value={report.month}>
              {convertIsoMonthToDescription(report.month)}
            </option>
          ))}
        </select>
      </>
    ) : (
      <p className="mt-4 text-xl">No reports available</p>
    )
  );

  const ReportList = () => (
    <ul>
      {reportsInfo.map(report => (
        report.count > 0 && (
          <li key={report.type} className="mt-5">
            <div className="text-gray-900">
              <h5 className="mb-1">{report.label} - {convertIsoMonthToDescription(selectedMonth)}</h5>
              <p>Transaction count: {report.count}</p>
            </div>
            <div className="flex gap-5 items-center">
              {downloadedReports.includes(report.type)
                ? (
                  <div className="flex gap-2 items-center">
                    <CheckCircleIcon className="h-8 w-8 text-green-600" />
                    <div>{`${getFilename(report.type)}.csv`}</div>
                  </div>
                ) : (
                  <Button onClick={() => handleReportDownload(report.type)}>Download CSV</Button>
                )
              }
            </div>
          </li>
        )
      ))}
    </ul>
  );

  return (
    <div className="p-4">
      <ProgramBanner />
      <h1 className="text-2xl text-gray-900">Accounting</h1>
      <hr />
      <div className="mb-16">
        <h2>Download Reconciliation Reports</h2>
        {overviewLoading ? (
          <p className="mt-4 text-base">Getting available reports...</p>
        ) : (
          <>
            {reportsOverview && <ReportsDropdown />}
            {!reportsError && selectedReport && (
              <ReportList />
            )}
          </>
        )}
        {reportsError && (
          <p className="text-red-500 text-lg mt-4">{reportsError}</p>
        )}
      </div>
    </div>
  );
}

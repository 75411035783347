export async function getDirectoryHandle(current: FileSystemDirectoryHandle, path?: string[]): Promise<FileSystemDirectoryHandle> {
  if (path && path.length > 0) {
    return getDirectoryHandle(await current.getDirectoryHandle(path[0], { create: true }), path.slice(1));
  } else return current;
}

export async function enumerate<T, TR, TNext>(iter: AsyncIterator<T, TR, TNext>): Promise<(T | TR)[]> {
  const items = [];
  do {
    const { done, value } = await iter.next();
    if (done) {
      break;
    }
    items.push(value);
  }
  while (true);

  return items;
}



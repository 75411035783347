import { PhoneArrowDownLeftIcon } from "@heroicons/react/24/solid";
import { Call } from "@twilio/voice-sdk";
import { useContext, useEffect, useState } from "react";
import InterfaceContext, { TwilioDeviceContext } from "../Context";

export function IncomingCallReceiver() {
  const context = useContext(InterfaceContext);
  const { twilioDevice, twilioCall, setTwilioCall } = useContext(TwilioDeviceContext);

  const [incomingCall, setIncomingCall] = useState(null as null | Call);

  useEffect(() => {
    if (!twilioDevice) return;
    twilioDevice.on('incoming', (call: Call) => {
      setIncomingCall(call);
      call.on('accepted', () => {
        setIncomingCall(null);
        setTwilioCall(call);
      });
      call.on('rejected', () => {
        setIncomingCall(null);
        setTwilioCall(null);
      });
      call.on('disconnected', () => {
        setIncomingCall(null);
        setTwilioCall(null);
      })
    });
    twilioDevice.on('disconnect', () => {
      setIncomingCall(null);
    })
  }, [twilioDevice])

  return <></> // TODO: Finish setting this up w/ a modal to accept or reject the call.
    
  return (
    <>
      <button 
        title={twilioCall ? "On a call" : "Ready to receive calls"}
        className="flex items-center justify-center bg-transparent border-0 p-1 rounded-full focus:outline-none">
        {!incomingCall && <PhoneArrowDownLeftIcon 
          className={`w-6 h-6 ${twilioCall ? 'text-green-600' : 'text-gray-300'}`} />}
        {incomingCall && <PhoneArrowDownLeftIcon 
          className={`w-6 h-6 text-green-600`} />}
      </button>
      {incomingCall && <button onClick={() => {

      }}>Open Incoming Call</button>}
    </>
  )
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.flagsForField = exports.formatSurveyFlagMetadata = void 0;
var json_1 = require("../../util/json");
// Function to provide backwards compatability
function formatSurveyFlagMetadata(metadataStr) {
    var metadata = (0, json_1.safeParse)(metadataStr || '{}');
    if (metadata['unique']) {
        if (Array.isArray(metadata['unique'])) {
            metadata['unique'] = { '.': metadata['unique'] };
        }
    }
    if (metadata['fuzzy_unique']) {
        if (Array.isArray(metadata['fuzzy_unique'])) {
            metadata['fuzzy_unique'] = { '.': metadata['fuzzy_unique'] };
        }
    }
    if (metadata['query']) {
        if (metadata['query']['sql']) {
            metadata['query'] = { '.': metadata['query'] };
        }
    }
    return metadata;
}
exports.formatSurveyFlagMetadata = formatSurveyFlagMetadata;
function flagsForField(infoDefs, targetField) {
    var flags = [];
    for (var k in infoDefs) {
        if (infoDefs[k]['type'] === 'Flag') {
            var metadata = formatSurveyFlagMetadata(infoDefs[k].metadata);
            // Update this list if the question should be pushed
            var anyConditions = [
                metadata['unique'] && (metadata['unique']['.'] || []).indexOf(targetField) !== -1,
                metadata['fuzzy_unique'] && (metadata['fuzzy_unique']['.'] || []).indexOf(targetField) !== -1,
                metadata['query'] && (metadata['query']['.']['depends_on'] || []).indexOf(targetField) !== -1,
                metadata['sum_target'] === targetField
            ];
            if (anyConditions.some(function (c) { return c; }))
                flags.push(infoDefs[k]);
        }
    }
    return flags;
}
exports.flagsForField = flagsForField;

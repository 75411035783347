/**
 * Attempts to determine if the current device is a mobile device.
 * Checks user agent and matchMedia pointer:coarse to detect touch-based devices.
 *
 * @returns {boolean} - Returns `true` if the device is a mobile device, otherwise `false`.
 */
export const isMobile = (): boolean => {
  const userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera;
  const isUserAgentMobile: boolean = /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
  const isTouchDevice: boolean = window.matchMedia("(pointer: coarse)").matches;

  return isUserAgentMobile || isTouchDevice;
};
import { createFromRawValue, State, TrackedArray, TrackedRecord } from "@aidkitorg/typesheets/lib/collab";
import { useState } from "react";
import { Modal, Table } from "react-bootstrap";


export function SurveyErrorsModal(
  {
    errors,
    closeModal,
  } : {
    errors: (string | {hint: string, error: string})[],
    closeModal: () => void,
  }
) {
  return <>
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title className="pl-2">
          <div>Uh oh! Failed to save survey.</div>
          <div className="text-sm pt-2">Fix the errors detailed below before attempting to save again.</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list-disc px-4">
          {errors.map(e => <li className="mb-3">
            {typeof e === 'string' ? e : e.error}
            {typeof e !== 'string' && 
                            <ul className="list-disc px-4">
                              <li>{e.hint}</li>
                            </ul>
            }
          </li>)}
        </ul>
      </Modal.Body>
    </Modal>
  </>;
}
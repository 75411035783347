import { Fragment, useContext, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  BanknotesIcon,
  CalculatorIcon,
  ChartBarIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ClockIcon,
  CreditCardIcon,
  CircleStackIcon,
  ComputerDesktopIcon,
  ArrowDownTrayIcon,
  HomeIcon,
  InboxIcon,
  MapIcon,
  PhoneIcon,
  TrashIcon,
  ArrowUpTrayIcon,
  VideoCameraIcon
} from '@heroicons/react/24/outline'
import InterfaceContext, { ConfigurationContext } from './Context'
import { LinkIcon, XMarkIcon, Bars3Icon, PencilIcon, CogIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { Link, useHistory, useLocation } from 'react-router-dom';
import FieldStatsPage from './FieldStats'
import SurveyDesignPage from './SurveyDesign'
import { RoboScreenerPage } from './RoboScreener'
import BatchSetPage from './BatchSet'
import ProgramConfigPage from './ProgramConfig'
import { OutreachPage } from './ProgramAdmin/Outreach'
import PublicS3 from './ProgramAdmin/PublicS3'
import { DeleteApplicants } from './ProgramAdmin/DeleteApplicants'
import ConfigPage from './Config'
import { HashedUploadSearchPage } from './ProgramAdmin/HashedUploadSearch'
import GeolookupPage from './Geolookup'
import { AIQueries } from './ProgramAdmin/AIQueries'
import { Explore } from './Explore'
import { inits, stringToColour } from './Realtime'
import USIOPage from './USIO'
import ConfigVersionsPage from './ConfigVersions'
import CronPage from './Cron'
import EncryptedReport from './EncryptedReport'
import { ImportPage } from './Import'
import { Exports } from './Exports'
import TransactionDownload from './Components/TransactionDownload'
import { ProvisionPage } from './ProvisionComms'
import AccountingPage from './Accounting'
import { ProvisionVideoCallsPage } from './ProvisionVideoCalls'
import React from 'react'
const PreviewAttachment = React.lazy(() => import('./ProgramAdmin/PreviewAttachment'));
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function HomePage() {
  return (
    <div>
      <span>Something useful might be placed here at some point, otherwise this page exists a springboard for the utilities in the left panel</span>
    </div>
  )
}

export const PA_PAGES = [
  { name: 'Home', href: '/program-admin', component: HomePage, icon: HomeIcon },
  { name: 'Stats by Field Type', href: '/field-stats', component: FieldStatsPage, icon: ChartBarIcon},
  { name: 'Field Hierarchy', title: 'Field Hierarchy for Survey Design Admin', component: SurveyDesignPage, href: '/field-hierarchy', icon: LinkIcon },
  { name: 'RoboScreener', href: '/roboscreener', component: RoboScreenerPage, icon: ComputerDesktopIcon },
  { name: 'Batch Set', href: '/batch-set', component: BatchSetPage, icon: PencilIcon },
  { name: 'Import', href: '/import', component: ImportPage, icon: ArrowUpTrayIcon },
  { name: 'Exports', href: '/exports', component: Exports, icon: ArrowDownTrayIcon },
  { name: 'Provision Comms', href: '/provision_comms', component: ProvisionPage, icon: PhoneIcon },
  { name: 'Provision Video Calls', href: '/provision_video_calls', component: ProvisionVideoCallsPage, icon: VideoCameraIcon },
  { name: 'Geolookup', href: '/geolookup', component: GeolookupPage, icon: MapIcon },
  { name: 'Distro', href: '/config', component: ConfigPage, icon: CogIcon },
  { name: 'Macro Editor', href: '/macro-editor', component: ConfigPage, icon: CogIcon },
  { name: 'Published Versions', href: '/config-versions', component: ConfigVersionsPage, icon: CogIcon },
  { name: 'Auto-saved Versions', href: '/collab-versions', component: ConfigVersionsPage, icon: CogIcon },
  { name: 'Program Config Playground', href: '/program-config', component: ProgramConfigPage, icon: CogIcon },
  { name: 'Explore', href: '/admin-explore', component: Explore, icon: CircleStackIcon },
  { name: 'Outreach', href: '/outreach', component: OutreachPage, icon: ChatBubbleOvalLeftEllipsisIcon },
  { name: 'Upload File to S3', href: '/publicS3', component: PublicS3, icon: ArrowUpTrayIcon },
  { name: 'Delete Applicants', href: '/sa-delete-applicants', component: DeleteApplicants, icon: TrashIcon },
  { name: 'Hashed Upload Search', href: '/hashed-upload-search', component: HashedUploadSearchPage, icon: MagnifyingGlassIcon },
  { name: 'AI ApplicantInfo Query', href: '/ai-applicantinfo-query', component: AIQueries, icon: CircleStackIcon },
  { name: 'USIO', href: '/usio', component: USIOPage, icon: CreditCardIcon },
  { name: 'Cron', href: '/cron', component: CronPage, icon: ClockIcon },
  { name: 'Get Encrypted Report', href: '/encrypted-report', component: EncryptedReport, icon: InboxIcon },
  { name: 'GiveCard Spending', href: '/givecard-spending', component: TransactionDownload, icon: BanknotesIcon },
  { name: 'Accounting', href: '/accounting', component: AccountingPage, icon: CalculatorIcon },
  { name: "Preview Attachment", href: "/preview-attachment", component: PreviewAttachment, icon: LinkIcon },
];

export default function ProgramAdmin(props: any) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarOpenDesktop, setSidebarOpenDesktop] = useState(true);
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const location = useLocation();
  const history = useHistory();

  const [navigation, setNavigation] = useState(PA_PAGES.map(p => ({...p, current: location.pathname === p.href})));

  useEffect(() => {
    setNavigation((prevState) => prevState.map((item) => ({
      ...item,
      current: location.pathname === item.href
    })));
  }, [location.pathname]);
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Overlay className="relative flex-1 flex flex-col max-w-xs w-full bg-indigo-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center bg-transparent border-0 text-gray-300 hover:text-gray-500 justify-center h-10 w-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto bg-aidkit-blue">
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img
                        className="h-24 w-auto"
                        src="logo_blue.png"
                        alt="AidKit"
                      />
                    </div>
                    <div className="flex flex-row items-center px-4">
                      <div
                        className="spinner red"
                        style={{
                          visibility:
                            context.activeRequests.size === 0 ? "hidden" : "visible",
                        }}
                      >
                        <div className="rect1"></div>&nbsp;
                        <div className="rect2"></div>&nbsp;
                        <div className="rect3"></div>&nbsp;
                        <div className="rect4"></div>&nbsp;
                        <div className="rect5"></div>
                      </div>
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-indigo-800 text-white'
                              : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <div className="flex-shrink-0 flex border-t border-indigo-800 p-4">
                    <Link to="/" className="flex-shrink-0 group block">
                      <div className="flex items-center">
                        <div>
                          <div title={config.user?.name} className="inline-block ml-2 p-1 radius-lg text-white rounded-full cursor-pointer" style={{backgroundColor: stringToColour(config.user?.name)}}>{inits(config.user?.name)}</div>
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium text-white">{config.user?.name || 'Anonymous'}</div>
                          <div className="text-sm font-medium text-indigo-200 group-hover:text-white">Exit Admin View</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Dialog.Overlay>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Hideable sidebar for desktop */}
        <div className={classNames('hidden md:flex md:flex-col md:fixed md:inset-y-0 z-50',
          sidebarOpenDesktop ? '' : 'w-100')}>
          <div className="sticky top-0 z-10 pl-1 pt-1 sm:pl-3 sm:pt-3 bg-aidkit-blue">
            <button
              type="button"
              title="Toggle sidebar"
              className="float -ml-0.5 -mt-0.5 h-12 w-12 bg-transparent border-0 inline-flex items-center justify-center text-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpenDesktop(!sidebarOpenDesktop)}
            >
              <span className="sr-only">Toggle sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            {!sidebarOpenDesktop ? <img
              className="h-12 w-auto align-middle mb-1 p-0 mt-0"
              src="logo_blue.png"
              alt="AidKit"
            /> : <></>}
          </div>
        </div>
        {sidebarOpenDesktop ? <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          
          <div className="flex-1 flex flex-col min-h-0 bg-aidkit-blue">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="h-24 w-auto"
                  src="logo_blue.png"
                  alt="AidKit"
                />
              </div>
              <div className="flex flex-row items-center px-4">
                <div
                  className="spinner red"
                  style={{
                    visibility:
                      context.activeRequests.size === 0 ? "hidden" : "visible",
                  }}
                >
                  <div className="rect1"></div>&nbsp;
                  <div className="rect2"></div>&nbsp;
                  <div className="rect3"></div>&nbsp;
                  <div className="rect4"></div>&nbsp;
                  <div className="rect5"></div>
                </div>
              </div>
              <nav className="mt-5 flex-1 px-2 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-indigo-800 text-white' : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300" aria-hidden="true" />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-indigo-800 p-4">
              <Link to="/" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div title={config.user?.name} className="inline-block ml-2 p-1 radius-lg text-white rounded-full cursor-pointer" style={{backgroundColor: stringToColour(config.user?.name)}}>{inits(config.user?.name)}</div>
                  <div className="ml-3">
                    <div className="text-sm font-medium text-white">{config.user?.name || 'Anonymous'}</div>
                    <div className="text-xs font-medium text-indigo-200 group-hover:text-white">
                      Exit Admin Panel
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div> : <></>}
        <div className={classNames(
          sidebarOpenDesktop 
            ? 'md:pl-64' 
            : 'md:pt-12',
          'flex flex-col flex-1')}>
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-aidkit-blue">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 bg-transparent border-0 inline-flex items-center justify-center text-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <img
              className="h-12 w-auto align-middle mb-1 p-0 mt-0"
              src="logo_blue.png"
              alt="AidKit"
            />
          </div>
          <main className="flex-1">
            <div className="py-6">
              <div className="px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">{props.title}</h1>
              </div>
              <div className="px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                {props.children}
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsafeReadOnlyQuery = exports.endDbConn = exports.getDbConn = exports.getReadOnlyDbConn = exports.withDB = exports.useDB = exports.deployments = void 0;
var secrets_1 = require("./secrets");
var neverthrow_1 = require("neverthrow");
var pg_1 = require("pg");
var CONNECTING = '===$=== Connecting to DB ===$===';
var QUERYING = '===$=== Querying DB ===$===';
exports.deployments = {};
function useDB(params, callback) {
    return __awaiter(this, void 0, void 0, function () {
        var deploymentKey, host, password, conn, client, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    deploymentKey = params.deploymentKey, host = params.host, password = params.password;
                    conn = new pg_1.Pool({
                        connectionString: "postgres://".concat(deploymentKey, ":").concat(password, "@").concat(host, "/").concat(deploymentKey),
                        keepAlive: true
                    });
                    return [4 /*yield*/, conn.connect()];
                case 1:
                    client = _a.sent();
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, 5, 7]);
                    return [4 /*yield*/, callback(client)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 7];
                case 4:
                    e_1 = _a.sent();
                    console.error(e_1);
                    throw e_1;
                case 5:
                    client.release();
                    return [4 /*yield*/, conn.end()];
                case 6:
                    _a.sent();
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    });
}
exports.useDB = useDB;
// Helper for useDB to assume host and pw based on env
var withDB = function (program, callback) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, DB_HOST, DB_PW;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, (0, secrets_1.getGlobalConfigs)('DB_HOST', 'DB_PW')];
            case 1:
                _a = _b.sent(), DB_HOST = _a.DB_HOST, DB_PW = _a.DB_PW;
                if (process.env.NODE_ENV === 'development') {
                    DB_HOST = 'localhost';
                    DB_PW = 'postgres';
                }
                if (!DB_HOST) {
                    console.warn("DB_HOST not set");
                    return [2 /*return*/];
                }
                if (!DB_PW) {
                    console.warn("DB_PW not set");
                    return [2 /*return*/];
                }
                return [4 /*yield*/, useDB({ deploymentKey: program, host: DB_HOST, password: DB_PW }, function (conn) { return __awaiter(void 0, void 0, void 0, function () {
                        var e_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, conn.query("BEGIN")];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2:
                                    _a.trys.push([2, 5, , 7]);
                                    return [4 /*yield*/, callback(conn)];
                                case 3:
                                    _a.sent();
                                    return [4 /*yield*/, conn.query("COMMIT")];
                                case 4:
                                    _a.sent();
                                    return [3 /*break*/, 7];
                                case 5:
                                    e_2 = _a.sent();
                                    console.error(e_2);
                                    return [4 /*yield*/, conn.query("ROLLBACK")];
                                case 6:
                                    _a.sent();
                                    console.log("Error, rolling back");
                                    return [2 /*return*/];
                                case 7: return [2 /*return*/];
                            }
                        });
                    }); })];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.withDB = withDB;
function getReadOnlyDbConn(deploymentKey, user) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, DB_HOST, DB_PW, pool, conn, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!!exports.deployments["readonly:" + deploymentKey]) return [3 /*break*/, 5];
                    return [4 /*yield*/, (0, secrets_1.getGlobalConfigs)('DB_HOST', 'DB_PW')];
                case 1:
                    _a = _b.sent(), DB_HOST = _a.DB_HOST, DB_PW = _a.DB_PW;
                    DB_HOST = DB_HOST === null || DB_HOST === void 0 ? void 0 : DB_HOST.replace('cluster-', 'cluster-ro-');
                    if (process.env.NODE_ENV === 'development') {
                        DB_HOST = '127.0.0.1';
                        DB_PW = 'postgres';
                    }
                    pool = new pg_1.Pool({
                        connectionString: "postgres://".concat(user || deploymentKey, ":").concat(DB_PW, "@").concat(DB_HOST, "/").concat(deploymentKey),
                        keepAlive: true
                    });
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, pool.connect()];
                case 3:
                    conn = _b.sent();
                    exports.deployments["readonly:" + deploymentKey] = { 'conn': conn, 'pool': pool };
                    return [3 /*break*/, 5];
                case 4:
                    e_3 = _b.sent();
                    console.warn("Error connecting to db: " + deploymentKey, e_3);
                    return [2 /*return*/, (0, neverthrow_1.err)('db_conn_failed')];
                case 5: return [2 /*return*/, (0, neverthrow_1.ok)(exports.deployments["readonly:" + deploymentKey]['conn'])];
            }
        });
    });
}
exports.getReadOnlyDbConn = getReadOnlyDbConn;
function getDbConn(deploymentKey, user, host, password) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, DB_HOST, DB_PW, pool, conn, e_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!!exports.deployments[deploymentKey]) return [3 /*break*/, 6];
                    if (!(!host || !password)) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, secrets_1.getGlobalConfigs)('DB_HOST', 'DB_PW')];
                case 1:
                    _a = _b.sent(), DB_HOST = _a.DB_HOST, DB_PW = _a.DB_PW;
                    host = DB_HOST, password = DB_PW;
                    _b.label = 2;
                case 2:
                    if (process.env.NODE_ENV === 'development') {
                        host = '127.0.0.1';
                        password = 'postgres';
                    }
                    pool = new pg_1.Pool({
                        connectionString: "postgres://".concat(user || deploymentKey, ":").concat(password, "@").concat(host, "/").concat(deploymentKey),
                        keepAlive: true
                    });
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, pool.connect()];
                case 4:
                    conn = _b.sent();
                    exports.deployments[deploymentKey] = { 'conn': conn, 'pool': pool };
                    return [3 /*break*/, 6];
                case 5:
                    e_4 = _b.sent();
                    console.warn("Error connecting to db: " + deploymentKey, e_4);
                    return [2 /*return*/, (0, neverthrow_1.err)('db_conn_failed')];
                case 6: return [2 /*return*/, (0, neverthrow_1.ok)(exports.deployments[deploymentKey]['conn'])];
            }
        });
    });
}
exports.getDbConn = getDbConn;
function endDbConn(deploymentKey) {
    if (exports.deployments[deploymentKey] && exports.deployments[deploymentKey]['conn']) {
        var _a = exports.deployments[deploymentKey], conn = _a.conn, pool = _a.pool;
        conn.release();
        delete exports.deployments[deploymentKey];
    }
}
exports.endDbConn = endDbConn;
/** Use $1, $2 format to pass parameters. */
function unsafeReadOnlyQuery(query, params, deploymentKey) {
    return __awaiter(this, void 0, void 0, function () {
        var conn, result, res, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.debug("ReadOnlyQuery called with query and deployment: ", query, deploymentKey);
                    return [4 /*yield*/, getReadOnlyDbConn(deploymentKey, deploymentKey + "_analyzer")];
                case 1:
                    conn = _a.sent();
                    if (conn.isErr())
                        return [2 /*return*/, (0, neverthrow_1.err)(conn.error)];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, conn.value.query(query, params)];
                case 3:
                    res = _a.sent();
                    // console.timeEnd(QUERYING);
                    if (res && res.rows.length) {
                        result = (0, neverthrow_1.ok)(res.rows);
                    }
                    else {
                        result = (0, neverthrow_1.err)("no_results");
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_5 = _a.sent();
                    console.error("Error in unsafeReadOnlyQuery: ", e_5);
                    result = (0, neverthrow_1.err)("error_in_unsafe_query");
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/, result];
            }
        });
    });
}
exports.unsafeReadOnlyQuery = unsafeReadOnlyQuery;

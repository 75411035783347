"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address = void 0;
console.time("Load Derived: Address");
var neverthrow_1 = require("neverthrow");
var request_1 = require("../../util/request");
var secrets_1 = require("../../util/secrets");
var runtime_1 = require("@pgtyped/runtime");
exports.Address = {
    getSlugs: function (params) {
        var e_1, _a;
        var slugs = [
            '_county',
            '_tract',
            '_lat',
            '_lng',
            '_building',
            '_subpremise',
            '_street',
            '_city',
            '_state',
            '_zip',
            '_dpb',
            '_formatted'
        ];
        var dependencies = [];
        var metadata = JSON.parse(params.infoDefs[params.targetField]['metadata'] || '{}');
        try {
            for (var _b = __values((metadata['lookups'] || [])), _c = _b.next(); !_c.done; _c = _b.next()) {
                var lookup = _c.value;
                slugs.push("_" + lookup);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return (0, neverthrow_1.ok)({ slugs: slugs, dependencies: dependencies });
    },
    compute: function (params) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var countyKey, parentKey, address, googleKey, e_2, baseUrl, headers, result, countyList, updates, lat, lng, getLookupValue, metadata, _d, _e, lookup, lookupResult, e_3_1, _f, _g, lookup;
            var _h, _j, _k, e_3, _l, e_4, _m;
            return __generator(this, function (_o) {
                switch (_o.label) {
                    case 0:
                        // if county is already populated, we don't need to do anything
                        // this would typically be from Smarty providing the county in its response,
                        // or a lookup table providing the county
                        if (!((_a = params.newInfoKeys[params.targetField + '_county']) !== null && _a !== void 0 ? _a : '').trim()) {
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        countyKey = params.targetField;
                        if (countyKey.indexOf('_county') === -1)
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        parentKey = countyKey.replace('_county', '');
                        console.debug("DEBUG COUNTY INFO DEF: ", params.infoDefs[countyKey]);
                        address = params.newInfoKeys[parentKey];
                        _o.label = 1;
                    case 1:
                        _o.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, (0, secrets_1.getGlobalConfig)('GOOGLE_KEY')];
                    case 2:
                        googleKey = _o.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _o.sent();
                        console.warn("Cannot optimistically compute Address derived fields.", e_2);
                        return [2 /*return*/, (0, neverthrow_1.ok)({})];
                    case 4:
                        baseUrl = "https://maps.googleapis.com/maps/api/geocode/json?address=" +
                            encodeURIComponent(address || '') + "&key=" + googleKey;
                        headers = { "content-type": "application/json" };
                        return [4 /*yield*/, (0, request_1.request)("GET", baseUrl, { headers: headers }, {})];
                    case 5:
                        result = _o.sent();
                        // console.log("Got result for Address: ", result);
                        if ((result['results'] || []).length === 0)
                            return [2 /*return*/, (0, neverthrow_1.ok)((_h = {}, _h[countyKey] = 'Unknown', _h))];
                        if (result['results'][0]['partial_match']) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_j = {}, _j[countyKey] = 'Unknown', _j))];
                        }
                        countyList = (result['results'][0]['address_components'] || [])
                            .filter(function (c) {
                            if (c.types && c.types.indexOf('administrative_area_level_2') !== -1)
                                return true;
                        }).map(function (c) {
                            return c['short_name'];
                        });
                        updates = (_k = {},
                            _k[countyKey] = countyList[0],
                            _k);
                        console.debug("Address debug: ", result['results'][0].geometry);
                        lat = (_b = result['results'][0].geometry) === null || _b === void 0 ? void 0 : _b.location.lat;
                        lng = (_c = result['results'][0].geometry) === null || _c === void 0 ? void 0 : _c.location.lng;
                        if (result['results'][0].formatted_address) {
                            updates[parentKey + '_formatted'] = result['results'][0].formatted_address;
                        }
                        updates[parentKey + '_lat'] = lat.toString();
                        updates[parentKey + '_lng'] = lng.toString();
                        getLookupValue = (0, runtime_1.sql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            SELECT value FROM geolookup WHERE ST_CONTAINS(shape, ST_POINT( $lng, $lat ))\n                and kind = $lookup\n                LIMIT 1;\n        "], ["\n            SELECT value FROM geolookup WHERE ST_CONTAINS(shape, ST_POINT( $lng, $lat ))\n                and kind = $lookup\n                LIMIT 1;\n        "])));
                        metadata = JSON.parse(params.infoDefs[parentKey].metadata || '{}');
                        if (!(params.conn && lat && lng)) return [3 /*break*/, 14];
                        _o.label = 6;
                    case 6:
                        _o.trys.push([6, 11, 12, 13]);
                        _d = __values((metadata['lookups'] || [])), _e = _d.next();
                        _o.label = 7;
                    case 7:
                        if (!!_e.done) return [3 /*break*/, 10];
                        lookup = _e.value;
                        console.log("Checking lookup " + lookup + " for field " + parentKey);
                        return [4 /*yield*/, getLookupValue.run({ lng: lng, lat: lat, lookup: lookup }, params.conn)];
                    case 8:
                        lookupResult = _o.sent();
                        if (lookupResult.length) {
                            updates[lookup] = lookupResult[0].value;
                            updates[parentKey + '_' + lookup] = lookupResult[0].value;
                        }
                        else {
                            updates[lookup] = '';
                            updates[parentKey + '_' + lookup] = '';
                        }
                        _o.label = 9;
                    case 9:
                        _e = _d.next();
                        return [3 /*break*/, 7];
                    case 10: return [3 /*break*/, 13];
                    case 11:
                        e_3_1 = _o.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 13];
                    case 12:
                        try {
                            if (_e && !_e.done && (_l = _d.return)) _l.call(_d);
                        }
                        finally { if (e_3) throw e_3.error; }
                        return [7 /*endfinally*/];
                    case 13: return [3 /*break*/, 15];
                    case 14:
                        try {
                            for (_f = __values((metadata['lookups'] || [])), _g = _f.next(); !_g.done; _g = _f.next()) {
                                lookup = _g.value;
                                updates[lookup] = '';
                                updates[parentKey + '_' + lookup] = '';
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (_g && !_g.done && (_m = _f.return)) _m.call(_f);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                        _o.label = 15;
                    case 15:
                        console.log("Returning updates for address derived: ", updates);
                        return [2 /*return*/, (0, neverthrow_1.ok)(updates)];
                }
            });
        });
    }
};
console.timeEnd("Load Derived: Address");
var templateObject_1;

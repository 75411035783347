export const LogLevels = [
  'warn',
  'info',
  'debug',
  'error',
  'assert'
] as const;

export type LogLevel = typeof LogLevels extends ReadonlyArray<infer T> ? T : never;

export const DefaultColors: Record<LogLevel, string> = {
  info: 'lightblue',
  warn: 'orange',
  error: 'red',
  debug: 'gray',
  assert: 'gray'
};

export const DefaultStyles = (colors: Record<LogLevel, string>) => (level: LogLevel) => [
  `background: ${colors[level]}`,
  `border-radius: 0.5em`,
  `color: black`,
  `font-weight: bold`,
  `padding: 2px 0.5em`,
];

function log(styles: string[], prefix: string, level: LogLevel, devOnly: boolean): (input: any, ...args: any[]) => void {
  if(process.env.NODE_ENV !== 'development' && devOnly) {
    // no-op
    return () => {};
  }
  else if (level === 'assert') {
    return function(input, ...args) {
      console.assert(input, `%c${prefix}`, styles.join(';'), ...args);
    }
  } else {
    return function(input, ...args) {
      (console as any)[level](`%c${prefix}`, styles.join(';'), input, ...args);
    }
  }
}


export const createLogger =
    (name: string, styles: (lvl: LogLevel) => string[], devOnly?: LogLevel[]) => LogLevels
      .reduce((a, b) => ({
        [b]: log(styles(b), name, b, devOnly?.includes(b) ?? false),
        ...a
      }), {} as { [K in LogLevel]: (...args: any[]) => void })

export type Logger = ReturnType<typeof createLogger>;

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeKeys = void 0;
var derived_1 = require("./derived");
var sorter_1 = require("./sorter");
var neverthrow_1 = require("neverthrow");
var stage_1 = require("./stage");
var runtime_1 = require("@pgtyped/runtime");
var json_1 = require("../util/json");
var util_1 = require("./util");
var crypto_1 = require("../util/crypto");
var wrapper_1 = require("./wrapper");
var program_configuration_1 = require("../util/program_configuration");
var client_dynamodb_1 = require("@aws-sdk/client-dynamodb");
var BUILD_TOPO = "---- Building Topological Node Map ----";
var STACK = "---- Computing the Queued Stack ----";
/**
 * This function computes all computations for a keyset given a survey definition.
    Inputs:
    infoKeys is the set of key/value pairs that are changed by the user.
    e.g. infoKeys: { 'name': 'John' }

    infoDefs is a simplified survey definition --
        type is the only required variable (The main record string is the key)
        name is optionally left in for debugging purposes.
        formula is used for Computed and Validated types
    e.g. infoDefs: { 'type': 'Computed', 'formula': "info.name + ' is a cool guy'" }

    screenerMetadata is a key/value pairing of the screener's metadata, which is sometimes used in computations.
    i.e. screenerMetadata: { 'Mail USIO': 'yes' }

    orgMetadata is similar, i.e. { 'Applicant Quota': 125 }
*/
function computeKeys(params) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
        var infoDefs, orgMetadata, screenerMetadata, uid, previousInfo, newInfo, derivedUpdates, dynamo, queue, vertices, pdmap, dmap, topoResponse, debugAll, key, alwaysComputeDerived, aidkitCrypto, configPromise, computeField, parallelFieldTypes, flyQueue, flyTasks, _loop_1, key, batchSize, _loop_2, i, queue_1, queue_1_1, s, e_1_1, CorrectionRequestExists, newStage, exists, stage, key;
        var e_1, _e;
        var _this = this;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    infoDefs = params.infoDefs, orgMetadata = params.orgMetadata || {}, screenerMetadata = params.screenerMetadata || {};
                    uid = params.infoKeys['uid'];
                    previousInfo = params.previousInfo || {};
                    newInfo = __assign({}, params.infoKeys);
                    derivedUpdates = {};
                    if (['development', 'test'].includes(process.env.NODE_ENV || '')) {
                        dynamo = new client_dynamodb_1.DynamoDBClient({ endpoint: 'http://127.0.0.1:8080' });
                    }
                    else {
                        dynamo = new client_dynamodb_1.DynamoDBClient({});
                    }
                    // Get the queue and vertices by passing relevant data that will allow us to determine the order of operations.
                    // This also updates the infoDefs dictionary with new definitions for derived fields.
                    console.time(BUILD_TOPO);
                    if (!((_a = params.computeOptions) === null || _a === void 0 ? void 0 : _a.topologicalInfo)) return [3 /*break*/, 1];
                    queue = params.computeOptions.topologicalInfo.queue;
                    vertices = params.computeOptions.topologicalInfo.vertices;
                    pdmap = params.computeOptions.topologicalInfo.pdmap;
                    dmap = params.computeOptions.topologicalInfo.dmap;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, (0, sorter_1.buildTopologicalNodeMap)(infoDefs, params.deploymentKey || 'postgres')];
                case 2:
                    topoResponse = _f.sent();
                    if (topoResponse.isErr())
                        return [2 /*return*/, (0, neverthrow_1.err)(topoResponse.error)];
                    queue = topoResponse.value.queue;
                    vertices = topoResponse.value.vertices;
                    pdmap = topoResponse.value.pdmap;
                    dmap = topoResponse.value.dmap;
                    _f.label = 3;
                case 3:
                    if ((_b = params.computeOptions) === null || _b === void 0 ? void 0 : _b.debugKeys) {
                        debugAll = false;
                        for (key in (((_c = params.computeOptions) === null || _c === void 0 ? void 0 : _c.debugKeys) || {})) {
                            if (!key)
                                continue;
                            debugAll = true;
                            console.debug("DEBUG INFO FOR KEY: " + key);
                            console.debug("DEPENDENTS: ", pdmap[key] || []);
                            console.debug("PARENTS: ", dmap[key] || []);
                            console.debug("QUEUE SPOT: ", queue.indexOf(vertices.indexOf(key)));
                            console.debug("INFO DEF: ", infoDefs[key]);
                        }
                        if (debugAll) {
                            console.debug("newInfo: ", newInfo);
                        }
                    }
                    console.timeEnd(BUILD_TOPO);
                    console.time(STACK);
                    alwaysComputeDerived = ['Lookup'];
                    aidkitCrypto = (0, crypto_1.startAidKitCryptoPromise)();
                    configPromise = params.conn ? (0, program_configuration_1.getDbConfiguration)(params.conn) : new Promise(function (resolve) { return resolve(null); });
                    computeField = function (s) { return __awaiter(_this, void 0, void 0, function () {
                        var u, t, debugThisKey, derived, doCompute, sectionMetadata, doLock, wrappedResult, result, func, fnStr, wrappedResult, result, error, fnStr, wrappedResult, result, error, t0, derivedResponse, t1, localDerivedUpdates, outerKey, record, theRecord, recordKey;
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        return __generator(this, function (_j) {
                            switch (_j.label) {
                                case 0:
                                    u = vertices[s];
                                    if (!infoDefs[u])
                                        return [2 /*return*/];
                                    if ((_b = (_a = params.computeOptions) === null || _a === void 0 ? void 0 : _a.skipFields) === null || _b === void 0 ? void 0 : _b.includes(u))
                                        return [2 /*return*/];
                                    t = infoDefs[u]['type'];
                                    debugThisKey = u in (((_c = params.computeOptions) === null || _c === void 0 ? void 0 : _c.debugKeys) || {});
                                    derived = infoDefs[u]['derivedType'];
                                    doCompute = (dmap[u] || [])
                                        .some(function (d) {
                                        return (previousInfo[d] === undefined && newInfo[d] !== undefined) ||
                                            (previousInfo[d] !== undefined && newInfo[d] !== previousInfo[d]);
                                    });
                                    if (!infoDefs[u].sectionMetadata) return [3 /*break*/, 2];
                                    sectionMetadata = (0, json_1.safeParse)(infoDefs[u].sectionMetadata || '{}');
                                    if (!sectionMetadata.lock_on_condition) return [3 /*break*/, 2];
                                    return [4 /*yield*/, (0, util_1.evalConditional)(sectionMetadata.lock_on_condition, newInfo, orgMetadata, screenerMetadata, __assign({}, (((_d = params.computeOptions) === null || _d === void 0 ? void 0 : _d.customClockTimestamp) && { customClockTimestamp: params.computeOptions.customClockTimestamp })))];
                                case 1:
                                    doLock = _j.sent();
                                    // If doLock is truthy, skip.
                                    if (doLock) {
                                        return [2 /*return*/];
                                    }
                                    _j.label = 2;
                                case 2:
                                    if (((_e = params.computeOptions) === null || _e === void 0 ? void 0 : _e.forceComputeFields) && params.computeOptions.forceComputeFields.indexOf(u) !== -1) {
                                        doCompute = true;
                                    }
                                    // Compute computations always
                                    if (['Computed', 'Validated'].includes(t)) {
                                        doCompute = true;
                                    }
                                    if (derived && alwaysComputeDerived.indexOf(derived) !== -1 && derivedUpdates[u] === undefined) {
                                        doCompute = true;
                                    }
                                    if (debugThisKey) {
                                        console.debug("[DEBUG 4]: Computing " + u + "?", doCompute);
                                    }
                                    if (!doCompute)
                                        return [2 /*return*/];
                                    // Don't compute if the conditional is not true
                                    // console.debug("checking Conditional");
                                    if (infoDefs[u]['conditional']) {
                                        wrappedResult = (0, wrapper_1.wrappedFn)("return (function(info, org, screener, view_info) { const out = " + infoDefs[u]['conditional'] + "; return out });", null, {
                                            args: [newInfo, orgMetadata, screenerMetadata, {}],
                                            mockDate: (_f = params.computeOptions) === null || _f === void 0 ? void 0 : _f.customClockTimestamp
                                        });
                                        // If result is null, could not parse conditional.
                                        if (wrappedResult === null || wrappedResult.error) {
                                            console.warn("Error parsing CONDITIONAL function for infoDef: " + u);
                                            if (wrappedResult === null || wrappedResult === void 0 ? void 0 : wrappedResult.error) {
                                                console.warn(wrappedResult.error);
                                            }
                                            return [2 /*return*/];
                                        }
                                        result = wrappedResult.result;
                                        if (debugThisKey) {
                                            console.debug("DEBUG INFO FOR KEY: " + u);
                                            console.debug("SURVEY DEFINITION:", infoDefs[u]);
                                            console.debug("CONDITIONAL RESULT: ", result);
                                        }
                                        if (!result) {
                                            // Don't compute.
                                            return [2 /*return*/];
                                        }
                                    }
                                    if (t === 'Computed') {
                                        func = void 0;
                                        fnStr = "return (function(info, org, screener) { const out = " + infoDefs[u].formula + "; return out });";
                                        wrappedResult = (0, wrapper_1.wrappedFn)(fnStr, null, {
                                            args: [newInfo, orgMetadata, screenerMetadata],
                                            mockDate: (_g = params.computeOptions) === null || _g === void 0 ? void 0 : _g.customClockTimestamp
                                        });
                                        // If result is null, could not parse formula.
                                        if (wrappedResult === null) {
                                            console.warn("Error parsing COMPUTED function for infoDef: " + u);
                                            return [2 /*return*/];
                                        }
                                        result = wrappedResult.result, error = wrappedResult.error;
                                        if (result !== undefined && result !== null) {
                                            result = result.toString();
                                        }
                                        else {
                                            result = '';
                                        }
                                        error = error || '';
                                        newInfo[u] = result;
                                        newInfo[u + "_error"] = error;
                                    }
                                    else if (t === 'Validated') {
                                        fnStr = "return (function(info, org, screener) { " + infoDefs[u].formula + " });";
                                        wrappedResult = (0, wrapper_1.wrappedFn)(fnStr, null, {
                                            args: [newInfo, orgMetadata, screenerMetadata],
                                            mockDate: (_h = params.computeOptions) === null || _h === void 0 ? void 0 : _h.customClockTimestamp
                                        });
                                        // If result is null, could not parse formula.
                                        if (wrappedResult === null) {
                                            console.warn("Error parsing VALIDATED function for infoDef: " + u);
                                            return [2 /*return*/];
                                        }
                                        result = wrappedResult.result, error = wrappedResult.error;
                                        result = (result || '').toString();
                                        error = error || '';
                                        newInfo[u] = result;
                                        newInfo[u + "_error"] = error;
                                    }
                                    if (debugThisKey) {
                                        console.debug("DEBUG KEY RESULT FOR KEY ".concat(u, ": "), newInfo[u]);
                                    }
                                    if (!derived) return [3 /*break*/, 4];
                                    // Get the derived answer for this key.
                                    // It may have already been updated by a previous stack key, which should be fine
                                    // assuming they both live within the same function.
                                    // So don't run the function again if it already exists as an update.
                                    if (debugThisKey) {
                                        console.debug("DEBUG INFO: Checking if ".concat(derivedUpdates[u], " is undefined for derived type ").concat(derived));
                                    }
                                    if (!(derivedUpdates[u] === undefined)) return [3 /*break*/, 4];
                                    if (debugThisKey)
                                        console.debug("DEBUG INFO: Calculating derivedUpdates for targetField ".concat(u, " with type ").concat(derived));
                                    t0 = new Date().getTime();
                                    return [4 /*yield*/, derived_1.DERIVED_MODELS[derived].compute({
                                            conn: params.conn,
                                            deploymentKey: params.deploymentKey || '',
                                            infoDefs: infoDefs,
                                            previousInfo: previousInfo,
                                            newInfoKeys: newInfo,
                                            targetField: u,
                                            aidkitCrypto: aidkitCrypto,
                                            derivedType: derived,
                                            configPromise: configPromise,
                                            dynamo: dynamo,
                                            computeOptions: params.computeOptions
                                        })];
                                case 3:
                                    derivedResponse = _j.sent();
                                    t1 = new Date().getTime();
                                    console.log("DERIVED ".concat(u, " COMPUTE TIME: ").concat(t1 - t0, "ms"), "status: ", derivedResponse.isOk() ? "OK" : derivedResponse.error);
                                    if (derivedResponse.isErr()) {
                                        console.warn("Error in derived response for key: " + u, derivedResponse.error);
                                        if (derivedResponse.error === 'no_db_conn' && !params.conn) {
                                            console.warn("No connection provided, skipping this computation.");
                                            return [2 /*return*/];
                                        }
                                        return [2 /*return*/, (0, neverthrow_1.err)(derivedResponse.error)];
                                    }
                                    localDerivedUpdates = derivedResponse.value;
                                    if (debugThisKey)
                                        console.debug("DEBUG INFO: Got derived updates:", localDerivedUpdates);
                                    // Add any updates to newInfo
                                    for (outerKey in localDerivedUpdates) {
                                        record = localDerivedUpdates[outerKey];
                                        if (typeof record === typeof 'string') {
                                            newInfo[outerKey] = record;
                                            derivedUpdates[outerKey] = record;
                                        }
                                        else {
                                            if (uid && uid === outerKey) {
                                                theRecord = record;
                                                for (recordKey in theRecord) {
                                                    newInfo[recordKey] = theRecord[recordKey];
                                                    derivedUpdates[recordKey] = theRecord[recordKey];
                                                }
                                            }
                                        }
                                    }
                                    _j.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); };
                    parallelFieldTypes = ['Lookup', 'Extract Text', 'Address', 'Detect Faces', 'Flag'];
                    flyQueue = [];
                    flyTasks = [];
                    _loop_1 = function (key) {
                        var e_2, _g;
                        if (parallelFieldTypes.indexOf(infoDefs[key].type) > -1) {
                            // Check if this field depends on any computed or other derived fields
                            // If so, we'll run it in the queue, otherwise we can run it up front.
                            var dependsOn = dmap[key] || [];
                            var doParallel = true;
                            try {
                                for (var dependsOn_1 = (e_2 = void 0, __values(dependsOn)), dependsOn_1_1 = dependsOn_1.next(); !dependsOn_1_1.done; dependsOn_1_1 = dependsOn_1.next()) {
                                    var parent_1 = dependsOn_1_1.value;
                                    if (parallelFieldTypes.indexOf(infoDefs[parent_1].type) > -1 || ['Computed', 'Validated'].indexOf(infoDefs[parent_1].type) > -1) {
                                        doParallel = false;
                                        break;
                                    }
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (dependsOn_1_1 && !dependsOn_1_1.done && (_g = dependsOn_1.return)) _g.call(dependsOn_1);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                            if (doParallel) {
                                flyTasks.push(function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, computeField(queue[vertices.indexOf(key)])];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); });
                            }
                        }
                    };
                    for (key in infoDefs) {
                        _loop_1(key);
                    }
                    if (!(flyTasks.length > 0)) return [3 /*break*/, 9];
                    batchSize = 5;
                    _loop_2 = function (i) {
                        var promise;
                        return __generator(this, function (_h) {
                            switch (_h.label) {
                                case 0:
                                    promise = flyTasks[i]()
                                        .then(function () {
                                        flyQueue = flyQueue.filter(function (p) { return p !== promise; });
                                    })
                                        .catch(function () {
                                        flyQueue = flyQueue.filter(function (p) { return p !== promise; });
                                    });
                                    flyQueue.push(promise);
                                    if (!(flyQueue.length >= batchSize)) return [3 /*break*/, 2];
                                    return [4 /*yield*/, Promise.race(flyQueue)];
                                case 1:
                                    _h.sent();
                                    _h.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _f.label = 4;
                case 4:
                    if (!(i < flyTasks.length)) return [3 /*break*/, 7];
                    return [5 /*yield**/, _loop_2(i)];
                case 5:
                    _f.sent();
                    _f.label = 6;
                case 6:
                    i++;
                    return [3 /*break*/, 4];
                case 7: return [4 /*yield*/, Promise.all(flyQueue)];
                case 8:
                    _f.sent();
                    _f.label = 9;
                case 9:
                    _f.trys.push([9, 14, 15, 16]);
                    queue_1 = __values(queue), queue_1_1 = queue_1.next();
                    _f.label = 10;
                case 10:
                    if (!!queue_1_1.done) return [3 /*break*/, 13];
                    s = queue_1_1.value;
                    return [4 /*yield*/, computeField(s)];
                case 11:
                    _f.sent();
                    _f.label = 12;
                case 12:
                    queue_1_1 = queue_1.next();
                    return [3 /*break*/, 10];
                case 13: return [3 /*break*/, 16];
                case 14:
                    e_1_1 = _f.sent();
                    e_1 = { error: e_1_1 };
                    return [3 /*break*/, 16];
                case 15:
                    try {
                        if (queue_1_1 && !queue_1_1.done && (_e = queue_1.return)) _e.call(queue_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                    return [7 /*endfinally*/];
                case 16:
                    ;
                    console.timeEnd(STACK);
                    CorrectionRequestExists = (0, runtime_1.sql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        SELECT id\n        FROM note \n        WHERE note.applicant = $uid\n            AND action_required = true\n            AND (action_completed is null OR action_completed = false)\n    "], ["\n        SELECT id\n        FROM note \n        WHERE note.applicant = $uid\n            AND action_required = true\n            AND (action_completed is null OR action_completed = false)\n    "])));
                    if (!params.conn) return [3 /*break*/, 18];
                    return [4 /*yield*/, CorrectionRequestExists.run({
                            uid: uid
                        }, params.conn)];
                case 17:
                    exists = (_f.sent()).length > 0;
                    if (exists) {
                        newStage = 'Correction Requested';
                        newInfo['stage'] = newStage;
                    }
                    _f.label = 18;
                case 18:
                    if (!!newStage) return [3 /*break*/, 20];
                    return [4 /*yield*/, (0, stage_1.computeStage)(newInfo, infoDefs, params.orgMetadata || {}, params.screenerMetadata || {}, params.computeOptions || {})];
                case 19:
                    stage = (_f.sent()).stage;
                    newStage = stage;
                    console.log("Computing Stage: " + (previousInfo['stage'] || '') + " -> " + newStage);
                    if (!previousInfo['stage'] || newStage !== previousInfo['stage']) {
                        newInfo['stage'] = newStage;
                    }
                    _f.label = 20;
                case 20:
                    // Dont error if this fails, just try it.
                    try {
                        for (key in ((_d = params.computeOptions) === null || _d === void 0 ? void 0 : _d.debugKeys) || {}) {
                            if (key in newInfo) {
                                console.log("DEBUG found key: ", key, " in newInfo: ", newInfo[key]);
                            }
                            else {
                                console.log("DEBUG DID NOT FIND key ", key, " in newInfo");
                            }
                        }
                    }
                    catch (e) {
                        // do nothing
                    }
                    return [2 /*return*/, (0, neverthrow_1.ok)(newInfo)];
            }
        });
    });
}
exports.computeKeys = computeKeys;
var templateObject_1;

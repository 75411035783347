import { LivenessDetection } from "@aidkitorg/types/lib/survey";
import { LivenessStage, LivenessState } from "./types";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useLocalizedStrings } from "../../Localization";
import { AidKitLogo, BarSpinner, SpacedSpinner } from "../../Util";
import { CardFront, DebitCardFront, CardBack, Selfie } from "./CoverImages";
import { facingModeForStage } from "./consts";

type RealtimeResults = Record<"front" | "back" | "selfie", "passed" | "failed" | "pending" | "max_attempts_reached" | undefined> | null

export function FinalPage(props: {
  realtimeResults: RealtimeResults, 
  livenessState: LivenessState,
  multipleCameras: boolean, 
  canCloseWindow: boolean,
  livenessConfig: LivenessDetection,
  setActionState: React.Dispatch<React.SetStateAction<LivenessStage | null>>,
  setRetrying: React.Dispatch<React.SetStateAction<boolean>>,
  setRealtimeResults: React.Dispatch<React.SetStateAction<RealtimeResults>>,
  setFacingMode: React.Dispatch<React.SetStateAction<"user" | "environment">>
}) {

  const L = useLocalizedStrings();
  const parts = props.livenessConfig.identification ?
    ['front', 'back', 'selfie'] as const :
    ['selfie'] as const;

  // Figure out the global state to display
  let state = L.questions.identity.please_wait_processing;
  let completeCount = 0;
  let failedCount = 0;
  parts.forEach((part) => {
    if ((props.livenessState as any)[part].videoUpload !== 1 || (props.livenessState as any)[part].imageUpload !== 1) return;
    if ((props.realtimeResults as any)?.[part] === 'passed' || (props.realtimeResults as any)?.[part] === 'max_attempts_reached') completeCount += 1;
    if ((props.realtimeResults as any)?.[part] === 'failed') failedCount += 1;
  });
  if (completeCount === parts.length) {
    state = props.canCloseWindow ? L.applicant.all_done_you_may_now_close_this_window : L.questions.identity.please_wait_processing;
    props.setActionState("done");
  }
  if (failedCount > 0) {
    state = L.questions.identity.blurry_sorry;
  }

  return <div className="bg-gray-100 flex flex-col fixed h-screen w-screen overflow-y-scroll" style={{ height: '-webkit-fill-available' }}>
    <div className="flex items-center flex flex-col py-2">
      <div className="px-10 py-10 text-xl m-0 bg-white w-full flex items-center flex-col">
        <div className="max-w-lg flex-1">
          <AidKitLogo width={100} height={50} />
          <h3>{state}</h3>
          <div>{state === L.questions.identity.please_wait_processing ? 
            <BarSpinner /> : null}</div>
        </div>
      </div>
    </div>
    {window.opener && state === L.applicant.all_done_you_may_now_close_this_window && <div className="flex flex-none items-center justify-center">
      <button type="button" 
        onClick={(e) => {
          e.preventDefault();
          window.close(); 
        }} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >{L.apply.close}</button>
    </div>}
    <div className="flex flex-grow bg-gray-100 text-gray-800 justify-center items-center">
      <div className="mx-auto max-w-lg px-4 py-4 sm:px-6 md:py-16">
        <div>
          <ul role="list" className="divide-y divide-gray-200">
            {parts.map((part) => (
              <li key={"progress-" + part} className="flex items-center justify-between gap-x-2 p-2 bg-white rounded-md m-3" style={{ "border": '2px solid black' }}>
                <div className="flex min-w-0 gap-x-4 text-center" style={{ width: '90px' }}>
                  <div className="text-center" style={{ 'width': '90px' }}>
                    {{
                      'front': <CardFront style={{ height: 50, width: 'auto' }} />,
                      'back': props.livenessConfig.identification?.back.alternativeDocument?.kind === 'Debit Card' 
                        ? <DebitCardFront style={{ height: 50, width: 'auto' }} /> 
                        : <CardBack style={{ height: 50, width: 'auto' }} />,
                      'selfie': <Selfie style={{ height: 50, width: 'auto', display: 'inline-block' }} />,
                    }[part]}
                  </div>
                </div>
                <div className="min-w-0 flex-auto mr-2 flex justify-center items-center text-gray-900">
                  {(() => {
                    let partState = props.realtimeResults?.[part] as any;
                    if (props.livenessState[part].videoUpload !== 1 || props.livenessState[part].imageUpload !== 1) {
                      partState = 'uploading';
                    }
                    switch (partState) {
                      case 'failed':
                        return L.questions.identity.photo_too_blurry;
                      case 'passed':
                      case 'max_attempts_reached':
                        return L.questions.identity.complete;
                      case 'uploading':
                        return L.questions.attachment.uploading + " " + Math.round(Math.min(props.livenessState[part].imageUpload || 0, props.livenessState[part].videoUpload || 0)*100) + '%';
                      case 'pending':
                      default:
                        return L.questions.identity.checking_quality;
                    }
                  })()}
                </div>
                <div className="text-center mr-2" style={{ width: "70px" }}>
                  {(() => {
                    let partState = props.realtimeResults?.[part];
                    if (props.livenessState[part].videoUpload !== 1 || props.livenessState[part].imageUpload !== 1) {
                      partState = 'pending';
                    }
                    switch (partState) {
                      case 'failed':
                        return <button className="border-0 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            props.setRetrying(true);
                            props.setActionState(part);
                            if (props.multipleCameras) props.setFacingMode(facingModeForStage[part])
                            props.setRealtimeResults((cur) => ({
                              ...cur, 
                              [part]: "pending"
                            } as RealtimeResults));
                            partState = 'pending';
                          }}>{L.questions.identity.retry}</button>;
                      case 'passed':
                      case 'max_attempts_reached': // ¯\_(ツ)_/¯
                        return <span><CheckCircleIcon className="text-green-500" width={30} /></span>;
                      case 'pending':
                      default:
                        return <span><SpacedSpinner /></span>;
                    }
                  })()}
                </div>
              </li>
            ))}
          </ul></div>
      </div>
    </div>
  </div>;
}

import { limitConcurrentRequests } from '@aidkitorg/types/lib/util';
import { ContextType, useContext, useEffect, useState } from "react";
import { usePost } from "../API";
import { OfflineSyncContext } from "../Context";
import { useDebouncedCallback } from "../Hooks/Debounce";
import { useLocalizedStrings } from "../Localization";
import { SyncState, SyncStatus } from "../offline/routes";
import { offlineLogger } from "../utils";
import { assertUnreachable } from "../utils/typechecks";

export type SyncProps = {
  id?: string | string[],
  override?: string | JSX.Element,
  hideStatusText?: boolean
};

export default function SyncBadge(props: SyncProps) {
  const context = useContext(OfflineSyncContext);
  const [statuses, setStatuses] = useState(context.statuses);

  const updateStatuses = useDebouncedCallback(() => {
    setStatuses(context.statuses);
  });

  useEffect(() => updateStatuses(), [context.statuses]);

  return <SyncBadgeInner {...{ ...props, statuses }} />;
}

function SyncBadgeInner(props: SyncProps & ContextType<typeof OfflineSyncContext>) {

  let statuses = props.statuses;
  const getEverything = usePost('/applicant/get_everything');
  const L = useLocalizedStrings();

  const state: SyncState | undefined = (() => {
    if (props.id) {
      if (Array.isArray(props.id)) {
        statuses = props.id.reduce((prev, curr) => ({
          ...prev,
          [curr]: statuses[curr] ?? { status: SyncStatus.Unavailable }
        }), {});
      } else {
        return statuses[props.id];
      }
    }

    const possibles = new Set(Object.values(statuses).map(s => s.status));

    if (possibles.has(SyncStatus.Ahead)) {
      return {
        status: SyncStatus.Ahead,
        changes: Object.values(statuses)
          .filter(s => s.status === SyncStatus.Ahead)
          .reduce((acc, b) => acc + (b as any).changes || 0, 0)
      }
    }

    if (possibles.size > 1) {
      return undefined;
    }

    return Object.values(statuses)[0];
  })();


  const classes = [
    'rounded-full',
    'border-0',
    'opacity-75',
    'text-black',
  ];

  let statusText: string | JSX.Element = 'Sync';
  let onClick: () => Promise<any> = async () => {
    const ids = props.id ? Array.isArray(props.id) ? props.id : [props.id] : Object
      .entries(statuses)
      .filter(([, { status }]) => status !== SyncStatus.BrandNew)
      .map(([id]) => id);

    await limitConcurrentRequests(ids.map((uid) => () => getEverything({ uid })), 5)
  };

  if (state) {
    switch (state.status) {
      case SyncStatus.Processing:
        classes.push('animate-pulse');
        classes.push('bg-green-300');
        statusText = L.offline.processing_status;
        break;
      case SyncStatus.InSync:
        classes.push('bg-green-400');
        statusText = L.offline.insync_status;
        break;
      case SyncStatus.Unavailable:
        classes.push('bg-gray-400');
        statusText = L.offline.unavailable_status;
        break;
      case SyncStatus.BrandNew:
        classes.push('bg-green-600');
        statusText = L.offline.new_status;
        // no-op, as there is nothing to refresh
        onClick = () => Promise.resolve();
        break;
      case SyncStatus.Stale:
        classes.push('bg-yellow-400');
        statusText = L.offline.stale_status;
        break;
      case SyncStatus.Ahead:
        classes.push('bg-blue-400');
        statusText = L.offline.n_ahead_status.replace('$n', (state as any).changes ?? 0);
        onClick = async () => {
          const ids = props.id ? (Array.isArray(props.id) ? props.id : [props.id]) : Object
            .entries(statuses)
            .filter(([, { status }]) => status !== SyncStatus.BrandNew)
            .map(([id]) => id);

          offlineLogger.debug(`attempting to sync ${ids.length} applicants`);

          navigator.serviceWorker.controller?.postMessage({
            action: 'forceSync',
            ids
          });

        };
        break;
      default:
        assertUnreachable(state);
    }
  } else {
    classes.push('bg-purple-400');
  }

  if (props.hideStatusText) {
    classes.push('h-3 w-3');
    return <span
      onClick={onClick}
      title={statusText.toString()}
      className="relative flex h-3 w-3 ml-1">
      <span className={classes.join(' ')}></span>
    </span>
  }

  classes.push('py-1 px-2');
  return (
    <button
      onClick={onClick}
      title={statusText.toString()}
      className={classes.join(' ')}>
      {(state?.status === SyncStatus.Ahead ? statusText : props.override) ?? statusText}
    </button>
  );
}

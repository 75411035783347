import 'moment/locale/es';

import React, { useContext, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useAPI } from "./API";
import { useLocalizedStrings } from "./Localization";
import { ConfigurationContext } from './Context';
import { Alert } from 'react-bootstrap';

type StatsProps = {
  showStages?: Boolean,
  stats: any,
  org?: String, 
  screener?: String
  stage?: String,
}

function Stage(props: StatsProps) {
  return <>
    <tr>
      <td></td>
      <td></td>
      <td>{props.stage}</td>
      <td>{props.stats}</td>
    </tr>
  </>;
}

function Screener(props: StatsProps) {
  if (props.showStages) {
    return <>
      <tr>
        <td></td>
        <td>{props.screener}</td>
        <td></td>
        <td></td>
      </tr>
      {Object.keys(props.stats).map((s: any) => {
        return <Stage stats={(props.stats as any)[s]} org={props.org} screener={props.screener} stage={s} key={s} />
      })}
    </>
  } else {
    let sum = 0;
    for (const stage in props.stats) {
      sum += parseInt(props.stats[stage]);
    }
    return <tr>
      <td></td>
      <td>{props.screener}</td>
      <td></td>
      <td>{sum}</td>
    </tr>
  }

}


function Organization(props: StatsProps) {
  const [expanded, setExpanded] = useState(false);

  if (expanded) {
    return <>
      <tr>
        <td>{props.org}<Button variant="light" style={{float: 'right'}} onClick={() => setExpanded(false)}>{'-'}</Button></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>

      {Object.keys(props.stats).map((s: any) => {
        return <Screener showStages={props.showStages} org={props.org} screener={s} stats={(props.stats as any)[s]} key={s} />
      })}
    </>
  } else {
    if (props.showStages) {
      let sums = {} as any;
      for (const screener in props.stats) {
        for (const stage in props.stats[screener]) {
          sums[stage] = (sums[stage] || 0) + parseInt(props.stats[screener][stage]);
        }
      }
      return <>
        <tr>
          <td>{props.org}<Button variant="light" style={{float: 'right'}} onClick={() => setExpanded(true)}>{'+'}</Button></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        {Object.keys(sums).map((s) => {
          return <tr key={s}>
            <td></td>
            <td></td>
            <td>{s}</td>
            <td>{sums[s]}</td>
          </tr>
        })}</>;
    } else {
      let sum = 0;
      for (const screener in props.stats) {
        for (const stage in props.stats[screener]) {
          sum += parseInt(props.stats[screener][stage]);
        }
      }

      return <tr>
        <td>{props.org}<Button variant="light" style={{float: 'right'}} onClick={() => setExpanded(true)}>{'+'}</Button></td>
        <td></td>
        <td></td>
        <td>{sum}</td>
      </tr>
    }
  }

}

function StatsPage() {

  const L = useLocalizedStrings();
  const configuration = useContext(ConfigurationContext);
  const stats = useAPI("/applicants/stage_stats");
  const [showStages, setShowStages] = useState(false)
  const [countIneligible, setCountIneligle] = useState(false)

  const [formattedStats, setFormattedStats] = useState({});
  useEffect(() => {
    let orgs: any = {};
    if (stats.stats) {
      stats.stats.forEach((row: any) => {
        if ((row.stage === 'Ineligible' || row.stage === 'Unreachable' || row.stage === 'Opted Out') && countIneligible === false) return;
        if (!orgs[row.org]) {
          orgs[row.org] = {}
        }
        if (!orgs[row.org][row.user]) {
          orgs[row.org][row.user] = {}
        }
        orgs[row.org][row.user][row.stage] = row.count
      });
      setFormattedStats(orgs);
    }
  }, [stats, setFormattedStats, countIneligible])

  let sums = {} as any;
  let totalsum = 0;
  if (Object.keys(formattedStats).length > 1) {
    for (const org in formattedStats) {
      for (const screener in (formattedStats as any)[org]) {
        for (const stage in (formattedStats as any)[org][screener]) {
          const n = parseInt((formattedStats as any)[org][screener][stage])
          sums[stage] = (sums[stage] || 0) + n;
          totalsum += n;
        }
      }
    }
  }

  return (
    <div className="p-5">
      <h2>{L.stats.current_status}</h2>
      {configuration && !configuration.roles?.includes('admin') && (
        <>
          <br/>
          {configuration.organization && <Alert variant="info">{L.stats.stats_for_org.replace('%v',configuration.organization?.name)}</Alert>}
          {!configuration.organization && <Alert variant="warning">{L.stats.no_org_cannot_show}</Alert>}
        </>
      )}
      {(configuration.organization || configuration.roles?.includes('admin')) && (
        <>
          <Form.Switch
            id="switchEnabled"
            type="switch"
            checked={showStages}
            onChange={(e) => setShowStages(!showStages)}
            label="Show Stages"
          />&nbsp;<Form.Switch
            id="ineligibleEnabled"
            type="switch"
            checked={countIneligible}
            onChange={(e) => setCountIneligle(!countIneligible)}
            label="Count Ineligible / Unreachable / Opted Out"
          /><br />
          <Table bordered>
            <thead>
              <tr>
                <th style={{'width': "25%"}}>{L.applicant.organization}</th>
                <th style={{'width': "25%"}}>{L.applicant.screener}</th>
                <th style={{'width': "25%"}}>{L.stats.stage}</th>
                <th style={{'width': "25%"}}>{L.stats.count}</th>
              </tr>
            </thead>
            <tbody>
              {totalsum > 0 && showStages &&
              <>
                <tr>
                  <td>{L.stats.globally}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {Object.keys(sums).map((s) => {
                  return <tr key={s}>
                    <td></td>
                    <td></td>
                    <td>{s}</td>
                    <td>{sums[s]}</td>
                  </tr>
                })}
              </>}
              {totalsum > 0 && !showStages &&
                <tr>
                  <td>{L.stats.globally}</td>
                  <td></td>
                  <td></td>
                  <td>{totalsum}</td>
                </tr>}
              {Object.keys(formattedStats).map((s: any) => {
                return <Organization showStages={showStages} stats={(formattedStats as any)[s]} org={s} key={s} />
              })}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
}

export default StatsPage;

import { useState } from "react";
import { usePost } from "./API";

export default function GeolookupPage() {
  const [kind, setKind] = useState('');
  const [property, setProperty] = useState('');
  const [url, setUrl] = useState('');
  const [loadDynamo, setLoadDynamo] = useState(false);
  const [loadPostgres, setLoadPostgres] = useState(false);
  const loadBoundaries = usePost('/program/admin/load_boundaries');

  const load = async function() {
    alert(JSON.stringify(await loadBoundaries({ kind, property, url, loadDynamo, loadPostgres })));
  }

  return <>
    <h1>Boundary Loading</h1>
    <hr/>
    <p>Note that for simplicity and performance this does not run RS. If you want to run RS, do so from the other admin interface</p>
    <fieldset>
      <legend>Lookup Kind</legend>
      <input value={kind} onChange={(e) => setKind(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
      <legend>Url</legend>
      <input value={url} onChange={(e) => setUrl(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
      <legend>Property</legend>
      <input value={property} onChange={(e) => setProperty(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
      <legend>Load Postgres</legend>
      <input type={"checkbox"} checked={loadPostgres} onChange={(e) => setLoadPostgres(e.target.checked)} />
    </fieldset>
    <fieldset>
      <legend>Load Dynamo</legend>
      <input type={"checkbox"} checked={loadDynamo} onChange={(e) => setLoadDynamo(e.target.checked)} />
    </fieldset>
    <br />
    <button onClick={load}>Load Boundaries</button>
  </>
}
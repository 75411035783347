"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BenefitsCalculator = void 0;
var BENEFITS_CALCULATOR_TIMER_NAME = "Load Derived: Benefits Calculator";
console.time(BENEFITS_CALCULATOR_TIMER_NAME);
var neverthrow_1 = require("neverthrow");
exports.BenefitsCalculator = {
    getSlugs: function (params) {
        var _a, _b;
        var flattenIntoFieldsWithPrefix = JSON.parse((_b = (_a = params.infoDefs[params.targetField]) === null || _a === void 0 ? void 0 : _a.metadata) !== null && _b !== void 0 ? _b : '{}').flattenIntoFieldsWithPrefix;
        var slugs = [];
        if (flattenIntoFieldsWithPrefix) {
            slugs = slugs.concat([
                '_pre_foodstamps_Basic_Food_Program_eligible',
                '_pre_foodstamps_Basic_Food_Program_amount',
                '_pre_foodstamps_CalFresh_eligible',
                '_pre_foodstamps_CalFresh_amount',
                '_pre_wic_WIC_eligible',
                '_pre_wic_WIC_amount',
                '_pre_welfare_WorkFirst_eligible',
                '_pre_welfare_WorkFirst_amount',
                '_pre_welfare_CalWorks_eligible',
                '_pre_welfare_CalWorks_amount',
                '_pre_welfare_General_Relief_eligible',
                '_pre_welfare_General_Relief_amount',
                '_pre_healthcare',
                '_pre_childcare_Child_Care_Assistance_eligible',
                '_pre_childcare_Child_Care_Assistance_amount',
                '_pre_utilities_HEAP_eligible',
                '_pre_utilities_HEAP_amount',
                '_pre_utilities_Lifeline_eligible',
                '_pre_utilities_Lifeline_amount',
                '_pre_utilities_FERA-CARE_eligible',
                '_pre_utilities_FERA-CARE_amount',
                '_pre_utilities_CARE_eligible',
                '_pre_utilities_CARE_amount',
                '_pre_taxes',
                '_pre_eitc_eitc_eligible',
                '_pre_eitc_eitc_amount',
                '_pre_cctc_Child_Care_Tax_Credit_eligible',
                '_pre_cctc_Child_Care_Tax_Credit_amount',
                '_pre_ctc_Child_Tax_Credit_eligible',
                '_pre_ctc_Child_Tax_Credit_amount',
                '_pre_hsi_hsi_eligible',
                '_pre_hsi_hsi_amount',
                '_pre_hsi_Not_Eligible_eligible',
                '_pre_hsi_Not_Eligible_amount',
                '_pre_hsi__eligible',
                '_pre_hsi__amount',
                '_pre_hsi_Affordable_Connectivity_Program_eligible',
                '_pre_hsi_Affordable_Connectivity_Program_amount',
                '_pre_sdoh',
                '_pre_unemployment_Unemployment_Insurance_eligible',
                '_pre_unemployment_Unemployment_Insurance_amount',
                '_pre_ssissd_ssissd_eligible',
                '_pre_ssissd_ssissd_amount',
                '_pre_section8',
                '_post_foodstamps_Basic_Food_Program_eligible',
                '_post_foodstamps_Basic_Food_Program_amount',
                '_post_foodstamps_CalFresh_Program_eligible',
                '_post_foodstamps_CalFresh_Program_amount',
                '_post_wic_WIC_eligible',
                '_post_wic_WIC_amount',
                '_post_welfare_WorkFirst_eligible',
                '_post_welfare_WorkFirst_amount',
                '_post_welfare_CalWorks_eligible',
                '_post_welfare_CalWorks_amount',
                '_post_welfare_General_Relief_eligible',
                '_post_welfare_General_Relief_amount',
                '_post_healthcare',
                '_post_childcare_Child_Care_Assistance_eligible',
                '_post_childcare_Child_Care_Assistance_amount',
                '_post_utilities_HEAP_eligible',
                '_post_utilities_HEAP_amount',
                '_post_utilities_Lifeline_eligible',
                '_post_utilities_Lifeline_amount',
                '_post_utilities_FERA-CARE_eligible',
                '_post_utilities_FERA-CARE_amount',
                '_post_utilities_CARE_eligible',
                '_post_utilities_CARE_amount',
                '_post_taxes',
                '_post_eitc_eitc_eligible',
                '_post_eitc_eitc_amount',
                '_post_cctc_Child_Care_Tax_Credit_eligible',
                '_post_cctc_Child_Care_Tax_Credit_amount',
                '_post_ctc_Child_Tax_Credit_eligible',
                '_post_ctc_Child_Tax_Credit_amount',
                '_post_hsi_hsi_eligible',
                '_post_hsi_hsi_amount',
                '_post_hsi_Not_Eligible_eligible',
                '_post_hsi_Not_Eligible_amount',
                '_post_hsi__eligible',
                '_post_hsi__amount',
                '_post_hsi_Affordable_Connectivity_Program_eligible',
                '_post_hsi_Affordable_Connectivity_Program_amount',
                '_post_sdoh',
                '_post_unemployment_Unemployment_Insurance_eligible',
                '_post_unemployment_Unemployment_Insurance_amount',
                '_post_ssissd_ssissd_eligible',
                '_post_ssissd_ssissd_amount',
                '_post_section8',
            ].map(function (fieldSuffix) { return (flattenIntoFieldsWithPrefix + fieldSuffix); }));
        }
        return (0, neverthrow_1.ok)({ slugs: slugs });
    },
    compute: function (params) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, (0, neverthrow_1.ok)({})];
            });
        });
    }
};
console.timeEnd(BENEFITS_CALCULATOR_TIMER_NAME);

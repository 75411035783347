import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import InterfaceContext, { ConfigurationContext } from "../Context";
import { languageContent, safeParse } from "../Util";
import { QuestionProps } from "./Props";
import html2canvas from "html2canvas";
import { useAPIUpload, usePost } from "../API";
import { useLocalizedStrings } from "../Localization";
import { ClickableButton } from "../Components/Button";
import { LightBulbIcon } from "@heroicons/react/24/solid";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import * as v0 from "@aidkitorg/types/lib/survey";
import { cropCanvasToDataURL } from "../CanvasUtil";

type extraProps = {
  // Special prop for InlineSignature
  // Allows replacing a string in the inline signature with a custom element
  // Used by the W9 Form to replace #checkbox with a checkbox to cross out line 2.
  replaceWithHtml?: {
    regex: RegExp,
    html: string
  }
}
export function InlineSignature(props: QuestionProps & extraProps) {
  const context = useContext(InterfaceContext);
  const content = props[languageContent(context.lang)]?.replace("\\(", "(").replace("\\[", "[") || '';
  const signatureCanvas = useRef(null as HTMLCanvasElement | null);
  const baseCanvas = useRef(null as HTMLCanvasElement | null);

  const contract = useRef(null as HTMLDivElement | null);
  const controls = useRef(null as HTMLDivElement | null);
  const signatureInstructionsRef = useRef(null as HTMLDivElement | null);
  const typeSignatureButtonRef = useRef(null as HTMLDivElement | null);

  const [isTypedSignature, setIsTypedSignature] = useState(false);
  const [isDrawnSignature, setIsDrawnSignature] = useState(false);

  const [signedImage, setSignedImage] = useState(props.info[props['Target Field']!] as string | null);
  const certifyContract = usePost("/document/certify");
  const [saving, setSaving] = useState(false);
  const [progress, setProgress] = useState(0);
  const config = useContext(ConfigurationContext);
  const metadata = safeParse(props.Metadata || '{}');

  const upload = useAPIUpload("/upload", (progress: number) => {
    setProgress(Math.round(progress * 100));
  });

  const L = useLocalizedStrings();

  useEffect(() => {
    if (props.info && props['Target Field']) {
      setSignedImage(props.info[props['Target Field']] as string | null);
    }
  }, [props.info, props['Target Field']]);

  const [strokes, setStrokes] = useState(0);
  const loadSignatureBox = useCallback((node: HTMLInputElement) => {
    if (node) {
      if (!signatureCanvas.current) {
        const base = document.createElement('canvas');
        base.className = "border rounded-sm"
        base.style.touchAction = "none";
        base.style.position = 'absolute';
        base.style.left = '0';
        base.style.top = '0';
        base.style.zIndex = '0';
        baseCanvas.current = base;
        base.width = 260;
        base.height = 100;

        const sig = document.createElement('canvas');
        sig.style.touchAction = "none";
        signatureCanvas.current = sig;
        sig.style.backgroundColor = 'transparent';
        sig.style.position = 'absolute';
        // Offset a little to not interrupt the border of the base
        sig.style.left = '2px';
        sig.style.top = '2px';
        sig.style.zIndex = '1';
        sig.width = base.width - 2;
        sig.height = base.height - 2;

        var isDrawing = false;
        var x = 0;
        var y = 0;
        var ctx = sig.getContext("2d")!;
        ctx.fillStyle = 'transparent';
        ctx.strokeStyle = "black";
        ctx.fillRect(0, 0, sig.width, sig.height);
        ctx.lineWidth = 1;
                
        var ctxBase = base.getContext("2d")!;
        ctxBase.fillStyle = 'white';
        ctxBase.fillRect(0, 0, base.width, base.height);
        ctxBase.beginPath();
        ctxBase.strokeStyle = "black";
        ctxBase.setLineDash([2,2]);
        ctxBase.lineWidth = 1;
        ctxBase.moveTo(20, 80);
        ctxBase.lineTo(240, 80);
        ctxBase.stroke();
        ctxBase.closePath();

        const drawLine = (x1: number, y1: number, x2: number, y2: number) => {                    
          ctx.beginPath();
          ctx.strokeStyle = "black";
          ctx.lineWidth = 1;
          ctx.setLineDash([]);
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.stroke();
          ctx.closePath();
        }
        const drawText = () => {
          var name = prompt("{{TYPE_NAME}}");
          if (name && name.length < 2) return;
          ctx.fillStyle = "black";
          ctx.font = "14px serif";
          ctx.fillText("/" + name + "/", 10, 50);
          //strokes += 1;
        }

        var mousedown = function(e: MouseEvent | TouchEvent) {
          setIsDrawnSignature(true);  
          setIsTypedSignature(false);
          //if(!canSign) return;
          e = e as MouseEvent;

          x = e.offsetX;
          y = e.offsetY;

          const t = e as unknown as TouchEvent
          if (t.touches) {
            const rect = sig.getBoundingClientRect();
            const top = rect.top + document.documentElement.scrollTop;
            const left = rect.left + document.documentElement.scrollLeft;
            x = t.touches[0].pageX - left;
            y = t.touches[0].pageY - top;
          }
          isDrawing = true;
        }
        var mousemove = function(e: MouseEvent | TouchEvent) {
          if (isDrawing === true) {
            setStrokes(strokes + 1);
            const t = e as unknown as TouchEvent
            if (t.touches) {
              const rect = sig.getBoundingClientRect();
              const top = rect.top + document.documentElement.scrollTop;
              const left = rect.left + document.documentElement.scrollLeft;
              var nx = t.touches[0].pageX - left;
              var ny = t.touches[0].pageY - top;
              drawLine(x, y, nx, ny);
              x = nx;
              y = ny;
            } else {
              e = e as MouseEvent;
              drawLine(x, y, e.offsetX, e.offsetY);
              x = e.offsetX;
              y = e.offsetY;
            }
          }
        };
        var mouseup = function(e: MouseEvent | TouchEvent) {
          document.body.style.backgroundColor = 'white';
          if (isDrawing === true) {
            const t = e as unknown as TouchEvent
            if (t.touches) {
              //var nx = e.touches[0].pageX - e.touches[0].target.offsetLeft;
              //var ny = e.touches[0].pageY - e.touches[0].target.offsetTop;
              //drawLine(x, y, nx, ny);
            } else {
              e = e as MouseEvent;
              drawLine(x, y, e.offsetX, e.offsetY);
            }
            x = 0;
            y = 0;
            isDrawing = false;
          }
        }
        sig.addEventListener('touchstart', mousedown);
        sig.addEventListener('mousedown', mousedown);
        sig.addEventListener('touchmove', mousemove);
        sig.addEventListener('mousemove', mousemove);
        sig.addEventListener('touchend', mouseup);
        sig.addEventListener('mouseup', mouseup);
        document.addEventListener('mouseup', function(e) { isDrawing = false; });
      }
      node.appendChild(baseCanvas.current!);
      node.appendChild(signatureCanvas.current);
    }
  }, [])

  function clearSignature() {
    if (signatureCanvas.current) {
      const sig = signatureCanvas.current;
      var ctx = sig.getContext("2d")!;
      ctx.clearRect(0, 0, sig.width, sig.height);
      setStrokes(0);
      setIsTypedSignature(false);
      setIsDrawnSignature(false);
      setSignedImage(null);
    } else {
      console.error("Signature canvas not found while clearing signature");
    }
  }

  function typeSignature() {
    clearSignature();
    const name = prompt(L.questions.inline_signature.please_type_your_name_to_sign);
    if (!name) return;

    setIsTypedSignature(true);
    setIsDrawnSignature(false);

    if (signatureCanvas.current) {
      const sig = signatureCanvas.current;
      var ctx = sig.getContext("2d")!;
      ctx.setLineDash([]);
      ctx.font = "20px serif";
      ctx.strokeText('/' + name + '/', 20, 60);

      sign();
    } else {
      console.error("Signature canvas not found while typing signature");
    }
  }

  function sign() {
    if (controls.current) controls.current!.style.visibility = "hidden";
    if (signatureInstructionsRef.current) signatureInstructionsRef.current.style.display = "none";
    if (typeSignatureButtonRef.current) typeSignatureButtonRef.current.style.display = "none";

    let signerName = props.info['legal_name'];
    if (metadata && metadata.signer_name) {
      if (typeof metadata.signer_name === 'string') {
        signerName = props.info[metadata.signer_name];
      } else if (metadata.signer_name.special === 'Viewer Name') {
        if(config?.user?.name) {
          signerName = config.user.name;
        }
      }
    }

    setSaving(true);
    setProgress(0);
    let interval = setInterval(() => {
      setProgress(p => Math.min(100, p + 1))
    }, 100);

    setTimeout(async () => {
      await Promise.all([
        html2canvas(contract.current!, { 'scale': 1 }),
        html2canvas(signatureCanvas.current!, { 'scale': 1 })
      ]).then(async function([contractCanvas, signatureCanvas]) {
        // Crop the signature canvas
        let rawSignature = cropCanvasToDataURL(signatureCanvas);

        const response = await certifyContract({
          image: contractCanvas.toDataURL(), 
          legal_name: signerName,
          anonymous: metadata.anonymous,
          rawSignature
        });
        if (metadata.captureRawSignature) {
          props.setInfoKey(props['Target Field']!, response.rawSignature, true, false);
        } else {
          props.setInfoKey(props['Target Field']!, response.image, true, false);
        }
        setSignedImage(response.image);
      }).finally(() => {
        clearInterval(interval);
        setProgress(100);
        setSaving(false);
      });
    }, 100);
  }

  async function deleteSignature() {
    const yn = await window.confirm(L.banking.are_you_sure);
    if (!yn) return;
    clearSignature();
    setSignedImage(null);
    props.setInfoKey(props['Target Field']!, '', false, true);
  }

  const marked = useModularMarkdown({
    content: metadata.modernType 
      ? (metadata.modernType as v0.InlineSignature).content 
      : content,
    uid: props.uid,
    info: props.info,
    replaceWithHtml: props.replaceWithHtml
  })

  if (signedImage) {
    return <>
      <img src={signedImage} />
      <br/>
      {(process.env.NODE_ENV === 'development' || (config.roles || '').indexOf('admin') !== -1) && 
                <div className="mt-2">
                  <ClickableButton color="gray" onClick={async () => deleteSignature()}>
                    {L.banking.delete}
                  </ClickableButton>
                </div>}
    </>
  }
  return <div className="relative">
    <div ref={contract} className={"rounded-md p-3"}>
      {!signedImage && <div>
        <div className="my-2">
          {marked}
        </div>
        <div id="signature_instructions" ref={signatureInstructionsRef} className="flex flex-row mt-2">
          <div className="flex-1 my-auto mr-1">
            <LightBulbIcon className="h-8 text-green-400" />
          </div>
          <div>
            <small>{L.questions.inline_signature.sign_with_your_finger}</small>
          </div>
        </div>
        <div id="type_signature_button" ref={typeSignatureButtonRef}>
          <button aria-label="Type signature" aria-expanded="false" onClick={typeSignature} className="inline-block my-3 mx-1 rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            {L.questions.inline_signature.type_signature} 
          </button>
        </div>
      </div>}
      <fieldset>
        <div style={{height: '6.3125em'}} className={isTypedSignature ? "mb-12" : ""}>
          <div style={{position: 'relative' }} ref={loadSignatureBox}></div>
        </div>
        <div ref={controls}>
          { strokes > 0 && (
            <>
              <button aria-label="Clear signature" onClick={clearSignature} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                {L.questions.inline_signature._clear}
              </button>
              <button onClick={sign} className="m-1 inline-block rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-indigo-700 text-white text-sm font-medium hover:bg-blue-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                aria-describedby="saveInstruction"
              >
                {L.questions.inline_signature._save} 
              </button>
            </> 
          )}
        </div>
      </fieldset>
    </div>
    {/* Overlay Component */}
    {saving ?
      <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-4 rounded shadow-lg text-center">
          <span className="text-lg font-bold">{L.questions.inline_signature._saving}</span>
          <span>{progress + "%"}</span>
        </div>
      </div> : null}
  </div>;
}
import { Card, Form, Button} from "react-bootstrap"
import { useLocalizedStrings } from "../Localization";
import { useRef, useState, useContext } from "react";
import { useAPIUpload, usePost } from "../API";
import { useToast } from "@aidkitorg/component-library";
import { copyToClipboard } from "../Util";
import InterfaceContext, { ConfigurationContext, SupportedLanguage } from "../Context";
import { langToWord } from "../Localization";

export default function PublicS3() {

  const L = useLocalizedStrings();
  const fileInput = useRef(null as any);
  const audioFileInput = useRef(null as any);
  const [fileInputLabel, setFileInputLabel] = useState(L.questions.attachment.choose_files);
  const [audioFileInputLabel, setAudioFileInputLabel] = useState(L.questions.attachment.choose_files);
  const [s3URL, setS3URL] = useState('');
  const [audioS3URL, setAudioS3URL] = useState('');
  const [lang, setLang] = useState<SupportedLanguage>('en');
  const uploadURL = usePost("/document/upload_url");
  const upload = useAPIUpload("/upload");
  const context = useContext(InterfaceContext);
  const configuration = useContext(ConfigurationContext);
  const { toast } = useToast();

  const handleLanguageChange = (selectedLanguage: SupportedLanguage) => {
    setLang(selectedLanguage);
  };
    
  async function uploadFile(files: File[], isAudioUpload: boolean) {
    if (isAudioUpload && !lang) {
      toast({
        description: "Language code is required for audio uploads.",
        variant: 'error'
      });
      return;
    }

    const filePath = isAudioUpload 
      ? `tts/${lang}/${files[0].name}`
      : files[0].name;

    const url = await uploadURL({
      path: filePath,
      length: files[0].size,
      publicFile: true
    }); 
        
    let result;
        
    try {
      result = await upload(files as unknown as any[], {}, url.uploadURL, "PUT");
      if (isAudioUpload) {
        setAudioS3URL(url.savedPath!);
      } else {
        setS3URL(url.savedPath!);
      }
    } catch (e) {
      console.warn("Error uploading file: ", e);
      toast({
        description: "Error uploading file",
        variant: 'error'
      });
    }

    if (typeof result === 'string' && url.savedPath) {
      toast({
        description: "Upload successful",
        variant: 'success'
      });
      if (fileInput.current) { 
        fileInput.current.value = "";
        setFileInputLabel(L.questions.attachment.choose_file);
      } else if (isAudioUpload && audioFileInput.current) {
        audioFileInput.current.value = "";
        setAudioFileInputLabel(L.questions.attachment.choose_file);
      }
    } 
  } 

  return(
    <>
      <h5>Upload a general document file to store on S3 and create a publicly accessible URL.</h5>
      <div>
        <Form>
          <div className="row">
            <div className="col">
              <Form.Group>
                <div className="custom-file">
                  <input
                    multiple
                    className="custom-file-input"
                    id={"fileupload"}
                    onChange={async (e) => {
                      if (e.target.files && e.target.files.length === 0) {
                        setFileInputLabel(L.questions.attachment.choose_file);
                      } else {
                        if (e.target.files) {
                          uploadFile(Array.from(e.target.files), false);
                          setFileInputLabel(L.questions.attachment.choose_file);
                        }
                      }
                    }}
                    type="file"
                    ref={fileInput}
                  />
                  <label
                    className={"custom-file-label"}
                    htmlFor={"fileupload"}
                  >
                    {fileInputLabel}
                  </label>
                </div>
              </Form.Group>
            </div>
          </div>
        </Form>
        <div hidden={!s3URL}>
          <div className="d-flex justify-content-center m-3">
            <Card style={{ width: '30rem', padding: '2rem', backgroundColor: "lavender" }}>
              <Card.Title>
                Public-Access URL:
              </Card.Title>
              <Card.Text id='url'>
                {s3URL}
              </Card.Text> 
              <Button variant="primary" onClick={() => { copyToClipboard(s3URL); toast({ description: L.copied_to_clipboard, variant: 'success' }); }}>
                Copy URL to Clipboard
              </Button> 
            </Card>
          </div>
        </div>
      </div>
      <hr/>
      <br/>
      <h5>Upload an audio file to store for custom text-to-speech.</h5>
      <div>
        <Form>
          <div className="row">
            <div className="col">
              <Form.Group>
                <Form.Label>Language:</Form.Label>
                <Form.Control
                  as="select"
                  value={lang}
                  onChange={(e) => setLang(e.target.value as SupportedLanguage)}
                >
                  {(configuration.languages || 'en,es').split(',').map((language: string) => (
                    <option key={language} value={language}>
                      {langToWord(language as SupportedLanguage, 'Name')}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <div className="custom-file">
                  <input />
                  <input
                    multiple
                    className="custom-file-input"
                    id={"audioFileUpload"}
                    onChange={async (e) => {
                      if (e.target.files && e.target.files.length === 0) {
                        setAudioFileInputLabel(L.questions.attachment.choose_file);
                      } else {
                        if (e.target.files) {
                          uploadFile(Array.from(e.target.files), true); // True for audio upload
                          setAudioFileInputLabel(L.questions.attachment.choose_file);
                        }
                      }
                    }}
                    type="file"
                    ref={audioFileInput}
                  />
                  <label
                    className={"custom-file-label"}
                    htmlFor={"audioFileUpload"}
                  >
                    {audioFileInputLabel}
                  </label>
                </div>
              </Form.Group>
            </div>
          </div>
        </Form>
        <div hidden={!audioS3URL}>
          <div className="d-flex justify-content-center m-3">
            <Card style={{ width: '30rem', padding: '2rem', backgroundColor: "lavender" }}>
              <Card.Title>
                Custom Text-to-Speech URL:
              </Card.Title>
              <Card.Text id='url'>
                {audioS3URL}
              </Card.Text> 
              <Button variant="primary" onClick={() => { copyToClipboard(audioS3URL); toast({ description: L.copied_to_clipboard, variant: 'success' }) }}>
                Copy URL to Clipboard
              </Button> 
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCachedInfoDefs = exports.getCachedInfoDefs = exports.createInfoDefsStore = void 0;
var neverthrow_1 = require("neverthrow");
var sorter_1 = require("../compute/sorter");
var neverthrow_2 = require("neverthrow");
var runtime_1 = require("@pgtyped/runtime");
function createInfoDefsStore(db, program) {
    return __awaiter(this, void 0, void 0, function () {
        var log, infoDefsResponse, insertMappedInfoDefs, rows, key, validated;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, db.query("CREATE TABLE IF NOT EXISTS info_defs (key TEXT PRIMARY KEY, data JSONB)")];
                case 1:
                    _a.sent();
                    log = function () {
                        var args = [];
                        for (var _i = 0; _i < arguments.length; _i++) {
                            args[_i] = arguments[_i];
                        }
                        console.log.apply(console, __spreadArray([program, "createInfoDefsStore:"], __read(args), false));
                    };
                    return [4 /*yield*/, (0, sorter_1.getAndSortInfoDefs)(db, program)];
                case 2:
                    infoDefsResponse = _a.sent();
                    if (!infoDefsResponse.isOk()) return [3 /*break*/, 8];
                    log("Inserting definitions");
                    insertMappedInfoDefs = (0, runtime_1.sql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            INSERT INTO info_defs (key, data) VALUES $$rows(key, data)\n            ON CONFLICT (key) DO UPDATE set data = EXCLUDED.data\n            "], ["\n            INSERT INTO info_defs (key, data) VALUES $$rows(key, data)\n            ON CONFLICT (key) DO UPDATE set data = EXCLUDED.data\n            "])));
                    rows = [];
                    for (key in infoDefsResponse.value.infoDefs) {
                        rows.push({ key: key, data: infoDefsResponse.value.infoDefs[key] });
                    }
                    return [4 /*yield*/, insertMappedInfoDefs.run({ rows: rows }, db)];
                case 3:
                    _a.sent();
                    // Insert the config (JSONB does not support array as top level)
                    return [4 /*yield*/, db.query("INSERT INTO info_defs (key, data) VALUES ('__config', $1)\n            ON CONFLICT (key) DO UPDATE set data = $1", [{ config: infoDefsResponse.value.config }])];
                case 4:
                    // Insert the config (JSONB does not support array as top level)
                    _a.sent();
                    // and topo
                    return [4 /*yield*/, db.query("INSERT INTO info_defs (key, data) VALUES \n            ('__topo', $1)\n            ON CONFLICT (key) DO UPDATE set data = $1", [infoDefsResponse.value.topologicalInfo])];
                case 5:
                    // and topo
                    _a.sent();
                    log("Validating");
                    return [4 /*yield*/, validateCachedInfoDefs(db, program, (0, neverthrow_2.ok)(infoDefsResponse.value))];
                case 6:
                    validated = _a.sent();
                    return [4 /*yield*/, db.query("INSERT INTO info_defs (key, data) VALUES \n            ('__validated', $1)\n            ON CONFLICT (key) DO UPDATE set data = EXCLUDED.data", [{ valid: validated }])];
                case 7:
                    _a.sent();
                    _a.label = 8;
                case 8:
                    log("Done");
                    return [2 /*return*/];
            }
        });
    });
}
exports.createInfoDefsStore = createInfoDefsStore;
function getCachedInfoDefs(db, options) {
    return __awaiter(this, void 0, void 0, function () {
        var infoDefsDB, infoDefs, obj, validated, _a, _b, row;
        var e_1, _c;
        var _this = this;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, (function () { return __awaiter(_this, void 0, void 0, function () { var _a, e_2; return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 2, , 3]);
                                _a = neverthrow_2.ok;
                                return [4 /*yield*/, db.query("SELECT * FROM info_defs")];
                            case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
                            case 2:
                                e_2 = _b.sent();
                                return [2 /*return*/, (0, neverthrow_1.err)("Info defs not yet created")];
                            case 3: return [2 /*return*/];
                        }
                    }); }); })()];
                case 1:
                    infoDefsDB = _d.sent();
                    if (infoDefsDB.isErr()) {
                        return [2 /*return*/, infoDefsDB];
                    }
                    infoDefs = {};
                    obj = {};
                    validated = false;
                    try {
                        for (_a = __values(infoDefsDB.value.rows), _b = _a.next(); !_b.done; _b = _a.next()) {
                            row = _b.value;
                            if (row.key === '__config') {
                                obj.config = row.data.config;
                                continue;
                            }
                            if (row.key === '__topo') {
                                obj.topologicalInfo = row.data;
                                continue;
                            }
                            if (row.key === '__validated') {
                                validated = row.data.valid;
                                continue;
                            }
                            infoDefs[row.key] = row.data;
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    if (!validated && !(options === null || options === void 0 ? void 0 : options.__validating)) {
                        return [2 /*return*/, (0, neverthrow_1.err)("Not validated")];
                    }
                    if (!obj.topologicalInfo) {
                        return [2 /*return*/, (0, neverthrow_1.err)("Topological Info not found")];
                    }
                    if (!obj.config) {
                        return [2 /*return*/, (0, neverthrow_1.err)("Config not found")];
                    }
                    return [2 /*return*/, (0, neverthrow_2.ok)({
                            infoDefs: infoDefs,
                            config: obj.config,
                            topologicalInfo: obj.topologicalInfo
                        })];
            }
        });
    });
}
exports.getCachedInfoDefs = getCachedInfoDefs;
function validateCachedInfoDefs(db, program, earlierIDCT) {
    return __awaiter(this, void 0, void 0, function () {
        var uncached, _a, cached;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(earlierIDCT !== null && earlierIDCT !== void 0)) return [3 /*break*/, 1];
                    _a = earlierIDCT;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, (0, sorter_1.getAndSortInfoDefs)(db, program)];
                case 2:
                    _a = _b.sent();
                    _b.label = 3;
                case 3:
                    uncached = _a;
                    return [4 /*yield*/, getCachedInfoDefs(db, { __validating: true })];
                case 4:
                    cached = _b.sent();
                    if (cached.isErr()) {
                        console.error("🛑 Cached info defs not found", cached.error);
                        return [2 /*return*/, false];
                    }
                    if (uncached.isErr()) {
                        console.error("🛑 Uncached info defs not found", uncached.error);
                        return [2 /*return*/, false];
                    }
                    if (!dictsAreEqual(uncached.value, cached.value)) {
                        console.error("🛑 Cached info defs do not match uncached info defs");
                        return [2 /*return*/, false];
                    }
                    console.log("✅ Cached info defs match uncached info defs!");
                    return [2 /*return*/, true];
            }
        });
    });
}
exports.validateCachedInfoDefs = validateCachedInfoDefs;
// deep compare dicts, each property should exist in the other dict
var deepCompare = function (dict1, dict2, depth) {
    if (!dict2) {
        console.log("Weird undefined dict at depth", depth, "when comparing dict:", dict1);
        return false;
    }
    for (var key in dict1) {
        if (Array.isArray(dict1[key])) {
            var sorted1 = dict1[key].sort();
            var sorted2 = dict2[key].sort();
            if (JSON.stringify(sorted1) !== JSON.stringify(sorted2)) {
                console.log("Mismatch in arrays", key);
                return false;
            }
        }
        if (typeof dict1[key] === 'object') {
            return deepCompare(dict1[key], dict2[key], depth + 1);
        }
        if (dict1[key] !== dict2[key]) {
            console.log("Mismatch in key", key);
            return false;
        }
    }
    return true;
};
function dictsAreEqual(dict1, dict2) {
    // Ensure the queues are the same:
    var queuesAreEqual = JSON.stringify(dict1.topologicalInfo.queue) === JSON.stringify(dict2.topologicalInfo.queue);
    if (!queuesAreEqual) {
        console.log("Queues are not equal");
        return false;
    }
    // Compare the rest of the object using deepCompare
    var first = deepCompare(dict1, dict2, 0);
    var second = deepCompare(dict2, dict1, 0);
    if (!first || !second)
        return false;
    return true;
}
var templateObject_1;

import * as v0 from '@aidkitorg/types/lib/survey';
import { useContext } from 'react';
import InterfaceContext from '../Context';
import { InfoDict } from '../Questions/Props';
import { useMarkdown } from '../Util';
import { sanitizeDangerousText } from '@aidkitorg/types/lib/utils/textSanitizer';
import buildConditionalContent from '@aidkitorg/types/lib/utils/conditionalContent';
import { useMemo } from 'react';

/**
 * - Converts Content (Array or Object or String) to a single Markdown JSX Element
 * - Replaces any $variables with the value of props.info[variable]
 */
export function useModularMarkdown(props: {
  content: string | v0.Content, 
  info: InfoDict,
  uid?: string,
  replacer?: (str: string) => string,
  replaceWithHtml?: {
    regex: RegExp, 
    html: string
  },
  collapsible?: boolean, 
  noParagraphs?: boolean
}) {
  const context = useContext(InterfaceContext);

  const { collapsible, noParagraphs } = props;

  const regex = /\$[a-zA-Z\\_][a-zA-Z\\_0-9]+/g;

  // Only rebuild conditional content if info or lang changes
  const marked = useMemo(() => { 
    const pieces = buildConditionalContent({ 
      uid: props.uid, 
      info: props.info, 
      lang: context.lang,
      content: props.content
    });

    // By wrapping substitutions with <bdi> tags like this, we greatly improve the odds
    // that they are displayed in the expected direction (ltr or rtl) regardless of the surrounding text.
    // See: https://www.w3.org/International/articles/inline-bidi-markup/index
    function wrapForDirection(wrappedFunc: (str: string) => string) {
      return (s: string) => `<bdi style="white-space: break-spaces">${wrappedFunc(s)}</bdi>`;
    }

    function infoReplacer(match: string): string {
      const variable = match.slice(1).replace(/\\_/g,'_');
      if(variable === "uid") return props.uid || '';
      if (props.info[variable] !== undefined && props.info[variable] !== null) return sanitizeDangerousText(props.info[variable]) || '';
      return match;
    }

    // Replaces uid and variables in text and sanitizes and wraps with directional span before using the
    // replacer function that was passed to useModularMarkdown, if there was one
    const firstReplace = (str: string) => str.replace(regex, wrapForDirection(infoReplacer));

    const replacer = props.replacer ? (str: string) => props.replacer!(firstReplace(str)) : firstReplace;

    return replacer(pieces.join("\n"));
  }, [context.lang, props.info, props.uid, props.content]);

  return useMarkdown(marked, collapsible, noParagraphs, props.replaceWithHtml);
}
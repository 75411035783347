import React, { useState } from 'react'
import { Popover, Transition, PopoverButtonProps } from '@headlessui/react'
import { usePopper } from "react-popper"

export const DEFAULT_CLASSES = "inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-blue-400 hover:text-gray-500 bg-blue-600 text-white";

export default function FlyoutMenu(props: { items: JSX.Element, label: string | JSX.Element, className?: string, popperProps?: PopoverButtonProps<"button">, zIndex?: number }) {

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className='h-full'>
      <Popover.Button
        ref={setReferenceElement}
        className={props.className ?? DEFAULT_CLASSES}
        {...props.popperProps}>
        {props.label}
      </Popover.Button>

      <Transition
        as={"div"}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel
          ref={setPopperElement}
          style={{
            ...styles.popper,
            zIndex: props.zIndex,
            position: 'fixed'
          }}
          {...attributes.popper}
          className={`flex w-screen max-w-min px-2 py-1`}>
          <div className="w-auto shrink min-w-max rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
            {props.items}
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

import * as fflate from 'fflate';
import { useContext } from 'react';
import { UserInfoContext } from '../Context';
import { useLocalizedStrings } from '../Localization';

export function ZipFileSystem() {
  const { uid } = useContext(UserInfoContext);
  const L = useLocalizedStrings();

  async function downloadTheThings() {
    let directoryHandle = await navigator.storage.getDirectory();
    const zipContents = {} as any;
    async function handleEntry(entry: any, path = '') {
      if (entry.kind === 'file') {
        const file = await entry.getFile();
        const lastModified = file.lastModified;
        const arrayBuffer = await file.arrayBuffer();
        const fullPath = path + `${lastModified}_${file.name}`;
        zipContents[fullPath] = new Uint8Array(arrayBuffer);
      } else if (entry.kind === 'directory') {
        for await (const subEntry of entry.values()) {
          await handleEntry(subEntry, path + entry.name + '/');
        }
      }
    }
    for await (const entry of directoryHandle.values() as any) {
      await handleEntry(entry);
    }
    const zippedData = fflate.zipSync(zipContents);
    const blob = new Blob([zippedData], { type: 'application/ zip' });
    const now = new Date();
    const formattedDate = now.toISOString().slice(0, 10); // YYYY-MM-DD
    const formattedTime = now.toTimeString().slice(0, 8).replace(/:/g, '-'); // HH-MM-SS
    const filename = `downloaded_files_${uid ?? 'user'}_${formattedDate}_${formattedTime}.zip`;
    // Create an anchor element and trigger a download
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <a className="cursor-pointer" onClick={downloadTheThings}>{L.offline.download_data}</a>
  );
}

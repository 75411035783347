export const accessibleTheme = {
    plain: {
        color: '#1F2937',
        backgroundColor: '#FFFFFF',
    },
    styles: [
        {
            types: ['comment', 'prolog', 'doctype', 'cdata'],
            style: {
                color: '#5B6A88',
                fontStyle: 'italic',
            },
        },
        {
            types: ['namespace'],
            style: {
                opacity: 0.7,
            },
        },
        {
            types: ['string', 'attr-value'],
            style: {
                color: '#0A5E47',
            },
        },
        {
            types: ['punctuation', 'operator'],
            style: {
                color: '#4A5568',
            },
        },
        {
            types: ["operator", "keyword"],
            style: {
                color: "#be185d",
                fontWeight: "bold",
            },
        },
        {
            types: ['entity', 'url', 'symbol', 'number', 'boolean', 'variable', 'constant', 'property', 'regex', 'inserted'],
            style: {
                color: '#0040C1',
            },
        },
        {
            types: ['atrule', 'keyword', 'attr-name', 'selector'],
            style: {
                color: '#0e7490',
            },
        },
        {
            types: ['function', 'deleted', 'tag'],
            style: {
                color: '#B91C1C',
            },
        },
        {
            types: ['function-variable'],
            style: {
                color: '#7C3AED',
            },
        },
        {
            types: ['tag', 'selector', 'keyword'],
            style: {
                color: '#1A56DB',
            },
        },
        {
            types: ['parameter', 'typing', 'type-declaration', 'type-annotation'],
            style: {
                color: '#4B5563',
            },
        },
        {
            types: ['class-name', 'maybe-class-name', 'interface', 'enum'],
            style: {
                color: '#0369A1',
            },
        },
        {
            types: ['builtin', 'char', 'changed', 'exports', 'module'],
            style: {
                color: '#c2410c',
            },
        },
        {
            types: ['title', 'section'],
            style: {
                color: '#1E40AF',
                fontWeight: 'bold',
            },
        },
        {
            types: ['italic'],
            style: {
                fontStyle: 'italic',
            },
        },
        {
            types: ['bold'],
            style: {
                fontWeight: 'bold',
            },
        },
        {
            types: ['list', 'list-item'],
            style: {
                color: '#374151',
            },
        },
        {
            types: ['code', 'code-line'],
            style: {
                color: '#B91C1C',
                backgroundColor: '#F3F4F6',
            },
        },
        {
            types: ['url'],
            style: {
                color: '#2563EB',
            },
        },
        {
            types: ['strike'],
            style: {
                color: '#6B7280',
            },
        },
        {
            types: ['table'],
            style: {
                color: '#374151',
            },
        },
        {
            types: ['important', 'bold'],
            style: {
                fontWeight: 'bold',
                fontStyle: 'italic',
            },
        },
    ],
};

import { useRef, useState, useCallback, useEffect } from "react";

/** Constants for Recording */
const MAX_CHUNKS = 5;
const MIN_CHUNKS = 1;
const CAPTURE_INTERVAL = 1000;

export type timestampedBlob = {
  timestamp: number,
  data: Blob
}


const stopRecorder = (recording: MediaRecorder) => {
  if (recording.state === 'recording') {
    recording.stop();
  }
}

// Custom hook to manage interval recording
const codecs = [
  'video/mp4; codecs="avc1.42E01E"', // Baseline profile for H.264
  'video/mp4; codecs="avc1.64001F"', // Main profile for H.264
  'video/mp4; codecs="avc1.640028"', // High profile for H.264
  'video/mp4; codecs="hev1.1.6.L93.B0"', // HEVC (H.265)
  'video/mp4; codecs="hvc1.1.6.L93.B0"', // HEVC (H.265)
  'video/webm; codecs="vp8"',
  'video/webm; codecs="vp9"',
  'video/webm; codecs="av1"',
  'video/ogg; codecs="theora"'
];

export const useIntervalRecording = (stream: MediaStream | null, log?: string[]) => {

  type RecorderKey = number;
  const recordedChunks = useRef({} as Record<RecorderKey, timestampedBlob[]>);
  const mediaRecorders = useRef({} as Record<RecorderKey, MediaRecorder>);
  const [captureReady, setCaptureReady] = useState(false);
  const i = useRef(0);

  const chosenCodec = useRef('');

  const startNewRecording = useCallback((stream: MediaStream | null) => {
    if (!stream) {
      log?.push("Waiting for stream");
      return; 
    }

    log?.push(`Creating Recorder ${i.current}`);
    
    const mediaRecorder = (() => {
      let recorder: MediaRecorder | undefined;
      for (let codec of codecs) {
        try {
          recorder = new MediaRecorder(stream, {
            mimeType: codec, 
            videoBitsPerSecond: 2500000
          });
          chosenCodec.current = codec;
          break;
        } catch (e) {
          console.log("Codec doesnt work with device: ", e, codec, navigator?.userAgent);
        }
      }
      if (!recorder) throw new Error("Device not supported");
      return recorder;
    })();

    const recorderKey = ++i.current;
    recordedChunks.current[recorderKey] = [];
    mediaRecorder.ondataavailable = (event) => {
      let { data } = event;
      if (data.size > 0) {
        recordedChunks.current[recorderKey].push({ 
          timestamp: Date.now(),
          data,
        });

        if (recordedChunks.current[recorderKey].length >= MAX_CHUNKS) {
          // Stop the recording
          stopRecorder(mediaRecorder);
        }
        
        log?.push(`Pushed chunks: ${recordedChunks.current[recorderKey].length} for recorder ${recorderKey}`);
      } else {
        log?.push(`No data available: ${recordedChunks.current[recorderKey]?.length} for recorder ${recorderKey}`);
        console.warn("No data available", event);
        return;
      }

      if (recordedChunks.current[recorderKey]?.length >= MIN_CHUNKS) {
        log?.push("Setting capture ready = true")
        setCaptureReady((cur) => {
          if (!cur) log?.push("Setting capture ready = true")
          return true
        });
      } else {
        log?.push("Setting capture ready = false")
        setCaptureReady(false);
      }

    };

    try {
      if (stream) mediaRecorder.start(CAPTURE_INTERVAL);
    } catch (e) {
      console.warn("Error starting recorder:", e);
    }

    setTimeout(() => {
      stopRecorder(mediaRecorder);
    }, MAX_CHUNKS * CAPTURE_INTERVAL);

    mediaRecorders.current[recorderKey] = mediaRecorder;
  }, []);

  const recordingInterval = useRef(null as NodeJS.Timeout | null);

  useEffect(() => {
    startNewRecording(stream);
    recordingInterval.current = setInterval(() => startNewRecording(stream), CAPTURE_INTERVAL * MAX_CHUNKS);
    return () => {
      if (recordingInterval.current) clearInterval(recordingInterval.current);
      Object.values(mediaRecorders.current).forEach((mediaRecorder) => {
        stopRecorder(mediaRecorder);
      });
      recordedChunks.current = {};
      mediaRecorders.current = {};
    };
  }, [stream]);

  const stopRecording = useCallback(() => {
    Object.values(mediaRecorders.current).forEach((mediaRecorder) => {
      stopRecorder(mediaRecorder);
    });
    if (recordingInterval.current) clearInterval(recordingInterval.current);
  }, []);

  return { recordedChunks, codec: chosenCodec, captureReady, stopRecording };
};
import { evalConditional } from "@aidkitorg/roboscreener/lib/compute/util";
import { QuestionProps, InfoDict, ValidatorDict } from "./Questions/Props";
import { safeParse } from "./Util";

/** questionIsComplete returns true for all non-question survey items,
  like Explanation, Flag, Show Field, Conditionals with unmet conditionals, etc.
  UNLESS onlyIfHumanFillable is true, in which case those questions will return false. 

  treatOptionalAsComplete is required condition and if set to true will return true for optional questions.
*/
export async function questionIsComplete(q: QuestionProps, 
  applicantInfo: InfoDict, 
  applicantInfoValid: ValidatorDict, 
  conditions: { treatOptionalAsComplete: boolean, onlyIfHumanFillable?: boolean, hasSubQuestions?: boolean },
  screenerData: InfoDict,
  orgData: InfoDict) {
  // This section is skipped for in progress:
  const automatedQuestions = ["Show Date","Show Field","Flag","Flag Review","Identity Flag Review","Persona Document","Notification"];

  // Mark whether this question is optional or not.
  const optional = conditions['treatOptionalAsComplete'] && ((q["Additional Options"] || []).indexOf("Optional") !== -1) ? true : false;
  
  if (!conditions['onlyIfHumanFillable']){
    if (!q["Target Field"]) return true;
  
    if (automatedQuestions.includes(q["Field Type"])) return true;
  
    if (q['Metadata'] && q['Field Type'] !== 'Validated') {
      const metadata = safeParse(q['Metadata'] || '{}');
      if (metadata?.conditional) {
        const metadataConditionalResult = await evalConditional(metadata.conditional, applicantInfo, screenerData, orgData);
        if (!metadataConditionalResult) {
          return true; // Conditional not met, so cannot complete this Q.
        }
      }
    }

    // Return true if there's a condition which has not been met
    if (q["Conditional On"] !== undefined) {
      if (q["Conditional On Value"] &&
          q["Conditional On Value"].indexOf(applicantInfo[q["Conditional On"]] || '') === -1
      )
        return true;
  
      // This should pass if the conditional on is there but there's no value, and there's no value for conditional on to be triggered.
      if (!q["Conditional On Value"] && !applicantInfo[q["Conditional On"]]){
        return true;
      }
    }

    // If the question is whether the contract is signed, return that.
    if ((q["Field Type"] as string) === "Contract Signer") {
      if (optional) return true;
      return applicantInfo[q["Target Field"]] === "signed";
    }
    
    if ((q["Field Type"] as string) === "Contract Stale") {
      if (optional) return true;
      return applicantInfo[q["Target Field"]] !== "stale";
    }

    if ((q["Field Type"] as string) === 'Ranking') {
      if (optional) return true;
      const metadata = safeParse(q['Metadata'] || '{}');
      // Verify that the required number of choices in the ranking have been selected
      return (applicantInfo[q['Target Field']] && safeParse(applicantInfo[q['Target Field']]!, []).filter((val: string) => !!val).length === metadata.num_choices);
    }

    if ((q["Field Type"] === 'Card Management')) {
      // Must be a card with a target field
      return !!applicantInfo[getCardIdFromTargetField(q["Target Field"])];
    }

    if ((q["Field Type"] === "Liveness Detection")) {
      return !!(applicantInfo[q["Target Field"]] && !applicantInfo[q["Target Field"]]?.startsWith('pending'));
    }
  } else {
    if (!q["Target Field"]) return false;
    if(["Computed","Validated"].includes(q["Field Type"])) return false;
    if(automatedQuestions.includes(q["Field Type"])) return false;
  }

  if (q["Field Type"] === 'Likert') {
    const metadata = safeParse(q['Metadata'] || '{}');
    if (!Array.isArray(metadata['questions'])) {
      const answerData = q['Target Field'] && applicantInfo[q['Target Field']] ? applicantInfo[q['Target Field']] : applicantInfo[metadata.questions.targetField];
      const answerField = metadata.questions.answerField;
      const possibleAnswers = metadata.choices.map((c: any) => c.value);
      if (answerData) {
        const parsedData = safeParse(answerData);
        if (Array.isArray(parsedData)) {
          return parsedData.every(v => {
            return answerField && possibleAnswers.includes(v[answerField])
          });
        }
      }
    }
  }

  // treatOptionalAsComplete will return true for an Optional question
  if (optional) return true;

  // Check for other
  for (const option of (q["Options (if relevant)"] || [])) {
    if (option.Name !== 'other' && !option["Other Field"]) continue;
    if ((applicantInfo[q["Target Field"]] === option.Name && q["Field Type"] === 'Single Select') ||
        (q["Field Type"] === "Multiple Select" && (applicantInfo[q["Target Field"]] || '').split(',').indexOf(option.Name) !== -1)) {
      const otherField = q["Target Field"] + "_" + option.Name;
      if (!applicantInfo[otherField] || applicantInfo[otherField]?.length === 0) return false;
    }
  }

  // If the question is a container for sub-questions, check each sub-question validity
  if (conditions.hasSubQuestions) {
    const questions = safeParse(q["Metadata"] || '{}').questions;
    if (questions && Array.isArray(questions)) {
      for (const question of questions) {
        if (!applicantInfoValid[question.targetField]) {
          return false;
        }
      }
    }
    return true;
  }

  const isValid = applicantInfoValid[q['Target Field']];
  return isValid;
}

/** This function should return card_id, or primary_card_id, or TARGET FIELD + _id if
 * the target field is something like primary_card */
export function getCardIdFromTargetField(targetField?: string) {
  if (!targetField) { 
    return "card_id";
  }
  if (targetField.endsWith('_id')) {
    return targetField;
  }
  return targetField + "_id";
}
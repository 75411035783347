import { User, UserGroup, Users } from "../survey";

/**
 * This function assumes the sheet being pasted in is a table with the following columns:
 * 0. timestamp, 1. firstname, 2. lastname, 3. [doesnt matter], 4. [doesnt matter], 
 *  5. role, 6. organization, 7. email, 
 *  8. phone (if not a valid phone, it wont be entered), 9. APPROVED or not 
 */
export function parseTableToUsers(input: string, orgLookup: Record<string, string>): Users {
  const lines = input.trim().split('\n');
  const users: User[] = [];

  const Orgs = [] as Users;
  const OrgMap = {} as Record<string, UserGroup>;

  const seenEmails = {} as Record<string, boolean>;

  for (const line of lines) {
    if (!line.includes('APPROVED')) {
      continue;
    }

    const fields = line.split('\t');
    const name = fields[1] + ' ' + fields[2];
    const role = fields[5];
    const org = fields[6];
    const email = fields[7].split(' ')[0].toLowerCase().trim();
    const phone = fields[8];

    const orgId = orgLookup[org]
    const orgName = org;

    if (!orgId) {
      continue;
    }

    if (seenEmails[email]) {
      continue;
    }
    seenEmails[email] = true;

    if (!OrgMap[orgId]) {
      OrgMap[orgId] = {
        kind: 'User Group',
        name: orgName,
        tags: [orgId],
        members: [{
          kind: 'User Group',
          name: 'Screeners',
          tags: ['screener'],
          members: []
        }, {
          kind: 'User Group',
          name: 'Reviewers',
          tags: ['reviewer'],
          members: [],
          limitedToApplicants: {
            field: 'cm_name',
            kind: 'Equals',
            value: orgId
          }
        }]
      }
      Orgs.push(OrgMap[orgId])
    }

    const user: User = {
      kind: 'User',
      name,
      email,
    };

    // Only add phone if its a valid phone
    if (phone && !phone.match(/[^0-9]/)) {
      user.phone = phone;
    }

    if (role === 'Screener') {
      (OrgMap[orgId].members[0] as UserGroup).members.push(user);
    }
    if (role === 'Reviewer') {
      (OrgMap[orgId].members[1] as UserGroup).members.push(user);
    }
  }

  return Orgs;
}

import { Collection, MacroExpander, PaymentModule } from "../survey";
import { generateAchPaymentsCollection } from "./payments/ach";
import { generatePaymentComputations } from "./payments/computations";
import { generatePaymentDashboards } from "./payments/dashboards";
import { generateDebitCardCollection } from "./payments/debitCards";
import { generatePaymentFAQs } from "./payments/faqs";
import { generatePaymentsAndNotificationsCollection } from "./payments/payments_notifications";
import { generatePaymentSubsurveys } from "./payments/subsurveys";

export const createPaymentModule: MacroExpander = (config: PaymentModule) => {
  let paymentSection: Collection[] = [
    ...[generatePaymentFAQs(config)],
    ...[generatePaymentDashboards(config)],
    ...[generatePaymentComputations(config)],
    ...[generatePaymentSubsurveys(config)],
    ...(config.ach.enabled) ? [generateAchPaymentsCollection(config)] : [],
    ...(config.debit.kind !== "None") ? [generateDebitCardCollection(config)] : [],
    ...[generatePaymentsAndNotificationsCollection(config)],
  ];

  return paymentSection;
};

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAndSortInfoDefs = exports.buildTopologicalNodeMap = void 0;
console.time("Load sorter");
var derived_1 = require("./derived");
var util_1 = require("./util");
var neverthrow_1 = require("neverthrow");
var survey_loader_1 = require("./survey_loader");
var json_1 = require("../util/json");
var util_2 = require("@aidkitorg/types/lib/util");
var info_defs_1 = require("../model/info_defs");
var quickJSModule_1 = require("./quickJSModule");
// Every unique key and every unique dependent is a vertex.
function Graph(vertices) {
    var e_1, _a;
    var V = vertices.length;
    var variableIndexes = {};
    var adj = new Array(V);
    var index = 0;
    try {
        for (var vertices_1 = __values(vertices), vertices_1_1 = vertices_1.next(); !vertices_1_1.done; vertices_1_1 = vertices_1.next()) {
            var v = vertices_1_1.value;
            adj[index] = [];
            variableIndexes[v] = index;
            index++;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (vertices_1_1 && !vertices_1_1.done && (_a = vertices_1.return)) _a.call(vertices_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return { graph: { V: V, adj: adj }, variableIndexes: variableIndexes };
}
function topologicalSort(graph) {
    var V = graph['V'];
    var adj = graph['adj'];
    var indegree = new Array(V);
    indegree.fill(0);
    for (var i = 0; i < V; i++) {
        var temp = adj[i];
        for (var node = 0; node < temp.length; node++) {
            // Don't add an indegree if it relies on itself:
            if (temp[node] !== i) {
                indegree[temp[node]]++;
            }
        }
    }
    var q = [];
    for (var i = 0; i < V; i++) {
        if (indegree[i] === 0) {
            q.push(i);
        }
    }
    // Initialize count of visited vertices
    var count = 0;
    // A vector to store result
    var topOrder = [];
    while (q.length) {
        var u = q.shift();
        topOrder.push(u);
        for (var node = 0; node < adj[u].length; node++) {
            if (--indegree[adj[u][node]] === 0) {
                q.push(adj[u][node]);
            }
        }
        count++;
        if (count > V) {
            console.warn("There is a cycle", count, V);
            break;
        }
    }
    if (count > V) {
        console.warn("There is a cycle... ", count, V);
    }
    return topOrder;
}
/**
 * This function orders every formula, every dependency, every derived field, in chronological
 * order so that all dependencies will be calculated before their dependents.
 * - Assumes screenerMetadata and orgMetadata do not need to be declared as dependencies because
 * they will always be input before any computation or derived field.
 *
 * Outputs are the vertex to variable map,
 * and the "queue" of variables which are computed in first in, first out order.
 */
function buildTopologicalNodeMap(infoDefs, deploymentKey) {
    return __awaiter(this, void 0, void 0, function () {
        var pdmap, dmap, _a, _b, _c, _i, key, response, _d, vertices, queue, not_in_queue, k;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    pdmap = {};
                    dmap = {};
                    console.time("TopoSort: Mapping InfoDefs");
                    _a = infoDefs;
                    _b = [];
                    for (_c in _a)
                        _b.push(_c);
                    _i = 0;
                    _e.label = 1;
                case 1:
                    if (!(_i < _b.length)) return [3 /*break*/, 4];
                    _c = _b[_i];
                    if (!(_c in _a)) return [3 /*break*/, 3];
                    key = _c;
                    return [4 /*yield*/, mapKeyInfo(pdmap, dmap, infoDefs, key, deploymentKey)];
                case 2:
                    _e.sent();
                    _e.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    console.timeEnd("TopoSort: Mapping InfoDefs");
                    response = getGraphVerticesAndQueue(pdmap, dmap);
                    if (response.isErr()) {
                        console.error("Error in topological sort", response.error);
                        return [2 /*return*/, (0, neverthrow_1.err)(response.error)];
                    }
                    _d = response.value, vertices = _d.vertices, queue = _d.queue;
                    not_in_queue = [];
                    for (k in infoDefs) {
                        if (['Computed', 'Validated', 'Derived'].indexOf(infoDefs[k]['type']) === -1)
                            continue;
                        if (queue.indexOf(vertices.indexOf(k)) === -1) {
                            console.error("Error in topological sort", k, "not in queue", queue, "Dependents: ", pdmap[k], "Parents: ", dmap[k]);
                            not_in_queue.push(k);
                        }
                    }
                    if (not_in_queue.length) {
                        throw new Error("Topological sort failed. Not in queue: " + not_in_queue.join(', '));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
}
exports.buildTopologicalNodeMap = buildTopologicalNodeMap;
function adjustMaps(pdmap, dmap, parents, keyAsDependent) {
    var e_2, _a;
    try {
        for (var parents_1 = __values(parents), parents_1_1 = parents_1.next(); !parents_1_1.done; parents_1_1 = parents_1.next()) {
            var parent_1 = parents_1_1.value;
            if (parent_1 === keyAsDependent)
                continue;
            if (!pdmap[parent_1])
                pdmap[parent_1] = [];
            if (pdmap[parent_1].indexOf(keyAsDependent) < 0)
                pdmap[parent_1].push(keyAsDependent);
            if (dmap[keyAsDependent].indexOf(parent_1) < 0)
                dmap[keyAsDependent].push(parent_1);
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (parents_1_1 && !parents_1_1.done && (_a = parents_1.return)) _a.call(parents_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
}
function mapKeyInfo(pdmap, dmap, infoDefs, key, deploymentKey) {
    return __awaiter(this, void 0, void 0, function () {
        var q, keyAsParent, keyAsDependent, derivedParams, typesAndOptions, derivedTypes, derivedTypes_1, derivedTypes_1_1, t, derivedType, derivedModel, slugsAndDependenciesResponse, slugsAndDependencies, _a, _b, slug, derivedSlug, dependentSlug, metadata, parsedMetadata, _c, _d, dependency, dependencyAsParent, _e, _f, _g, _h;
        var e_3, _j, e_4, _k, e_5, _l;
        return __generator(this, function (_m) {
            switch (_m.label) {
                case 0:
                    q = infoDefs[key];
                    if (['Stage', 'Section'].indexOf(q['type']) !== -1)
                        return [2 /*return*/]; // Special case Stage and Section
                    keyAsParent = key;
                    keyAsDependent = key;
                    if (!pdmap[keyAsParent])
                        pdmap[keyAsParent] = [];
                    if (!dmap[keyAsDependent])
                        dmap[keyAsDependent] = [];
                    derivedParams = {
                        deploymentKey: deploymentKey,
                        infoDefs: infoDefs,
                        targetField: key,
                        derivedType: undefined
                    };
                    typesAndOptions = __spreadArray([q['type']], __read(q['options'] || []), false);
                    derivedTypes = (0, util_1.getDerivedTypesFromArray)(typesAndOptions);
                    try {
                        for (derivedTypes_1 = __values(derivedTypes), derivedTypes_1_1 = derivedTypes_1.next(); !derivedTypes_1_1.done; derivedTypes_1_1 = derivedTypes_1.next()) {
                            t = derivedTypes_1_1.value;
                            derivedType = t;
                            derivedModel = derived_1.DERIVED_MODELS[derivedType];
                            slugsAndDependenciesResponse = derivedModel.getSlugs(derivedParams);
                            if (slugsAndDependenciesResponse.isErr()) {
                                console.error("Error in derived type " + t);
                                throw slugsAndDependenciesResponse.error;
                            }
                            slugsAndDependencies = slugsAndDependenciesResponse.value;
                            try {
                                for (_a = (e_4 = void 0, __values(slugsAndDependencies['slugs'] || [])), _b = _a.next(); !_b.done; _b = _a.next()) {
                                    slug = _b.value;
                                    if (!slug.length)
                                        continue;
                                    derivedSlug = slug[0] === '_' ? key + slug : slug;
                                    dependentSlug = derivedSlug;
                                    if (pdmap[keyAsParent].indexOf(dependentSlug) < 0)
                                        pdmap[keyAsParent].push(dependentSlug);
                                    if (!dmap[dependentSlug])
                                        dmap[dependentSlug] = [];
                                    if (dmap[dependentSlug].indexOf(keyAsParent) < 0)
                                        dmap[dependentSlug].push(keyAsParent);
                                    metadata = infoDefs[key]['metadata'] || '';
                                    parsedMetadata = (0, json_1.safeParse)(metadata || '{}');
                                    infoDefs[derivedSlug] = __assign({ key: derivedSlug, type: 'Derived', editableBy: infoDefs[key].editableBy, derivedType: derivedType, metadata: metadata }, (parsedMetadata && parsedMetadata.conditional ? { conditional: parsedMetadata.conditional } : {}));
                                    // console.log("Added derived infoDef: ", infoDefs[derivedSlug]);
                                }
                            }
                            catch (e_4_1) { e_4 = { error: e_4_1 }; }
                            finally {
                                try {
                                    if (_b && !_b.done && (_k = _a.return)) _k.call(_a);
                                }
                                finally { if (e_4) throw e_4.error; }
                            }
                            try {
                                for (_c = (e_5 = void 0, __values(slugsAndDependencies['dependencies'] || [])), _d = _c.next(); !_d.done; _d = _c.next()) {
                                    dependency = _d.value;
                                    if (!dependency.length)
                                        continue;
                                    dependencyAsParent = dependency;
                                    if (!pdmap[dependencyAsParent])
                                        pdmap[dependencyAsParent] = [];
                                    if (pdmap[dependencyAsParent].indexOf(keyAsDependent) < 0)
                                        pdmap[dependencyAsParent].push(keyAsDependent);
                                    if (dmap[keyAsDependent].indexOf(dependencyAsParent) < 0)
                                        dmap[keyAsDependent].push(dependencyAsParent);
                                }
                            }
                            catch (e_5_1) { e_5 = { error: e_5_1 }; }
                            finally {
                                try {
                                    if (_d && !_d.done && (_l = _c.return)) _l.call(_c);
                                }
                                finally { if (e_5) throw e_5.error; }
                            }
                        }
                    }
                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                    finally {
                        try {
                            if (derivedTypes_1_1 && !derivedTypes_1_1.done && (_j = derivedTypes_1.return)) _j.call(derivedTypes_1);
                        }
                        finally { if (e_3) throw e_3.error; }
                    }
                    if (!(q['conditional'] !== undefined && ['Computed', 'Validated', 'Derived'].indexOf(q['type']) !== -1)) return [3 /*break*/, 2];
                    // console.debug("Adding conditional to ", key, q['type']);
                    _e = adjustMaps;
                    _f = [pdmap, dmap];
                    return [4 /*yield*/, getAllFormulaParents(q['conditional'])];
                case 1:
                    // console.debug("Adding conditional to ", key, q['type']);
                    _e.apply(void 0, _f.concat([_m.sent(), keyAsDependent]));
                    _m.label = 2;
                case 2:
                    if (['Computed', 'Validated'].indexOf(q.type) < 0)
                        return [2 /*return*/];
                    _g = adjustMaps;
                    _h = [pdmap, dmap];
                    return [4 /*yield*/, getAllFormulaParents(q['formula'])];
                case 3:
                    _g.apply(void 0, _h.concat([_m.sent(), keyAsDependent]));
                    return [2 /*return*/];
            }
        });
    });
}
function getAllFormulaParents(formula) {
    return __awaiter(this, void 0, void 0, function () {
        var info, _a, deps, res, err;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    info = {};
                    return [4 /*yield*/, quickJSModule_1.QuickJSModule.computeWithDeps(formula, { info: info })];
                case 1:
                    _a = __read.apply(void 0, [_b.sent(), 3]), deps = _a[0], res = _a[1], err = _a[2];
                    (0, util_2.getFormulaParents)(formula).forEach(function (p) { return deps.add(p); });
                    return [2 /*return*/, Array.from(deps)];
            }
        });
    });
}
function getGraphVerticesAndQueue(pdmap, dmap) {
    var e_6, _a;
    // 1. Topologically sort the formulas
    // Initialize the graph of visited nodes
    var vertices = __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(Object.keys(pdmap)), false), __read(Object.values(pdmap).flat()), false))), false);
    var _b = Graph(vertices), graph = _b.graph, variableIndexes = _b.variableIndexes;
    for (var k in pdmap) {
        var dependents = pdmap[k];
        try {
            for (var dependents_1 = (e_6 = void 0, __values(dependents)), dependents_1_1 = dependents_1.next(); !dependents_1_1.done; dependents_1_1 = dependents_1.next()) {
                var dep = dependents_1_1.value;
                graph['adj'][variableIndexes[k]].push(variableIndexes[dep]);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (dependents_1_1 && !dependents_1_1.done && (_a = dependents_1.return)) _a.call(dependents_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
    }
    // console.debug("Graph: ", graph);
    // console.debug("variableIndexes: ", variableIndexes);
    var queue = topologicalSort(graph);
    // console.debug("Queue: ", queue);
    return (0, neverthrow_1.ok)({ queue: queue, vertices: vertices, pdmap: pdmap, dmap: dmap });
}
function getAndSortInfoDefs(conn, deploymentKey, debugKeys, options) {
    return __awaiter(this, void 0, void 0, function () {
        var t0, cachedInfoDefs, e_7, pdmap, dmap, infoDefsResponse, topoResults;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(options === null || options === void 0 ? void 0 : options.useCacheIfAvailable)) return [3 /*break*/, 4];
                    t0 = Date.now();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, (0, info_defs_1.getCachedInfoDefs)(conn)];
                case 2:
                    cachedInfoDefs = _a.sent();
                    console.log(deploymentKey, "Got cached info defs in:", Date.now() - t0, "ms");
                    if (cachedInfoDefs.isOk()) {
                        return [2 /*return*/, (0, neverthrow_1.ok)(cachedInfoDefs.value)];
                    }
                    else {
                        console.warn(deploymentKey, "Error getting cached info defs", cachedInfoDefs.error);
                        // Go on to get the info defs the usual way
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_7 = _a.sent();
                    console.log("Uncaught error in getCachedInfoDefs:", e_7);
                    return [3 /*break*/, 4];
                case 4:
                    console.time("TopoSort: Mapping InfoDefs (Optimized)");
                    pdmap = {};
                    dmap = {};
                    return [4 /*yield*/, (0, survey_loader_1.loadInfoDefs)(conn, deploymentKey, {
                            debugKeys: debugKeys,
                            dependencyMapper: {
                                pdmap: pdmap,
                                dmap: dmap,
                                mapKeyInfo: mapKeyInfo
                            }
                        })];
                case 5:
                    infoDefsResponse = _a.sent();
                    console.timeEnd("TopoSort: Mapping InfoDefs (Optimized)");
                    if (infoDefsResponse.isErr())
                        return [2 /*return*/, (0, neverthrow_1.err)(infoDefsResponse.error)];
                    topoResults = getGraphVerticesAndQueue(pdmap, dmap);
                    return [2 /*return*/, (0, neverthrow_1.ok)({ infoDefs: infoDefsResponse.value[0], config: infoDefsResponse.value[1], topologicalInfo: topoResults.value })];
            }
        });
    });
}
exports.getAndSortInfoDefs = getAndSortInfoDefs;
console.timeEnd("Load sorter");

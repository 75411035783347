"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.safeDecodeURIComponent = void 0;
var safeDecodeURIComponent = function (encoded) {
    var decoded;
    try {
        decoded = decodeURIComponent(encoded);
    }
    catch (e) {
        decoded = decodeURIComponent(encoded.replace(/\%/g, ''));
    }
    return decoded;
};
exports.safeDecodeURIComponent = safeDecodeURIComponent;

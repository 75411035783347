import { useState, useCallback } from 'react';
import { usePost } from '../API';

type CompletedPart = {
  ETag?: string;
  PartNumber?: number;
};

export function useMultipartUpload() {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0); // Progress in percentage
  const [error, setError] = useState<string | null>(null);
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);

  const initiate = usePost("/document/create_upload");
  const finish = usePost("/document/finish_upload");

  const startUpload = useCallback(async (file: File, params: {
    pathParams: {
      kind: 'liveness',
      livenessId: string, 
      part: 'front' | 'back' | 'selfie',
      videoIndex: number
    } | {
      kind: 'collab',
      survey: string
    },
    contentType: "video/mp4" | "video/webm" | "text/plain",
    length: number
  }) => {
    setIsUploading(true);
    setProgress(0);
    setError(null);
    setUploadedUrl(null);

    try {
      // Step 1: Calculate total parts
      const chunkSize = 5 * 1024 * 1024; // 5 MB
      const totalParts = Math.ceil(file.size / chunkSize);

      // Step 2: Initiate the multipart upload
      const initiateResponse = await initiate({
        ...params,
        totalParts
      });

      const { uploadId, Key, presignedUrls, error } = initiateResponse;
      if (!presignedUrls || !Key || !uploadId || error) {
        throw new Error('Failed to initiate upload');
      }

      // Step 3. Presigned Urls are already generated by initiate
      // Step 4: Upload each part
      const uploadedParts: CompletedPart[] = [];

      for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
        const start = (partNumber - 1) * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const fileChunk = file.slice(start, end);

        const uploadUrl = presignedUrls[partNumber - 1];

        // Upload the part to S3 using the presigned URL
        console.log("uploading partNumber", partNumber, uploadId, uploadUrl);
        const uploadResponse = await fetch(uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          body: fileChunk,
        });

        if (!uploadResponse.ok) {
          throw new Error(`Failed to upload part ${partNumber}`);
        }
        console.log(uploadResponse.headers, uploadResponse.body, uploadResponse.status);
        const eTag = uploadResponse.headers.get('ETag');
        if (!eTag) {
          throw new Error(`No ETag received for part ${partNumber}`);
        }

        uploadedParts.push({ ETag: eTag, PartNumber: partNumber });

        // Update progress
        setProgress((partNumber / totalParts) * 100);
      }

      // Step 5: Complete the multipart upload
      const completeResponse = await finish({ uploadId, Key, parts: uploadedParts });

      if (!completeResponse.success) {
        throw new Error('Failed to complete upload');
      }

      return { location: completeResponse.location }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsUploading(false);
    }
  }, []);

  return {
    isUploading,
    progress,
    error,
    startUpload,
  };
}

import * as v0 from "@aidkitorg/types/lib/survey";
import { useContext, useEffect, useRef, useState } from "react";
import InterfaceContext from "./Context";
import { useModularMarkdown } from "./Hooks/ModularMarkdown";
import { InfoDict } from "./Questions/Props";

/**
 * Wraps MutableText in order to avoid its associated overhead when not inside Inline Editor
 */
export function MutableTextWrapper(props: { content: v0.RichText | v0.Text, trackedObjInfo?: v0.TrackedObjectInfo, info?: InfoDict, collapsible?: boolean }) {

  // Saved to variable to avoid unequal hook rendering
  const normalMarkdownElement = useModularMarkdown({
    content: props.content,
    info: props.info || {},
    replacer: (str) => str.replace(' **\n', '**\n').replace('^** ', '**'),
    collapsible: props.collapsible
  });

  return (
    !props.trackedObjInfo
      ? normalMarkdownElement
      : <MutableText
        content={props.content}
        trackedObjInfo={props.trackedObjInfo}
        info={props.info}
        collapsible={props.collapsible}
      />
  );
}

function MutableText(props: { content: v0.RichText | v0.Text, trackedObjInfo: v0.TrackedObjectInfo, info?: InfoDict, collapsible?: boolean }) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const context = useContext(InterfaceContext);
  const [currentText, setCurrentText] = useState('');
  const editTimer = useRef<number | undefined>(undefined);

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setCurrentText(e.target.value);
    e.preventDefault();

    if (editTimer.current) {
      clearTimeout(editTimer.current);
    }
    editTimer.current = setTimeout(() => {
      const editedTrackedObjectInfo = props.trackedObjInfo[context.lang];
      if (!editedTrackedObjectInfo) {
        console.log('No key for lang: ', context.lang, 'trackedObjInfo: ', props.trackedObjInfo);
        return;
      }

      context.inlineEdit.setInlineEdits((inlineEdits) => {
        // TODO: check if there are actually any changes before executing the rest of this.
        return {
          edits: {
            // All inline edits
            ...inlineEdits.edits,
            // Plus this particular content's edits
            [editedTrackedObjectInfo['id']]: {
              ...(inlineEdits.edits || {})[editedTrackedObjectInfo['id']], // TODO: Is this line necessary?
              [context.lang]: { value: e.target.value, prev_gen: editedTrackedObjectInfo?.['prev_gen'] }
            }
          },
          latestEditedObjectId: editedTrackedObjectInfo['id'],
        };;
      });
    });
  };

  useEffect(() => {
    if (currentText !== props.content[context.lang]) {
      setCurrentText(props.content[context.lang] ?? '');
      console.log('Updating text to ', props.content[context.lang]);
    }
  }, [context.lang])

  // Saved to variable to avoid unequal hook rendering
  const renderableMarkdown = useModularMarkdown({
    content: currentText,
    info: props.info || {},
    replacer: (str) => str.replace(' **\n', '**\n').replace('^** ', '**'),
    collapsible: props.collapsible
  });

  return <>
    {(context.inlineEdit.inlineEditing && !context.inlineEdit.showPreview) ? (
      <div className={'grow-wrap'} data-replicated-value={currentText}>
        <textarea
          ref={textareaRef}
          className="rounded-md my-1"
          value={currentText}
          onChange={handleInputChange}
        />
      </div>
    ) : (
    // TODO: There is some serious magic that happens with collapsible elements... and currently they render properly,
    // but the text in them doesn't show up as content when editing?
    // Also, any HTML/JSX element placed around this one will break expand/collapse
      renderableMarkdown
    )}
  </>;
}
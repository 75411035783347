import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";

export const FullScreenModal = ({ open, onClose, children }: {
  open: boolean,
  onClose: () => void,
  children: ReactNode,
}) => {
  if (!open) return null;

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div
        className="bg-white mx-auto my-8 p-4 rounded-lg relative"
        onClick={(e) => e.stopPropagation()}
        style={{
          width: 'calc(100% - 2rem)', // Adjust width
          height: 'calc(100% - 2rem)', // Adjust height
          overflow: 'visible', // Change overflow to 'visible'
        }}
      >
        <XMarkIcon 
          className="w-6 h-6 absolute top-0 right-0 m-2 text-gray-400 hover:text-gray-500 cursor-pointer"
          onClick={onClose}/>
        {children}
      </div>
    </div>,
    document.body
  );
};
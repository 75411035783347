export function WhiteButton(props: {children: React.ReactNode, onClick?: (...args: any) => any}) {
  return <button 
    type="button" 
    onClick={props.onClick || (() => {})} 
    className="inline-flex bg-transparent rounded-md border-0 items-center px-4 py-2 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
    {props.children} 
  </button>
}

export function Button(props: {children: React.ReactNode, disabled?: boolean, className?: string, style?: any, onClick?: (...args: any) => any}) {
  return <button
    type="button"
    style={props.style || {}}
    disabled={props.disabled}
    onClick={props.onClick || (() => {})}
    className={(props.className || '') + " inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"} >
    {props.children}
  </button>
}
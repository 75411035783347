import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, useContext, useState } from 'react';
import InterfaceContext from '../Context';
import { useLocalizedStrings } from '../Localization';
import { ClickableButton } from './Button';
import type { PotentialCandidate } from 'aidkit/lib/application/multiplex';
import { RichText } from '@aidkitorg/types/lib/survey';
import { BUTTON_CLASS, SpacedSpinner, classNames, useMarkdown } from '../Util';

type CandidateOption = {
  // Optional because one of the candidates might be "Start a new app"
  applicant?: { kind: "ingested", uid: string } | { kind: "pending", dynamo_uid: string },
  subsurveys?: { name: Record<string, string | undefined>, link: string, style: "button" | "link", openMode?: "_self" | "_blank" }[],
  summary?: RichText,
  buttonLabel?: string,
  onClick: () => Promise<void>
}
function Candidate(props: {
  candidate: CandidateOption
}) {

  const { candidate } = props;

  const uid = candidate.applicant 
    ? candidate.applicant.kind === 'ingested' 
      ? candidate.applicant.uid : candidate.applicant.dynamo_uid 
    : 'newapp';

  const context = useContext(InterfaceContext);
  const md = useMarkdown(candidate.summary ? (candidate.summary[context.lang] || candidate.summary['en']) : '');
  const L = useLocalizedStrings();

  const [awaiting, setAwaiting] = useState(false);

  return (
    <div key={"chooserdiv-" + uid} className="flex flex-col rounded-md bg-gray-50 p-3">
      {candidate.summary && <label htmlFor={'chooser-'+uid} className={classNames(
        `text-left block text-md font-medium leading-6 text-gray-900`,
      )}>{md}</label>}

      {candidate.subsurveys?.length ? <>
        <div></div>
        <ul>
          {(candidate.subsurveys || []).map((s) => <li>
            {s.style === 'button' ? 
              <button type="button" className={BUTTON_CLASS} onClick={(e) => {
                e.preventDefault();
                window.open(s.link, s.openMode || "_self");
              }}> 
                {s.name[context.lang] || s.name['en']}
              </button> :
              <a href={s.link} target={s.openMode || "_blank"} rel="noreferrer">{s.name[context.lang] || s.name['en']}</a>}
          </li>)}
        </ul>
      </> : 
        <button
          type="button"
          disabled={awaiting}
          className="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={async () => {
            setAwaiting(true);
            await candidate.onClick();
            setAwaiting(false);
          }}
        >
          <span>{awaiting ? <SpacedSpinner /> : null}</span>
          <span>{candidate.buttonLabel || L.apply.resume}</span>
        </button>}
    </div>)
}

export function CandidateChooser(props: {
  label?: string | JSX.Element,
  candidates: CandidateOption[],
  onClose: () => void
}) {
  const context = useContext(InterfaceContext);
  const [open, setOpen] = useState(true);

  const L = useLocalizedStrings();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {
        setOpen(false);
        props.onClose();
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-14 w-14 items-center justify-center rounded-full">
                    <InformationCircleIcon className="h-10 w-10 text-gray-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 text-lg">
                      {props.label}
                    </Dialog.Title>
                    
                    <fieldset className="mt-3">
                      <legend className="sr-only">Application</legend>
                      <div className="space-y-4">
                        {(props.candidates || []).map((c, i) => (
                          <Candidate candidate={c} />
                        ))}
                      </div>
                    </fieldset>
                    
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
import React, { useContext } from "react";
import InterfaceContext, { ConfigurationContext, SupportedLanguage } from "../Context";
import { langToWord, useLocalizedStrings } from "../Localization";
import { languageContent } from "../Util";
import { QuestionProps } from "./Props";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

export function InlineLanguageSelector(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const languages = (config.languages || 'en,es').split(',');
  const content = props[languageContent(context.lang)] || '';

  const L = useLocalizedStrings();

  const marked = useModularMarkdown({
    content: content, 
    info: {...props.info, uid: props.uid || 'No UID'},
  });

  return (
    <div>
      <fieldset>
        <legend>{marked}</legend>
        <div className="flex flex-col">
          {languages.map((l: SupportedLanguage) => (
            <label key={l} className="mr-4">
              <input
                type="radio"
                name="language"
                value={l}
                checked={context.lang === l}
                onChange={() => {
                  console.log("Setting context language to: ", l);
                  context.setLanguage(l);
                }}
              />
              <span className="ml-1.5 mr-0.5">
                {langToWord(l, 'Name')}
              </span>
            </label>
          ))}
        </div>
      </fieldset>
    </div>
  );
}


// list existing phone numbers/orders if they exist
import { useEffect, useState, useContext, createContext, useRef } from "react";
import { usePost } from "./API";
import type { SipRule, PhoneNumber, PhoneNumberOrder } from "@aws-sdk/client-chime-sdk-voice"; 
import { SpacedSpinner } from "./Util";
import { ExclamationCircleIcon, CheckCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { LoggedInConfig } from "@aidkitorg/types/lib/config";
import { TailwindSwitch } from "./Components/Switch";
import { LoggedInConfigurationContext } from "./Context";
import { useLocalizedStrings } from "./Localization";

const STATUSES_SUCCESS = ["Successful"];
const STATUSES_PENDING = ["Processing", "CancelRequested", "ChangeRequested", "PendingDocuments", "Submitted"];
const STATUSES_FAIL = ["Cancelled", "Failed", "Exception", "FOC", "Partial"];
type PhoneNumberWithOrder = PhoneNumber & { order?: PhoneNumberOrder };
function PhoneNumberSearch(){
  const [loading, setLoading] = useState<boolean | null>(null);
  const [availableNumbers, setAvailableNumbers] = useState<string[]>([]);
  const [areaCode, setAreaCode] = useState<string | null>(null);
  const [country, setCountry] = useState<string>('US');
  const { selectedNumberToOrder, setSelectedNumberToOrder, isVideoCallingEnabled } = useContext(ProvisionVideoCallsContext);
  const [nextToken, setNextToken] = useState<string | undefined>();
  const searchPhoneNumbers = usePost('/admin/video_calling/search_numbers');
  const L = useLocalizedStrings();

  async function fetchAvailableNumbers(){
    setLoading(true);
    const response = await searchPhoneNumbers({
      areaCode: areaCode!,
      country,
      nextToken
    });
        
    if(!(response as any).error && response.E164PhoneNumbers){
      setAvailableNumbers(response.E164PhoneNumbers);
      setNextToken(response.NextToken)
    }
    else {
      setAvailableNumbers([]);
      setNextToken(undefined);
    }
    setLoading(false);
  }

  if(selectedNumberToOrder){
    return <PhoneNumberOrderForm phoneNumber={selectedNumberToOrder} />
  }

  return <div className="flex flex-col gap-4">
    <div className="mt-1 flex flex-column">
      <h4 className="text-lg">{L.program_admin.video_calls.choose_phone_number}</h4>
      <label className="text-gray-600">{L.program_admin.video_calls.enter_country_area_code}</label>
      <form className="flex gap-4 flex-wrap" onSubmit={(e) => {
        e.preventDefault(); 
        fetchAvailableNumbers();
      }}>
        <div className="flex rounded border items-stretch">
          {/* TODO, add more countries */}
          <select className="rounded-left min-w-12 bg-gray-100 px-2 md:px-4 cursor-pointer appearance-none" value={country} onChange={e => setCountry(e.target.value)}>
            <option value="US">US</option>
          </select>
          <input autoComplete="false" disabled={!!loading} type="number" className="block min-w-32 h-10 p-2 border-left rounded-right w-full" placeholder="eg. 512" autoFocus onChange={(e) => setAreaCode(e.target.value)} />
        </div>
        <button disabled={loading || !country || !areaCode} className="inline-flex items-center p-2 px-4 text-white font-medium bg-blue-600 hover:bg-blue-700 rounded-md text-gray-700 disabled:bg-gray-400 disabled:opacity-75" type="submit">{L.program_admin.video_calls.search}</button>
      </form>
    </div>

    <div className="flex gap-2 md:gap-4 flex-wrap">
      {(loading === false && availableNumbers.length === 0) && <div>{L.program_admin.video_calls.no_phone_number_results}</div>}
      {
        loading ? <SpacedSpinner /> : <> 
          {
            availableNumbers.map((phoneNumber) => <button className="block items-center p-2 text-white text-center font-medium bg-green-600 hover:no-underline hover:bg-green-700 rounded-md font-bold font-mono" onClick={() => setSelectedNumberToOrder(phoneNumber)}>
              {phoneNumber}
            </button>)
          }
          { nextToken && <button className="block items-center p-2 text-white text-center font-medium bg-indigo-600 hover:no-underline hover:bg-indigo-700 rounded-md font-bold" onClick={() => fetchAvailableNumbers()}>{L.program_admin.video_calls.load_more}</button>}
        </>
      }
    </div>
  </div>
}

function PhoneNumberOrderForm(props: { 
  phoneNumber: string; 
}){
  const [loading, setLoading] = useState<boolean>(false);
  const orderPhoneNumber = usePost('/admin/video_calling/order_phone_number');
  const { setSelectedNumberToOrder, fetchPhoneNumberData } = useContext(ProvisionVideoCallsContext);
  const L = useLocalizedStrings();

  async function doOrderPhoneNumber(){
    setLoading(true);
    const response = await orderPhoneNumber({
      phoneNumber: props.phoneNumber
    });
    if(!(response as any).error){
      // on success, fetch newly created phone number order
      fetchPhoneNumberData();
    }
    setLoading(false);
  }

  return <div className="flex flex-col gap-2">
    <h3>{L.program_admin.video_calls.purchase} <code>{props.phoneNumber}</code>?</h3>
    <div className="flex gap-2">
      <button className="inline-flex justify-center items-center p-2 px-4 text-white font-medium bg-red-600 hover:bg-red-700 rounded-md text-gray-700" onClick={() => setSelectedNumberToOrder('')}>{L.program_admin.video_calls.cancel}</button>
      <button disabled={loading} onClick={() => doOrderPhoneNumber()} className="inline-flex justify-center items-center p-2 px-4 text-white font-medium bg-green-600 hover:bg-green-700 rounded-md text-gray-700 disabled:bg-gray-400 disabled:opacity-75">
        {L.program_admin.video_calls.purchase} {props.phoneNumber}
      </button>
    </div>
    { loading && <SpacedSpinner /> }
  </div>
}

function SipRuleForPhoneNumber(props: {phoneNumber: PhoneNumberWithOrder}){
  const { loadingSipRules, sipRules, fetchSipRules } = useContext(ProvisionVideoCallsContext);
  const [loading, setLoading] = useState<boolean>(false);
  const createSipRule = usePost('/admin/video_calling/create_sip_rule');
  const L = useLocalizedStrings();

  async function doCreateSipRule(){
    setLoading(true);
    const response = await createSipRule({
      phoneNumber: props.phoneNumber.E164PhoneNumber!
    });
    if(!(response as any).error){
      fetchSipRules();
    }
    setLoading(false);
  }

  if(props.phoneNumber.order?.Status !== 'Successful'){
    return null;
  }

  if(loadingSipRules){
    return <SpacedSpinner />
  }

  const matchingSipRule = sipRules.find(sipRule => {
    return sipRule.TriggerValue === props.phoneNumber.E164PhoneNumber;
  })

  return <div className="flex self-center gap-2">
    {
      matchingSipRule 
        ? <div className="flex items-center bg-indigo-600 font-bold text-sm text-white py-1 px-4 rounded-full"><CheckCircleIcon className="h-5 mr-1" /> {L.program_admin.video_calls.enabled}</div>
        : <><div className="flex items-center text-red-600 font-bold text-sm mr-2"><ExclamationCircleIcon className="h-5 mr-1" /> {L.program_admin.video_calls.not_enabled}</div> <button disabled={loading} onClick={() => doCreateSipRule()} className="bg-indigo-600 hover:bg-indigo-700 font-bold text-sm text-white py-2 px-4 rounded">{L.program_admin.video_calls.enable_now}</button></>
    }
  </div>
}

function PhoneNumberSection(){
  const { fetchPhoneNumberData, fetchSipRules, loadingNumbers, phoneNumbers, sipRules } = useContext(ProvisionVideoCallsContext);
  const L = useLocalizedStrings();

  useEffect(() => {
    fetchPhoneNumberData();
    fetchSipRules();
  }, []);

  return <section className="video-calls-phone-numbers flex flex-column">
    <h3>{L.device.phone_number}</h3>

    {
      sipRules.length < 1 && <div className='bg-gray-100 rounded p-4 my-4'>
        <p>{L.program_admin.video_calls.phone_instructions_intro}:</p>
        <ol className="list-decimal list-inside">
          <li className={phoneNumbers.length ? 'line-through' : ''}>{L.program_admin.video_calls.phone_instructions_01}</li>
          <li className={phoneNumbers.length ? 'line-through' : ''}>{L.program_admin.video_calls.phone_instructions_02}</li>
          <li>{L.program_admin.video_calls.phone_instructions_03.replaceAll(/\$enable_now/ig, L.program_admin.video_calls.enable_now)}</li>
        </ol>
      </div>
    }

    { loadingNumbers && <SpacedSpinner /> }
    <div className="flex flex-col divide-y">
      {
        (Array.isArray(phoneNumbers) && phoneNumbers.length > 0) && phoneNumbers.map(
          phoneNumber => <PhoneNumberItem phoneNumber={phoneNumber} />
        )
      }

    </div>
    {
      (!loadingNumbers && !phoneNumbers.length) && <PhoneNumberSearch />
    }
  </section>
}

function PhoneNumberItem(props: { phoneNumber: PhoneNumberWithOrder }){
  const { fetchPhoneNumberData } = useContext(ProvisionVideoCallsContext);
  const [callerIdName, setCallerIdName] = useState<string | undefined>(props.phoneNumber.CallingName);
  const [loading, setLoading] = useState<boolean | null>(null);
  const deletePhoneNumber = usePost('/admin/video_calling/delete_phone_number');
  const updatePhoneNumber = usePost('/admin/video_calling/update_phone_number');
  const L = useLocalizedStrings();
  const pollingRef = useRef<NodeJS.Timer | null>();

  let badgeColors = ['bg-gray-300'];
  let statusMessage = 'Unknown';
  let isPending = false;
  let isSuccessful = false;
  const { phoneNumber } = props;

  if(props.phoneNumber.order?.Status){
    statusMessage = props.phoneNumber.order.Status;
    if(props.phoneNumber.order.Status && STATUSES_SUCCESS.includes(props.phoneNumber.order.Status)){
      isSuccessful = true;
      badgeColors = ['bg-green-500', 'text-white'];
    }
    else if(props.phoneNumber.order.Status && STATUSES_PENDING.includes(props.phoneNumber.order.Status)){
      badgeColors = ['bg-yellow-400'];
      isPending = true;
    }
    else if(props.phoneNumber.order.Status && STATUSES_FAIL.includes(props.phoneNumber.order.Status)){
      badgeColors = ['bg-red-500', 'text-white'];
    }    
  }

  function clearPollingInterval(){
    if(pollingRef.current){
      clearInterval(pollingRef.current);
    }
    pollingRef.current = null;
  }

  useEffect(
    () => {
      if (isPending) {
        if(!pollingRef.current){
          pollingRef.current = setInterval(
            () => {
              fetchPhoneNumberData();
            },
            5000
          ) as NodeJS.Timer;    
        }
      }
      else {
        clearPollingInterval();
      }
      return () => {
        clearPollingInterval();
      }
    },
    [isPending, pollingRef.current]
  );

  async function doDeletePhoneNumber(phoneNumber:string){
    setLoading(true);
    const response = await deletePhoneNumber({
      phoneNumber
    });
    if(!(response as any).error){
      await fetchPhoneNumberData();
    }
    setLoading(false);
  }

  async function doUpdatePhoneNumber(){
    setLoading(true);
    const response = await updatePhoneNumber({
      PhoneNumberId: props.phoneNumber.PhoneNumberId,
      CallingName: callerIdName
    });
    if(!(response as any).error){
      await fetchPhoneNumberData();
    }
    setLoading(false);
  }

  if(loading){
    return <SpacedSpinner />
  }

  return <div>
    <div className="flex flex-col md:flex-row gap-2 py-2">
      <div className="items-center flex gap-2">
        <code className="font-bold">{phoneNumber.E164PhoneNumber}</code> 
        <button className="rounded-full p-1 text-sm font-bold text-white bg-red-600 hover:bg-red-700" onClick={() => {
          if(window.confirm(`${L.program_admin.video_calls.delete_confirmation}:\n\n${phoneNumber.E164PhoneNumber}`)){
            doDeletePhoneNumber(phoneNumber.E164PhoneNumber!);
          }
        }}><TrashIcon className="h-5" /></button>
        <span className={`rounded-full py-1 px-4 text-sm font-bold ${badgeColors.join(' ')}`}>{statusMessage}</span>                                
      </div>
      { isPending && <div>{L.program_admin.video_calls.order_pending} <SpacedSpinner /></div> }
      <SipRuleForPhoneNumber phoneNumber={phoneNumber} />
    </div>        
    { 
      isSuccessful && <> {
        phoneNumber.CallingNameStatus === 'UpdateInProgress' 
          ?  <div className="text-xs">{L.program_admin.video_calls.caller_id_update_status}: <code>{phoneNumber.CallingNameStatus || 'unknown'}</code></div>
          : <div>
            <label>{L.program_admin.video_calls.caller_id}</label>
            <form className="flex items-stretch justify-stretch" onSubmit={e => { e.preventDefault(); doUpdatePhoneNumber(); }}>
              <input minLength={2} maxLength={15} className="rounded-l p-2 rounded-r-none border border-r-none" onChange={e => setCallerIdName(e.target.value)} value={callerIdName} type="text" />
              <button disabled={callerIdName === props.phoneNumber.CallingName} className="p-2 text-white shrink-0 text-xs bg-green-600 hover:bg-green-700 rounded-r disabled:bg-gray-400 disabled:opacity-75">{L.program_admin.video_calls.update}</button>
            </form>            
          </div>
      } </>            
    }        
  </div>
}

function VideoCallingEnabledSection(props:any){
  const [loading, setIsLoading] = useState<boolean>(false);
  const { setIsVideoCallingEnabled, isVideoCallingEnabled } = useContext(ProvisionVideoCallsContext);
  const updateEnableVideoMeetings = usePost('/admin/video_calling/update_enabled');
    
  function onChange(newValue:boolean){
    setIsVideoCallingEnabled(newValue);
    (async function makeUpdateDistroComms(){
      setIsLoading(true);
      await updateEnableVideoMeetings({
        enabled: newValue
      });
      setIsLoading(false);
    })();
  }
    
  return <div className="flex gap-2 items-center">
    <TailwindSwitch label="Enable video calling" 
      checked={isVideoCallingEnabled}
      onChange={() => onChange(!isVideoCallingEnabled)}
    />
    { loading && <SpacedSpinner />}
  </div>
}

function LoggedInConfigPage(props: React.PropsWithChildren){
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loggedInConfig, setLoggedInConfig] = useState<LoggedInConfig | undefined>();
  const getLoggedInConfig = usePost('/program/logged_in_configuration');

  async function fetchLoggedInConfig(){
    setIsLoading(true);
    const response = await getLoggedInConfig({});
    if(!(response as any).error){
      setLoggedInConfig(response);    
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!loggedInConfig) {
      fetchLoggedInConfig();
    }
  }, [loggedInConfig]);

  if(isLoading){
    return <SpacedSpinner />
  }
  return loggedInConfig 
    ? <LoggedInConfigurationContext.Provider value={loggedInConfig}>
      {props.children}
    </LoggedInConfigurationContext.Provider> 
    : null;
}

type ProvisionVideoCallsState = {
  phoneNumbers: PhoneNumberWithOrder[],
  setPhoneNumbers: (phoneNumbers: ProvisionVideoCallsState['phoneNumbers']) => void,
  selectedNumberToOrder: string | null,
  setSelectedNumberToOrder: (numberToOrder: ProvisionVideoCallsState['selectedNumberToOrder']) => void,
  loadingNumbers: boolean | null,
  setLoadingNumbers: (loadingNumbers: ProvisionVideoCallsState['loadingNumbers']) => void,
  loadingSipRules: boolean | null,
  setLoadingSipRules: (loadingSipRules: ProvisionVideoCallsState['loadingSipRules']) => void,
  sipRules: SipRule[],
  setSipRules: (sipRules: ProvisionVideoCallsState['sipRules']) => void,
  isVideoCallingEnabled: boolean,
  setIsVideoCallingEnabled: (isVideoCallingEnabled: ProvisionVideoCallsState['isVideoCallingEnabled']) => void,
  fetchPhoneNumberData: () => Promise<void>,
  fetchSipRules: () => Promise<void>
}
const ProvisionVideoCallsContext = createContext({} as ProvisionVideoCallsState);

function ProvisionVideoCallsPageContent() {    
  const loggedInConfig = useContext(LoggedInConfigurationContext);
  const [phoneNumbers, setPhoneNumbers] = useState<ProvisionVideoCallsState['phoneNumbers']>([]);
  const [selectedNumberToOrder, setSelectedNumberToOrder] = useState<ProvisionVideoCallsState['selectedNumberToOrder']>(null);
  const [loadingNumbers, setLoadingNumbers] = useState<boolean | null>(false);
  const [loadingSipRules, setLoadingSipRules] = useState<boolean | null>(false);
  const [sipRules, setSipRules] = useState<SipRule[]>([]);
  const [isVideoCallingEnabled, setIsVideoCallingEnabled] = useState<boolean>(!!loggedInConfig?.comms?.videoCalling?.enabled);
  const listPhoneNumbers = usePost('/admin/video_calling/list_phone_numbers');
  const listSipRules = usePost('/admin/video_calling/list_sip_rules');

  async function fetchSipRules(){
    setLoadingSipRules(true);
    const response = await listSipRules({});
    if(!(response as any).error){
      setSipRules(response);
    }
    setLoadingSipRules(false);
  }

  async function fetchPhoneNumberData(){
    setLoadingNumbers(true);
    const response = await listPhoneNumbers({});
    if(!(response as any).error){
      setPhoneNumbers(response);
    }
    setLoadingNumbers(false);
  }

  const contextValue = {
    phoneNumbers,
    setPhoneNumbers,
    selectedNumberToOrder,
    setSelectedNumberToOrder,
    loadingNumbers,
    setLoadingNumbers,
    loadingSipRules,
    setLoadingSipRules,
    sipRules,
    setSipRules,
    isVideoCallingEnabled,
    setIsVideoCallingEnabled,
    fetchSipRules,
    fetchPhoneNumberData
  }

  return <ProvisionVideoCallsContext.Provider value={contextValue}>
    <div className="p-4 align-center max-w-7xl flex flex-col gap-4">
      <VideoCallingEnabledSection />
      { isVideoCallingEnabled && <PhoneNumberSection /> }
    </div>        
  </ProvisionVideoCallsContext.Provider>;
}

export function ProvisionVideoCallsPage() {
  return <LoggedInConfigPage>
    <ProvisionVideoCallsPageContent />
  </LoggedInConfigPage>
}
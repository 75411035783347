import { ChatBubbleLeftRightIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { createNote } from "aidkit/lib/model/Note";
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useToast } from "@aidkitorg/component-library";
import { get_deployment, useAPIPost, useGet, usePost, useToken } from "./API";
import { CommsWindow } from "./Applicant/Comms";
import { AdminOnlyBadge, Badge } from "./Components/Badge";
import { ClickableButton } from "./Components/Button";
import { SupportedColor } from "./Components/Color";
import { Dropdown } from "./Components/Dropdown";
import { useSurveyDescription } from "./Components/Roboscreener";
import InterfaceContext, { ConfigurationContext, PublicConfigurationContext, SupportedLanguage } from "./Context";
import { useLocalizedStrings } from "./Localization";
import { CaseStatus } from "./SupportUtil";
import { safeParse, snakeToEnglish, SpacedSpinner, useInterval } from "./Util";
import { ApplicantInfo } from "aidkit/lib/model/ApplicantInfo";
import { configureProgramShortLink } from "aidkit/lib/common/link";
import { interfaceNumber } from "@aidkitorg/types/lib/survey";
import { ConfirmationStep } from "./VerificationPage";
import { PUBSUB } from "./Realtime";
import { InfoDict } from "./Questions/Props";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function langForDate(lang: SupportedLanguage) {
  return lang.replaceAll('_', '-');
}  

export const getAssigneeDisplayName = (assignee: { uid: string, name: string, email: string }) => {
  if (!assignee.email || !(assignee.email.includes('@aidkit.org') || assignee.email.includes('@aidkit.cloud'))) {
    return assignee.name;
  }

  return `${assignee.name} (AidKit)`;
};

export function SupportCaseHeader(props: { 
  supportCase: any,
  messages: { data: { needs_attention?: boolean | null, id: any }[] }
}) {
  let { supportCase } = props;

  const addToBlocklist = usePost("/admin/add_to_blocklist");
  const config = useContext(ConfigurationContext);
  const resolveCase = usePost("/support/resolve_case");
  const resolveAll = usePost("/messages/resolve_all");
  const L = useLocalizedStrings();

  return <span>Case {supportCase.uid.split('-')[0]}, {supportCase.applicant?.info?.legal_name || supportCase.contact}
    {(config.roles || '').includes('admin') ? <Dropdown label="..."
      className="h-0 text-blue-500 hover:text-blue-700 bg-transparent border-0" 
      options={[
        { label: "Report Spam", callback: async () => {
          let reason = window.prompt(L.support.reason_for_reporting_spam);
          if (reason === null) return;
          await addToBlocklist({
            contact: supportCase.contact,
            reason: reason || ''
          });
          if (props.messages.data && props.messages.data.some((m: any) => m.needs_attention)) {
            await resolveAll({
              ...(supportCase.applicant ? { applicant:  supportCase.applicant } : { contact: supportCase.contact }), 
              message_ids: props.messages.data.filter((m: any) => m.needs_attention).map((m: any) => m.id as number)
            });
          }
          await resolveCase({ caseId: supportCase.uid });
        }}
      ]}/> : null}</span>
}

export function SupportCaseApplicantInfo(props: {
  info: InfoDict
}) {
  const infoDefs = useSurveyDescription({ getContent: true });
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();

  return <div className="mt-3 border-t border-gray-200 rounded-md bg-indigo-50 p-4">
    <h4 className="text-xl2 font-bold">{L.support.applicant_info}</h4>
    <hr/>
    {infoDefs && Object.keys(props.info || {}).sort((a, b) => {
      let aIndex = infoDefs[a]?.index || 0;
      let bIndex = infoDefs[b]?.index || 0;
      return aIndex - bIndex;
    }).map((infoKey) => {
      return <div className="mb-4" key={infoKey}>
        <h5>{infoDefs[infoKey]?.content ? infoDefs[infoKey].content[context.lang] : <></>}</h5>
        <h6><code>{infoKey}</code></h6>
        <span className="whitespace-pre-wrap break-all">{props.info?.[infoKey]}</span>
      </div>})}
  </div>
}

export function SupportCaseBadge(props: {
  status: "open" | "closed" | "resolved" | "escalated" | "viewed",
}) { 
  const L = useLocalizedStrings();
  let color =  {
    'open': ['bg-yellow-100 text-yellow-800', 'fill-yellow-500'],
    'resolved': ['bg-green-100 text-green-800', 'fill-green-500'],
    'closed': ['bg-red-100 text-red-800', 'fill-red-500'],
    'escalated': ['bg-gray-100 text-gray-800', 'fill-gray-500'],
    'viewed': ['bg-blue-100 text-blue-800', 'fill-blue-500'],
  }[props.status] || ['bg-gray-100 text-gray-800', 'fill-gray-500'];
  return <span className={classNames(`inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium`, color[0])}>
    <svg className={classNames("h-1.5 w-1.5", color[1])} viewBox="0 0 6 6" aria-hidden="true">
      <circle cx={3} cy={3} r={3} />
    </svg>{L.stats.current_status}: {snakeToEnglish(props.status)}</span>
}

export function SupportCasePage(props: any) {
  const { caseId, channel } = useParams<{ caseId: string, channel?: string}>();

  const L = useLocalizedStrings();
  const infoDefs = useSurveyDescription();
    
  const getCaseInfo = usePost("/support/get_case");
  const getRelatedApplicants = usePost("/support/related_applicants");

  const loading = useRef(false);
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const publicConfig = useContext(PublicConfigurationContext);
  const aOrApplicant = interfaceNumber(publicConfig.interface?.version) > 0 ? 'a' : 'applicant';
    
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const resolveCase = usePost("/support/resolve_case");
  const escalateCase = usePost("/support/escalate_case");

  const reassignCase = usePost("/support/reassign_case");
  const getAssignees = usePost("/support/assignees");
  const [assignees, setAssignees] = useState([] as { uid: string, name: string, email: string}[]);

    type PossibleSupportCase = Awaited<ReturnType<typeof getCaseInfo>>['supportCase'];
    const [supportCase, setCase] = useState<null | PossibleSupportCase>(null);
    const [contact, setContact] = useState("");
    const [contactNameKey, setContactNameKey] = useState("legal_name");
    const [selectedApplicant, setSelectedApplicant] = useState('');
    
    const [tab, setTab] = useState("comms");

    const getMessages = usePost("/support/get_messages");
    const getApplicantMessages = usePost("/v2/applicant/get_messages");

    const [messages, setMessages] = useState({} as Awaited<ReturnType<typeof getMessages>>);
    const [refreshed, setRefreshed] = useState(0);
    const [fetchingInitialMessages, setFetchingInitialMessages] = useState(false);

    // For the confirmation step
    const [sentConfirmationTo, setSentConfirmationTo] = useState('');
    const [verifiedContacts, setVerifiedContacts] = useState<string[] | null>(null);

    // Related applicants and their info
    const [relatedApplicants, setRelatedApplicants] = useState<Record<string, InfoDict>>({});
    const loadingRelatedApps = useRef(false);

    const [selectedApplicantInfo, setSelectedApplicantInfo] = useState<undefined | InfoDict>(undefined);

    const getStageFromUid = (uid?: string) => {
      if (!uid) return L.application_stage.pre_application;
      // Length <= 22 means it's an ingested UID
      if (uid.length <= 22) return L.application_stage.applied;
      return L.application_stage.in_progress;
    }

    const getLabelFromUidAndInfo = (uid?: string, info?: ApplicantInfo) => {
      if (!uid || !info) return L.support.select_an_applicant;

      let relatedApplicationDetails;
      if (!channel || channel === 'default') {
        relatedApplicationDetails = publicConfig.comms?.default.supportDashboard.relatedApplicationDetails;
      } else {
        relatedApplicationDetails = publicConfig.comms?.otherChannels.find(channel => channel.channelName)?.supportDashboard.relatedApplicationDetails;
      }
      relatedApplicationDetails ??= ['phone_number', 'email'];

      return (
        <div>
          {/* Length > 22 means it's a non-ingested UID */}
          <div><b>{info.legal_name || uid}{uid.length > 22 ? ` (${L.application_stage.in_progress})` : ''}</b></div>
          {relatedApplicationDetails.map(field => <div key={field}>{info[field]}</div>)}
        </div>
      );
    }

    useEffect(() => {
      (async () => {
        const result = await getAssignees({
          channel: channel || "default"
        });
    
        if (result && result.assignees) {
          setAssignees(result.assignees);
        }
      })();
    }, []);

    const refreshMessages = useCallback(async (contact: string) => {
      if (!contact || document.hidden || window.document.hidden) {
        setFetchingInitialMessages(false);
        return
      }

      if (selectedApplicant) {
        const newMessages = await getApplicantMessages({
          channel: channel || "default",
          applicant: selectedApplicant,
          contactNameKey
        });
        setMessages(newMessages);
      } else {
        const newMessages = await getMessages({
          contact,
          channel: channel || "default"
        });
        setMessages(newMessages);
      }
      setFetchingInitialMessages(false);
    }, [document.hidden, supportCase, contactNameKey, channel]);

    const refreshRelatedApplicants = async () => {
      if (document.hidden || window.document.hidden) return;
      loadingRelatedApps.current = true;
      const res = await getRelatedApplicants({ caseId });
      if (res.applicantRecords) {
        setRelatedApplicants(res.applicantRecords);
        let uids = Object.keys(res.applicantRecords);
        if (uids.length === 1) {
          setSelectedApplicant(uids[0]);
          setSelectedApplicantInfo(res.applicantRecords[uids[0]]);
        }
      }
      loadingRelatedApps.current = false;
    }

    const refreshSupportCase = useCallback(async () => {
      if (document.hidden) return;
      if (window.document.hidden) return;

      loading.current = true;
      const res = await getCaseInfo({ caseId });
      if (res.supportCase) {
        setCase(res.supportCase);
        if (res.supportCase.history && typeof res.supportCase.history === 'object' && !Array.isArray(res.supportCase.history)) {
          // Find any verified contacts
          setVerifiedContacts(Object.entries(res.supportCase.history).reduce<string[]>((agg, [created_at, obj]) => {
            if (obj.action === 'verify_finished' && !agg.includes(obj.metadata.contact)) {
              agg.push(obj.metadata.contact);
            }
            return agg;
          }, []));
        }
        setContact(res.supportCase.contact);
      }
      setRefreshed((prev) => prev + 1);
      loading.current = false;
    }, [caseId, document.hidden]);

    useEffect(() => {
      if (sentConfirmationTo && verifiedContacts?.includes(sentConfirmationTo)) {
        setSentConfirmationTo('');
      }
    }, [verifiedContacts, sentConfirmationTo]);

    const isAlternateContact = useCallback((caseContact: string, applicantInfo: InfoDict) => {
      const isContactMatch = (contact: string, checkAgainst?: string) => (
        checkAgainst && (contact === checkAgainst || contact === '+1' + checkAgainst)
      );

      if (isContactMatch(caseContact, applicantInfo['phone_number']) || isContactMatch(caseContact, applicantInfo['email'])) {
        return false;
      }

      const alternateContacts = safeParse(config.alternate_contact || '[]', []) as { phone_key?: string, email_key?: string }[];

      for (const ac of alternateContacts) {
        if (ac.email_key && isContactMatch(caseContact, applicantInfo[ac.email_key])) {
          return true;
        }
        if (ac.phone_key && isContactMatch(caseContact, applicantInfo[ac.phone_key])) {
          return true;
        }
      }

      return false;
    }, [config]);

    // For showing a spinner when resolving unhandled messages
    const [resolving, setResolving] = useState(false); 
    const resolveRequests = usePost("/messages/resolve_all");

    async function resolveAllRequests(message_ids: number[]) {
      setResolving(true);
      await resolveRequests({ 
        applicant: props.uid || 'unknown',
        message_ids
      });
      setResolving(false);
      await refreshMessages(contact);
    }

    useEffect(() => {
      if (!loading.current) refreshSupportCase();
      if (!loadingRelatedApps.current) refreshRelatedApplicants();
    }, [caseId]);
    
    useEffect(() => {
      if (contact) {
        setFetchingInitialMessages(true);
        (async () => {
          await refreshMessages(contact);
        })();
      }
    }, [contact]);

    // Don't call if document hidden
    const cb = useCallback(async () => {
      if (document.hidden) return;
      if (window.document.hidden) return;
      await refreshMessages(contact);
    }, [contact]);

    useInterval(cb, 5000);
    useInterval(refreshSupportCase, 5000);
    useInterval(refreshRelatedApplicants, 20000);

    if (!supportCase) return <>{L.support.loading}</>

    if (!supportCase.status || !supportCase.uid) {
      return <>{L.support.support_case_does_not_exist}</>
    }

    return <>
      <h2 className="sticky top-0 z-10 name-and-status">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="text-xl2">
            <SupportCaseHeader supportCase={supportCase} messages={messages} />
          </div>
          <div>
            <div className="flex-col text-normal align-right justify-end">
              <div className="space-x-2 md:space-x-3">
                <SupportCaseBadge status={supportCase.status as CaseStatus} />
                {/** TODO: Add 're-open case' and remove this barrier: */ } 
                <Dropdown className="px-3 max-h-32" label={<>
                  {updatingStatus && <SpacedSpinner />}{supportCase.assigned_to === 'system' ? L.support.unassigned : L.support.assigned_to + supportCase.agent_name || supportCase.assigned_to}
                </>} options={[{
                  label: "Unassign",
                  callback: async () => {
                    setUpdatingStatus(true);
                    await reassignCase({
                      caseId,
                      reassignTo: 'system'
                    });
                    setUpdatingStatus(false);
                    await refreshSupportCase();
                  }},
                ...assignees
                  .filter(u => u.uid != 'system')
                  .map((assignee, idx) => ({
                    label: getAssigneeDisplayName(assignee),
                    key: assignee.uid + '-' + idx,
                    callback: async () => {
                      setUpdatingStatus(true);
                      await reassignCase({
                        caseId,
                        reassignTo: assignee.uid
                      });
                      setUpdatingStatus(false);
                      await refreshSupportCase();
                    }
                  }))
                ]}/>
                {['resolved','closed'].indexOf(supportCase.status) < 0 && 
                            <Dropdown className="px-3" label={<>
                              {updatingStatus && <SpacedSpinner />}{L.support.update_status}
                            </>} options={[{
                              label: L.support.mark_resolved,
                              callback: async () => {
                                // If still unhandled messages, ask if you want to mark those as handled first.
                                if (messages.data && messages.data.some((m) => m.needs_attention)) {
                                  const doHandle = window.confirm(L.support.mark_unhandled);
                                  if (doHandle) {
                                    await resolveAllRequests(messages.data.filter((m) => m.needs_attention).map((m) => m.id as number));
                                  }
                                }

                                const yn = window.confirm(L.support.confirm_mark_resolved);
                                if (!yn) return;

                                setUpdatingStatus(true);
                                const caseUpdate = await resolveCase({ caseId });
                                if (supportCase) {
                                  setCase((prevState: any) => {
                                    if (caseUpdate?.row) {
                                      return {
                                        ...prevState,
                                        ...caseUpdate.row
                                      }
                                    }
                                    return prevState;
                                  });
                                }
                                setUpdatingStatus(false);
                              }
                            }, ...(supportCase.status !== 'escalated' ? [{
                              label: "Escalate",
                              callback: async () => {
                                const yn = window.confirm(L.support.confirm_escalate_case);
                                if (!yn) return;

                                setUpdatingStatus(true);
                                const caseUpdate = await escalateCase({ caseId });
                                if (supportCase) {
                                  setCase((prevState: any) => {
                                    if (caseUpdate?.row) {
                                      return {
                                        ...prevState,
                                        ...caseUpdate.row
                                      }
                                    }
                                    return prevState;
                                  });
                                }
                                setUpdatingStatus(false);
                              }
                            }] : [])]} />}
              </div>
            </div>
          </div>
        </div>
        {/** TODO: When we get read-only app page for unsubmitted apps, remove this length barrier */}
        {(selectedApplicant && selectedApplicant.length <= 22)
          ? <div>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="text-lg font-normal">
                <span>{L.support.found_app_for_case}</span>
                <Link to={`/${aOrApplicant}/${selectedApplicant}`} className="px-3">
                  {selectedApplicantInfo?.['legal_name']}
                </Link>
              </div>
            </div>
            {selectedApplicantInfo && isAlternateContact(supportCase.contact, selectedApplicantInfo) && <div className="text-sm font-normal my-2 text-yellow-700 ">
              <span className="rounded-full bg-yellow-50 py-1 px-2 border-yellow-400 ">
                {L.support.initiated_by_alternate}
              </span>
            </div>}
          </div> : <></>}
        {Object.keys(relatedApplicants).length > 1 ?
        // If there are multiple applicants, show a dropdown to select one
          <div>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="text-lg font-normal">
                <span>{L.support.multiple_applicants_for_case}</span>
                <Dropdown className="px-3" label={getLabelFromUidAndInfo(selectedApplicant, selectedApplicantInfo)} 
                  options={Object.entries(relatedApplicants).map(([applicant, info], idx) => ({
                    label: getLabelFromUidAndInfo(applicant, info),
                    key: applicant,
                    callback: () => { 
                      setSelectedApplicant(applicant);
                      setSelectedApplicantInfo(info);
                    }
                  }))} />
              </div>
                    
              {selectedApplicant && <div className="text-lg font-normal mt-2">
                <span className="font-bold">{L.support.applicant_stage}</span>
                <span className="px-3">{getStageFromUid(selectedApplicant)}</span>
              </div>}
            </div>
          </div> : null}
      </h2>
            
      <div className="sm:p-3 md:p-5 -mt-2">
        <div className="flex flex-col md:flex-row space-x-4 justify-between">
          <div className="w-full md:w-1/2 wrap-text">
            <div className="border-2 rounded-md drop-shadow-lg bg-blue-50 p-4">
              <h4 className="text-xl2 font-bold">{L.support.context}</h4>
              <hr/>
              <div className="mb-4">
                <h5>{L.support.phone_number}</h5>
                <div>
                  {selectedApplicantInfo?.['phone_number']}
                  {selectedApplicantInfo?.['phone_number'] && (verifiedContacts || []).includes(selectedApplicantInfo?.['phone_number']) &&
                                    <span className='ml-2'>✅</span>
                  }
                </div>
              </div>
              <div className="mb-4">
                <h5>{L.support.email}</h5>
                <div>
                  {selectedApplicantInfo?.['email']}
                  {selectedApplicantInfo?.['email'] && (verifiedContacts || []).includes(selectedApplicantInfo?.['email']) &&
                                    <span className='ml-2'>✅</span>
                  }
                </div>
              </div>
              <div className="mb-4">
                <h5>{L.support.applicants_name}</h5>
                <div>{selectedApplicantInfo?.['legal_name']}</div>
              </div>
              <div className="mb-4">
                <h5>{L.support.case_created}</h5>
                <div>{new Date(supportCase.created_at).toLocaleString(langForDate(context.lang))} {L.support.by} {supportCase.created_by}</div>
              </div>
              <div className="mb-4">
                <h5>{L.support.applicant_stage}</h5>
                <div>{getStageFromUid(selectedApplicant)}</div>
              </div>
              {selectedApplicantInfo?.['language'] && <div className="mb-4">
                <h5>{L.support.language_spoken}</h5>
                <div>{selectedApplicantInfo?.['language']}</div>
              </div>}
            </div>
            {selectedApplicant && selectedApplicantInfo && <SupportCaseApplicantInfo info={selectedApplicantInfo} />}
          </div>
          <div className="w-full md:w-1/2">
            <div className="p-4 bg-gray-50 border-2 rounded-md mb-10">
              <div>
                <div className="sm:hidden">
                  <select id="tabs" name="tabs" 
                    defaultValue={"defaulttab"}
                    className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                    <option id="defaulttab">{L.support.applicant_comms}</option>
                    <option>{L.support.internal_notes}</option>
                    <option>{L.support.tools}</option>
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="flex space-x-4" aria-label="Tabs">
                    {/* Current: "bg-indigo-100 text-indigo-700", Default: "text-gray-500 hover:text-gray-700" */}
                    <button type="button" onClick={() => setTab("comms")} aria-current="page" 
                      className={`border-0 ${tab === 'comms' ? 'bg-indigo-100 text-indigo-700' : 'border-gray-100 bg-white text-gray-400 hover:text-gray-700'} 
                                            px-3 py-2 font-medium text-sm rounded-md`}>{L.support.comms}</button>
                    <button type="button" onClick={() => setTab("notes")} 
                      className={`border-0 ${tab === 'notes' ? 'bg-indigo-100 text-indigo-700' : 'border-gray-100 bg-white text-gray-400 hover:text-gray-700'}
                                            px-3 py-2 drop-shadow font-medium text-sm rounded-md`}>{L.support.case_notes}</button>
                    <button type="button" onClick={() => setTab("tools")}
                      className={`border-0 ${tab === 'tools' ? 'bg-indigo-100 text-indigo-700' : 'border-gray-100 bg-white text-gray-400 hover:text-gray-700'}
                                        px-3 py-2 drop-shadow font-medium text-sm rounded-md`}>{L.support.tools}</button>
                  </nav>
                </div>
              </div>
              <div className="mt-4 mb-4">
                {tab === 'comms' && <>
                  <CommsWindow 
                    contact={{ supportCase: supportCase.uid, mainContact: supportCase.contact, applicant: selectedApplicant}} 
                    contactNameKey={contactNameKey}
                    setContactNameKey={setContactNameKey}
                    info={selectedApplicantInfo || (supportCase.contact.includes("@") ? {email: supportCase.contact} : {phone_number: supportCase.contact})} 
                    messages={messages}
                    fetchingInitialMessages={fetchingInitialMessages}
                    channel={channel ?? "default"}
                    refreshMessages={async () => { await refreshMessages(contact) }}
                  />
                </>}
                {tab === 'notes' && <ContactNotes 
                  refresh={refreshed}
                  doRefresh={() => setRefreshed(prev => prev + 1)}
                  contact={supportCase.contact} />}
                {tab === 'tools' && <div className="flex flex-col">
                  {/** A small form to send an email verification link */}
                  <div className="border border-2 border-gray-50 p-2">
                    <ConfirmationStep supportCaseId={supportCase.uid} 
                      setSent={(email: string) => setSentConfirmationTo(email)} 
                      sent={sentConfirmationTo} 
                      verified={verifiedContacts || undefined}
                      lang={(selectedApplicantInfo?.language as SupportedLanguage) || 'en'}
                    />
                  </div>
                </div>}
              </div> 
            </div>
          </div>
        </div>
      </div>
    </>
}

export const ContactNotes = (props: {
  refresh: number,
  doRefresh: () => void,
  contact: string,
  updateActivity?: (activity: any[]) => void
}) => {

  const [note, setNote] = useState('');
  const { channel } = useParams<{ channel: string | undefined }>();
  const createNote = usePost('/support/create_note');
  const getContactActivity = usePost('/support/get_activity');
  const L = useLocalizedStrings();
  const [activity, setActivity] = useState([] as {
    uid: string,
    kind: 'Note' | 'Support Action',
    action: string,
    created_at: string,
    created_by: string    
  }[]);
  const { toast } = useToast();

  useEffect(() => {
    (async () => {
      const response = await getContactActivity({ contact: props.contact, channel });
      if (response?.activity) {
        setActivity((prevState) => [...response.activity]);
        if (props.updateActivity) {
          props.updateActivity(response.activity);
        }
      }
    })();
  }, [props.refresh]);

  return <>
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <form action="#" className="relative">
          <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
            <label htmlFor="comment" className="sr-only">
              {L.support.add_your_note}
            </label>
            <textarea
              rows={3}
              onChange={(e) => setNote(e.target.value)}
              className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
              placeholder={L.support.add_your_note_dot}
              value={note}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-between">
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
              </div>
              <div className="flex items-center">
              </div>
            </div>
            <div className="flex-shrink-0">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={async () => {
                  const response = await createNote({
                    contact: props.contact,
                    note,
                    channel
                  });
                  if (response?.created) {
                    toast({
                      description: L.support.note_created,
                      variant: 'success'
                    })
                    props.doRefresh();
                    setNote('');
                  }
                }}
              >
                {L.support.post_note}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="flow-root mt-8 mb-8">
      <ul role="list" className="-mb-8">
        {activity.map((activityItem, activityItemIdx) => (
          <li key={activityItem.uid + '-' + activityItemIdx}>
            <div className="relative pb-8">
              {activityItemIdx !== activity.length - 1 ? (
                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
              ) : null}
              <div className="relative flex items-start space-x-3">
                {activityItem.kind === 'Note' ? (
                  <>
                    <div className="relative px-1">
                      <div className="h-8 w-8 bg-blue-100 rounded-full ring-8 ring-blue-100 flex items-center justify-center">
                        <ChatBubbleLeftRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <a href='#' className="font-medium text-gray-900">
                            {activityItem.created_by}
                          </a>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">{L.support.commented} {new Date(activityItem.created_at).toLocaleString()}</p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        <p>{activityItem.action}</p>
                      </div>
                    </div>
                  </>
                ) : activityItem.kind === 'Support Action' ? (
                  <>
                    <div>
                      <div className="relative px-1">
                        <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-gray-100 flex items-center justify-center">
                          <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1.5">
                      <div className="text-sm text-gray-500">
                        <a href={'#'} className="font-medium text-gray-900">
                          {activityItem.created_by}
                        </a>&nbsp;{activityItem.action}&nbsp;at&nbsp;
                        <span className="whitespace-nowrap">{new Date(activityItem.created_at).toLocaleString()}</span>
                      </div>
                    </div>
                  </>
                ) : <></>}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </>
}

import { Survey, Block, Text, BooleanExpr, ClickQuery, Collection, ExpandedSurvey, Notification, Payment, Section, Subsurvey, User, Users, SurveyComponent, NumberWithUnit } from "../survey";
import * as uuid from 'uuid';
const slugid = require('slugid');

type Field = string;
type Tag = string;
type Email = string;
type Channel = string;

export type NavigationNode = {
  name: string | Text,
  href: string,
  icon?: any,
  subnodes?: NavigationNode[], 
  isFolder?: boolean
}

export function collectNavigation(props: Collection | Section | Subsurvey, scope?: "Global" | "Applicant", applicantUid?: string): NavigationNode {
  const appLinkPrefix = (applicantUid && applicantUid?.length > 22) ? '/ua/' : '/a/';
    
  return {
    name: props.kind === 'Section' ? props.title : (props.kind === 'Subsurvey' ? '/p/' + props.path : props.name),
    href: props.kind === 'Section' ? appLinkPrefix + applicantUid + '#' + props.title.en : (props.kind === 'Subsurvey' ? appLinkPrefix + applicantUid + '/' + props.path : ''),
    icon: props.kind,
    isFolder: props.kind === 'Collection',
    subnodes: (props.kind === 'Subsurvey' ? props.sections : props.components).map((c) => {
      // We no longer show subsurvey sections w/in the navigator since subsurveys 
      // are progressively navigated
      if (c.kind === 'Subsurvey') {
        if (scope === 'Global' || c.hideFromNavigation) return null;
        return collectNavigation(c, scope, applicantUid);
      }
      if (c.kind === 'Collection' || c.kind === 'Section') {
        if (scope === "Global" && c.kind !== "Collection") {
          return null;
        }
        return collectNavigation(c, scope, applicantUid);
      }

      if (scope === 'Applicant' && c.kind === 'Applicant Identities') {
        return {
          name: c.title,
          href: '/ad/' + applicantUid + '/' + c.path,
          icon: 'Section',
          isFolder: false
        }
      }

      // The remaining pieces are all global scoped things
      if (scope === 'Applicant') return null;

      if (c.kind === 'Dashboard') {
        return {
          name: c.title,
          icon: 'Dashboard',
          href: '/d/' + c.path,
        }
      }
      return null;

    }).filter((c) => c !== null) as NavigationNode[],
  }
}

export function filterNavigation(nav: NavigationNode, scope: 'Global' | 'Applicant') {
  return {
    ...nav,
    subnodes: nav.subnodes?.map((c: NavigationNode): NavigationNode | null => {
      if (c.isFolder) {
        return filterNavigation(c, scope);
      }
      if (c.icon === 'Section' || c.icon === 'Subsurvey') {
        return scope === 'Global' ? null : c;
      } else {
        return scope === 'Global' ? c : null;
      }
    }).filter((c) => c !== null) as NavigationNode[],
  }
}

export type PermissionScope = {
  applicantScope: BooleanExpr,
  fieldScope: Field[],
  tags: Tag[],
  channelScope?: Channel[],
  userId?: string,
}

export function EmailToUserId(email: string) {
  return slugid.encode(uuid.v5(email.trim().toLowerCase(), uuid.v5.URL));
}

export function CollectScopeForUser(user: string, survey: ExpandedSurvey): PermissionScope {
  const [usersTags, userFilters] = CollectUserTagsAndFilters(survey.users || []);
  if (!usersTags[user]) {
    return {
      applicantScope: {
        kind: 'Never'
      },
      fieldScope: [],
      channelScope: [],
      tags: []
    }
  }
  const tags = usersTags[user];
  const filters = userFilters[user];
  const fieldPermissions = CollectFieldPermissions(survey);

  return {
    applicantScope: {
      kind: 'And',
      clauses: filters
    },
    fieldScope: Object.keys(fieldPermissions).filter(field => {
      const fieldTags = fieldPermissions[field];
      return fieldTags.length === 0 || tags.some(tag => fieldTags.indexOf(tag) !== -1);
    }),
    channelScope: [],
    tags: [] 
  }
}

export function CollectIndividualUsers(users: Users) {
  const toReturn: (User & { tags: Tag[], filters: BooleanExpr[] })[] = [];
  function traverse(users: Users, tags: string[], filters: BooleanExpr[]) {
    users.forEach(user => {
      if (user.kind === 'User') {
        toReturn.push({
          ...user,
          tags,
          filters
        })
      }
      if (user.kind === 'User Group') {
        traverse(user.members, 
          [...tags, ...user.tags], 
          [...filters, ...(user.limitedToApplicants ? [user.limitedToApplicants] : [])])
      }
    })
  };
  traverse(users, [], []);
  return toReturn;
}

export function CollectUserTagsAndFilters(users: Users): [Record<Email, Tag[]>, Record<Email, BooleanExpr[]>] {
  const userTags = {} as Record<string, string[]>;
  const userFilters = {} as Record<string, BooleanExpr[]>;
  function traverse(users: Users, tags: string[], filters: BooleanExpr[]) {
    users.forEach(user => {
      if (user.kind === 'User') {
        const uid = EmailToUserId(user.email);
        userTags[uid] = tags;
        userFilters[uid] = filters;
      }
      if (user.kind === 'User Group') {
        traverse(user.members, 
          [...tags, ...user.tags], 
          [...filters, ...(user.limitedToApplicants ? [user.limitedToApplicants] : [])])
      }
    })
  };
  traverse(users, [], []);
  return [userTags, userFilters];
}

export function CollectFieldPermissions(survey: ExpandedSurvey): Record<Field, Tag[]> {
  // This is target field to tags
  const fieldPermissions = {} as Record<string, string[]>;
  // whyyyyy console.log(survey)

  const hasTagAlready = {} as Record<string, Record<string, true | undefined>>;

  const addTagsToField = (targetField: string, tags: string[]) => {
    // If a field appears in a collection with no tags, 
    // intuit that means everyone should have access to it.
    // Overwrite existing tags, and given the if else below, 
    // it will always stay true the fieldPermissions[targetField] = [];
    if (tags.length === 0) {
      fieldPermissions[targetField] = tags;
      return;
    }

    hasTagAlready[targetField] ||= {};
        
    if (!fieldPermissions[targetField] || fieldPermissions[targetField]?.some(d => d)) {
      // If no tag array has been set or if specific tags have been defined,
      // Go ahead and push the rest.
      fieldPermissions[targetField] ||= [];

      for (const tag of tags) {
        if (!hasTagAlready[targetField][tag]) {
          fieldPermissions[targetField].push(tag);
          // Setting it in the record avoids tedious .includes calls
          hasTagAlready[targetField][tag] = true;
        }
      }
    } 

  }

  function traverse(block: SurveyComponent, tags: string[]) {
    if ((block as any).targetField) {
      if ('allowedUserTags' in block) {
        tags = tags.concat(block.allowedUserTags);
      }
      addTagsToField((block as any).targetField, tags);
    }

    // Special case Likert because it is kind of a shell around its subquestions; it does
    // not have a top-level target field, but its subquestions do
    if (block.kind === 'Likert' && Array.isArray(block.questions)) {
      block.questions.forEach(question => {
        addTagsToField(question.targetField, tags);
      })
    }

    // Special case NumberWithUnit because it does not have a top-level target field
    if (block.kind === 'NumberWithUnit') {
      addTagsToField((block as NumberWithUnit).numberQuestion.targetField, tags);
    }

    if (block.kind == 'Subsurvey') {
      block.sections.flatMap((s) => traverse(s, tags));
    }
    if (block.kind == 'Section') {
      tags = tags.concat(block.limitedToTags ?? []);
      block.components.flatMap((s) => traverse(s, tags));
    }
    if (block.kind == 'Collection') {
      tags = tags.concat(block.options.limitedToTags ?? []);
      block.components.flatMap((s) => traverse(s, tags));
    }
    if (block.kind == 'Conditional Block') {
      block.components.flatMap((s) => traverse(s, tags));
      (block.otherwise || []).flatMap((s) => traverse(s, tags));
    }
  }
  (survey.survey || []).flatMap((s) => traverse(s, []));

  return fieldPermissions;
}

export function pruneForUserTags(survey: Survey, tags: string[]) {
  return survey.reduce((prev, s) => {
    if (s.kind === 'Collection') {
      if (s.options.limitedToTags && 
                !s.options.limitedToTags.some((t) => tags.includes(t))) {
        console.log("PRUNING - Collection", s.options.limitedToTags, tags, s.name)
        return prev;
      }
      s.components = pruneForUserTags(s.components, tags);
    }
    if (s.kind === 'Subsurvey') {
      s.sections = pruneForUserTags(s.sections, tags) as Subsurvey['sections'];
    }
    if (s.kind === 'Section') {
      if (s.limitedToTags && !s.limitedToTags.some((t) => tags.includes(t))) {
        console.log("PRUNING - Section", s.limitedToTags, tags, s.title);
        return prev;
      }
    }
    prev.push(s);
    return prev;
  }, [] as Survey);
}

/*
export function FilterSurveyForUser(user: string, survey: ExpandedSurvey): ExpandedSurvey {
}
*/

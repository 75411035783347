import React, { } from "react";
import { useAPIPost, useAPI } from "../API";
import { useLocalizedStrings } from "../Localization";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Badge, Dropdown } from "react-bootstrap";

type ReassignProps = { uid: string, applicant: any, applicantInfo: any }

function ReassignDropdown(props: ReassignProps) {
  const L = useLocalizedStrings();
  const screeners = useAPI("/users/screeners");
  const reassignApplicant = useAPIPost("/applicant/" + props.uid + "/reassign");

  // Only show dropdown with proper permissions.
  if (props.applicant.current_user !== props.applicant.screener_uid && !props.applicant.admin) {
    return <>{props.applicant.screener_name}</>
  }

  return (
    <Dropdown style={{ display: "inline-block" }}>
      <Dropdown.Toggle variant="light" size="lg">
        {props.applicant.screener_name}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>{L.applicant.reassign} ({(screeners.data && screeners.data.length > 0 && screeners.data[0].quotas === true && (
          <>{L.applicant.quotas_enabled}</>
        )) || (<>{L.applicant.no_quotas}</>)})</Dropdown.Header>
        <div style={{"maxHeight": 300, "overflowY": "scroll"}}>
          {screeners.data && screeners.data.map((u: any, i: number) => {
            async function reassign() {
              const result = await reassignApplicant({ screener: u.uid });
              if (result && result.status && result.status === 'ok') {
                window.location.reload();
              }
            }

            return (
              <Dropdown.Item onSelect={() => reassign()} key={`reassign-${i}`}>
                {u.name} {u.organization && (
                  <Badge key={`badge-${i}`} variant="primary">{u.organization}</Badge>)}
              </Dropdown.Item>
            );  
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )

}

export default ReassignDropdown;
import { useState } from 'react';
import { ClickableButton } from './Button';

export function SurveyFieldsList(props: { 
  dmap: Record<string, string[]>,
  onFieldClick: (key: string) => void,
  description?: string
}) {

  const [searchKeyValue, setSearchKeyValue] = useState('');

  return (    
    <div className="p-4">
      <h2>Survey Keys</h2>
      <p>{props.description || ''}</p>
      <div className="flex flex-row py-2">
        <input className="pr-2 mr-1 flex-1 block border w-full pl-2 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" value={searchKeyValue} onChange={(e) => {
          setSearchKeyValue(e.target.value);
        }} /> 
        <ClickableButton color="gray" onClick={() => { setSearchKeyValue('')}}>Clear</ClickableButton>
      </div>
      <div className="border-2 border rounded-sm border-blue-600">
        <ul className="p-1" style={{ maxHeight: "400px", overflowY: "scroll" }}>
          {(Object.keys(props.dmap || {}) || []).map((key: string) => {
            if (searchKeyValue && key.indexOf(searchKeyValue) === -1) return <></>;
            return <li key={`dependent-${key}`} className="py-1">
              <ClickableButton color="blue" onClick={() => props.onFieldClick(key)}>
                {key}
              </ClickableButton>
            </li>
          })}
        </ul>
      </div>
    </div>
  );
}
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getURLsFromCSV = void 0;
var getURLsFromCSV = function (csv) {
    // This avoids splitting on commas inside of filenames, etc.
    // Splitting on a regex with a captured group splices the groups into the array,
    // so we'll end up with things like ['http:', '//example.com', 'https:', '//google.com'] that we need to pair back together below
    var splitResults = (',' + (csv || '')).split(/,(https?:)/);
    var urls = [];
    for (var i = 1; i < splitResults.length; i += 2) {
        if (splitResults[i] && splitResults[i + 1]) {
            urls.push(splitResults[i] + splitResults[i + 1]);
        }
    }
    return urls;
};
exports.getURLsFromCSV = getURLsFromCSV;

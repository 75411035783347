"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadInfoDefs = exports.loadInfoDefsFromSurveyDraft = exports.findAndValidateFormulas = exports.getRawSurvey = void 0;
console.time("Load survey loader");
var db_1 = require("../util/db");
var derived_1 = require("./derived");
var survey_constants_1 = require("./survey.constants");
var json_1 = require("../util/json");
var util_1 = require("./util");
var neverthrow_1 = require("neverthrow");
var runtime_1 = require("@pgtyped/runtime");
var v0_to_legacy_1 = require("@aidkitorg/types/lib/translation/v0_to_legacy");
var s3_1 = require("../util/s3");
var quickJSModule_1 = require("./quickJSModule");
var LOADING_SURVEY = "Mapping Survey Fields to infoDefs";
var streamToString = function (stream) {
    return new Promise(function (resolve, reject) {
        var chunks = [];
        stream.on("data", function (chunk) { return chunks.push(chunk); });
        stream.on("error", reject);
        stream.on("end", function () { return resolve(Buffer.concat(chunks).toString("utf8")); });
    });
};
var s3 = (0, s3_1.getS3)();
function getSurveyDefinition(filename) {
    return __awaiter(this, void 0, void 0, function () {
        var obj;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, s3.getObject({
                        // TODO: get this from ENV
                        Bucket: 'aidkit-surveys-c285c37',
                        Key: "".concat(filename, ".json")
                    })];
                case 1:
                    obj = _a.sent();
                    return [4 /*yield*/, streamToString(obj.Body)];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function getRawSurvey(params) {
    return __awaiter(this, void 0, void 0, function () {
        var TestSurvey, conn, connAttempt, getLatestSurvey, resp, distTimer, toReturn, t0, auditEnabled, _a, _b, config, testSurvey, _c, _d, _e;
        var e_1, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    if (params.deploymentKey === 'test') {
                        TestSurvey = require(process.env.DONOTINLINE + '../test_data/test-survey.json');
                        return [2 /*return*/, (0, neverthrow_1.ok)(TestSurvey)];
                    }
                    // We need a connection to get the survey:
                    if (!params.conn && !params.deploymentKey) {
                        return [2 /*return*/, (0, neverthrow_1.err)("no_conn_or_deployment_key_provided")];
                    }
                    console.time("Getting raw survey");
                    if (!!params.conn) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, db_1.getDbConn)(params.deploymentKey)];
                case 1:
                    connAttempt = _g.sent();
                    if (connAttempt.isOk()) {
                        conn = connAttempt.value;
                    }
                    else {
                        console.error(connAttempt.error);
                        return [2 /*return*/, (0, neverthrow_1.err)("cannot_load_survey_without_db")];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    conn = params.conn;
                    _g.label = 3;
                case 3:
                    getLatestSurvey = (0, runtime_1.sql)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        SELECT id, survey_response FROM surveyversion ORDER BY id desc limit 1\n    "], ["\n        SELECT id, survey_response FROM surveyversion ORDER BY id desc limit 1\n    "])));
                    return [4 /*yield*/, getLatestSurvey.run(void (''), conn)];
                case 4:
                    resp = _g.sent();
                    console.timeEnd("Getting raw survey");
                    distTimer = Date.now();
                    if (!resp) return [3 /*break*/, 8];
                    if (!resp.length) {
                        return [2 /*return*/, (0, neverthrow_1.err)("survey_not_found")];
                    }
                    toReturn = resp[0]['survey_response'];
                    t0 = Date.now();
                    return [4 /*yield*/, (0, v0_to_legacy_1.handleImports)(toReturn, params.deploymentKey, getSurveyDefinition)];
                case 5:
                    _g.sent();
                    console.log(params.deploymentKey, "imports took", Date.now() - t0, "ms");
                    auditEnabled = false;
                    try {
                        for (_a = __values(toReturn.Configuration), _b = _a.next(); !_b.done; _b = _a.next()) {
                            config = _b.value;
                            if (config.fields.Key === 'enable_audit' && config.fields.Value === 'true') {
                                auditEnabled = true;
                            }
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_b && !_b.done && (_f = _a.return)) _f.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    console.log(params.deploymentKey, "Audit enabled:", auditEnabled);
                    if (!auditEnabled) return [3 /*break*/, 7];
                    console.time("Audit Stuff");
                    _c = v0_to_legacy_1.v0ToLegacy;
                    _e = (_d = JSON).parse;
                    return [4 /*yield*/, getSurveyDefinition("dso-".concat(params.deploymentKey, "-audit"))];
                case 6:
                    testSurvey = _c.apply(void 0, [_e.apply(_d, [_g.sent()])]);
                    toReturn['Rental Survey'] = __spreadArray(__spreadArray([], __read(toReturn['Rental Survey']), false), __read(testSurvey['Rental Survey']), false);
                    toReturn['Choices'] = __spreadArray(__spreadArray([], __read(toReturn['Choices']), false), __read(testSurvey['Choices']), false);
                    toReturn['Roles'] = __spreadArray(__spreadArray([], __read(toReturn['Roles']), false), __read(testSurvey['Roles']), false);
                    toReturn['Translations'] = __spreadArray(__spreadArray([], __read(toReturn['Translations']), false), __read(testSurvey['Translations']), false);
                    console.timeEnd("Audit Stuff");
                    _g.label = 7;
                case 7:
                    console.log("Distro stuff:", Date.now() - distTimer);
                    return [2 /*return*/, (0, neverthrow_1.ok)(toReturn)];
                case 8:
                    console.log("No Distro stuff:", Date.now() - distTimer);
                    return [2 /*return*/, (0, neverthrow_1.err)("survey_not_found")];
            }
        });
    });
}
exports.getRawSurvey = getRawSurvey;
// This function validates that a survey doesn't have any js forumlas with syntax errors, 
// and doesn't use any fields in formulas that it shouldn't (ie: Last Modified Date)
function findAndValidateFormulas(tree, useQuickJS) {
    return __awaiter(this, void 0, void 0, function () {
        function traverse(tree, crumbsSoFar) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function () {
                var invalidFormulas, tree_1, tree_1_1, s, _d, _e, _f, _g, e_2_1, _h, _j, k, fnStr, args, programState, _k, deps, res, err_1, fn, e_3, formula, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, e_4_1;
                var e_2, _y, e_4, _z;
                return __generator(this, function (_0) {
                    switch (_0.label) {
                        case 0:
                            invalidFormulas = [];
                            if (!Array.isArray(tree)) return [3 /*break*/, 9];
                            _0.label = 1;
                        case 1:
                            _0.trys.push([1, 6, 7, 8]);
                            tree_1 = __values(tree), tree_1_1 = tree_1.next();
                            _0.label = 2;
                        case 2:
                            if (!!tree_1_1.done) return [3 /*break*/, 5];
                            s = tree_1_1.value;
                            _e = (_d = invalidFormulas.push).apply;
                            _f = [invalidFormulas];
                            _g = [[]];
                            return [4 /*yield*/, traverse(s, crumbsSoFar)];
                        case 3:
                            _e.apply(_d, _f.concat([__spreadArray.apply(void 0, _g.concat([__read.apply(void 0, [(_0.sent())]), false]))]));
                            _0.label = 4;
                        case 4:
                            tree_1_1 = tree_1.next();
                            return [3 /*break*/, 2];
                        case 5: return [3 /*break*/, 8];
                        case 6:
                            e_2_1 = _0.sent();
                            e_2 = { error: e_2_1 };
                            return [3 /*break*/, 8];
                        case 7:
                            try {
                                if (tree_1_1 && !tree_1_1.done && (_y = tree_1.return)) _y.call(tree_1);
                            }
                            finally { if (e_2) throw e_2.error; }
                            return [7 /*endfinally*/];
                        case 8: return [3 /*break*/, 29];
                        case 9:
                            if (!(typeof tree === 'object' && tree !== null)) return [3 /*break*/, 29];
                            _0.label = 10;
                        case 10:
                            _0.trys.push([10, 27, 28, 29]);
                            _h = __values(Object.keys(tree)), _j = _h.next();
                            _0.label = 11;
                        case 11:
                            if (!!_j.done) return [3 /*break*/, 26];
                            k = _j.value;
                            if (!jsToCheck.includes(k)) return [3 /*break*/, 21];
                            if (!(typeof tree[k] === 'string')) return [3 /*break*/, 18];
                            _0.label = 12;
                        case 12:
                            _0.trys.push([12, 16, , 17]);
                            fnStr = '';
                            args = [{}, {}, {}, {}];
                            programState = { info: {}, org: {}, screener: {}, view_info: {} };
                            if (tree.kind === 'Validated') {
                                fnStr = 'return ((info,org,screener,view_info) => { ' + tree[k] + ' })';
                            }
                            else if (k === 'action') {
                                fnStr = 'return ((info,org,screener,view_info) => { const newInfo = ' + tree[k] + '; return newInfo; })';
                            }
                            else if (k === 'macroUpdate') {
                                fnStr = 'return ((macroConfig,component) => { let out = ' + tree[k] + '; return out; })';
                            }
                            else if (k === 'macroIncludeIf') {
                                fnStr = 'return ((macroConfig) => { let out = ' + tree[k] + '; return out; })';
                            }
                            else if (tree.kind === 'Computed' || k === 'conditional' || tree.kind === 'Code Boolean Expr') {
                                fnStr = 'return ((info,org,screener,view_info) => { let out = ' + tree[k] + '; return out; })';
                            }
                            else if (k === 'matchingFunction' || k === 'allowNewApplicationFunction') {
                                console.log(k, tree[k]);
                                fnStr = 'return ((dbApps, provided) => { let out = ' + tree[k] + '; return out; })';
                                args = [[], {}];
                                programState = { dbApps: [], provided: {} };
                            }
                            else if (k === 'propagateUpdates') {
                                fnStr = 'return ((shared_apps) => { let out = ' + tree[k] + '; return out; })';
                                args = [{}];
                                programState = { shared_apps: {} };
                            }
                            else {
                                fnStr = 'return ((info) => { ' + tree[k] + ' })';
                            }
                            if (!useQuickJS) return [3 /*break*/, 14];
                            fnStr = '(() => { ' + fnStr + ' })()';
                            return [4 /*yield*/, quickJSModule_1.QuickJSModule.computeWithDeps(fnStr, programState)];
                        case 13:
                            _k = __read.apply(void 0, [_0.sent(), 3]), deps = _k[0], res = _k[1], err_1 = _k[2];
                            if (err_1 !== '') {
                                throw err_1;
                            }
                            return [3 /*break*/, 15];
                        case 14:
                            fn = new Function(fnStr)();
                            fn.apply(void 0, __spreadArray([], __read(args), false));
                            _0.label = 15;
                        case 15: return [3 /*break*/, 17];
                        case 16:
                            e_3 = _0.sent();
                            if (tree.kind === 'Validated' && e_3.toString().includes('$content')) {
                                return [3 /*break*/, 25];
                            }
                            formula = tree[k];
                            if (typeof tree[k] !== 'string') {
                                formula = JSON.stringify(formula);
                            }
                            invalidFormulas.push({
                                error: 'Error in ' + k + ' ' + formula + ': ' + e_3,
                                hint: crumbsSoFar + "".concat(crumbsSoFar !== '' ? ' -> ' : '').concat(tree.kind, " : ").concat(((_a = tree.title) === null || _a === void 0 ? void 0 : _a.en) || tree.targetField || k)
                            });
                            return [3 /*break*/, 17];
                        case 17: return [3 /*break*/, 20];
                        case 18:
                            _m = (_l = invalidFormulas.push).apply;
                            _o = [invalidFormulas];
                            _p = [[]];
                            return [4 /*yield*/, traverse(tree[k], crumbsSoFar + "".concat(crumbsSoFar !== '' ? ' -> ' : '').concat(tree.kind, " : ").concat(JSON.stringify(Object.keys(tree))))];
                        case 19:
                            _m.apply(_l, _o.concat([__spreadArray.apply(void 0, _p.concat([__read.apply(void 0, [(_0.sent())]), false]))]));
                            if (tree.kind === 'Computed' || tree.kind === 'Conditional Block') {
                                if (JSON.stringify(tree[k]).includes('Last Modified Date')) {
                                    invalidFormulas.push({
                                        error: 'Error in ' + tree.kind + ' ' + k + ': you may not use Last Modified Date in this type.',
                                        hint: crumbsSoFar + "".concat(crumbsSoFar !== '' ? ' -> ' : '').concat(tree.kind)
                                    });
                                }
                            }
                            _0.label = 20;
                        case 20: return [3 /*break*/, 25];
                        case 21:
                            if (!tree.kind) return [3 /*break*/, 23];
                            _r = (_q = invalidFormulas.push).apply;
                            _s = [invalidFormulas];
                            _t = [[]];
                            return [4 /*yield*/, traverse(tree[k], crumbsSoFar + "".concat(crumbsSoFar !== '' ? ' -> ' : '').concat(tree.kind, " : ").concat(((_b = tree.title) === null || _b === void 0 ? void 0 : _b.en) || tree.title || tree.path || ((_c = tree.name) === null || _c === void 0 ? void 0 : _c.en)))];
                        case 22:
                            _r.apply(_q, _s.concat([__spreadArray.apply(void 0, _t.concat([__read.apply(void 0, [(_0.sent())]), false]))]));
                            return [3 /*break*/, 25];
                        case 23:
                            _v = (_u = invalidFormulas.push).apply;
                            _w = [invalidFormulas];
                            _x = [[]];
                            return [4 /*yield*/, traverse(tree[k], crumbsSoFar)];
                        case 24:
                            _v.apply(_u, _w.concat([__spreadArray.apply(void 0, _x.concat([__read.apply(void 0, [(_0.sent())]), false]))]));
                            _0.label = 25;
                        case 25:
                            _j = _h.next();
                            return [3 /*break*/, 11];
                        case 26: return [3 /*break*/, 29];
                        case 27:
                            e_4_1 = _0.sent();
                            e_4 = { error: e_4_1 };
                            return [3 /*break*/, 29];
                        case 28:
                            try {
                                if (_j && !_j.done && (_z = _h.return)) _z.call(_h);
                            }
                            finally { if (e_4) throw e_4.error; }
                            return [7 /*endfinally*/];
                        case 29: return [2 /*return*/, invalidFormulas];
                    }
                });
            });
        }
        var jsToCheck;
        return __generator(this, function (_a) {
            jsToCheck = ['conditionalOn', 'cond', 'expr', 'conditional', 'filter', 'condition', 'formula',
                'code', 'matchingFunction', 'allowNewApplicationFunction', 'action', 'propagateUpdates', 'macroIncludeIf', 'macroUpdate'];
            return [2 /*return*/, traverse(tree, '')];
        });
    });
}
exports.findAndValidateFormulas = findAndValidateFormulas;
function buildInfoDefs(base, deploymentKey, loadOptions) {
    return __awaiter(this, void 0, void 0, function () {
        var surveyTable, rolesTable, choicesTable, config, roles, rolesTable_1, rolesTable_1_1, row, choices, choicesTable_1, choicesTable_1_1, c, questionMap, stages, checkedQuestions, infoDefs, addInfoDef, state, surveyTable_1, surveyTable_1_1, row, q, type, trueStage, stageDef, expandedQuestion, sectionKeys, sectionKeys_1, sectionKeys_1_1, sectionKey, pseudoQuestions, pseudoQuestions_1, pseudoQuestions_1_1, pseudoQuestion, e_5_1, e_6_1, _a, _b, key, e_7_1;
        var e_8, _c, e_9, _d, e_6, _e, e_10, _f, e_5, _g, e_7, _h;
        var _this = this;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    surveyTable = base[survey_constants_1.SURVEY_TABLE];
                    rolesTable = base['Roles'];
                    choicesTable = base['Choices'];
                    config = base['Configuration'].map(function (r) { return r['fields']; }) || [];
                    roles = {};
                    try {
                        // initialize Roles
                        for (rolesTable_1 = __values(rolesTable), rolesTable_1_1 = rolesTable_1.next(); !rolesTable_1_1.done; rolesTable_1_1 = rolesTable_1.next()) {
                            row = rolesTable_1_1.value;
                            roles[row.id] = row.fields;
                        }
                    }
                    catch (e_8_1) { e_8 = { error: e_8_1 }; }
                    finally {
                        try {
                            if (rolesTable_1_1 && !rolesTable_1_1.done && (_c = rolesTable_1.return)) _c.call(rolesTable_1);
                        }
                        finally { if (e_8) throw e_8.error; }
                    }
                    choices = {};
                    try {
                        for (choicesTable_1 = __values(choicesTable), choicesTable_1_1 = choicesTable_1.next(); !choicesTable_1_1.done; choicesTable_1_1 = choicesTable_1.next()) {
                            c = choicesTable_1_1.value;
                            choices[c['id']] = c['fields'];
                        }
                    }
                    catch (e_9_1) { e_9 = { error: e_9_1 }; }
                    finally {
                        try {
                            if (choicesTable_1_1 && !choicesTable_1_1.done && (_d = choicesTable_1.return)) _d.call(choicesTable_1);
                        }
                        finally { if (e_9) throw e_9.error; }
                    }
                    questionMap = {};
                    stages = [];
                    checkedQuestions = [];
                    infoDefs = {};
                    addInfoDef = function (q) { return __awaiter(_this, void 0, void 0, function () {
                        // Get formula from legacy (metadata is plain string) or v0 (metadata is object) Validated types.
                        function safeParseValidatedFormula(metadata) {
                            try {
                                if (JSON.parse(metadata).formula) {
                                    return JSON.parse(metadata).formula;
                                }
                                else {
                                    return '';
                                }
                            }
                            catch (e) {
                                return metadata;
                            }
                        }
                        var key, type, NOT_NEEDED, infoDef, isNeeded, options, derivedTypes, c_1, sign_1, cformulas_1, metadata, _a, _b, o;
                        var e_11, _c;
                        var _d;
                        return __generator(this, function (_e) {
                            switch (_e.label) {
                                case 0:
                                    // Don't add if no target field
                                    if (!q[survey_constants_1.TARGET_FIELD] || !q[survey_constants_1.FIELD_TYPE])
                                        return [2 /*return*/];
                                    key = q[survey_constants_1.TARGET_FIELD];
                                    type = q[survey_constants_1.FIELD_TYPE];
                                    NOT_NEEDED = ['Flag Review', 'Show Field', 'Show Date'];
                                    if (NOT_NEEDED.indexOf(type) !== -1)
                                        return [2 /*return*/];
                                    infoDef = { key: key, type: type };
                                    isNeeded = false;
                                    if (derived_1.DERIVED_MODELS[type]) {
                                        // Include metadata if it's there
                                        if (q['Metadata']) {
                                            infoDef['metadata'] = q['Metadata'];
                                        }
                                        isNeeded = true;
                                    }
                                    if (type === survey_constants_1.COMPUTED) {
                                        infoDef['formula'] = (0, json_1.safeParse)(q['Metadata'] || '{}')['formula'];
                                        isNeeded = true;
                                    }
                                    else if (type === survey_constants_1.VALIDATED) {
                                        infoDef['formula'] = safeParseValidatedFormula(q['Metadata'] || '');
                                        isNeeded = true;
                                    }
                                    options = q['Additional Options'] || [];
                                    derivedTypes = (0, util_1.getDerivedTypesFromArray)(options);
                                    if (derivedTypes.length) {
                                        infoDef['options'] = options;
                                        isNeeded = true;
                                    }
                                    if (q['Conditional On']) {
                                        c_1 = 'info.' + q['Conditional On'];
                                        if (q['Conditional On Value']) {
                                            sign_1 = ' === ';
                                            cformulas_1 = [];
                                            if (key in ((loadOptions === null || loadOptions === void 0 ? void 0 : loadOptions.debugKeys) || {})) {
                                                console.debug("DEBUG INFODEF FOR KEY: " + key);
                                                console.debug("CONDITIONAL ON VALUE: ", q['Conditional On Value']);
                                            }
                                            q['Conditional On Value'].forEach(function (val) {
                                                cformulas_1.push(c_1 + sign_1 + "'" + val + "'");
                                            });
                                            infoDef['conditional'] = cformulas_1.join(' || ');
                                        }
                                        else {
                                            infoDef['conditional'] = c_1 + " !== ''";
                                        }
                                    }
                                    else if (q['Metadata'] && type !== survey_constants_1.VALIDATED) {
                                        metadata = (0, json_1.safeParse)(q['Metadata'] || '{}');
                                        if (metadata === null || metadata === void 0 ? void 0 : metadata.conditional) {
                                            infoDef['conditional'] = metadata.conditional;
                                        }
                                    }
                                    infoDef['editableBy'] = q['Who Can Edit'] || [];
                                    // Add any options if relevant because those can alter survey state
                                    if ((q['Options (if relevant)'] || []).length > 0) {
                                        infoDef['choices'] = q['Options (if relevant)'];
                                        // Has Other
                                        if (!infoDef['options'])
                                            infoDef['options'] = [];
                                        try {
                                            for (_a = __values(infoDef['choices']), _b = _a.next(); !_b.done; _b = _a.next()) {
                                                o = _b.value;
                                                if (o.Name === 'other' || o["Other Field"]) {
                                                    isNeeded = true;
                                                    infoDef['options'].push('Other');
                                                }
                                            }
                                        }
                                        catch (e_11_1) { e_11 = { error: e_11_1 }; }
                                        finally {
                                            try {
                                                if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                                            }
                                            finally { if (e_11) throw e_11.error; }
                                        }
                                    }
                                    // Add any section metadata:
                                    if ((_d = q.Section) === null || _d === void 0 ? void 0 : _d.Metadata) {
                                        infoDef.sectionMetadata = q.Section.Metadata;
                                    }
                                    if (!!infoDefs[key]) return [3 /*break*/, 2];
                                    infoDefs[key] = infoDef;
                                    // Add infoDef for pre-cleaning metadata of Attachment files if available.
                                    if (type === 'Attachment') {
                                        infoDefs[key + '_metadata'] = { key: key + '_metadata', type: 'Single Line Text Entry', editableBy: infoDef['editableBy'] };
                                    }
                                    if (!(isNeeded && (loadOptions === null || loadOptions === void 0 ? void 0 : loadOptions.dependencyMapper))) return [3 /*break*/, 2];
                                    return [4 /*yield*/, loadOptions.dependencyMapper.mapKeyInfo(loadOptions.dependencyMapper.pdmap, loadOptions.dependencyMapper.dmap, infoDefs, key, deploymentKey)];
                                case 1:
                                    _e.sent();
                                    _e.label = 2;
                                case 2: return [2 /*return*/];
                            }
                        });
                    }); };
                    state = { currentSection: {} };
                    _j.label = 1;
                case 1:
                    _j.trys.push([1, 14, 15, 16]);
                    surveyTable_1 = __values(surveyTable), surveyTable_1_1 = surveyTable_1.next();
                    _j.label = 2;
                case 2:
                    if (!!surveyTable_1_1.done) return [3 /*break*/, 13];
                    row = surveyTable_1_1.value;
                    questionMap[row.id] = row.fields;
                    q = row.fields;
                    type = q['Field Type'];
                    // Don't include the question if it doesnt have basic fields
                    if (!type || !q['Question'])
                        return [3 /*break*/, 12];
                    if (type === "Stage") {
                        trueStage = {
                            milestone: q['Question'],
                            metadata: (0, json_1.safeParse)(q['Metadata'] || '{}'),
                            requirements: __spreadArray([], __read(checkedQuestions), false),
                            notifies: (q['Notifies'] || []).map(function (id) { return roles[id]; })
                        };
                        stageDef = {
                            key: 'stage_' + q['Question'],
                            name: q['Question'],
                            type: 'Stage',
                            sequence: stages.length,
                            requirements: __spreadArray([], __read(checkedQuestions), false)
                        };
                        infoDefs['stage_' + q['Question']] = stageDef;
                        stages.push(trueStage);
                    }
                    // Special case card management
                    if (type === 'Card Management' && !q[survey_constants_1.TARGET_FIELD]) {
                        q[survey_constants_1.TARGET_FIELD] = 'card';
                    }
                    expandedQuestion = expandQuestion(q, questionMap, choices, roles);
                    // Keep track of sections for conditional sections
                    if (q['Field Type'] === 'Section') {
                        sectionKeys = Object.keys(state.currentSection);
                        try {
                            for (sectionKeys_1 = (e_10 = void 0, __values(sectionKeys)), sectionKeys_1_1 = sectionKeys_1.next(); !sectionKeys_1_1.done; sectionKeys_1_1 = sectionKeys_1.next()) {
                                sectionKey = sectionKeys_1_1.value;
                                delete state.currentSection[sectionKey];
                            }
                        }
                        catch (e_10_1) { e_10 = { error: e_10_1 }; }
                        finally {
                            try {
                                if (sectionKeys_1_1 && !sectionKeys_1_1.done && (_f = sectionKeys_1.return)) _f.call(sectionKeys_1);
                            }
                            finally { if (e_10) throw e_10.error; }
                        }
                        Object.assign(state.currentSection, expandedQuestion);
                        return [3 /*break*/, 12];
                    }
                    if (!q[survey_constants_1.TARGET_FIELD]) return [3 /*break*/, 4];
                    // console.log("Using section: ", state.currentSection);
                    expandedQuestion['Section'] = {
                        "Metadata": state.currentSection["Metadata"],
                        "Conditional On": state.currentSection["Conditional On"],
                        "Conditional On Value": state.currentSection["Conditional On Value"]
                    };
                    checkedQuestions.push(expandedQuestion);
                    return [4 /*yield*/, addInfoDef(expandedQuestion)];
                case 3:
                    _j.sent();
                    _j.label = 4;
                case 4:
                    pseudoQuestions = getPseudoQuestions(expandedQuestion);
                    _j.label = 5;
                case 5:
                    _j.trys.push([5, 10, 11, 12]);
                    pseudoQuestions_1 = (e_5 = void 0, __values(pseudoQuestions)), pseudoQuestions_1_1 = pseudoQuestions_1.next();
                    _j.label = 6;
                case 6:
                    if (!!pseudoQuestions_1_1.done) return [3 /*break*/, 9];
                    pseudoQuestion = pseudoQuestions_1_1.value;
                    if ((pseudoQuestion['Additional Options'] || '[]').indexOf('Optional') === -1) {
                        checkedQuestions.push(pseudoQuestion);
                    }
                    return [4 /*yield*/, addInfoDef(pseudoQuestion)];
                case 7:
                    _j.sent();
                    _j.label = 8;
                case 8:
                    pseudoQuestions_1_1 = pseudoQuestions_1.next();
                    return [3 /*break*/, 6];
                case 9: return [3 /*break*/, 12];
                case 10:
                    e_5_1 = _j.sent();
                    e_5 = { error: e_5_1 };
                    return [3 /*break*/, 12];
                case 11:
                    try {
                        if (pseudoQuestions_1_1 && !pseudoQuestions_1_1.done && (_g = pseudoQuestions_1.return)) _g.call(pseudoQuestions_1);
                    }
                    finally { if (e_5) throw e_5.error; }
                    return [7 /*endfinally*/];
                case 12:
                    surveyTable_1_1 = surveyTable_1.next();
                    return [3 /*break*/, 2];
                case 13: return [3 /*break*/, 16];
                case 14:
                    e_6_1 = _j.sent();
                    e_6 = { error: e_6_1 };
                    return [3 /*break*/, 16];
                case 15:
                    try {
                        if (surveyTable_1_1 && !surveyTable_1_1.done && (_e = surveyTable_1.return)) _e.call(surveyTable_1);
                    }
                    finally { if (e_6) throw e_6.error; }
                    return [7 /*endfinally*/];
                case 16:
                    _j.trys.push([16, 21, 22, 23]);
                    _a = __values(['_lang', '_ip', '_useragent', '_hostnames', '_ipgeo', 'program_identity']), _b = _a.next();
                    _j.label = 17;
                case 17:
                    if (!!_b.done) return [3 /*break*/, 20];
                    key = _b.value;
                    return [4 /*yield*/, addInfoDef({ "Target Field": key, Question: key, "Who Can Edit": ["Admin"], "Field Type": 'Single Line Text Entry' })];
                case 18:
                    _j.sent();
                    _j.label = 19;
                case 19:
                    _b = _a.next();
                    return [3 /*break*/, 17];
                case 20: return [3 /*break*/, 23];
                case 21:
                    e_7_1 = _j.sent();
                    e_7 = { error: e_7_1 };
                    return [3 /*break*/, 23];
                case 22:
                    try {
                        if (_b && !_b.done && (_h = _a.return)) _h.call(_a);
                    }
                    finally { if (e_7) throw e_7.error; }
                    return [7 /*endfinally*/];
                case 23:
                    console.timeEnd(LOADING_SURVEY);
                    return [2 /*return*/, [infoDefs, config]];
            }
        });
    });
}
function loadInfoDefsFromSurveyDraft(survey, deploymentKey) {
    return __awaiter(this, void 0, void 0, function () {
        var v0Survey, legacySurvey, _a, infoDefs, config;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    v0Survey = JSON.parse(survey);
                    legacySurvey = (0, v0_to_legacy_1.v0ToLegacy)(v0Survey);
                    return [4 /*yield*/, buildInfoDefs(legacySurvey, deploymentKey)];
                case 1:
                    _a = __read.apply(void 0, [_b.sent(), 2]), infoDefs = _a[0], config = _a[1];
                    return [2 /*return*/, infoDefs];
            }
        });
    });
}
exports.loadInfoDefsFromSurveyDraft = loadInfoDefsFromSurveyDraft;
function loadInfoDefs(conn, deploymentKey, loadOptions) {
    return __awaiter(this, void 0, void 0, function () {
        var rawSurvey, base, _a, infoDefs, config;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getRawSurvey({ conn: conn, deploymentKey: deploymentKey })];
                case 1:
                    rawSurvey = _b.sent();
                    if (rawSurvey.isErr()) {
                        return [2 /*return*/, (0, neverthrow_1.err)(rawSurvey.error)];
                    }
                    console.time(LOADING_SURVEY);
                    base = rawSurvey.value;
                    return [4 /*yield*/, buildInfoDefs(base, deploymentKey, loadOptions)];
                case 2:
                    _a = __read.apply(void 0, [_b.sent(), 2]), infoDefs = _a[0], config = _a[1];
                    return [2 /*return*/, (0, neverthrow_1.ok)([infoDefs, config])];
            }
        });
    });
}
exports.loadInfoDefs = loadInfoDefs;
function expandQuestion(q, questionMap, choices, roles) {
    var e_12, _a, e_13, _b, e_14, _c;
    // For RS We care about Conditionals, Options, and who Can Edit
    var newQ = __assign({}, q);
    var newConditionalValues = [];
    try {
        for (var _d = __values((q[survey_constants_1.CONDITIONAL_ON_VALUE] || [])), _e = _d.next(); !_e.done; _e = _d.next()) {
            var record = _e.value;
            if (choices[record]) {
                newConditionalValues.push(choices[record]['Name']);
            }
            else {
                newConditionalValues.push(record);
            }
        }
    }
    catch (e_12_1) { e_12 = { error: e_12_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_12) throw e_12.error; }
    }
    newQ[survey_constants_1.CONDITIONAL_ON_VALUE] = __spreadArray([], __read(newConditionalValues), false);
    var actualRoles = [];
    try {
        for (var _f = __values((q['Who Can Edit'] || [])), _g = _f.next(); !_g.done; _g = _f.next()) {
            var record = _g.value;
            actualRoles.push(roles[record].Name);
        }
    }
    catch (e_13_1) { e_13 = { error: e_13_1 }; }
    finally {
        try {
            if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
        }
        finally { if (e_13) throw e_13.error; }
    }
    newQ['Who Can Edit'] = actualRoles;
    var choiceFields = [];
    try {
        for (var _h = __values((q['Options (if relevant)'] || [])), _j = _h.next(); !_j.done; _j = _h.next()) {
            var record = _j.value;
            if (choices[record]) {
                choiceFields.push(choices[record]);
            }
        }
    }
    catch (e_14_1) { e_14 = { error: e_14_1 }; }
    finally {
        try {
            if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
        }
        finally { if (e_14) throw e_14.error; }
    }
    newQ['Options (if relevant)'] = choiceFields;
    return newQ;
}
function getPseudoQuestions(question) {
    var _a;
    var pseudoQuestions = [];
    if (question['Field Type'] === 'Contract') {
        if (!question['Metadata']) {
            throw "Malformed contract";
        }
        var metadata = (0, json_1.safeParse)(question['Metadata'] || '{}');
        if ((_a = question['Additional Options']) === null || _a === void 0 ? void 0 : _a.includes('No Signatures'))
            return [];
        Object.keys(metadata['signers']).forEach(function (role) {
            var _a;
            var signerQuestion = (_a = {},
                _a[survey_constants_1.QUESTION] = question[survey_constants_1.QUESTION] + ' - ' + role,
                _a[survey_constants_1.FIELD_TYPE] = 'Contract Signer',
                _a['English Content'] = role,
                _a["Additional Options"] = question['Additional Options'],
                _a[survey_constants_1.TARGET_FIELD] = question[survey_constants_1.TARGET_FIELD] + '_' + role,
                _a);
            if (question[survey_constants_1.CONDITIONAL_ON]) {
                signerQuestion[survey_constants_1.CONDITIONAL_ON] = question[survey_constants_1.CONDITIONAL_ON];
            }
            if (question[survey_constants_1.CONDITIONAL_ON_VALUE]) {
                signerQuestion[survey_constants_1.CONDITIONAL_ON_VALUE] = question[survey_constants_1.CONDITIONAL_ON_VALUE];
            }
            pseudoQuestions.push(signerQuestion);
        });
    }
    else if (question['Field Type'] === 'Payment') {
        ['issued', 'issued_info'].forEach(function (slug) {
            var _a;
            pseudoQuestions.push((_a = {},
                _a[survey_constants_1.QUESTION] = question[survey_constants_1.QUESTION] + ' - ' + slug,
                _a[survey_constants_1.FIELD_TYPE] = 'Single Line Text Entry',
                _a['English Content'] = '--',
                _a["Additional Options"] = ["Hidden", "Optional"],
                _a["Who Can Edit"] = question['Who Can Edit'],
                _a[survey_constants_1.TARGET_FIELD] = question[survey_constants_1.TARGET_FIELD] + '_' + slug,
                _a));
        });
    }
    return pseudoQuestions;
}
console.timeEnd("Load survey loader");
var templateObject_1;

import React from "react"

export function TextInput(props: { 
  label: string,
  helptext?: string,
  value: string,
  onChange: (event: any) => void,
  required?: boolean,
  textarea?: boolean,
  placeholder?: string
}) {
  return <fieldset>
    <legend>
      <b>{props.label}</b>
      <br/>
      {props.helptext && <span className="text-sm text-gray-600 text-medium">{props.helptext}</span>}
    </legend>
    <div className="flex flex-row">
      {React.createElement(props.textarea === true ? 'textarea' : 'input', {
        className: 
          "max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md",
        value: props.value || '',
        onChange: props.onChange,
        required: props.required,
        placeholder: props.placeholder || ""
      })}
    </div>
  </fieldset>
}
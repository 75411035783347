import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { CogIcon, CheckIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { SpacedSpinner } from "./Util";
import { useToast } from "@aidkitorg/component-library";
import Papa from "papaparse";

import { get_deployment, usePost } from "./API";
import { supportedLanguages, VALID_LANGS } from "./Localization";

import { Text } from "@aidkitorg/types/lib/survey"

// This swaps the language value (English) to the key so that the new record is English: "en".
// Used for injesting CSV's with human readable headers.
const languageLabelToKey = Object.fromEntries(
  Object.entries(supportedLanguages).map(([key, label]) => [label, key])
);

export type TranslationTarget = { translation: Text; language: keyof Text };

function TranslationItem({
  translation,
  selectedLanguages,
  chatGPTTranslate,
  translationIDLoading,
  saveTextEdit
}: {
  translation: Text;
  selectedLanguages: (keyof Text)[];
  chatGPTTranslate: (translationTarget: TranslationTarget) => void;
  translationIDLoading: Record<string, boolean>;
  saveTextEdit: (target: TranslationTarget, newText: string) => void;
}) {
  const [editingItemTemp, setEditingItemTemp] = useState<Record<string, string>>({});
  const [isEditingItem, setIsEditingItem] = useState<Record<string, boolean>>({});

  return (
    <li key={translation._id} className="border-b-2 pb-2 mb-2 text-xs whitespace-pre-line">
      <div className="pb-2"><b>English:</b> {translation.en}</div>
      {selectedLanguages.map((language) => {
        if (language !== 'en') {
          return (
            <div key={language} className="pb-2">
              <button className="border-0 cursor-pointer bg-transparent p-0 m-0 text-blue-600 hover:text-blue-800" 
                onClick={() => chatGPTTranslate({translation, language})}
                title="Ask ChatGPT to translate"
                disabled={isEditingItem[language] || translationIDLoading[translation._id+language]}>
                {supportedLanguages[language]}:
              </button> 
              {
                isEditingItem[language] 
                  ? <div className="inline border-0 bg-transparent p-0 m-0 text-blue-400 hover:text-blue-800" >
                    <span title="Save changes">
                      <CheckIcon  
                        className="border-0 cursor-pointer bg-transparent p-0 m-0 text-blue-400 hover:text-blue-800 pb-1 ml-1 h-5 w-5"  
                        onClick={() => {
                          saveTextEdit({translation, language}, editingItemTemp[language] || "");
                          setIsEditingItem(prev => ({...prev, [language]: false}));
                        }}
                      />
                    </span>
                    <span title="Discard changes">
                      <TrashIcon  
                        className="border-0 cursor-pointer bg-transparent p-0 m-0 text-blue-400 hover:text-blue-800 pb-1 h-5 w-5"  
                        onClick={() => {
                          setIsEditingItem(prev => ({...prev, [language]: false}));
                        }}
                      />
                    </span>
                    <textarea
                      className="w-full"
                      value={editingItemTemp[language]}
                      onChange={(event) => {
                        setEditingItemTemp(prev => ({
                          ...prev,
                          [language]: event.target.value,
                        }))
                      }}
                    />
                  </div>
                  : <div className="inline">
                    {translationIDLoading[translation._id+language] ? <div><SpacedSpinner /></div>
                      : <>
                        <span title="Edit translation">
                          <PencilIcon
                            className="border-0 cursor-pointer bg-transparent p-0 m-0 text-blue-400 hover:text-blue-800 pb-1 h-5 w-5 ml-1" 
                            onClick={() => {
                              setEditingItemTemp(prev => ({...prev, [language]: translation[language] || ""}));
                              setIsEditingItem(prev => ({...prev, [language]: true}));
                            }}
                          />
                        </span>
                        <div >{translation[language]}</div>
                      </>}
                  </div>
              }
            </div>
          )
        }
        return null;
      })}
    </li>
  )
}

export function TranslationModal({
  translations,
  setTranslations,
  injestTranslations,
  translationModalOpen,
  setTranslationModalOpen,
  initialTranslations,
}: {
  translations: Text[];
  setTranslations: React.Dispatch<React.SetStateAction<Text[]>>;
  injestTranslations: () => void;
  translationModalOpen: boolean;
  setTranslationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialTranslations: React.MutableRefObject<string>;
}) {

  const [translationsLoading, setTranslationsLoading] = useState(false);
  const [translationIDLoading, setTranslationIDLoading] = useState<Record<string, boolean>>({})
  const [selectedLanguages, setSelectedLanguages] = useState<Array<keyof Text>>([]);
  const [showLanguages, setShowLanguages] = useState(false);
  const [overwriteExisting, setOverwriteExisting] = useState(false);
  const [translationErrors, setTranslationErrors] = useState<TranslationTarget[]>([]);
  const [countTranslated, setCountTranslated] = useState(0);
  const [countToTranslate, setCountToTranslate] = useState(0);
  const translationStopped = useRef(false);
  const { toast } = useToast();

  const doMagicV2 = usePost('/program/admin/magicV2');

  const handleClose = () => {
    // check if translations have been modified
    if (JSON.stringify(translations) !== initialTranslations.current) {
      if (window.confirm('You have unsaved translation updates. Are you sure you want to close?')) {
        setTranslationModalOpen(false);
        translationStopped.current = true;
      }
    } else {
      setTranslationModalOpen(false);
    }
  };

  useEffect(() => {
    if (translations.length > 0) {
      const keys = Object.keys(translations[0]).filter((key) => key !== '_id' && key !== 'en') as Array<keyof Text>;;
      setSelectedLanguages((prevSelected) => {
        const newSelected = [...prevSelected];
        for (const key of keys) {
          if (!newSelected.includes(key)) {
            newSelected.push(key);
          }
        }
        return newSelected;
      });
    }
  }, [initialTranslations.current]);

  function exportCSV() {
    let allKeys = Array.from(new Set(translations.flatMap(Object.keys)))
      .filter(key => key in supportedLanguages && key !== 'en' && key !== '_id')
      .sort();
    
    let csv = '"_id","English",' + allKeys.map(key => `"${supportedLanguages[key as keyof Text]}"`).join(',') + '\n';
    
    translations.forEach((row) => {
      csv += `"${row._id!.replace(/"/g, '""')}",` + `"${row.en.replace(/"/g, '""')}",`;
      allKeys.forEach((key, i) => {
        csv += (key in row ? `"${row[key as keyof Text]!.replace(/"/g, '""')}"` : '""') + (i < allKeys.length - 1 ? ',' : '');
      });
      csv += '\n';
    });
    
    // Create a blob and download
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    
    let a = document.createElement('a');
    a.href = url;
    a.download = `${get_deployment()}-translations.csv`;
    a.click();
  }

  const importCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }
    
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          alert('Failed to read file');
          return;
        }
    
        const records = (results.data as Record<string, string>[]).map((row) => {
          // Filter csv for headers that either match '_id' or exist in languageLabelToKey
          const header = Object.keys(row).filter(label => label === '_id' || languageLabelToKey[label]);
          let obj = {} as Text;
          header.forEach((key) => {
            const targetKey = key === '_id' ? '_id' : languageLabelToKey[key];
            if(row[key]) { // Only assign if it's not empty
              obj[targetKey as keyof Text] = row[key];
            }
          });
          return obj;
        });
    
        // Merge the new translations into the existing ones
        setTranslations(prevState => {
          const mergedTranslations = [...prevState];
          records.forEach(record => {
            const existingIndex = prevState.findIndex(t => t._id === record._id);
            if (existingIndex !== -1) {
              mergedTranslations[existingIndex] = { ...prevState[existingIndex], ...record };
            }
          });
          return mergedTranslations;
        });
    
        toast({
          description: "Translations imported successfully! Inject them into Distro and save.",
          variant: 'success'
        })
      }
    });
  };

  async function chatGPTTranslate(translationTarget: TranslationTarget) {
    const { translation, language } = translationTarget;
    let newTranslation = { ...translation };
    setTranslationIDLoading((prev) => ({
      ...prev,
      [translation._id + language]: true,
    }));
    setTranslationErrors((prev) =>
      prev.filter((error) => !(error.translation._id === translation._id && error.language === language))
    );

    const translation_prompt = 
            `Please complete translating the following English markdown text into ${supportedLanguages[language]}. Match the tone and formatting of the English text.
            Words that begin with $ are placeholders, they should be moved to the appropriate place in the translation but not translated.
            If ${supportedLanguages[language]} uses T-V distinction, use the more formal distinction when addressing the person.
            Reply with ONLY the translation (i.e. english Yes should be responded with Spanish Sí) and nothing else, and do not repeat yourself or glitch out.
            If something cannot be translated, keep the original text, do not insert any $ placeholders unless they are in the original text.
            When you are done, end the messgae with STOP
            The text will be wrapped by characters ===, return the response without any wrapping
            The text to translate is:
            ===${translation.en}===`
    
    try {
      const response = await doMagicV2({
        model: "gpt-4o", 
        message: translation_prompt,
        max_tokens: 1000,
        stop: "STOP",
        temperature: 0,
      });
        
      if (response.choices[0]?.message?.content) {
        setTranslations((prevTranslations: Text[]) => {
          return prevTranslations.map((t: Text) => {
            if (t._id === newTranslation._id) {
              t[language] = (response.choices[0]?.message?.content || '').replace(/(^===)|(===$)/g,'')
              return t;
            } else {
              return t;
            }
          });
        });
      } else {
        console.warn('Response content missing for /magicV2 endpoint:', response);
        setTranslationErrors(prevErrors => [
          { translation, language },
          ...prevErrors,
        ]);
      }
    } catch (error) {
      console.error(`Error calling /magicV2 endpoint:`, error);
      setTranslationErrors(prevErrors => [
        { translation, language },
        ...prevErrors,
      ]);
    }
    setTranslationIDLoading((prev) => ({
      ...prev,
      [translation._id + language]: false,
    }));
    setCountTranslated((prev) => prev + 1);
  };

  async function translateAll(toTranslate?: TranslationTarget[]) {
    translationStopped.current = false;
    setTranslationsLoading(true);
    setTranslationErrors([]);

    const workerPoolSize = 5;
    let activeWorkers = 0;
    let tasks: TranslationTarget[] = [];

        
    if (toTranslate) {
      // If translating a subset: only translate to the requested language and always overwrite.
      for (const translation of toTranslate) {
        tasks.push(translation)
      }
    } else {
      // translate to all selected languages, only overwrite if selected.
      for (let i = 0; i < translations.length; i++) {
        for (const language of selectedLanguages) {
          if (!translations[i][language] || overwriteExisting) {
            tasks.push({ translation: translations[i], language });
          }
        }
      }
    }

    setCountToTranslate(tasks.length);
    setCountTranslated(0);
      
    // Run the tasks
    while (tasks.length > 0 && !translationStopped.current) {
      if (activeWorkers < workerPoolSize) {
        const task = tasks.shift();
        if (task) {
          activeWorkers++;
          chatGPTTranslate({translation: task.translation, language: task.language}).finally(() => activeWorkers--);
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
      
    while (activeWorkers > 0) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
      
    setTranslationsLoading(false);
    
    toast({
      description: "Translations Completed! Verify the output below or Export CSV.",
      variant: 'success'
    })
  }

  function injest() {
    injestTranslations();
    setTranslationModalOpen(false);
  }

  const saveTextEdit = (target: TranslationTarget, newText: string) => {
    setTranslations((prevTranslations: Text[]) => {
      return prevTranslations.map((t: Text) => {
        if (t._id === target.translation._id) {
          t[target.language] = newText;
          return t;
        } else {
          return t;
        }
      });
    });
  };

  return (
    <Modal  show={translationModalOpen} 
      onHide={handleClose} 
      size="xl"
      className="p-4 rounded-lg">
      <Modal.Header closeButton className="border-b w-full pb-0">
        <Modal.Title className="text-xl font-semibold w-full">
          <div className="flex items-center w-full mb-10">
            Manage Translations
            <button className="border-0 bg-transparent p-2 pb-0 mb-0 text-blue-600 hover:text-blue-800 ml-auto text-sm" onClick={injest}>
              Inject Translations into Distro
            </button>
          </div>
          <div className="flex items-center w-full">
            {translationsLoading ? <button 
              onClick={() => {(translationStopped.current = true); setTranslationsLoading(false)}}
              className="border-0 bg-transparent p-2 pl-0 pb-0 mb-0 text-blue-600 hover:text-blue-800 text-sm">
              Stop ChatGPT <SpacedSpinner />
            </button> : <button 
              onClick={() => translateAll()}
              className="border-0 bg-transparent p-2 pl-0 pb-0 mb-0 text-blue-600 hover:text-blue-800 text-sm">
              ChatGPT Translate
            </button>}
            <input
              type="checkbox"
              id="hide-diff"
              className="form-checkbox h-3 w-3 cursor-pointer"
              checked={overwriteExisting}
              onChange={() => setOverwriteExisting(!overwriteExisting)}
            />
            <label htmlFor="hide-diff" className="ml-1 text-sm mb-0 cursor-pointer">
              Overwrite Existing
            </label>
            <button className="border-0 bg-transparent p-2 pb-0 mb-0 text-blue-600 hover:text-blue-800 ml-auto text-sm" onClick={exportCSV}>
              Export CSV
            </button><span className="text-gray-500 pb-1 text-lg">|</span>
            <input 
              type="file" 
              id="file" 
              onChange={importCSV} 
              accept=".csv" 
              style={{display: 'none'}}
            />
            <button 
              className="border-0 bg-transparent p-2 pb-0 mb-0 text-blue-600 hover:text-blue-800 text-sm"
              onClick={() => document.getElementById('file')?.click()}
            >
              Import CSV
            </button>
          </div>
          {translationsLoading && <div className="text-xs">{countTranslated} of {countToTranslate} translated: {translationErrors.length} errors.</div>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-gray-100 p-2 mb-4 text-xs" >
          <div className="flex items-center">
            <span className="font-bold">Working Languages:&nbsp;</span>{selectedLanguages.map(lang => supportedLanguages[lang]).join(', ')}
            <button className="border-0 bg-transparent p-2 pb-0 mb-0 text-blue-600 hover:text-blue-800 ml-auto text-sm">
              <CogIcon className="h-5 w-5" onClick={() => setShowLanguages(!showLanguages)}/>
            </button>
          </div>
          {showLanguages && <div>
            <p></p>
            <p>Add/Remove languages to control what gets displayed here and sent to ChatGPT for translation</p>
            {Object.entries(supportedLanguages).map(([languageCode, languageName]) => (
              languageCode != 'en' ? 
                <div key={languageCode} className="flex items-center space-x-2 mb-1">
                  <input 
                    type="checkbox" 
                    value={languageCode}
                    checked={selectedLanguages.includes(languageCode as keyof Text)}
                    onChange={(event: { target: { checked: any; value: any } }) => {
                      const { checked, value } = event.target;
                      setSelectedLanguages(prev =>
                        checked ?
                          [...prev, value] :
                          prev.filter(languageCode => languageCode !== value)
                      );
                    }}
                    className="form-checkbox h-4 w-4 text-blue-500"
                  />
                  <label className="text-gray-700 mb-0">{languageName}</label>
                </div> : null
            ))}
          </div>}
        </div>
        {(Boolean(translationErrors.length) && (!translationsLoading)) && <div className="w-full bg-red-100 p-2 mb-4 text-xs">
          <div className="flex items-center">
            <div className="font-bold">There were errors translating the following phrases: Please check them manually via the csv export or re translate them.</div>
            <button className="border-0 bg-transparent p-2 pb-0 mb-0 text-blue-600 hover:text-blue-800 ml-auto text-sm" onClick={() => setTranslationErrors([])}>
              Clear Errors
            </button>
            <button className="border-0 bg-transparent p-2 pb-0 mb-0 text-blue-600 hover:text-blue-800 ml-auto text-sm" onClick={() => translateAll(translationErrors)}>
              Retry Errors
            </button>
          </div>
          {translationErrors.map((error) => <div>
            <p className="pb-0 mb-0"><span className="font-bold">_id: </span>{error.translation._id}</p>
            <p className="pb-0 mb-0"><span className="font-bold">Target Language: </span>{supportedLanguages[error.language]}</p>
            <p><span className="font-bold">English source: </span>{error.translation.en}</p>
          </div>)}
        </div>}
        <ul>
          {translations.map(item => (
            <TranslationItem 
              key={item._id} 
              translation={item} 
              selectedLanguages={selectedLanguages}
              chatGPTTranslate={chatGPTTranslate}
              translationIDLoading={translationIDLoading}
              saveTextEdit={saveTextEdit}
            />
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  )
}

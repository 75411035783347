"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unionSets = exports.intersectSets = void 0;
/** Takes two arguments,
 *  @set1 is the first set, which is being filled or is having entities deleted.
 *  @set2 is the second set which wants to intersect with set1.
 */
function intersectSets(set1, set2, fillIfEmpty) {
    var e_1, _a, e_2, _b;
    if (set1.size > 0) {
        var toDelete_2 = [];
        set1.forEach(function (m) {
            if (!set2.has(m)) {
                toDelete_2.push(m);
            }
        });
        try {
            for (var toDelete_1 = __values(toDelete_2), toDelete_1_1 = toDelete_1.next(); !toDelete_1_1.done; toDelete_1_1 = toDelete_1.next()) {
                var m = toDelete_1_1.value;
                set1.delete(m);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (toDelete_1_1 && !toDelete_1_1.done && (_a = toDelete_1.return)) _a.call(toDelete_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    else if (fillIfEmpty) {
        try {
            for (var _c = __values(__spreadArray([], __read(set2), false)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var m = _d.value;
                set1.add(m);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    return set1;
}
exports.intersectSets = intersectSets;
/** Simple union, add all items from set2 to set1. */
function unionSets(set1, set2) {
    var e_3, _a;
    try {
        for (var _b = __values(__spreadArray([], __read(set2), false)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var m = _c.value;
            set1.add(m);
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
}
exports.unionSets = unionSets;

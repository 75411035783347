"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankRoutingNumber = void 0;
console.time("Load Bank Routing Number");
var neverthrow_1 = require("neverthrow");
var client_dynamodb_1 = require("@aws-sdk/client-dynamodb");
exports.BankRoutingNumber = {
    getSlugs: function (params) {
        var slugs = ['_bankname'];
        var dependencies = [];
        return (0, neverthrow_1.ok)({ slugs: slugs, dependencies: dependencies });
    },
    compute: function (params) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var key, number, invalid, sum, result, bankName;
            var _d, _e;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        key = params.targetField;
                        number = params.newInfoKeys[key.replace('_bankname', '')];
                        if (!number)
                            return [2 /*return*/, (0, neverthrow_1.ok)((_d = {}, _d[key] = '', _d))];
                        if (number.length !== 9)
                            invalid = true;
                        sum = parseInt(number.split('').reduce(function (prev, cur, i) {
                            var x = parseInt(cur) * [3, 7, 1][i % 3];
                            return (parseInt(prev || '0') + x).toString();
                        }));
                        if (sum % 10 !== 0)
                            invalid = true;
                        if (invalid)
                            return [2 /*return*/, (0, neverthrow_1.err)('invalid_routing_number')];
                        return [4 /*yield*/, params.dynamo.send(new client_dynamodb_1.GetItemCommand({
                                TableName: 'global.bankRouting',
                                Key: {
                                    aba: {
                                        S: number
                                    }
                                }
                            }))];
                    case 1:
                        result = _f.sent();
                        bankName = (_c = (_b = (_a = result.Item) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.S) !== null && _c !== void 0 ? _c : '';
                        if (bankName) {
                            return [2 /*return*/, (0, neverthrow_1.ok)((_e = {}, _e[key] = bankName, _e))];
                        }
                        return [2 /*return*/, (0, neverthrow_1.err)('bankname_not_found')];
                }
            });
        });
    }
};
console.timeEnd("Load Bank Routing Number");

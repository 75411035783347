import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { get_deployment, usePost } from "./API";
import { ClickableButton } from "./Components/Button";
import { SupportedLanguage } from "./Context";
import { useLocalizedStrings } from "./Localization";
import { SpacedSpinner } from "./Util";
  
export default function FieldStatsPage(props: any) {
  const L = useLocalizedStrings();
  const location = useLocation();
  const history = useHistory();

  const [fieldType, setFieldType] = useState('');

  const givens = [
    'Notification',
    'Payment',
    'Single Line Text Entry',
    'Confirmation',
    'Inline Signature'
  ] as const;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setFieldType(params.get('field_type') || '');
  }, [location.search]);

  const getFieldStats = usePost("/program/admin/field_stats");

  const [filters, setFilters] = useState({
    ...(['chicagorcp','dev','postgres'].includes(get_deployment()) ? { 
      'treatment': 'yes'
    } : {})
  } as Record<string, string>);
  const fetching = useRef('');

  const [fetchedType, setFetchedType] = useState('');

  const [keyResults, setKeyResults] = useState([] as { 
    key: string,
    time_region: string,
    count: string
  }[]);

  const [fields, setFields] = useState([] as {
    target_field?: string,
    name: string,
    content: Record<SupportedLanguage, string>,
    metadata?: string
  }[]);

  const [queryTime, setQueryTime] = useState(undefined as number | undefined);

  const daysSinceCreated = [5, 10, 15, 20, 30, 60, 120, 365] as const;
  useEffect(() => {
    if (!fieldType) return;
    if (fetching.current || fetchedType === fieldType) return;
    (async () => { 
      if (fetching.current || fetchedType === fieldType) return;
      fetching.current = fieldType
      setFetchedType(fieldType);
            
      const response = await getFieldStats({ 
        fieldType, 
        filters,
        daysSinceCreated: [...daysSinceCreated]
      });
      fetching.current = '';

      if ((response as any).value) {
        setFields((prevState) => prevState.filter(() => false).concat((response as any).value.fields));

        const results = (response as any).value.result;
        setKeyResults((prevState) => prevState.filter(() => false).concat(results));

        setQueryTime((response as any).value.queryTime);
      } else {
        console.error("Error in response:", response);
      }
    })();
  }, [fieldType, filters]);

  return (
    <div>
      {givens.map((given) => (
        <><ClickableButton extraClasses="text-white bg-indigo-600"
          color="indigo"
          disabled={fieldType === given}
          onClick={() => { 
            setFieldType(given);
            history.replace(location.pathname + "?field_type=" + given);
          }}>
          {fetching.current === given && <SpacedSpinner />}{given}
        </ClickableButton>&nbsp;</>
      ))}
      <hr/>
      <h2 className="text-lg leading-6 font-medium text-gray-900">{fieldType ? fieldType + " Counts" : 'Select a Field Type'}</h2>
      {queryTime && <p className="text-xs text-gray-600">Stats gathered in {queryTime} ms</p>}
      {keyResults && queryTime && daysSinceCreated.map((days, idx) => {
        let timeRegion = idx === 0 ? 'Last ' + days + ' days' : 'Between ' + daysSinceCreated[idx-1] + ' and ' + days + ' days ago';

        const results = keyResults.filter((keyResult) => keyResult.time_region === timeRegion && parseInt(keyResult.count + '' || '0') > 0);
        if (results.length === 0) return <></>

        return (
          <>
            <hr/>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{timeRegion}</h3>
            <dl className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-3">
              {keyResults.filter((keyResult) => keyResult.time_region === timeRegion && parseInt(keyResult.count + '' || '0') > 0)
                .map((keyResult) => {
                  const field = fields.find((field) => field.target_field === keyResult.key);
                  if (!field) return <></>
                  return <div key={keyResult.key + '-' + timeRegion} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                    <h3 className="text-sm font-medium text-gray-500">{field.name}</h3>
                    <p className="text-sm font-normal text-gray-500">{keyResult.key}</p>
                    <p className="mt-2 text-xs font-normal text-black-900">{field.content.en}</p>
                    <p className="mt-2 text-3xl font-medium text-gray-900">{keyResult.count}</p>
                  </div>
                })}
            </dl>
          </>
        )
      })}
    </div>
  )
}
/**
 *  This flattens the objects to a list of pairs of (path-to-value, value)
 *  const obj = {
 *    a: 1,
 *    b: {
 *      c: 2,
 *      h: null,
 *      i: [5, { j: 6, k: 7 }, 8]
 *    }
 *  };
 *
 *  Becomes:
 *
 *  {
 *    "a": 1,
 *    "b_c": 2,
 *    "b_h": null,
 *    "b_i_0": 5,
 *    "b_i_1_j": 6,
 *    "b_i_1_k": 7,
 *    "b_i_2": 8
 *  }
 */
export function flattenObject(obj: Object, out?: Record<string, any>, path?: string) {
  if (out === undefined) {
    out = {};
  }

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      flattenObject(item, out, (path ? path + '_' : '') + index);
    });
    return;
  }

  if (obj === null || typeof obj !== 'object') {
    if (path) {
      out[path] = obj;
    }
    return;
  }

  const keys = Object.keys(obj) as Array<keyof typeof obj>;

  if (keys.length === 0 && path) {
    out[path] = obj;
    return;
  }

  for (const key of keys) {
    flattenObject(obj[key], out, (path ? path + '_' : '') + key.replaceAll(' ', '_'));
  }

  return out;
}
"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.wrappedFn = void 0;
var mock_date_1 = require("../util/mock_date");
/** wrappedFn, used by evalConditional, and the 3 core Function()s. */
function wrappedFn(fnStr, safeReturn, params) {
    var fn;
    var result;
    var error;
    try {
        fn = Function(fnStr)();
    }
    catch (e) {
        console.error("wrappedFn parse error: ".concat(fnStr));
        return safeReturn;
    }
    // mockDate can come after the function is created. 
    // new Date() won't be computed until the fn runs, 
    // and the proxy will overwrite it.
    if (params === null || params === void 0 ? void 0 : params.mockDate)
        (0, mock_date_1.mockDate)(params.mockDate);
    try {
        result = fn.apply(void 0, __spreadArray([], __read((params === null || params === void 0 ? void 0 : params.args) || []), false));
    }
    catch (e) {
        (0, mock_date_1.unmockDate)();
        // Console log must come AFTER unmock or we may get weird log timestamps.
        console.log('wrappedFn compute error:', e);
        result = '';
        error = e instanceof Error ? e.message : e;
    }
    finally {
        (0, mock_date_1.unmockDate)();
    }
    return { result: result, error: error };
}
exports.wrappedFn = wrappedFn;

import { offlineLogger } from ".";

export function headersToObject(headers: Headers) {
  let obj: any = {};
  for (let [key, value] of Array.from(headers.entries())) {
    // Check if the key already exists in our object
    if (obj[key]) {
      // If it does, and the existing value is not an array, make it an array
      if (!Array.isArray(obj[key])) {
        obj[key] = [obj[key]];
      }
      obj[key].push(value);
    } else {
      obj[key] = value;
    }
  }
  return obj;
}

export async function sleep(waitForMillis: number) {
  return new Promise(resolve => setTimeout(resolve, waitForMillis));
}

export async function bottleneck(promises: (() => Promise<any>)[], maxConcurrent: number): Promise<PromiseSettledResult<any>[]> {
  if (promises.length === 0) {
    return [];
  }
  let completed = await Promise.allSettled(promises.splice(0, maxConcurrent).map(p => p()));
  completed = completed.concat(await bottleneck(promises, maxConcurrent));
  return completed;
}

export async function copy(req: Request, replace?: RequestInit & { url?: URL, modifyUrl?: (url: URL | string) => URL | string }) {
  const headers = headersToObject(req.headers);
  const url = (replace?.url || req.url);
  const init: RequestInit = {
    method: req.method,
    headers,
    body: await req.clone().arrayBuffer(),
    mode: req.mode,
    cache: req.cache,
    credentials: req.credentials,
    redirect: req.redirect,
    referrer: req.referrer,
    integrity: req.integrity,
    keepalive: req.keepalive,
    signal: req.signal,
    referrerPolicy: req.referrerPolicy
  };
  if (replace) {
    Object.assign(init, replace);
  }
  return new Request((replace?.modifyUrl ? replace?.modifyUrl(url) : url).toString(), init);
}

export const success = (p: Promise<any>) => p.then(() => true).catch(() => false);

export async function getToken() {
  const value = (await (globalThis as any)?.cookieStore?.get('auth_token'))?.value
  if(!value) {
    return value;
  }
  return decodeURIComponent(value);
}

export async function getLang() {
  return (await (globalThis as any)?.cookieStore?.get('lang'))?.value as string ?? navigator.language ?? 'en';
}

/**
 * ensures regex used for service worker routes are safe and explicit
 */
export function safeHttpRegex(str: string | RegExp) {
  if(str instanceof RegExp) {
    str = str.source;
  }
  return new RegExp(`https*:\/\/[a-zA-Z0-9_\\-\\.:]*(?:\/api)*${str}`);
}

import { safeParse } from '../Util';
import { useContext, useEffect, useState } from 'react';
import { usePost } from '../API';
import { QuestionProps } from './Props';
import { ApplicantTable, RelatedApplicants } from '@aidkitorg/types/lib/survey';
import InterfaceContext from '../Context';
import { useLocalizedStrings } from '../Localization';
import { DistroDashboardTable } from '../DistroDashboard/DistroDashboardTable';
import { useToast } from "@aidkitorg/component-library";

export default function RelatedApplicantsQuestion(props: QuestionProps) {

  const metadata = safeParse(props["Metadata"]!) as RelatedApplicants;
  const { lang } = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const { toast } = useToast();

  const { uid } = props;
  const findRelatedApplicants = usePost('/applicant/find_related');
  const [related, setRelated] = useState<{ uid: string, name: string }[]>([]);

  useEffect(() => {
    (async () => {
      const r = await findRelatedApplicants({ uid: uid!, keys: metadata.byKeys });
      if('error' in r) {
        toast({
          description: "Could not search for related applicants",
          variant: 'error'
        });
      } else {
        setRelated(r);
      }
    })()
  }, []);

  return <DistroDashboardTable 
    title={metadata.title?.[lang]}
    records={related}
    component={{
      hideViewButton: !metadata.showView,
    } as ApplicantTable}
  />
}

import { PaymentModule, Collection, Computed, When } from "../../survey";

export const generatePaymentComputations = (config: PaymentModule): Collection => {
  return {
    "kind": "Collection",
    "name": {
      "en": "Computations"
    },
    "options": {},
    "components": [
      {
        "kind": "Section",
        "title": {
          "en": "Computations"
        },
        "components": [
          {
            "kind": "Computed",
            "targetField": "program_name",
            "content": {
              "en": "Program Name"
            },
            "formula": {
              "kind": "StringValue",
              "value": config.program
            }
          },
          {
            "kind": "Computed",
            "targetField": "support_phone",
            "content": {
              "en": "Support Phone Number"
            },
            "formula": {
              "kind": "StringValue",
              "value": "Insert Support Number Here"
            }
          },
          {
            "kind": "Computed",
            "targetField": "support_email",
            "content": {
              "en": "Support Email"
            },
            "formula": {
              "kind": "StringValue",
              "value": "Insert Support Email Here"
            }
          },
          {
            "kind": "Computed",
            "targetField": "grant_amount",
            "content": {
              "en": "Grant Amount"
            },
            "formula": {
              "kind": "StringValue",
              "value": config.frequency.paymentAmount.kind === 'fixed' ? config.frequency.paymentAmount.amount.toString() : ""
            }
          },
          {
            "kind": "Computed",
            "targetField": "incentive_amount",
            "content": {
              "en": "Incentive Amount"
            },
            "formula": {
              "kind": "StringValue",
              "value": ""
            }
          },
          ...[generatePaymentStatusComputation(config)]
        ]
      }
    ]
  };
};

const generatePaymentStatusComputation = (config: PaymentModule): Computed => {
  return {
    "kind": "Computed",
    "targetField": "payment_status",
    "content": {
      "en": "Status of Applicant's Payment",
    },
    "formula": {
      "kind": "When",
      "when": [
        ...(config.frequency.kind === 'One Time Payment') ? [{
          "then": {
            "kind": "StringValue",
            "value": "grant_payment_paid"
          },
          "cond": {
            "field": "grant_payment",
            "kind": "Exists"
          }
        }] : [],
        ...(config.frequency.kind === 'Recurring Payment') ? Array.from({ length: config.frequency.numberOfPayments }, (_, index) => (
          {
            "then": {
              "kind": "StringValue",
              "value": `payment_${index + 1}_paid`
            },
            "cond": {
              "field": `payment_${index + 1}`,
              "kind": "Exists"
            }
          }
        )) : [],
        {
          "cond": {
            "field": "payment_group",
            "kind": "Exists"
          },
          "then": {
            "kind": "StringValue",
            "value": "Payment Group Assigned - Pending Payment"
          }
        },
        {
          "cond": {
            "kind": "And",
            "clauses": [
              {
                "field": "review_status",
                "kind": "Equals",
                "value": "approved"
              },
              {
                "field": "payment_group",
                "kind": "DoesntExist"
              }
            ]
          },
          "then": {
            "kind": "StringValue",
            "value": "awaiting_payment_group_assignment"
          }
        }
      ],
      "otherwise": {
        "kind": "StringValue",
        "value": "not_applicable"
      }
    } as When
  }
};
import { NumberFields } from "@aidkitorg/types/lib/survey";

export function stringToColor(str: string) {
  str = str || '';
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = ((hash << 5) ^ (hash >> 2) ^ str.charCodeAt(i)) + Math.pow(str.charCodeAt(i), 2);
    hash = Math.abs(hash * 997 + str.charCodeAt(i)) % 9941;
  }
  var color = 'hsl(' + (hash % 360) + ', 60%, 70%)';
  return color;
}

export function getFormattedNumber(value: number | string | undefined, format: NumberFields = {}) {
  if (value == null || value === '') return '';

  const num = typeof value === 'number' ? value : parseFloat(value);
  if (isNaN(num)) return '';

  const { decimalPlaces = 2, prefix = '', suffix = '', useCommas = false } = format;
  const formattedValue = useCommas
    ? new Intl.NumberFormat('en-US', { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces }).format(num)
    : num.toFixed(decimalPlaces);

  return `${prefix}${formattedValue}${suffix}`;
}

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import React from 'react';

export function ValidatorMessage(props: { message: string }) { 
  return <div className="flex flex-row py-auto mt-1 flex-wrap max-w-400">
    <ExclamationTriangleIcon className="text-red-300 h-7 w-7 my-auto" />
    <span className="text-gray-800 my-1 ml-1">
      {props.message}
    </span>
  </div>
}
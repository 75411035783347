"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeStage = exports.questionConditionalMet = void 0;
console.time("Load stage");
var json_1 = require("../util/json");
var util_1 = require("./util");
/** Returns true based on conditional is met or does not exist,
 * returns false if conditional is present and not met */
function questionConditionalMet(def, infoKeys, orgMetadata, screenerMetadata, options) {
    return __awaiter(this, void 0, void 0, function () {
        var metadata, result, conditionalOn, conditionalOnValue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(def["Field Type"] !== "Validated" && def['Metadata'])) return [3 /*break*/, 2];
                    metadata = (0, json_1.safeParse)(def['Metadata'] || '{}');
                    if (!metadata.conditional) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, util_1.evalConditional)(metadata.conditional, infoKeys, orgMetadata, screenerMetadata, options)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result];
                case 2:
                    conditionalOn = def["Conditional On"];
                    if (!conditionalOn)
                        return [2 /*return*/, true];
                    conditionalOnValue = def["Conditional On Value"];
                    if ((!conditionalOnValue || conditionalOnValue.length === 0) && !infoKeys[conditionalOn])
                        return [2 /*return*/, false];
                    if ((conditionalOnValue && conditionalOnValue.length > 0) && conditionalOnValue.indexOf(infoKeys[conditionalOn] || '!!do_not_set_a_value_to_this!!') < 0) {
                        return [2 /*return*/, false];
                    }
                    return [2 /*return*/, true];
            }
        });
    });
}
exports.questionConditionalMet = questionConditionalMet;
/** Automated in the sense that they are not required to advance a stage */
var AUTOMATED_QUESTIONS = ['Flag', 'Flag Review', 'Correction Request', 'Show Field', 'Show Date', 'Notification'];
/** Stage progression is based on whether all required keys [in a stage] are satisfied */
function computeStage(infoKeys, infoDefs, orgMetadata, screenerMetadata, options) {
    return __awaiter(this, void 0, void 0, function () {
        var state, missingInfo, missingInfoStages, addMissingInfo, checkDisqualifyingOptions, skipQuestion, compute, currentStages, stage;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    state = { isIneligible: false, isUnreachable: false, isOptedOut: false, currentStage: '' };
                    missingInfo = {};
                    missingInfoStages = [];
                    addMissingInfo = function (stage, targetField) {
                        if (!missingInfo[stage])
                            missingInfo[stage] = new Set();
                        missingInfo[stage].add(targetField);
                        if (missingInfoStages.indexOf(stage) < 0) {
                            missingInfoStages.push(stage);
                        }
                    };
                    checkDisqualifyingOptions = function (question) {
                        var e_1, _a;
                        var key = question["Target Field"];
                        try {
                            for (var _b = __values((question["Options (if relevant)"] || [])), _c = _b.next(); !_c.done; _c = _b.next()) {
                                var option = _c.value;
                                if (infoKeys[key] === option['Name']) {
                                    if (option['Name'] === 'other' || option['Other Field']) {
                                        var otherInfo = infoKeys[key + "_" + option['Name']];
                                        if (otherInfo && otherInfo.length > 0) {
                                            return; // Reqs satisfied for this question
                                        }
                                        addMissingInfo(state['currentStage'], key);
                                    }
                                    if (option['Terminates Application']) {
                                        state.isIneligible = true;
                                    }
                                    if (option['Marks Unreachable']) {
                                        state.isUnreachable = true;
                                    }
                                    if (option['Marks Opted Out']) {
                                        state.isOptedOut = true;
                                    }
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                    };
                    skipQuestion = function (question) { return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, questionConditionalMet(question, infoKeys, orgMetadata, screenerMetadata, options)];
                                case 1:
                                    // Skip requiring a conditional question if conditions are not met
                                    if (!(_a.sent())) {
                                        return [2 /*return*/, true];
                                    }
                                    if (AUTOMATED_QUESTIONS.indexOf(question["Field Type"]) !== -1) {
                                        return [2 /*return*/, true];
                                    }
                                    if ((question["Additional Options"] || []).indexOf('Optional') !== -1) {
                                        // it may be optional but it may also include a terminating answer, so check that
                                        if (['Single Select', 'Multiple Select'].indexOf(question["Field Type"]) !== -1) {
                                            checkDisqualifyingOptions(question);
                                        }
                                        return [2 /*return*/, true];
                                    }
                                    // Skip card management req if we have a target field and card id
                                    if (question["Field Type"] === 'Card Management' && infoKeys[question["Target Field"] + "_id"]) {
                                        return [2 /*return*/, true];
                                    }
                                    if (!(question['Section'] && (question.Section["Conditional On"] || question.Section["Metadata"]))) return [3 /*break*/, 3];
                                    return [4 /*yield*/, questionConditionalMet(question.Section, infoKeys, orgMetadata, screenerMetadata)];
                                case 2:
                                    if (!(_a.sent())) {
                                        return [2 /*return*/, true];
                                    }
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); };
                    compute = function () { return __awaiter(_this, void 0, void 0, function () {
                        var key, question, _a, _b, stage_1, _c, _d, question, key, e_2_1, e_3_1, stage_2;
                        var e_3, _e, e_2, _f;
                        return __generator(this, function (_g) {
                            switch (_g.label) {
                                case 0:
                                    for (key in infoDefs) {
                                        question = infoDefs[key];
                                        if (question.type === 'Correction Request' && (infoKeys[key] || '').length > 0) {
                                            return [2 /*return*/, ["Correction Requested"]];
                                        }
                                        // an unresolved payment failure exists in the new Custom Handling flow. 
                                        // stage should be Correction Requested to block future payments for this applicant
                                        if (key.endsWith('_unresolved_failure') && infoKeys[key] === 'yes') {
                                            return [2 /*return*/, ["Correction Requested"]];
                                        }
                                    }
                                    _g.label = 1;
                                case 1:
                                    _g.trys.push([1, 13, 14, 15]);
                                    _a = __values(Object.values(infoDefs).filter(function (d) { return d.type === 'Stage'; }).sort(function (a, b) {
                                        return a.sequence - b.sequence;
                                    })), _b = _a.next();
                                    _g.label = 2;
                                case 2:
                                    if (!!_b.done) return [3 /*break*/, 12];
                                    stage_1 = _b.value;
                                    _g.label = 3;
                                case 3:
                                    _g.trys.push([3, 8, 9, 10]);
                                    _c = (e_2 = void 0, __values((stage_1.requirements || []))), _d = _c.next();
                                    _g.label = 4;
                                case 4:
                                    if (!!_d.done) return [3 /*break*/, 7];
                                    question = _d.value;
                                    return [4 /*yield*/, skipQuestion(question)];
                                case 5:
                                    if (_g.sent()) {
                                        //if (question["Field Type"] === 'Payment' && (question.Question || '').indexOf("First") !== -1) {
                                        //    console.log("Skipping question: ", question.Question)
                                        //}
                                        return [3 /*break*/, 6];
                                    }
                                    key = question["Target Field"];
                                    if (!key)
                                        return [3 /*break*/, 6];
                                    if (question["Field Type"] === 'Validated' && infoKeys[key + "_error"]) {
                                        if ((question["Additional Options"] || []).indexOf('Marks Ineligible') !== -1) {
                                            return [2 /*return*/, ['Ineligible']];
                                        }
                                    }
                                    if (key in infoKeys) {
                                        // Just run this, it will return quickly if there are no options for this question
                                        checkDisqualifyingOptions(question);
                                        if ((infoKeys[key] || '').length === 0) {
                                            addMissingInfo(state.currentStage, key);
                                        }
                                        if (state.isIneligible)
                                            return [2 /*return*/, ["Ineligible"]];
                                        if (state.isOptedOut)
                                            return [2 /*return*/, ["Opted Out"]];
                                        if (state.isUnreachable)
                                            return [2 /*return*/, ["Unreachable"]];
                                    }
                                    else {
                                        addMissingInfo(state.currentStage, key);
                                    }
                                    if ((question["Field Type"] === 'Contract Signer' && infoKeys[key] !== 'signed') ||
                                        (question["Field Type"] === 'Contract Stale' && infoKeys[key] === 'stale')) {
                                        addMissingInfo(state.currentStage, key);
                                    }
                                    if (question["Field Type"] === 'Correction Resolver' && key in infoKeys) {
                                        addMissingInfo(state.currentStage, key);
                                    }
                                    _g.label = 6;
                                case 6:
                                    _d = _c.next();
                                    return [3 /*break*/, 4];
                                case 7: return [3 /*break*/, 10];
                                case 8:
                                    e_2_1 = _g.sent();
                                    e_2 = { error: e_2_1 };
                                    return [3 /*break*/, 10];
                                case 9:
                                    try {
                                        if (_d && !_d.done && (_f = _c.return)) _f.call(_c);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                    return [7 /*endfinally*/];
                                case 10:
                                    // console.log("Updating currentStage state to: " + stage.name);
                                    state.currentStage = stage_1.name || stage_1.key;
                                    _g.label = 11;
                                case 11:
                                    _b = _a.next();
                                    return [3 /*break*/, 2];
                                case 12: return [3 /*break*/, 15];
                                case 13:
                                    e_3_1 = _g.sent();
                                    e_3 = { error: e_3_1 };
                                    return [3 /*break*/, 15];
                                case 14:
                                    try {
                                        if (_b && !_b.done && (_e = _a.return)) _e.call(_a);
                                    }
                                    finally { if (e_3) throw e_3.error; }
                                    return [7 /*endfinally*/];
                                case 15:
                                    // TODO: Print missing info to log
                                    for (stage_2 in missingInfo) {
                                        if (missingInfoStages.length && stage_2 !== missingInfoStages[0]) {
                                            continue;
                                        }
                                        // console.log(`${stage} is missing info: `, missingInfo[stage]);
                                    }
                                    // Return missingInfoStages or the current stage.
                                    if (missingInfoStages.length > 0) {
                                        return [2 /*return*/, missingInfoStages];
                                    }
                                    return [2 /*return*/, [state.currentStage]];
                            }
                        });
                    }); };
                    return [4 /*yield*/, compute()];
                case 1:
                    currentStages = _a.sent();
                    if (true || (options === null || options === void 0 ? void 0 : options.logMissingInfo)) {
                        console.debug("Missing info: ", missingInfo);
                    }
                    stage = currentStages[0];
                    if (state.isIneligible) {
                        stage = "Ineligible";
                    }
                    else if (state.isUnreachable) {
                        stage = "Unreachable";
                    }
                    else if (state.isOptedOut) {
                        stage = "Opted Out";
                    }
                    return [2 /*return*/, {
                            stage: stage,
                            missingInfo: missingInfo
                        }];
            }
        });
    });
}
exports.computeStage = computeStage;
console.timeEnd("Load stage");

import { CheckCircleIcon, XCircleIcon, XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'

export default function DismissibleAlert(props: {
  message: string,
  dismiss: () => void,
  error?: boolean,
  warning?: boolean
}) {
  let alertIcon = props.error ? <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" /> :
    props.warning ? <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" /> :
      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />;

  return <div className={props.error ? 
    "rounded-md bg-red-50 p-2.5 mt-2" :
    props.warning ? "rounded-md bg-yellow-50 p-2.5 mt-2" :
      "rounded-md bg-green-50 p-2.5 mt-2"}>
    <div className="flex items-center">
      <div className="flex-shrink-0">
        {alertIcon}
      </div>
      <div className="ml-3 mb-0">
        <span className={props.error ? 
          "text-sm font-medium text-red-800" :
          props.warning ? "text-sm font-medium text-yellow-800" :
            "text-sm font-medium text-green-800"}
        >{props.message}</span>
      </div>
      <div className="ml-auto pl-3">
        <div className="-mx-1.5 -my-1.5">
          <button
            type="button"
            style={{boxSizing: 'border-box', borderWidth: '0'}}
            onClick={props.dismiss}
            className={props.error ? 
              "inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50" :
              props.warning ? "inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50" :
                "inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
}
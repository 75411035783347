import { useRef, useCallback } from "react";

export function useDebouncedCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay: number = 250,
  deps: any[] = []
) {
  const timeoutHandler = useRef<NodeJS.Timeout | null>(null);
  const debouncedFn = useCallback(
    (...args: any[]) => {
      if (timeoutHandler.current) clearTimeout(timeoutHandler.current);

      timeoutHandler.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay, ...deps]
  );
  return debouncedFn;
}

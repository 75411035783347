import React from 'react';
import { useToast } from "@aidkitorg/component-library";
import { useLocalizedStrings } from '../Localization';

export function ClearVirtualFileSystemButton() {
  const L = useLocalizedStrings();
  const { toast } = useToast();

  const test = L.offline.confirm_delete_files_test;

  async function blowItAway() {
    const result = prompt(L.offline.confirm_delete_files.replace("$n", test));
    if (result?.toLowerCase() === test.toLowerCase()) {
      const dir = await navigator.storage.getDirectory();
      toast({
        description: L.offline.notify_delete_all_data
      })
      await dir.remove();
      toast({
        description: L.offline.notify_data_deleted
      })
    } else {
      toast({
        description: L.offline.notify_data_delete_cancelled
      })
    }
  }

  return (<a onClick={blowItAway} className="cursor-pointer text-blue-500">{L.offline.clear_files}</a>);
}

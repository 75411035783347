export function openWindowWithLoadingContent() {
  let win = window.open();
  if (!win) return win;

  win.document.title = "Loading...";

  // Simple HTML with inline styling
  const html = `
        <!DOCTYPE html>
        <html style="height: 100%; margin: 0; display: flex; align-items: center; justify-content: center; background-color: #f4f4f4;">
        <head>
            <title>Loading</title>
        </head>
        <body style="font-family: Arial, sans-serif; color: #333; text-align: center; margin: 0;">
            <div>
                <h1 style="font-size: 24px;">Loading...</h1>
                <p>We are pulling an applicant from the queue.. please wait</p>
                <div style="border-top: 3px solid #f3f3f3; border-right: 3px solid #f3f3f3; border-bottom: 3px solid #f3f3f3; border-left: 3px solid #3498db; border-radius: 50%; width: 40px; height: 40px; animation: spin 2s linear infinite;"></div>
            </div>
        </body>
        </html>
        <style>
            @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
            }
        </style>
    `;

  // Write the HTML directly to the new window document
  win.document.write(html);
  win.document.close(); // close document stream
    
  return win;
}
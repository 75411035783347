import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useAPIPost, useAPIUpload, usePost } from "./API";
import { ConfigurationContext } from "./Context";
import { useLocalizedStrings } from "./Localization";
import { SpacedSpinner } from "./Util";

function AdminFunctionsPage() {
  const L = useLocalizedStrings();
  const [value, setValue] = useState("");
  const [encryptedValue, setEncryptedValue] = useState("");
  const [hashValue, setHashValue] = useState("");
  const [generationResult, setGenerationResult] = useState("");
  const config = useContext(ConfigurationContext);

  const first = (config.lookups?.split(",") || [''])[0];
  const [lookupKey, setLookupKey] = useState(first);

  const date = new Date();

  const [month, setMonth] = useState((date.getMonth() + 1).toString());
  const [year, setYear] = useState(date.getFullYear().toString()); 

  const [generating, setGenerating] = useState(false);

  const encrypt = usePost("/admin/encrypt");
  const generate_org_payments = useAPIPost("/generate_organization_payments");

  const getCategories = usePost('/income/plaid/categories');

  const months = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

  const years = ['2022', '2021', '2020'];
  
  async function doEncryption() {
    // Take value and encrypt it using our encrypt api
    const result = await encrypt({ text: value });

    console.log("Got result: ", result);
    if (result && result.encrypted) {
      console.log("Setting encrypted value to ", result.encrypted);
      setEncryptedValue(result.encrypted);
      setHashValue(result.hashed);
    }
  }

  useEffect(() => {
    const first = (config.lookups?.split(",") || [''])[0];
    setLookupKey(first);
  }, [config])

  async function doGeneration() {
    setGenerating(true);
    const result = await generate_org_payments({ month, year });

    if (result && result.status) {
      setGenerationResult(result.status + ": " + result.message);
    } else if (result && result.error) {
      setGenerationResult(result.error + ": " + result.message);
    }

    setGenerating(false);
  }

  const upload = useAPIUpload("/upload_lookup", (progress: number) => {
    console.log(progress);
  });

  const fileInput = useRef(null as any);
  const fileInputLabel = useRef(null as any);
  async function doUpload(files?: any) {
    const result = await upload(files, {
      kind: lookupKey,
    });
    if (result && result.urls) {
      console.log(fileInput.current);
      fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
      console.log(result.urls);
    }
  }

  return (
    <>
      <div className="p-5">
        <h2>{L.admin.number_encryption}</h2>
        <Form>
          <Form.Group controlId="formForNumberEncryption">
            <Form.Label>{L.admin.enter_a_number}</Form.Label>
            <Form.Control type="text" placeholder="12345678910"
              onChange={(e) => setValue(e.target.value)} />
          </Form.Group>
          <Button variant="primary" onClick={() => doEncryption()}>
            {L.admin.do_encryption}
          </Button>
        </Form>
        {encryptedValue && hashValue && (
          <>
            <small>{encryptedValue}</small>
            <br/>
            <small>hashed: {hashValue}</small>
          </>
        )}
      </div>
      <div className="mt-4 p-5">
        <h2>{L.admin.generate_org_payments}</h2>
        <Form>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formForPaymentGeneration">
                <Form.Label htmlFor="month" >{L.admin.invoices.month}</Form.Label>
                <Form.Control id="month" as="select" custom
                  defaultValue={month}
                  onChange={e => setMonth(e.target.value)}>
                  {months.map((m: string, i: number) => {
                    return (<option value={i+1}>{m}</option>)
                  })}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group>
                <Form.Label htmlFor="year">{L.admin.invoices.year}</Form.Label>
                <Form.Control id="year"
                  as="select" custom
                  defaultValue={year}
                  onChange={e => setYear(e.target.value)}>
                  {years.map((y: any) => {
                    return (<option value={y}>{y}</option>);
                  })}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <Button variant="primary" onClick={() => doGeneration()}>
            {generating && <SpacedSpinner />}
            {L.admin.do_generation}
          </Button>
        </Form>
        {generationResult && (
          <>
            <b>{generationResult}</b>
          </>
        )}
      </div>
      <div className="mt-4 p-5 mb-4">
        <h2>Upload Lookup Tables</h2>
        <Form>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formForPaymentGeneration">
                <Form.Label htmlFor="key">Lookup Key</Form.Label>
                <Form.Control id="key" as="select" custom
                  defaultValue={lookupKey}
                  onChange={e => setLookupKey(e.target.value)}>
                  {(config.lookups?.split(",") || []).map((m: string, i: number) => {
                    return (<option value={i+1}>{m}</option>)
                  })}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group>
                <Form.Label>{L.questions.attachment.file_too_big}</Form.Label>
                <div className="custom-file">
                  <input
                    multiple
                    className="custom-file-input"
                    id={"fileupload"}
                    onChange={async (e) => {
                      if (e.target.files && e.target.files.length === 0) {
                        fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
                      } else {
                        if (e.target.files) {
                          doUpload(Array.from(e.target.files));
                          fileInputLabel.current.innerHTML = L.questions.attachment.choose_files;
                          alert('Uploaded')
                        }
                      }
                    }}
                    type="file"
                  />
                  <label
                    ref={fileInputLabel}
                    className={"custom-file-label"}
                    htmlFor={"fileupload"}
                  >
                    {L.questions.attachment.choose_files}
                  </label>
                </div>
              </Form.Group>
            </div>
          </div>
        </Form>
        <div>
          <h2>Plaid Categories</h2>
          <button onClick={async () => {
            let res = await getCategories({});
            console.log(res);
          }}>Click and check console</button>
        </div>
      </div>
    </>
  );
}

export default AdminFunctionsPage; 
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.streamToBuffer = void 0;
var streamToBuffer = function (stream) {
    return new Promise(function (resolve, reject) {
        var chunks = [];
        stream.on("data", function (chunk) { return chunks.push(chunk); });
        stream.on("error", reject);
        stream.on("end", function () { return resolve(Buffer.concat(chunks)); });
    });
};
exports.streamToBuffer = streamToBuffer;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App, { browserHistory } from "./App";
import * as Sentry from "@sentry/react";
import { VERSION } from "./Version";
import { dsn } from './Sentry';
import { createRoot } from 'react-dom/client';

const host = window.location.host;
let environment = 'production';
if (host?.includes('local.dev') || host?.includes('goat-mamba') || host?.includes('.local')) {
  environment = 'development';
}

Sentry.init({
  dsn,
  debug: false,
  environment,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({
    history: browserHistory
  })],
  tracesSampleRate: 1.0,
  release: "aidkit@" + VERSION
});

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<App />);

import { DataDictionary as DataDictionaryType } from "@aidkitorg/types/lib/survey";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import Papa from "papaparse";
import { useState } from "react";
import { usePost } from "../API";
import { BUTTON_CLASS, SpacedSpinner } from "../Util";
import { useToast } from "@aidkitorg/component-library";

export function DataDictionary(props: { component: DataDictionaryType }) {
  const getDataDictionary = usePost('/survey/get_data_dictionary');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { toast } = useToast();

  const handleDataDictionaryDownload = async () => {
    setIsLoading(true);

    await (async () => {
      const dataDictionary = await getDataDictionary({
        limitToSubsurvey: props.component.limitToSubsurvey,
        excludeFields: props.component.excludeFields,
        includeComputations: props.component.includeComputations,
      });

      if (dataDictionary.error) {
        // The API handler will toast this error for us
        return;
      }

      let blob: Blob;
      // Create a new anchor element
      const a = document.createElement('a');

      if (props.component.type === 'json') {
        // Stringify your JSON data.
        const jsonData = JSON.stringify(dataDictionary.rows, null, 2); // `null` and `2` are for formatting with 2-space indentation.

        blob = new Blob([jsonData], { type: 'application/json' });
        a.download = 'dataDictionary.json';
      } else if (props.component.type === 'csv') {
        // Convert JSON to csv.
        const csvData = Papa.unparse(dataDictionary.rows, { columns: dataDictionary.columns });

        blob = new Blob([csvData], { type: 'text/csv' });
        a.download = 'dataDictionary.csv';
      } else {
        toast({
          description: 'Unrecognized DataDictionary type' + props.component.type,
          variant: 'error'
        });
        return;
      }

      const url = window.URL.createObjectURL(blob);

      // Set the href to the object URL and initiate download.
      a.href = url;

      // This is necessary as some browsers won't support direct .click() on the element without appending it to the DOM.
      document.body.appendChild(a); // Append anchor to body.
      a.click();
      document.body.removeChild(a); // Remove anchor from body after download starts.

      // It's important to revoke the object URL to avoid memory leaks.
      window.URL.revokeObjectURL(url);
    })();

    setIsLoading(false);
  }

  return <div className="my-2">
    <button className={BUTTON_CLASS}
      onClick={handleDataDictionaryDownload}>
      Get Data Dictionary {props.component.limitToSubsurvey} ( {props.component.type} )
      <span className="ml-2">
        <ArrowDownOnSquareIcon width="20" height="20" className="inline" />
      </span>
    </button>
    {isLoading && <SpacedSpinner />}
  </div>
}

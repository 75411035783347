import React, { useContext, useMemo } from "react";
import Form from "react-bootstrap/Form";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";

import { languageContent, safeParse, useMarkdown } from "../Util";
import InterfaceContext from "../Context";

function RankingQuestion(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const rawMetadata = props["Metadata"]!;
  const metadata = useMemo(() => safeParse(rawMetadata), [rawMetadata]);
  const numChoices = metadata['num_choices'];

  // props.info[props["Target Field"]!] will return json array of options
  const name = props["Target Field"]!;
  const fields = generateFields();

  const content = useMarkdown(props[languageContent(context.lang)]);

  function setRank(rank: number, item: string) {
    // Parse the JSON field currently in existence:
    let rankings = [];
    try{
      rankings = JSON.parse(props.info[name] || "[]");
    } catch(e){
      console.log("props.info[name] not set properly: ", props.info[name]);
    }
    
    // Check if key already selected, if so, unselect it!
    if(rankings.includes(item)){
      rankings[rankings.indexOf(item)] = null;
    }
    rankings[rank] = item;
    props.setInfoKey(props["Target Field"]!, JSON.stringify(rankings), true, false);
  }

  function generateFields(): any[] {
    const localFields = [];

    const options = props["Options (if relevant)"];

    let choices = JSON.parse(props.info[name] || "[]");
    
    for(let i=0; i < numChoices; i++){
      // available options will change for each iterator
      localFields.push(
        <li className="mt-2" key={name + '_li_' + i}>
          <Form.Control as="select" custom
            onChange={(e: any) => setRank(i, e.target.value)}
            key={name + '_' + i}
            name={name + '_' + i}
            value={choices[i] || ""}>
            <option value=""></option>
            {options?.map((o) => {
              return (
                <option value={o["Name"]} key={o["Name"]}>
                  {(o as any)[languageContent(context.lang).replace('Content','Text')] || o['English Text']}
                </option>
              );
            })}
          </Form.Control>
        </li>
      );
    }

    return localFields;
  }

  return (
    <Form.Group>
      {content}
      <ol className='list-decimal pl-10'>
        {fields}
      </ol>
    </Form.Group>
  );
}

export { RankingQuestion };

import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from "@aidkitorg/component-library";
import { OfflineSyncContext } from '../Context';
import { useDebouncedCallback } from '../Hooks/Debounce';
import { useLocalizedStrings } from '../Localization';
import { getOrCreateHttpCache } from '../offline';
import { SyncStatus, toApplicant } from '../offline/routes';
import FlyoutMenu from './FlyoutMenu';

export default function OfflineChangeCounter(props: { size?: number }) {

  const { uid } = useParams<{ uid?: string }>();
  const { statuses } = useContext(OfflineSyncContext);
  const [details, setDetails] = useState<any[]>([]);
  const [pendingChanges, setPendingChanges] = useState<number>(0);
  const L = useLocalizedStrings();
  const { toast } = useToast();
  
  const updateCount = useDebouncedCallback(() =>
    setPendingChanges(Object.values(statuses)
      .filter(s => s.status === SyncStatus.Ahead && (!uid || uid === s.id))
      .reduce((count, cur) => ((cur as any).changes + count), 0)),
  );

  useEffect(() => updateCount(), [statuses]);

  async function getDetails() {
    const applicantDirs = await getOrCreateHttpCache('applicants');

    const changeFiles = await Promise.all(
      (await Promise.all(
        (await applicantDirs.dirs())
          .filter(d => !uid || d.name === uid)
          .map(async a =>
            (await a.as(toApplicant))
              .changes.get())))
        .flat()
        .map(async c => ({ ...JSON.parse(await c.file.text()), file: c.file.name }))
    );
    setDetails(changeFiles);
  }

  async function sync() {

    const registrations = await navigator.serviceWorker.getRegistrations();

    let syncing = false;
    // typically there is only 1 worker even registered.
    // during worker update events (e.g. new deployments),
    // there may be multiple registrations present, but only 1 will
    // ever be active.
    for (const worker of registrations) {
      try {
        worker.active?.postMessage({ action: 'forceSync' });
        syncing = true;
      } catch (err) {
        console.error('failed to register sync', err);
      }
    }

    if (syncing) {
      toast({
        description: "Syncing changes with the server..."
      })
    }
  }


  return (
    <div className="inline-flex isolate">
      <button onClick={sync} className="relative inline-flex items-center rounded-l-md border border-transparent px-3 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-blue-400 hover:text-gray-500 bg-blue-600 text-white flex-wrap">
        <span>{L.offline.sync}</span>
      </button>
      <FlyoutMenu
        popperProps={{
          async onClick() { await getDetails() },
          disabled: pendingChanges < 1
        }}
        className={"-ml-1 nline-flex items-center rounded-r-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-blue-400 hover:text-gray-500 bg-blue-600 text-white" + (pendingChanges ? '' : ' bg-gray-500 hover:bg-gray-500')}
        label={
          <div className="relative">
            <CloudArrowUpIcon height={props.size ?? 20} width={props.size ?? 20} />
            <span className="ml-2.5 mt-1 mb-2">{pendingChanges}</span>
          </div>
        }
        items={<>
          <table className="table-auto min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <td>Applicant</td>
                <td>Key</td>
                <td>Value</td>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {details.flatMap((c, i) => Object.entries<any>(c.changedKeys).map(([key, value]) => (
                <tr key={i + key} className="px-3 py-4 text-sm text-gray-500 max-w-md break-word">
                  <td><a target="_blank" href={`filesystem:${window.location.protocol}//${window.location.host}/temporary/applicants/${c.uid}/changes/${c.file}`}>{c.uid}</a></td>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              )))}
            </tbody>
          </table>
        </>}
      />
    </div>
  );
}

import React from "react";
import Alert from "react-bootstrap/Alert";
import { useLocalizedStrings } from "../Localization";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";
import { BaseTextEntry } from "./TextEntry";

function CorrectionRequest(props: QuestionProps) {
  return React.createElement(BaseTextEntry, {
    ...props,
    sanitizer: (v) => v,
    validator: (v) => v.length > 0,
    textarea: true,
  });
}

function CorrectionResolver(props: QuestionProps) {
  const L = useLocalizedStrings();
  
  if (!props.info[props["Target Field"]!]) return <></>;

  return (
    <div>
      <Alert variant="danger">
        {L.questions.correction.in_order_to_proceed}
      </Alert>
    </div>
  );
}

export { CorrectionRequest, CorrectionResolver };

import React, { useContext, useState } from 'react';
import { useAPI, useDownloader, usePost } from '../API';
import { ClickableButton } from '../Components/Button';
import { DateTimeInput } from '../Components/DateTimeInput';
import { ConfigurationContext } from '../Context';
import { useLocalizedStrings } from '../Localization';
import { useRoboScreenerAPI } from '../RoboScreener';
import { SpacedSpinner } from '../Util';

export function AdminTab(props: { uid: string }) {

  const L = useLocalizedStrings();
  const reports = useAPI('/reports/list_reports?report_type=applicant');
  const download = useDownloader();
  const [downloading, setDownloading] = useState(false);
  const config = useContext(ConfigurationContext);

  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const saveInfo = usePost('/applicant/update');

  async function saveKeyValue() {
    if (key === '') {
      return;
    }
    await saveInfo({ app: props.uid, key, value });
    setKey('');
    setValue('');
  }
    
  // RS Stuff
  const [rsClock, setRSClock] = useState(new Date().toLocaleDateString());
  const [gotUpdates, setGotUpdates] = useState(false); 
  const { applicantUpdates, computing, saveUpdates, refreshRS } = useRoboScreenerAPI({ manualOnly: true, uid: props.uid, computeOptions: { customClockTimestamp: rsClock } });

  // Applicant Reports
  async function downloadApplicantReport(report_name: string) {
    setDownloading(true);
    download('/report/' + encodeURIComponent(report_name) + '/download_report?uid=' + props.uid);
    setDownloading(false);
  }

  return (
    <>
      <div className="mb-2 p-2 bg-gray-50 rounded-md">
        <h3 className="text-xl font-bold">{L.applicant.reports.reports_for_this}</h3>
        {(reports?.reports || []).length <= 0 && <h6>{L.applicant.reports.no_applicant_reports}</h6>}
        <ul>
          {(reports?.reports || []).map((report: any, index: number) => {
            return (
              <li key={`applicant_report_${index}`} className="flex items-center">
                {report.name}
                <div className="ml-2">    
                  <ClickableButton extraClasses="text-xs px-2 py-1 bg-indigo-600 text-white hover:bg-indigo-800" 
                    color="indigo"
                    onClick={() => downloadApplicantReport(report.name)}>
                    {downloading && <SpacedSpinner />}
                    {L.applicant.reports.download}    
                  </ClickableButton>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {(config.roles || '').indexOf('admin') !== -1 && (
        <>
          <hr/>
          <div className="rounded-md p-2 my-2 bg-gray-100">
            <h3 className="text-xl font-bold">Key Override</h3>
            <input placeholder='key' value={key} onChange={(e) => setKey(e.target.value)} />&nbsp;
            <input placeholder='value' value={value} onChange={(e) => setValue(e.target.value)} />
            <ClickableButton extraClasses="my-2" 
              color="indigo" onClick={saveKeyValue}>Save</ClickableButton>
          </div>
          <div className="rounded-md my-2 p-2 bg-white-50">
            <h3 className="text-xl font-bold">{L.admin.computations.title}</h3>

            <div className="my-1 rounded-md p-2 border-dashed border-2 border-indigo-200">
              <DateTimeInput id={'rs-custom-clock-input'} label={"Customize Clock (Optional)"}
                init={'hour'}
                includeTime={true}
                includeReset={true}
                hideLabels={true}
                setValue={setRSClock} />
            </div>
            <div className="my-1 mt-3">
              {applicantUpdates && (Object.keys(applicantUpdates[props.uid] || {}) || []).length > 0 ? 
                <>
                  <ClickableButton color="blue" onClick={() => saveUpdates() } disabled={computing}>
                    {computing && <SpacedSpinner />}{L.admin.computations.save_these_updates}
                  </ClickableButton>&nbsp;
                  <ClickableButton color="gray" onClick={async () => { await refreshRS(); setGotUpdates(true); }} disabled={computing}>
                    {computing && <SpacedSpinner />}{L.admin.computations.get_updates}
                  </ClickableButton>
                </> : 
                <ClickableButton color="indigo" onClick={async () => { 
                  console.log("Refreshing RS for applicant: " + props.uid);
                  await refreshRS(); setGotUpdates(true);
                }} disabled={computing}>
                  {computing && <SpacedSpinner />}{L.admin.computations.get_updates}
                </ClickableButton>
              }
            </div>
            <div className="flex-1 pt-1.5 m-2 divide-y grid grid-cols-1 divide-black">
              {gotUpdates && Object.keys(applicantUpdates[props.uid] || {}).length === 0 ? 
                <div className="text-sm">No computed updates for this applicant</div> : <></>}
              {(Object.keys(applicantUpdates[props.uid] || {}) || []).map((key: string) => {
                return <div className="mt-1 w-full h-fit bg-gray-100 break-words p-2 border-4 rounded-md border-black" key={`rs-update-${key}`}>
                  <h3 className="text-sm text-gray-700 font-bold"><var>{key}</var></h3>
                  <data className="text-gray-900">{applicantUpdates[props.uid][key]}</data>
                </div>
              })}
            </div>
          </div>
        </>)}
    </>
  );
}
